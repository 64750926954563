import React from "react";

const Price = ({ search, searchResults, handlePriceChange }) => {
  return (
    <td>
      <input
        value={search.length === 0 ? "" : searchResults.sellPrice}
        className="form-control"
        readOnly
        style={{ width: "7rem" }}
      />
    </td>
  );
};

export default Price;
