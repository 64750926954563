import "bootstrap/dist/css/bootstrap.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import config from "./components/config/config";

// import Navigation from "./components/navigation";
import CreateUser from "./components/auth/CreateUser";
import Login from "./components/auth/Login";
import Logout from "./components/auth/Logout";
import Header from "./components/HeaderNavSideNav";
import Dashboard from "./components/dashboard";
import ProformaInvoice from "./components/ProformaInvoice";
import ProformaDraftsView from "./components/ProformaDraftsView";
import ProformaInvoices from "./components/ProformaInvoices";
import CreateInvoice from "./components/CreateInvoice";
import InvoicesDrafts from "./components/InvoicesDrafts";
import Invoices from "./components/Invoices";
import ActiveOrders from "./components/ActiveOrders";
import MissingItems from "./components/MissingItems";
import ImportInvoicesExcelTable from "./components/ImportInvoicesExcelTable";

import EditInvoice from "./components/EditInvoice";
import DuplicateInvoice from "./components/DuplicateInvoice";

import EditInvoices from "./components/EditIncoices";

import Bank from "./components/Bank";

import Catalog from "./components/Catalog";
import AddProduct from "./components/AddProduct";
import EditProduct from "./components/EditProduct";
import ImportProductsFromExcel from "./components/ImportProductsFromExcel";

import PlaceAnOrder from "./components/PlaceAnOrder";

import AddCustomer from "./components/AddCustomer";
import ContactsView from "./components/ContactsView";
import ImportExcelContacts from "./components/ImportExcelContacts";
import EditCustomer from "./components/EditCustomer";

import Settings from "./admin/AdminSettings";
import CompanyDetails from "./admin/CompanyDetails";
import InvoiceNumberSetting from "./admin/settings/InvoiceNumberSetting";
import ProformaNumberSetting from "./admin/settings/ProformaNumberSetting";
import QuoteNumberSetting from "./admin/settings/QuoteNumberSetting";
import Footer from "./admin/Footer";
import AddLogo from "./admin/AddLogo";
import TableHeaderBackgroundColor from "./admin/settings/TableHeaderBackgroundColor";
import AlertNoQnty from "./admin/settings/AlertNoQnty";
import AdminSettings from "./admin/settings/AdminSettings";
import POS from "./components/pos/pos";

import "./App.css";
import "./components/navigation.css";
import "./components/grid.css";
import "./background.css";

const defaultSettings = {
  showBtw: true,
  tableHeaderBackgroundColor: "#002c6a",
  companyDetails: {
    companyName: "",
    companyAddress: "",
    companyCity: "",
    companyZipCode: "",
    companyCountry: "",
    companyEmail: "",
    companyPhone: "",
    companyBtwNumber: "",
  },
  proformaNumber: 0,
  alertNoQnty: true,
  invoiceNumber: 0,
  dubbleDiscount: false,
  removeTextForCompanyInInvoiceHeader: false,
  showIdInsteadOfName: false,
};

const addUser = {
  name: "",
  email: "",
  password: "",
};
// ever time load the page, fetch the user data from the server
/* const fetchUserData = async () => {
  const response = await fetch(`${config.URL_PROD}/auth/register`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(addUser),
  });
  const data = await response.json();
  return data;
}; */

function App() {
  const [settings, setSettings] = useState(defaultSettings);
  const fetchSettings = async () => {
    try {
      const response = await fetch(`${config.URL_PROD}/settings`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.ok) {
        const responseJson = await response.json();
        setSettings(...responseJson.data);
        console.log("success fetching settings");
      } else {
        console.error("Error fetching settings:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching settings:", error);
    }
  };
  useEffect(() => {
    fetchSettings();
  }, []);

  useEffect(() => {
    localStorage.setItem("showBtw", JSON.stringify(settings?.showBtw));
    localStorage.setItem(
      "tableHeaderBackgroundColor",
      settings?.tableHeaderBackgroundColor
    );
    localStorage.setItem(
      "companyDetails",
      JSON.stringify(settings?.companyDetails || {})
    );
    localStorage.setItem(
      "proformaNumber",
      JSON.stringify(settings?.proformaNumber)
    );
    localStorage.setItem("alertNoQnty", JSON.stringify(settings?.alertNoQnty));
    localStorage.setItem(
      "invoiceNumber",
      JSON.stringify(settings?.invoiceNumber)
    );
    localStorage.setItem(
      "dubbleDiscount",
      JSON.stringify(settings?.dubbleDiscount)
    );
    localStorage.setItem(
      "removeTextForCompanyInInvoiceHeader",
      JSON.stringify(settings?.removeTextForCompanyInInvoiceHeader)
    );
    localStorage.setItem(
      "showIdInsteadOfName",
      JSON.stringify(settings?.showIdInsteadOfName)
    );

    // footer
    localStorage.setItem("footer", JSON.stringify(settings?.footer || {}));
  }, [settings]);

  return (
    <React.Fragment
      style={{
        position: "relative",
      }}
    >
      <Router>
        {/*   {<Navigation />} */}
        {/* {<HeaderNavSideNav />} */}
        <Header />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/CreateUser" element={<CreateUser />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/Logout" element={<Logout />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/AddCustomer" element={<AddCustomer />} />
          <Route path="/ContactsView" element={<ContactsView />} />
          <Route
            path="/ImportExcelContacts"
            element={<ImportExcelContacts />}
          />
          <Route path="/EditCustomer/:index" element={<EditCustomer />} />
          <Route path="/ProformaInvoice" element={<ProformaInvoice />} />
          <Route path="/ProformaDraftsView" element={<ProformaDraftsView />} />
          <Route path="/ProformaInvoices" element={<ProformaInvoices />} />
          <Route
            path="/CreateInvoice"
            element={<CreateInvoice settings={settings} />}
          />
          <Route path="/InvoicesDrafts" element={<InvoicesDrafts />} />
          <Route path="/Invoices" element={<Invoices />} />
          <Route path="/ActiveOrders" element={<ActiveOrders />} />
          <Route path="/EditInvoice/:id" element={<EditInvoice />} />
          <Route path="/DuplicateInvoice/:id" element={<DuplicateInvoice />} />
          <Route path="/EditInvoices/:index" element={<EditInvoices />} />
          <Route path="/Bank" element={<Bank />} />
          <Route path="/Catalog" element={<Catalog />} />
          <Route path="/AddProduct" element={<AddProduct />} />
          <Route path="/EditProduct/:index" element={<EditProduct />} />
          <Route path="/MissingItems" element={<MissingItems />} />
          <Route path="/PlaceAnOrder" element={<PlaceAnOrder />} />
          <Route
            path="/ImportInvoicesExcelTable"
            element={<ImportInvoicesExcelTable />}
          />
          <Route
            path="/ImportProductsFromExcel"
            element={<ImportProductsFromExcel />}
          />
          <Route path="/CompanyDetails" element={<CompanyDetails />} />
          <Route path="/Settings" element={<Settings />} />
          <Route
            path="/InvoiceNumberSetting"
            element={<InvoiceNumberSetting />}
          />
          <Route
            path="/ProformaNumberSetting"
            element={<ProformaNumberSetting />}
          />
          <Route path="/QuoteNumberSetting" element={<QuoteNumberSetting />} />
          <Route path="/Footer" element={<Footer />} />
          <Route path="/AddLogo" element={<AddLogo />} />
          <Route
            path="/TableHeaderBackgroundColor"
            element={<TableHeaderBackgroundColor />}
          />
          <Route
            path="/AdminSettings"
            element={
              <AdminSettings settings={settings} setSettings={setSettings} />
            }
          />
          <Route path="/AlertNoQnty" element={<AlertNoQnty />} />
          <Route path="/POS" element={<POS settings={settings} />} />
        </Routes>
      </Router>
    </React.Fragment>
  );
}

export default App;
