import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Invoice from "./invoice";
import config from "./config/config";

import styles from "./loading.module.css";

const CreateInvoice = ({
  invoiceDraftData,
  invoiceToEdit,
  invoiceToDuplicate,
}) => {
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState([]);

  // tried before to pass the settings from the parent component but it didn't work (it was creating bugs)
  const fetchSettings = async () => {
    try {
      const response = await fetch(`${config.URL_PROD}/settings`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.ok) {
        const responseJson = await response.json();
        setSettings(...responseJson.data);
        console.log("success fetching settings");
      } else {
        console.error("Error fetching settings:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching settings:", error);
    }
  };
  useEffect(() => {
    fetchSettings();
  }, []);

  if (!localStorage.getItem("token")) {
    window.location.href = "/login";
  }
  const [products, setProducts] = useState([]);

  const fetchProducts = async () => {
    try {
      const response = await fetch(`${config.URL_PROD}/products`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const { count } = data;
        const { products } = data;
        setProducts(products);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    // Fetch the products from the server
    fetchProducts();
  }, []);
  const navigate = useNavigate();
  const invoiceNumber = JSON.parse(localStorage.getItem("invoiceNumber") || 1);

  async function generateInvoiceNumberWithPrefix(
    invoiceNumber,
    serverEndpoint
  ) {
    // Define the prefix for the invoice number
    // split the invoiceNumber by the first digit even if it is  0
    const prefix = invoiceNumber.toString().split(/(\d)/)[0] || "";

    // Determine the number of leading zeros in the existing invoice number
    const leadingZerosCount = invoiceNumber.toString().match(/^0*/)[0].length;

    // Extract only the numeric part of the invoiceNumber
    const numericPart = invoiceNumber.replace(/[^\d]/g, ""); // This removes all non-digit characters

    // Parse the numeric part as an integer
    const invoiceNumberDigits = parseInt(numericPart, 10);

    let invoiceNumberWithPrefix = "";

    if (!isNaN(invoiceNumberDigits)) {
      // Increment the invoice number
      const incrementedNumber = invoiceNumberDigits + 1;

      // Pad the incremented number with leading zeros
      const paddedNumber = incrementedNumber
        .toString()
        .padStart(leadingZerosCount + incrementedNumber.toString().length, "0");

      // Form the complete invoice number dynamically
      invoiceNumberWithPrefix = `${prefix}${paddedNumber}`;

      console.log("invoiceNumberWithPrefix", invoiceNumberWithPrefix);

      // Send the generated invoice number to the server
      try {
        const response = await fetch(serverEndpoint, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ invoiceNumber: invoiceNumberWithPrefix }),
        });

        if (response.ok) {
          console.log("Invoice number sent to the server successfully!");
          setLoading(false);
        } else {
          console.error(
            "Failed to send invoice number to the server:",
            response.statusText
          );
        }
      } catch (error) {
        console.error("Error sending invoice number to the server:", error);
      }
    } else {
      console.error("Failed to parse invoiceNumber:", invoiceNumber);
    }

    return invoiceNumberWithPrefix;
  }

  if (loading) {
    return <div className={styles.loadingPage}>Loading...</div>;
  }
  /* const finalizeInvoice = () => {
/*     if (invoiceDraftData) {
      const getAllInvoices =
        JSON.parse(localStorage.getItem("invoicesDrafts")) || [];
      const getInvoice = JSON.parse(localStorage.getItem("invoice")) || {};

      // Add the proformaNumber to be saved also as id
      getInvoice.id = invoiceDraftData.id;

      const items = getInvoice.items;

      // Check if the invoice has items
      if (!items?.length) {
        alert("Please add items to the invoice");
        return;
      }

      // Determine the invoice status
      const hasItems = items?.some((item) => {
        const product = products.find((product) => product.id === item.id);
        return product.quantity < item.quantity;
      });

      const status = hasItems
        ? "waiting-for-missing-items"
        : "Pending-fulfillment";

      // Update the invoice with the status and finalized date
      getInvoice.status = status;

      getInvoice.payments = [{ amount: 0, date: "", paymentMethod: "" }];

      // Update the product quantities in the catalog
      const updatedCatalog = products.map((catalogItem) => {
        const editItem = invoiceDraftData.items.find(
          (item) => item.id === catalogItem.id
        );

        return {
          ...catalogItem,
          quantity: catalogItem.quantity + (editItem ? editItem.quantity : 0),
          papular: catalogItem.papular,
        };
      });

      localStorage.setItem("products", JSON.stringify(updatedCatalog));

      // Remove the edited draft from the drafts
      const updatedDraftInvoices = getAllInvoices.filter(
        (invoice) => invoice.id !== invoiceDraftData.id
      );

      // Update the local storage with the modified drafts
      localStorage.setItem(
        "invoicesDrafts",
        JSON.stringify(updatedDraftInvoices)
      );

      // Navigate to the invoices page
      navigate("/invoices");
    }
    if (invoiceToEdit) {
      // console.log(invoiceToEdit)
      const getAllInvoices = JSON.parse(localStorage.getItem("invoices")) || [];
      const getInvoice = JSON.parse(localStorage.getItem("invoice")) || "{}";

      // add the proformaNumber to been save also as id
      getInvoice.id = invoiceToEdit.id;

      // const products = JSON.parse(localStorage.getItem("products") || "[]");
      const items = getInvoice.items;

      // Check if the invoice has items
      if (!items?.length) {
        alert("Please add items to the invoice");
        return;
      }

      // Determine the invoice status
      const hasItems = items?.some((item) => {
        const product = products.find((product) => product.id === item.id);
        return product.quantity < item.quantity;
      });

      const status = hasItems
        ? "waiting-for-missing-items"
        : "Pending-fiilment";

      // Update the invoice with the status and finalized date
      getInvoice.status = status;
      getInvoice.payments = [{ amount: 0, date: "", paymentMethod: "" }];
      /*   console.log(invoiceToEdit.items); */
  // console.log(getInvoice);

  /*  const updatedCatalog = products.map((catalogItem) => {
        const editItem = invoiceToEdit.items.find(
          (item) => item.id === catalogItem.id
        );
        const getItem = getInvoice.items.find(
          (item) => item.id === catalogItem.id
        );

        return {
          ...catalogItem,
          quantity:
            catalogItem.quantity +
            (editItem ? editItem.quantity : 0) -
            (getItem ? getItem.quantity : 0),
          papular: catalogItem.papular,
        };
      });

      /* console.log(updatedCatalog); */
  /*   localStorage.setItem("products", JSON.stringify(updatedCatalog));

      // update the invoice in the local storage
      const updatedInvoices = getAllInvoices.map((invoice) => {
        if (invoice.id === invoiceToEdit.id) {
          return getInvoice;
        }
        return invoice;
      });
      /*  console.log(updatedInvoices); */
  /* localStorage.setItem("invoices", JSON.stringify(updatedInvoices));
    } else {
      const invoiceNotParsed = localStorage.getItem("invoice") || "{}";
      const invoice = JSON.parse(invoiceNotParsed);

      // Retrieve the value of costumerAdded from local storage
      const costumerAdded = JSON.parse(localStorage.getItem("costumerAdded"));

      // check if the invoice has a customer
      if (!costumerAdded) {
        alert(`Please fill in the customer details`);
        return;
      } else {
        // add the proformaNumber to been save also as id
        invoice.id = invoiceNumber;
        // const products = JSON.parse(localStorage.getItem("products") || "[]");
        const items = invoice.items;

        // Check if the invoice has items
        if (!items?.length) {
          alert("Please add items to the invoice");
          return;
        }

        //

        // Determine the invoice status
        const hasItems = items?.some((item) => {
          const product = products.find((product) => product.id === item.id);
          return product.quantity < item.quantity;
        });

        if (hasItems) {
          console.log("There are missing items");
          // remove User field from the items
          items.forEach((item) => {
            delete item.User;
          });
        }

        /* console.log(hasItems); */
  /*  const status = hasItems
          ? "waiting-for-missing-items"
          : "Pending-fiilment";

        // Update the invoice with the status and finalized date
        invoice.status = status;
        invoice.dateFinalized = new Date().toLocaleDateString();
        invoice.payments = [{ amount: 0, date: "", paymentMethod: "" }];

        console.log(invoice);
        // Save the invoice to local storage with key 'invoices'
        localStorage.setItem(
          "invoices",
          JSON.stringify([
            ...JSON.parse(localStorage.getItem("invoices") || "[]"),
            invoice,
          ])
        );
        localStorage.setItem("costumerAdded", JSON.stringify(false));

        // Clear the local storage invoice to an empty object
        localStorage.setItem("invoice", JSON.stringify({}));

        // Increment the invoice number
        localStorage.setItem(
          "invoiceNumber",
          JSON.stringify(invoiceNumber + 1)
        );

        // Update the product quantities in the catalog
        const catalog = products;
        const updatedCatalog = catalog.map((item) => {
          const invoiceItem = items.find(
            (invoiceItem) => invoiceItem.id === item.id
          );
          if (invoiceItem) {
            return {
              ...item,
              quantity: item.quantity - invoiceItem.quantity,
              papular: item.papular + invoiceItem.quantity,
            };
          }
          return item;
        });
        /*     console.log(updatedCatalog); */
  /* localStorage.setItem("products", JSON.stringify(updatedCatalog));
      }
    } */
  // go to invoices page
  /*  window.location.href = "/invoices"; */
  /*  navigate("/invoices"); */
  /* }; */

  const finalizeInvoice = async () => {
    // check the a customer is added to the invoice
    const costumerAdded = JSON.parse(localStorage.getItem("costumerAdded"));
    if (costumerAdded !== true) {
      alert("Please fill in the customer details");
      return;
    }

    // check for < 1 quantity in the invoice
    const items = JSON.parse(localStorage.getItem("invoice")).items;

    if (items.some((item) => item.quantity < 1)) {
      alert("Please add a quantity greater than 0");
      return;
    }

    const getInvoice = JSON.parse(localStorage.getItem("invoice")) || {};
    const invoiceNumber = JSON.parse(localStorage.getItem("invoiceNumber"));
    console.log(invoiceNumber);

    // Transform getInvoice to match your Mongoose schema
    const transformedInvoice = {
      proformaNumber: getInvoice.proformaNumber,
      companyDetails: {
        companyName: getInvoice.companyDetails.companyName,
        companyAddress: getInvoice.companyDetails.companyAddress,
        companyCity: getInvoice.companyDetails.companyCity,
        companyZipCode: getInvoice.companyDetails.companyZipCode,
        companyCountry: getInvoice.companyDetails.companyCountry,
        companyEmail: getInvoice.companyDetails.companyEmail,
        companyPhone: getInvoice.companyDetails.companyPhone,
        companyBtwNumber: getInvoice.companyDetails.companyBtwNumber,
      },
      selectedResultCustomer: {
        _id:
          getInvoice.selectedResultCustomer?._id ||
          getInvoice.selectedResultCustomer.id,
        name: getInvoice.selectedResultCustomer.name,
        saveAs: getInvoice.selectedResultCustomer.saveAs,
        clientNumber: getInvoice.selectedResultCustomer.clientNumber,
        email: getInvoice.selectedResultCustomer.email,
        phone: getInvoice.selectedResultCustomer.phone,
        deliveryAddress: getInvoice.selectedResultCustomer.deliveryAddress,
        transportCode: getInvoice.selectedResultCustomer.transportCode,
        discount: getInvoice.selectedResultCustomer.discount,
        btwAmount: getInvoice.selectedResultCustomer.btwAmount,
        btwNumber: getInvoice.selectedResultCustomer.btwNumber,
        city: getInvoice.selectedResultCustomer.city,
        country: getInvoice.selectedResultCustomer.country,
        notes: getInvoice.selectedResultCustomer.notes,
        position: getInvoice.selectedResultCustomer.position,
        contactPerson: getInvoice.selectedResultCustomer.contactPerson,
        deleted: getInvoice.selectedResultCustomer.deleted,
      },
      items: getInvoice.items.map((item) => ({
        id: item.id,
        name: item.name,
        description: item.description,
        transportCode: item.transportCode,
        sellPrice: item.sellPrice,
        discount: item.discount,
        quantity: item.quantity,
        btw: item.btw || 0,
        totalDiscounted: item.totalDiscounted,
        total: item.total,
      })),
      subTotal: getInvoice.subTotal,
      totalAmount: getInvoice.totalAmount,
      discountedPriceTotal: getInvoice.discountedPriceTotal,
      btw: getInvoice.btw,
      date: getInvoice.date,
      id: invoiceNumber,
      status: getInvoice.status,
      dateFinalized: getInvoice.dateFinalized,
      payments: getInvoice?.payments?.map((payment) => ({
        amount: payment.amount,
        date: payment.date,
        paymentMethod: payment.paymentMethod,
      })),
      expDate: getInvoice?.expDate,
      date: getInvoice?.date,
      paymentMethod: getInvoice?.paymentMethod,
      remarks: getInvoice?.remarks,
      selectedCurrency: getInvoice?.selectedCurrency,
    };

    // Update the catalog with updated quantities of the products in invoice if the invoice is not edited
    const updatedCatalog = products
      .map((product) => {
        const invoiceItem = getInvoice.items.find(
          (invoiceItem) => invoiceItem.id === product.id
        );
        if (invoiceItem) {
          const updatedQuantity = product.quantity - invoiceItem.quantity;
          const updatedPapular = product.papular + invoiceItem.quantity;
          // Check if quantities have changed
          if (
            updatedQuantity !== product.quantity ||
            updatedPapular !== product.papular
          ) {
            return {
              ...product,
              quantity: updatedQuantity,
              papular: updatedPapular,
            };
          }
        }
        return null; // Mark products that don't need updating as null
      })
      .filter(Boolean); // Filter out null values

    if (!invoiceToEdit && !invoiceDraftData && !invoiceToDuplicate) {
      setLoading(true);

      // Update the product quantities in the catalog with a single fetch operation
      try {
        // Construct the array of updated product data
        const updatedProductsData = updatedCatalog.map((product) => ({
          ...product,
          id: Number(product.id),
        }));

        const response = await fetch(`${config.URL_PROD}/products`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(updatedProductsData),
        });

        if (!response.ok) {
          throw new Error("Failed to update products");
        }

        console.log("Products updated successfully");

        // Send invoice data
        const invoicesResponse = await fetch(`${config.URL_PROD}/invoices`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(transformedInvoice),
        });

        if (!invoicesResponse.ok) {
          throw new Error("Failed to send invoice data");
        }

        // Process response data for invoices
        const invoiceCreationData = await invoicesResponse.json();

        const invoiceNumber = JSON.parse(localStorage.getItem("invoiceNumber"));
        const id = settings._id;

        // If the invoice was successfully created, increment the invoice number
        if (settings) {
          const serverEndpoint = `${config.URL_PROD}/settings/${id}`;
          const generatedInvoiceNumber = await generateInvoiceNumberWithPrefix(
            invoiceNumber,
            serverEndpoint
          );

          localStorage.setItem(
            "invoiceNumber",
            JSON.stringify(generatedInvoiceNumber)
          );
        } else {
          console.error("Settings not found!");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }

    const getInvoicedBtw = (btw) => {
      return btw === "NaN" ? 0 : btw;
    };

    if (invoiceToEdit) {
      setLoading(true);
      const invoicesChanges = {
        ...transformedInvoice,
        id: invoiceToEdit.id,
        btw: getInvoicedBtw(transformedInvoice.btw),
      };

      const updatedCatalog = products
        .filter((product) => {
          // Check if the product is found in either invoiceToEdit.items or getInvoice.items
          return (
            invoiceToEdit.items.some((item) => item.id === product.id) ||
            getInvoice.items.some((item) => item.id === product.id)
          );
        })
        .map((product) => {
          const editItem = invoiceToEdit.items.find(
            (item) => item.id === product.id
          );

          const getItem = getInvoice.items.find(
            (item) => item.id === product.id
          );

          return {
            ...product,
            quantity:
              product.quantity +
              (editItem ? editItem.quantity : 0) -
              (getItem ? getItem.quantity : 0),
            papular: product.papular,
          };
        });

      // Construct the array of updated product data
      const updatedProductsData = updatedCatalog.map((product) => ({
        ...product,
        id: Number(product.id),
      }));

      try {
        // Send a single fetch request to update products and the invoice
        const productResponse = await fetch(`${config.URL_PROD}/products`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(updatedProductsData),
        });

        if (!productResponse.ok) {
          throw new Error("Failed to update products");
        }

        console.log("Products updated successfully");

        const invoiceResponse = await fetch(
          `${config.URL_PROD}/invoices/${invoicesChanges.id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify(invoicesChanges),
          }
        );

        if (!invoiceResponse.ok) {
          throw new Error("Failed to update invoice");
        }

        setLoading(false);
        console.log("Invoice updated successfully");

        // Clear the local storage invoice to an empty object
        localStorage.setItem("invoice", JSON.stringify({}));
        localStorage.setItem("costumerAdded", "false");

        // Redirect to the invoices page
        navigate("/invoices");
      } catch (error) {
        console.error("Error updating products and invoice:", error);
        // Handle error, display message to the user, or roll back changes if necessary
      }
    }

    if (invoiceToDuplicate) {
      setLoading(true);
      // Update the product quantities in the catalog
      try {
        const response = await fetch(`${config.URL_PROD}/products`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(updatedCatalog),
        });

        const responseData = await response.json();
        if (response.ok) {
          console.log(
            `Products updated successfully` /* ,
              responseData */
          );
        } else {
          console.error(`Failed to update products`, responseData);
        }

        const response2 = await fetch(`${config.URL_PROD}/invoices`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(transformedInvoice),
        });

        if (response2.ok) {
          console.log("Invoice updated successfully");

          const invoiceNumber = JSON.parse(
            localStorage.getItem("invoiceNumber")
          );
          const id = settings._id;

          // If the invoice was successfully created, increment the invoice number
          if (settings) {
            const serverEndpoint = `${config.URL_PROD}/settings/${id}`;
            const generatedInvoiceNumber =
              await generateInvoiceNumberWithPrefix(
                invoiceNumber,
                serverEndpoint
              );

            localStorage.setItem(
              "invoiceNumber",
              JSON.stringify(generatedInvoiceNumber)
            );
            // Clear the local storage invoice to an empty object
            localStorage.setItem("invoice", JSON.stringify({}));

            localStorage.setItem("costumerAdded", "false");

            // Redirect to the invoices page
            navigate("/invoices");
            setLoading(false);
          } else {
            console.error("Settings not found!");
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const saveToDraft = () => {
    alert("Under maintnens");
    /*   // check if the invoice was edited then alert the user to finalize the invoice instead of saving it as draft
    if (invoiceToEdit) {
      const confirm = window.confirm(
        "Please finalize the invoice instead of saving it as draft" +
          " Do you want to finalize the invoice?"
      );
      if (confirm) {
        finalizeInvoice();
      } else {
        return;
      }
    } else if (invoiceDraftData) {
      const getAllInvoices =
        JSON.parse(localStorage.getItem("invoicesDrafts")) || [];
      const getInvoice = JSON.parse(localStorage.getItem("invoice")) || {};

      // Add the proformaNumber to be saved also as id
      getInvoice.id = invoiceDraftData.id;

      // const products = JSON.parse(localStorage.getItem("products") || "[]");
      const items = getInvoice.items;

      // Check if the invoice has items
      if (!items?.length) {
        alert("Please add items to the invoice");
        return;
      }

      // Determine the invoice status
      const hasItems = items?.some((item) => {
        const product = products.find((product) => product.id === item.id);
        return product.quantity < item.quantity;
      });

      const status = hasItems
        ? "waiting-for-missing-items"
        : "Pending-fulfillment";

      // Update the invoice with the status and finalized date
      getInvoice.status = status;
      getInvoice.payments = [{ amount: 0, date: "", paymentMethod: "" }];

      // Update the product quantities in the catalog
      /*   const updatedCatalog = products.map((catalogItem) => {
      const editItem = invoiceDraftData.items.find((item) => item.id === catalogItem.id);

      return {
        ...catalogItem,
        quantity: catalogItem.quantity + (editItem ? editItem.quantity : 0),
        papular: catalogItem.papular,
      };
    }); 

      // else if items are added to the invoice then update the catalog
      const updatedCatalog = products.map((catalogItem) => {
        const editItem = invoiceDraftData.items.find(
          (item) => item.id === catalogItem.id
        );
        const getItem = getInvoice.items.find(
          (item) => item.id === catalogItem.id
        );

        return {
          ...catalogItem,
          quantity:
            catalogItem.quantity +
            (editItem ? editItem.quantity : 0) -
            (getItem ? getItem.quantity : 0),
          papular: catalogItem.papular,
        };
      });

      localStorage.setItem("products", JSON.stringify(updatedCatalog));

      // Update the edited draft from the drafts
      const updatedDraftInvoices = getAllInvoices.map((invoice) => {
        if (invoice.id === invoiceDraftData.id) {
          return getInvoice;
        }
        return invoice;
      });

      // Update the local storage with the modified drafts
      localStorage.setItem(
        "invoicesDrafts",
        JSON.stringify(updatedDraftInvoices)
      );

      // Navigate to the invoicesDrafts page
      navigate("/invoicesDrafts");
    } else {
      const invoiceNotParsed = localStorage.getItem("invoice") || "{}";
      const invoice = JSON.parse(invoiceNotParsed);

      // Check if customer details are filled
      const costumerAdded = JSON.parse(localStorage.getItem("costumerAdded"));
      if (!costumerAdded) {
        alert("Please fill in the customer details");
        return;
      }

      const invoiceNumber =
        parseInt(localStorage.getItem("invoiceNumber")) || 1;

      // Add proformaNumber to be saved as id
      invoice.id = invoiceNumber;

      // Check if the invoice has items
      const items = invoice.items || [];
      if (items.length === 0) {
        alert("Please add items to the invoice");
        return;
      }

      // Determine the invoice status
      // const products = JSON.parse(localStorage.getItem("products")) || [];
      const status = items.some((item) => {
        const product = products.find((product) => product.id === item.id);
        return product && product.quantity < item.quantity;
      })
        ? "waiting-for-missing-items"
        : "Pending-fulfillment";

      // Update the invoice with the status and finalized date
      invoice.status = status;
      invoice.dateFinalized = new Date().toLocaleDateString();

      const invoicesDrafts =
        JSON.parse(localStorage.getItem("invoicesDrafts")) || [];

      // Update or add the draft to invoicesDrafts
      const updatedInvoices = invoicesDrafts.map((draft) => {
        return draft.id === invoice.id ? { ...draft, ...invoice } : draft;
      });

      if (!updatedInvoices.some((draft) => draft.id === invoice.id)) {
        updatedInvoices.push(invoice);
      }

      localStorage.setItem("invoicesDrafts", JSON.stringify(updatedInvoices));

      // Update product quantities in the catalog
      const updatedCatalog = products.map((product) => {
        const invoiceItem = items.find((item) => item.id === product.id);

        if (invoiceItem) {
          return {
            ...product,
            quantity: product.quantity - invoiceItem.quantity,
            popular: product.popular + invoiceItem.quantity,
          };
        }

        return product;
      });

      localStorage.setItem("products", JSON.stringify(updatedCatalog));

      // Clear the local storage invoice to an empty object
      localStorage.setItem("invoice", JSON.stringify({}));

      // Increment the invoice number
      localStorage.setItem("invoiceNumber", JSON.stringify(invoiceNumber + 1));

      // Redirect to drafts page
      navigate("/invoicesDrafts");
    }*/
  };

  // const showAlert = () => {
  //   alert("Please fill in the customer details from the show alert");
  //   // console.log(invoice.ResultCustomer === undefined);
  // };

  return (
    <div className="d-flex align-items-center justify-content-between">
      <main id="main" className="main">
        <h1
          style={{
            textAlign: "center",
            marginBottom: "2rem",
          }}
        >
          {invoiceToEdit
            ? "Edit Invoice"
            : invoiceToDuplicate
            ? "Duplicate Invoice"
            : invoiceDraftData
            ? "Edit Draft Invoice"
            : "Create Invoice"}
        </h1>
        <Invoice
          invoiceNumber={invoiceNumber}
          invoiceToEdit={invoiceToEdit}
          invoiceDraftData={invoiceDraftData}
          invoiceToDuplicate={invoiceToDuplicate}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: "4rem",
          }}
        >
          <button className="btn btn-primary" onClick={finalizeInvoice}>
            Finalize
          </button>

          <button className="btn btn-primary" onClick={saveToDraft}>
            Save as Draft
          </button>

          <Link to="/invoices">
            <button className="btn btn-primary">Back</button>
          </Link>
        </div>
      </main>
    </div>
  );
};

export default CreateInvoice;
