import React from "react";

const Price = ({
  item,
  itemsOnInvoice,
  setItemsOnInvoice,
  setItemsArray,
  setSubTotal,
  setDiscountedPriceTotal,
  setTaxAmount,
  setTotalAmount,
}) => {
  const handlePriceChange = (itemId, sellPrice) => {
    const parsedPrice = parseFloat(sellPrice) || 0;

    const calUpdatedItemsOnInvoice = itemsOnInvoice.map((invoiceItem) =>
      invoiceItem.id === itemId
        ? {
            ...invoiceItem,
            sellPrice: isNaN(parsedPrice) ? 0 : parsedPrice,
            discount: isNaN(parsedPrice) ? 0 : invoiceItem.discount,
            total:
              parseFloat(invoiceItem.quantity) * parsedPrice +
              ((parsedPrice * invoiceItem.quantity * invoiceItem.btw) / 100 -
                (parsedPrice * invoiceItem.quantity * invoiceItem.discount) /
                  100),
          }
        : invoiceItem
    );

    const updatedDiscountArray = calUpdatedItemsOnInvoice.map(
      (invoiceItem) =>
        invoiceItem.quantity *
        invoiceItem.sellPrice *
        (invoiceItem.discount / 100)
    );

    const sumForSub = calUpdatedItemsOnInvoice.reduce(
      (acc, invoiceItem) =>
        acc +
        parseFloat(invoiceItem.sellPrice) * parseFloat(invoiceItem.quantity),
      0
    );

    const sumForTotalDiscount = updatedDiscountArray.reduce((a, b) => a + b, 0);

    const updatedTaxAmount = calUpdatedItemsOnInvoice.map(
      (invoiceItem) =>
        ((invoiceItem.sellPrice * invoiceItem.btw) / 100) * invoiceItem.quantity
    );

    const sumForTax = updatedTaxAmount.reduce(
      (acc, tax) => acc + parseFloat(tax),
      0
    );

    // Perform calculations with higher precision
    const sumForTotal = calUpdatedItemsOnInvoice.reduce(
      (acc, invoiceItem) => acc + parseFloat(invoiceItem.total),
      0
    );

    console.log("sumForTotal", sumForTotal);
    console.log("sumForSub", sumForSub);

    setSubTotal(parseFloat(sumForSub).toFixed(2));
    setDiscountedPriceTotal(parseFloat(sumForTotalDiscount).toFixed(2));
    setTaxAmount(parseFloat(sumForTax).toFixed(3));
    setTotalAmount(parseFloat(Math.round(sumForTotal * 20) / 20).toFixed(2));

    setItemsOnInvoice(calUpdatedItemsOnInvoice);
    setItemsArray(calUpdatedItemsOnInvoice);
  };

  return (
    <td>
      <input
        type="number"
        value={item.sellPrice}
        onChange={(e) => {
          if (e.target.value >= 0) {
            handlePriceChange(item.id, e.target.value);
          }
        }}
        style={{ width: "6rem" }}
        className="form-control"
      />
    </td>
  );
};

export default Price;
