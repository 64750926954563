import React, { Component } from "react";

class Todo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      animate: false,
    };
  }

  componentDidMount() {
    // Added two nested requestAnimationFrames
    requestAnimationFrame(() => {
      // Firefox will sometimes merge changes that happened here
      requestAnimationFrame(() => {
        this.setState({ animate: true });
      });
    });
  }

  render() {
    const { todo, done, id, deleteTodo, toggleDone } = this.props;
    const { animate } = this.state;
    const checkBoxStyle = {
      fontSize: "20px",
      color: "green",
    };
    const emptyCheckBoxStyle = {
      fontSize: "20px",
      color: "red",
    };
    const listItemStyle = {
      opacity: animate ? 1 : 0,
    };

    return (
      <li style={listItemStyle}>
        <div className="todo-buttons-left" onClick={() => toggleDone(id)}>
          <span className="todo-button">
            {done === true ? (
              <i
                className="checkbox fa fa-check-square"
                style={checkBoxStyle}
              ></i>
            ) : (
              <i
                className="checkbox fa fa-square"
                style={emptyCheckBoxStyle}
              ></i>
            )}
          </span>
          <span className={done ? "done" : "todo-text"}>
            <p>{todo}</p>
          </span>
        </div>
        <i
          className="delete-button fa fa-trash"
          style={{ height: "32px", backgroundColor: "red" }}
          aria-hidden="true"
          onClick={() => deleteTodo(id)}
        >
          Done & Remove
        </i>
      </li>
    );
  }
}

class AddTodo extends Component {
  constructor(props) {
    super(props);
    this.state = { newTodo: "" };
  }

  handleChange = (e) => {
    const text = e.target.value;
    this.setState({
      newTodo: text,
    });
  };

  addTodo = (newTodo) => {
    this.props.addTodoHandler(newTodo);
    this.setState({
      newTodo: "",
    });
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.addTodo(this.state.newTodo);
    }
  };

  render() {
    const { newTodo } = this.state;
    const inputStyle = {
      padding: "10px",
      fontSize: "16px",
    };
    const buttonStyle = {
      padding: "10px",
      fontSize: "16px",
      backgroundColor: "green",
      color: "white",
    };

    return (
      <li id="add-todo">
        <input
          type="text"
          placeholder="What do you want to do today?"
          value={newTodo}
          autoFocus={true}
          onChange={(e) => this.handleChange(e)}
          onKeyDown={(e) => this.handleKeyPress(e)}
          style={inputStyle}
        />
        <button
          id="addTodoButton"
          onClick={() => this.addTodo(newTodo)}
          style={buttonStyle}
        >
          Add
        </button>
      </li>
    );
  }
}

class TodoApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      greeting: "",
      hours: new Date().getHours(),
      time: new Date().toLocaleTimeString(),
      newTodo: "",
      todos: [
        { todo: "make breakfast", done: true },
        { todo: "finish blog post about how to make a todo list", done: false },
        { todo: "read 'You Don't Know JS'", done: true },
        { todo: "finish todo app on codepen", done: false },
      ],
    };
  }

  componentDidMount() {
    setInterval(this.setTime, 1000);
  }

  addTodoHandler = (newTodo) => {
    const { todos } = this.state;
    newTodo = newTodo.trim();
    if (newTodo.length > 0) {
      todos.push({ todo: newTodo, done: false });
      this.setState({
        todos: todos,
      });
    }
  };

  deleteTodo = (id) => {
    const { todos } = this.state;
    todos.splice(id, 1);
    this.setState({
      todos: todos,
    });
  };

  toggleDone = (id) => {
    const { todos } = this.state;
    todos[id].done = !todos[id].done;
    this.setState({
      todos: todos,
    });
  };

  setTime = () => {
    const time = new Date().toLocaleTimeString();
    this.setState({
      time: time,
    });
  };

  render() {
    const { hours, time, todos } = this.state;
    let greeting = "";
    if (hours > 5 && hours < 12) {
      greeting = "Good Morning!";
    } else if (hours > 12 && hours < 17) {
      greeting = "Good afternoon!";
    } else if (hours > 17 && hours < 24) {
      greeting = "Good evening!";
    } else if (hours > 24 && hours < 5) {
      greeting = "Get to bed!";
    }

    const headingStyle = {
      fontSize: "24px",
      fontWeight: "bold",
    };

    return (
      <div id="todoList">
        <h1>
          {greeting} It's {time}
        </h1>
        <AddTodo addTodoHandler={this.addTodoHandler} />
        <ul>
          {todos.map((item, index) => (
            <Todo
              key={index}
              id={index}
              todo={item.todo}
              done={item.done}
              deleteTodo={this.deleteTodo}
              toggleDone={this.toggleDone}
            />
          ))}
        </ul>
      </div>
    );
  }
}

export default TodoApp;
