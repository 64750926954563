import React, { useState, useEffect } from "react";
import SearchNoResults from "./SearchNoResults";

function SearchResults({
  filteredItems,
  filteredContacts,
  handleResultSelection,
  source,
}) {
  return (
    <>
      <div style={{ position: "relative", top: "-40px" }}>
        {filteredContacts && filteredContacts.length > 0 && (
          <table
            className="table table-hover table-striped"
            tabIndex="0"
            size={filteredContacts.length + 1}
            style={{
              position: "absolute",
              zIndex: "1",
              width: "80%",
            }}
          >
            <thead>
              <tr>
                <th>Name</th>
                <th>Phone</th>
                <th>Client Number</th>
              </tr>
            </thead>
            <tbody>
              {filteredContacts.map((contact, index) => (
                <tr
                  key={index}
                  onClick={() => {
                    handleResultSelection(contact);
                  }}
                  style={{
                    backgroundColor:
                      index % 2 === 0 ? "rgba(48, 124, 234, 0.15)" : "white",
                    color: index % 2 === 0 ? "navy" : "black",
                    textAlign: "left",
                    paddingLeft: "15px",
                    cursor: "pointer",
                    marginLeft: "0px",
                    textDecoration: "underline",
                    hover: "background-color: lightblue",
                    fontSize: "1.2rem",
                    // Add focus styling
                    outline: "none", // Remove the default focus outline
                  }}
                >
                  <td>{contact.saveAs}</td>
                  <td>{contact.phone}</td>
                  <td>{contact.clientNumber}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      <div
        style={{
          position: "relative",
          top: "-30px",
        }}
      >
        {filteredItems && filteredItems.length > 0 && (
          <table
            className="table table-hover table-striped"
            tabIndex="0"
            size={filteredItems.length + 1}
            style={{
              position: "absolute",
              zIndex: "1",
              width: "70%",
            }}
          >
            <thead>
              <tr>
                <th>ID</th>
                <th>Product Name</th>
                <th>Discription</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {filteredItems.map((item, index) => (
                <tr
                  key={index}
                  onClick={() => {
                    handleResultSelection(item);
                  }}
                  style={{
                    backgroundColor:
                      index % 2 === 0 ? "rgba(48, 124, 234, 0.15)" : "white",
                    color: index % 2 === 0 ? "navy" : "black",
                    textAlign: "left",
                    paddingLeft: "15px",
                    cursor: "pointer",
                    marginLeft: "0px",
                    textDecoration: "underline",
                    hover: "background-color: lightblue",
                    fontSize: "1.2rem",
                    // Add focus styling
                    outline: "none", // Remove the default focus outline
                  }}
                >
                  <td>{item.id}</td>
                  <td>{item.name ? item.name : ""}</td>
                  <td>{item.description}</td>
                  <td>{item.sellPrice ? item.sellPrice : ""}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      {filteredItems && filteredItems.length === 0 && (
        <SearchNoResults source={source} />
      )}
    </>
  );
}

export default SearchResults;
