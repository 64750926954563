import jsPDF from "jspdf";
import "jspdf-autotable";
import base64Rubic from "../components/base64Rubic";
import EmptyLogo from "../components/EmptyLogo.png";

const generateReceiptPDF = (data) => {
  const removeTextForCompanyInInvoiceHeader = JSON.parse(
    localStorage.getItem("removeTextForCompanyInInvoiceHeader")
  );

  console.log(
    "removeTextForCompanyInInvoiceHeader",
    removeTextForCompanyInInvoiceHeader
  );
  const companyPerferdHeaderBackgroundColor =
    localStorage.getItem("tableHeaderBackgroundColor") || "#2e2e2e";

  const showBtw = localStorage.getItem("showBtw")
    ? localStorage.getItem("showBtw") === "true"
      ? "SHOW"
      : "HIDE"
    : "SHOW";

  const logoImage = localStorage.getItem("companyLogo") || "";
  const footer = JSON.parse(localStorage.getItem("footer")) || {};
  const myCompany = JSON.parse(localStorage.getItem("companyDetails"));

  console.log("starting generateReceiptPDF");
  // Create a new jsPDF document
  const doc = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: "a4",
    putOnlyUsedFonts: false,
  });

  // Add the custom font to the PDF
  doc.addFileToVFS("Rubik", base64Rubic);
  doc.addFont("Rubik", "Rubik", "normal");

  // Set the font for the document
  doc.setFont("Rubik");

  const columns = [
    "ID",
    "Name",
    "Description",
    "Price",
    "Quantity",
    "Discount",
    "Total",
  ];

  if (data.items.length > 0) {
    const firstItem = data.items[0];

    /*   if (firstItem.id) {
       columns.unshift("ID");
     }
   
     if (!firstItem.name) {
       columns.splice(firstItem.id ? 1 : 0, firstItem.id ? 1 : 2);
     }
   
     if (!firstItem.description) {
       const nameIndex = firstItem.id ? 1 : 0;
       columns.splice(nameIndex + (firstItem.name ? 1 : 0), 1);
     } */

    /*   // Remove "Discount" column if it exists
     const discountIndex = columns.indexOf("Discount");
     if (discountIndex !== -1) {
       columns.splice(discountIndex, 1);
     } */

    // Assuming selectedResultCustomer is an object
    if (
      firstItem.selectedResultCustomer &&
      firstItem.selectedResultCustomer.showTransportCode !== false
    ) {
      columns.splice(4, 0, "TransportCode");
    }

    // Assuming showBtw is a variable
    if (showBtw === "SHOW") {
      columns.splice(columns.length - 1, 0, "BTW");
    }
  }

  const tableData = data.items.map((item) => {
    const rowData = [
      item.id || "", // Assuming ID should always be present
      item.name || "",
      item.description || "",
      item.sellPrice || 0,
      item.quantity || 0,
      // item.discount || 0, round it to 2 decimal places with Math.round
      Number(Math.round(item.discount * 100) / 100).toFixed(0) + "%" || 0,
      // Number(item.total).toFixed(2) || 0, round it to 2 decimal places with Math.round
      Number(Math.round(item.total * 100) / 100).toFixed(2) || 0,
    ];

    // Assuming showBtw is a variable
    if (showBtw === "SHOW") {
      // Assuming BTW should always be present it should be added to one before the last column
      rowData.splice(rowData.length - 1, 0, item.btw || 0);
    }

    // Assuming selectedResultCustomer is an object
    if (
      item.selectedResultCustomer &&
      item.selectedResultCustomer.showTransportCode !== false
    ) {
      rowData.splice(4, 0, item.transportCode || ""); // Insert transportCode or an empty string
    }

    return rowData;
  });

  // Check if the text is in Yiddish or Hebrew
  function isYiddishOrHebrew(text) {
    // Yiddish and Hebrew share the same script, so it's challenging to distinguish between them.
    // You can look for specific keywords, patterns, or characters that indicate Yiddish.
    // This is a simple example; you may need to adjust the condition to better match your data.
    return /[א-ת]/.test(text); // This regex checks if there are any Hebrew characters in the text.
  }

  // Page dimensions and starting position
  const pageWidth = doc.internal.pageSize.width;
  const margin = 10;
  let y = margin + 15;

  // Function to add the logo image
  const addLogo = () => {
    if (logoImage !== "") {
      doc.addImage(logoImage, "PNG", 10, 30, 60, 0);
    }
    // Adjust the x-coordinate (3rd argument) to move the image to the right
    // Adjust the y-coordinate (4th argument) to move the image down
    // Adjust the width (4th argument) to make it smaller
    // Adjust the height (5th argument) to make it smaller
  };

  // Function to add content to the page
  const addContentToPage = () => {
    // Calculate the height of the "From" section
    const fromSectionHeight = 20 + 6 * 5; // Initial offset + 6 lines of text

    y += 10; // Initial offset
    addLogo(); // Add the logo image

    if (removeTextForCompanyInInvoiceHeader !== true) {
      y += 10; // Increase the vertical space
      // Add "From" section
      doc.setFontSize(12);
      doc.text("From:", margin, y);
      doc.setFontSize(10);
      if (
        isYiddishOrHebrew(
          data.companyDetails?.companyName || myCompany.companyName
        )
      ) {
        doc.text(
          `${
            data.companyDetails?.companyName.split("").reverse().join("") ||
            myCompany.companyName.split("").reverse().join("")
          }`,
          margin,
          (y += 5)
        );
      } else {
        doc.text(
          `Name: ${data.companyDetails?.companyName || myCompany.companyName}`,
          margin,
          (y += 5)
        );
      }
      if (data.companyDetails?.companyAddress || myCompany.companyAddress) {
        doc.text(
          `Address: ${
            data.companyDetails?.companyAddress || myCompany.companyAddress
          }`,
          margin,
          (y += 5)
        );
      } else {
        y += 10;
      }
      if (data.companyDetails?.companyCity || myCompany.companyCity) {
        doc.text(
          `City: ${data.companyDetails?.companyCity || myCompany.companyCity}`,
          margin,
          (y += 5)
        );
      }
      if (data.companyDetails?.companyPhone || myCompany.companyPhone) {
        doc.text(
          `Phone: ${
            data.companyDetails?.companyPhone || myCompany.companyPhone
          }`,
          margin,
          (y += 5)
        );
      }
      if (data.companyDetails?.companyEmail || myCompany.companyEmail) {
        doc.text(
          `Email: ${
            data.companyDetails?.companyEmail || myCompany.companyEmail
          }`,
          margin,
          (y += 5)
        );
      }
      if (data.companyDetails?.companyWebsite || myCompany.companyWebsite) {
        doc.text(
          `Website: ${
            data.companyDetails?.companyWebsite || myCompany.companyWebsite
          }`,
          margin,
          (y += 5)
        );
      }
      if (data.companyDetails?.companyBtwNumber || myCompany.companyBtwNumber) {
        doc.text(
          `BTW Number: ${
            data.companyDetails?.companyBtwNumber || myCompany.companyBtwNumber
          }`,
          margin,
          (y += 5)
        );
      }
    }
    y += 20;

    // if (removeTextForCompanyInInvoiceHeader) y += 10;

    // Calculate the height of the "To" section based on the "From" section
    const toSectionHeight = fromSectionHeight;

    // "To" section
    doc.setFontSize(12);
    doc.setFont("Rubik");
    const xOffset = 60; // Adjust this value to control the position
    y -= toSectionHeight - 8; // Move "To" section up to meet the height of "From"
    doc.text("To:", pageWidth - margin - xOffset, (y += 5));
    doc.setFontSize(10);
    doc.setFont("Rubik");
    if (isYiddishOrHebrew(data.selectedResultCustomer.name)) {
      doc.text(
        `${data.selectedResultCustomer.name.split("").reverse().join("")}`,
        pageWidth - margin - xOffset,
        (y += 5)
      );
    } else {
      doc.text(
        `Name: ${data.selectedResultCustomer.name}`,
        pageWidth - margin - xOffset,
        (y += 5)
      );
    }
    if (data.selectedResultCustomer.deliveryAddress) {
      doc.text(
        `Address: ${data.selectedResultCustomer.deliveryAddress}`,
        pageWidth - margin - xOffset,
        (y += 5)
      );
    }
    if (data.selectedResultCustomer.email) {
      doc.text(
        `Email: ${data.selectedResultCustomer.email}`,
        pageWidth - margin - xOffset,
        (y += 5)
      );
    }
  };
  doc.page = 1; // Initialize the page counter.

  const addPageHeader = () => {
    y = 35; // Reset the y-coordinate
    doc.setFontSize(12);
    /*   doc.setFont('helvetica', 'normal'); */
    doc.text(
      `Invoice Number: ${data.id || data.proformaNumber}`,
      margin + 140,
      y
    );
    y += 5;
    doc.text(`Date: ${data.date}`, margin + 140, y);
    y += 5;
    doc.text(
      `Client Number: ${data.selectedResultCustomer.clientNumber}`,
      margin + 140,
      y
    );
    y += 20;

    doc.page++; // Increment the counter.

    addContentToPage();
  };

  // Create the first page
  addPageHeader();

  // Invoice Details Table Header
  y += 30; // Increase the vertical space
  doc.setFontSize(12);
  /* doc.setFont('helvetica', 'bold'); */
  doc.text("Invoice Details", margin, y);
  y += 5;

  // Calculate remaining space on the current page
  // Check if there's enough space for both sections
  const requiredSpace = 60; // Space for both sections
  const remainingSpaceOnPage = doc.internal.pageSize.height - y;

  if (remainingSpaceOnPage >= requiredSpace) {
    // There's enough space, continue on the current page
  } else {
    doc.addPage(); // Move to the next page
    y = 10; // Reset the y-coordinate
    addPageHeader(); // Re-add the header content
    y += 50; // Increase the vertical space
  }

  // Set a fixed column width for each column
  const columnWidth = pageWidth / columns.length;
  const columnWidths = columns.map(() => columnWidth);

  // doc.autoTable({
  //   startY: y + 2,
  //   head: [columns],
  //   body: tableData,
  //   headStyles: {
  //     fontStyle: "bold",
  //     fillColor: companyPerferdHeaderBackgroundColor || [41, 41, 41],
  //     textColor: 240,
  //   },
  //   columnStyles: columnWidths,
  //   margin: { top: y + 10, hcenter: "center", hwidth: "95%" },
  //   styles: { overflow: "linebreak" },
  // }); if the header is split into two pages, then add the header again
  doc.autoTable({
    startY: y + 2,
    head: [columns],
    body: tableData,
    headStyles: {
      fontStyle: "bold",
      fillColor: companyPerferdHeaderBackgroundColor || [41, 41, 41],
      textColor: 240,
    },
    columnStyles: columnWidths,
    margin: { top: y + 10, hcenter: "center", hwidth: "95%" },
    styles: { overflow: "linebreak" },
    didDrawPage: function (dataArg) {
      if (dataArg.pageCount > 1) {
        addPageHeader();
      }
    },
  });

  y = doc.autoTable.previous.finalY + 5; // Move below the table

  const remainingPageSpace = doc.internal.pageSize.height - y - 20;

  if (remainingPageSpace >= 30) {
    addPageHeader(); // Re-add the header content

    y += 150; // Increase the vertical space after the table dynamically

    // set font to bold
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    // Move "Subtotal" and its value to the right side of the page
    const subtotalX = 140; // Adjust this value as needed
    doc.text(`Subtotal:`, subtotalX, y);
    doc.text(`€${Number(data.subTotal).toFixed(2)}`, subtotalX + 25, y);
    y += 5;
    if (data.discountedPriceTotal > 0) {
      doc.text(`Discount:`, subtotalX, y);
      doc.text(
        `€${Number(data.discountedPriceTotal).toFixed(2)}`,
        subtotalX + 25,
        y
      );
      y += 5;
    }
    if (showBtw === "SHOW") {
      doc.text(`BTW:`, subtotalX, y);
      doc.text(`€${Number(data.btw).toFixed(2)}`, subtotalX + 25, y);
      y += 5;
    }
    if (data?.totalPaid > 0) {
      doc.text(`Total Paid:`, subtotalX, y);
      doc.text(`€${Number(data.totalPaid).toFixed(2)}`, subtotalX + 25, y);
      y += 5;
    }
    doc.text(`Total:`, subtotalX, y);
    if (data?.totalPaid > 0) {
      doc.text(
        `€${Number(
          Math.round((data.totalAmount - data.totalPaid) * 100) / 100
        ).toFixed(2)}`,
        subtotalX + 25,
        y
      );
    } else {
      doc.text(
        `€${Number(Math.round(data.totalAmount * 100) / 100).toFixed(2)}`,
        subtotalX + 25,
        y
      );
    }
  } else {
    doc.addPage(); // Move to the next page
    y = 10; // Reset the y-coordinate
    addPageHeader(); // You might have a function for adding page headers
    y += 50; // Increase the vertical space
    y += 10; // Increase the vertical space
    // set font to bold
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    // Move "Subtotal" and its value to the right side of the page
    const subtotalX = 140; // Adjust this value as needed
    doc.text(`Subtotal:`, subtotalX, y);
    doc.text(`€${Number(data.subTotal).toFixed(2)}`, subtotalX + 25, y);
    y += 5;
    if (data.discountedPriceTotal > 0) {
      doc.text(`Discount:`, subtotalX, y);
      doc.text(
        `€${Number(data.discountedPriceTotal).toFixed(2)}`,
        subtotalX + 25,
        y
      );
      y += 5;
    }
    if (showBtw === "SHOW") {
      doc.text(`BTW:`, subtotalX, y);
      doc.text(`€${Number(data.btw).toFixed(2)}`, subtotalX + 25, y);
      y += 5;
    }
    if (data?.totalPaid > 0) {
      doc.text(`Total Paid:`, subtotalX, y);
      doc.text(`€${Number(data.totalPaid).toFixed(2)}`, subtotalX + 25, y);
      y += 5;
    }
    doc.text(`Total:`, subtotalX, y);
    if (data?.totalPaid > 0) {
      doc.text(
        `€${Number(
          Math.round((data.totalAmount - data.totalPaid) * 100) / 100
        ).toFixed(2)}`,
        subtotalX + 25,
        y
      );
    } else {
      doc.text(
        `€${Number(Math.round(data.totalAmount * 100) / 100).toFixed(2)}`,
        subtotalX + 25,
        y
      );
    }
  }

  // Remarks
  y += 20; // Increase the vertical space
  /*  doc.setFont('helvetica', 'bold'); */

  if (data.remarks) {
    y += 5;

    const remarksText = data.remarks;
    const remarksWidth = pageWidth - 2 * margin;
    const remainingPageSpace = doc.internal.pageSize.height - y;

    if (remainingPageSpace >= 60) {
      const remarksLines = doc.splitTextToSize(remarksText, remarksWidth);

      /* doc.setFont('helvetica', 'normal'); */
      for (let i = 0; i < remarksLines.length; i++) {
        if (y + 5 > doc.internal.pageSize.height) {
          doc.addPage(); // Move to the next page if there's not enough space
          y = 10; // Reset the y-coordinate
          addPageHeader();
        }
        doc.text(remarksLines[i], margin, y);
        y += 5; // Adjust the line spacing as needed
      }
    } else {
      doc.addPage(); // Move to the next page if there's not enough space
      y = 15; // Reset the y-coordinate
      addPageHeader();
      y += 45;
      doc.text("Remarks", margin, y);
      // doc.setFont('helvetica', 'normal');
      y += 5;

      const remarksText = data.remarks;
      const remarksWidth = pageWidth - 2 * margin;
      const remainingPageSpace = doc.internal.pageSize.height - y;

      if (remainingPageSpace >= 60) {
        const remarksLines = doc.splitTextToSize(remarksText, remarksWidth);

        for (let i = 0; i < remarksLines.length; i++) {
          if (y + 5 > doc.internal.pageSize.height) {
            doc.addPage(); // Move to the next page if there's not enough space
            y = 10; // Reset the y-coordinate
            addPageHeader();
          }
          doc.text(remarksLines[i], margin, y);
          y += 5; // Adjust the line spacing as needed
        }
      }
    }
  }

  const textWidth =
    (doc.getStringUnitWidth("Bank Details") * 12) / doc.internal.scaleFactor; // Assuming font size is 12
  const centerPos = (doc.internal.pageSize.width - textWidth) / 2;

  // Calculate y position for the bottom
  const bottomMargin = 20; // Adjust as needed
  const yBottom = doc.internal.pageSize.height - bottomMargin + 15;

  if (footer && Object.keys(footer).length > 0) {
    const { bic, iban, bankName, companyName, companyAddress, message } =
      footer;

    // Check if footer title exists
    if (bankName || iban || bic || companyName || companyAddress || message) {
      // Reset y position to bottom
      let y = yBottom;

      // Increase the vertical space
      y -= 20;

      // Set font size and style
      doc.setFontSize(12);
      doc.setFont("helvetica", "normal");

      y += 5;

      // Set font size for details
      doc.setFontSize(10);

      // Construct details in two lines
      let line1 = [];
      let line2 = [];

      // Push non-empty details to respective lines
      if (bankName) line1.push(`Bank Name: ${bankName}`);
      if (iban) line1.push(`IBAN: ${iban}`);
      if (bic) line2.push(`BIC: ${bic}`);
      if (companyName) {
        if (isYiddishOrHebrew(companyName)) {
          doc.setFont("Rubik");
          line2.push(`Company Name: ${companyName}`);
        } else {
          line2.push(`Company Name: ${companyName}`);
        }
      }

      if (companyAddress) line2.push(`Company Address: ${companyAddress}`);
      if (message) {
        if (isYiddishOrHebrew(message)) {
          doc.setFont("Rubik");
          line2.push(`${message}`);
        } else {
          line2.push(`Message: ${message}`);
        }
      }

      // Render line 1
      doc.text(line1.join(", "), centerPos, y);
      y += 5;

      // Render line 2 if exists
      if (line2.length > 0) {
        doc.text(line2.join(", "), centerPos, y);
        y += 5;
      }
    }
  }

  // PAGE NUMBERING
  const pageCount = doc.internal.getNumberOfPages();

  // For each page, print the page number and the total pages
  for (let i = 1; i <= pageCount; i++) {
    // if i has more than 1 page,  add the page number else don't add it
    if (pageCount > 1) {
      doc.setPage(i);
      doc.setFontSize(16);
      // doc.setFont('helvetica', 'bold');
      doc.text(
        `Invoice Receipt - Page ${i} of ${pageCount}`,
        pageWidth / 2,
        (y = 25),
        { align: "center" }
      );
      y += 20;
    } else {
      doc.setFontSize(16);
      // doc.setFont('helvetica', 'bold');
      doc.text(`Invoice Receipt`, pageWidth / 2, (y = 25), {
        align: "center",
      });
      y += 20;
    }
  }

  // Save the PDF
  doc.save(`Invoice-${data.id || data.proformaNumber}.pdf`);
};

export { generateReceiptPDF };
