import React, { useState, useEffect } from "react";
import HandleQuantity from "./handleQuantity";
import Price from "./PosPrice";
import Checkout from "./Checkout";

import styles from "./pos.module.css";
import config from "../config/config";

const POS = (settings) => {
  if (!localStorage.getItem("token")) {
    window.location.href = "/login";
  }

  const [costumers, setCostumers] = useState([]);
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState([]);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState({});
  const [quantity, setQuantity] = useState("");
  const [quantityBox, setQuantityBox] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [popupCheckout, setPopupCheckout] = useState(false);

  const getCostumers = async () => {
    try {
      const response = await fetch(`${config.URL_PROD}/contacts`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.ok) {
        console.log("response", response);
        const data = await response.json();
        setCostumers(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCostumers();
    console.log("costumers", costumers);
  }, []);

  useEffect(() => {
    setLoading(true);
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${config.URL_PROD}/products`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        const data = await response.json();
        const { count } = data;
        const { products } = data;
        console.log(count);
        console.log(products);
        // set products quantity to "" and quantityBox to "" for each product
        products.forEach((product) => {
          product.quantity = "";
          product.quantityBox = "";
        });
        setProducts(products);
        setLoading(false);
      } catch (error) {
        setError("Error fetching products");
        setLoading(false);
        console.log(error);
      }
    };
    fetchProducts(products);
  }, []);

  useEffect(() => {
    if (search) {
      searchProduct(search);
    }
  }, [search]);

  const searchProduct = (id) => {
    const foundProduct = products.find(
      (product) => product.id === parseInt(id)
    );
    setSearchResults(foundProduct || {});
  };

  const handleQuantityChange = (quantity) => {
    console.log("Quantity changed:", quantity);
    setQuantity(quantity);
    setSearchResults({
      ...searchResults,
      quantity,
    });
  };

  const handleQuantityBoxChange = (quantityBox) => {
    console.log("Quantity box changed:", quantityBox);
    setQuantityBox(quantityBox);
    setSearchResults({
      ...searchResults,
      quantityBox,
    });
  };

  const addToCart = () => {
    if (quantity < 1 && quantityBox < 1) {
      console.log("Quantity and quantityBox not set, defaulting to 1");
      setQuantity(1);
    }
    console.log("Adding to cart:", searchResults);
    console.log("Quantity:", quantity);
    console.log("Quantity Box:", quantityBox);

    // if the quantityBox is > 0 calculate the totalAmount using quantityBox * quantityBox also
    if (quantityBox > 0 && quantity > 0) {
      console.log("Quantity and quantityBox set");
      searchResults.total =
        (searchResults.salePrice || searchResults.sellPrice) * (quantity || 0) +
        (searchResults.salePrice || searchResults.sellPrice) *
          (quantityBox || 0) *
          Number(searchResults.unitsInBox);
      searchResults.quantity = quantity;
      searchResults.quantityBox = quantityBox;
      setQuantity("");
      setQuantityBox("");
    } else if (quantity > 0) {
      console.log("Quantity set");
      searchResults.total =
        (searchResults.salePrice || searchResults.sellPrice) * (quantity || 0);
      searchResults.quantity = quantity;
      setQuantity("");
    } else if (quantityBox > 0) {
      console.log("QuantityBox set");
      searchResults.total =
        (searchResults.salePrice || searchResults.sellPrice) *
        (quantityBox || 0) *
        Number(searchResults.unitsInBox);
      searchResults.quantityBox = quantityBox;
      setQuantityBox("");
    } else if (quantityBox < 1 && quantity < 1) {
      console.log("Quantity and quantityBox not set, defaulting to 1");
      searchResults.total =
        (searchResults.salePrice || searchResults.sellPrice) * 1;
      searchResults.quantity = 1;
    }
    const updatedCart = [
      ...cart,
      {
        ...searchResults,
        total: searchResults.total,
        discount: Number(searchResults.percentageDiscount),
        totalDiscounted:
          (searchResults.total * searchResults.percentageDiscount) / 100,
      },
    ];

    /* console.log("Total:", searchResults);
    console.log("Updated cart:", updatedCart); */
    setCart(updatedCart);
    setTotal(
      // calculate total of the updatedCart and sum it up
      updatedCart.reduce((acc, item) => acc + item.total, 0)
    );

    // set quantity and quantityBox to "" after adding to cart
    setQuantity("");
    setQuantityBox("");
    searchResults.quantity = "";
    searchResults.quantityBox = "";
    setSearch("");
    setSearchResults({});
    document.getElementById("id").focus();
    // Scroll to bottom of the cart table and a bit more after a brief delay
    setTimeout(() => {
      const cartTable = document.getElementById("cartTable");
      cartTable.scrollTop = cartTable.scrollHeight + 20; // Add a margin of 20 pixels
    }, 0.1); // Delay of 100 milliseconds
  };

  const removeFromCart = (product) => {
    // quantity and quantityBox set "" and remove from cart
    const newCart = cart.filter((item) => {
      setTotal(
        total -
          (Number(product.salePrice) || Number(product.sellPrice)) *
            product.quantity
      );
      if (item.id === product.id) {
        item.quantity = "";
        item.quantityBox = "";
        setQuantity("");
        setQuantityBox("");
      }
      return item.id !== product.id;
    });

    setCart(newCart);
    setSearch("");
    setSearchResults({});
    setQuantity("");
    setQuantityBox("");
    document.getElementById("id").focus();
  };

  let tabPressedInQuantity = false;

  // on key press,  // Enter key + Shift  + Ctrl

  window.addEventListener("keydown", (e) => {
    if (e.key === "Enter" && e.shiftKey && e.ctrlKey) {
      console.log("Enter + Shift + Ctrl pressed");
      setPopupCheckout(true);
      // stop the event from propagating to the parent div
      e.stopPropagation();
    }
  });
  const handleKeyPress = (e) => {
    // console.log("Key pressed:", e.key, e.shiftKey, e.ctrlKey);

    if (e.key === "Enter") {
      // searchResults.id is already in cart alert user and return
      if (cart.find((item) => item.id === searchResults.id)) {
        alert("Item already in cart");
        setSearch("");
        // set id in focus
        document.getElementById("id").focus();
        return;
      }

      if (searchResults.id) {
        // if no quantity is entered, default to 1
        if (!searchResults.qnty && !searchResults.quantityBox) {
          searchResults.quantity = 1;
        }
        addToCart();
      }
    }

    if (e.key === "Tab" || e.key === "ArrowRight") {
      if (!tabPressedInQuantity) {
        e.preventDefault(); // Prevent default tab behavior
        document.getElementById("quantity").focus();
      }
      tabPressedInQuantity = true; // Update flag to indicate Tab press in quantity field
    }
  };

  const handleCheckoutClick = (e) => {
    setPopupCheckout(true);
    // stop the event from propagating to the parent div
    e.stopPropagation();
  };

  if (loading) {
    return <div className={styles.loadingPage}>Loading...</div>;
  }

  const inputRow = (
    <tr key="inputTableRow">
      <td>
        <input
          id="id"
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={handleKeyPress}
          autoFocus
          style={{ width: "6rem" }}
        />
      </td>
      <td>
        <input
          type="text"
          value={search.length === 0 ? "" : searchResults.name || ""}
          readOnly
          className="form-control"
          style={{ width: "10rem" }}
        />
      </td>

      <td>
        <input
          type="text"
          value={search.length === 0 ? "" : searchResults.description || ""}
          readOnly
          className="form-control"
          style={{ width: "10rem" }}
        />
      </td>
      <Price
        searchResults={searchResults}
        setSearchResults={setSearchResults}
        search={search}
      />
      <HandleQuantity
        searchResults={searchResults}
        setSearchResults={setSearchResults}
        onQuantityChange={handleQuantityChange}
        onQuantityBoxChange={handleQuantityBoxChange}
        onEnterPress={handleKeyPress}
      />
    </tr>
  );

  const renderCartItems = () => {
    return cart.map((product, index) => (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{product.id}</td>
        <td>{product.name}</td>
        <td>{product.description}</td>
        <td>{Number(product.sellPrice || product.salePrice).toFixed(2)}</td>
        <td>{product.quantity}</td>
        <td>{product.quantityBox}</td>
        <td>{Number(product.percentageDiscount).toFixed(2)}</td>
        <td>{Number(product.total).toFixed(2)}</td>
        <td>
          <button onClick={() => removeFromCart(product)}>Remove</button>
        </td>
      </tr>
    ));
  };

  return (
    <div
      className={styles.main}
      onClick={() => {
        console.log("Main div clicked");
        setPopupCheckout(false);
      }}
    >
      <h1>Cart</h1>
      <div
        className={popupCheckout ? " " + styles.backgroundOverlay : ""}
        style={{
          // position fixed and height 10 rem to make the table scrollable
          position: "relative",
          height: "50vh",
          overflowY: "scroll",
        }}
        id="cartTable"
      >
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Id</th>
              <th>Product</th>
              <th>Description</th>
              <th>Price</th>
              <th>Quantity</th>
              <th>Quantity Box</th>
              <th>Discount %</th>
              <th>Total</th>
              <th>Remove</th>
            </tr>
          </thead>
          <tbody>{renderCartItems()}</tbody>
        </table>
      </div>
      <table
        style={{
          position: "sticky",
        }}
        className={
          styles.secondTable +
          (popupCheckout ? " " + styles.backgroundOverlay : "")
        }
      >
        <thead>
          <tr>
            <th>Id</th>
            <th>Product</th>
            <th>Description</th>
            <th>Price</th>
            <th>Quantity</th>
            <th>Quantity Box</th>
          </tr>
        </thead>
        <tbody>{inputRow}</tbody>
      </table>

      {error && <p>{error}</p>}
      <h2 className={popupCheckout ? " " + styles.backgroundOverlay : ""}>
        Total: {Number(total).toFixed(2)}
      </h2>
      <button
        className={popupCheckout ? " " + styles.backgroundOverlay : ""}
        onClick={(e) => handleCheckoutClick(e)}
      >
        Checkout
      </button>
      {popupCheckout && (
        <Checkout
          total={total}
          setTotal={setTotal}
          setPopupCheckout={setPopupCheckout}
          popupCheckout={popupCheckout}
          cart={cart}
          setCart={setCart}
          setQuantityBox={setQuantityBox}
          setQuantity={setQuantity}
          costumers={costumers}
          settings={settings}
        />
      )}
    </div>
  );
};

export default POS;
