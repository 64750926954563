import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "./invoice.css";
import styles from "./HeaderNavSideNav.module.css";

function Header() {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const userName = user?.user?.name;
  const [sidebarOpen, setSidebarOpen] = useState(window.innerWidth > 1199);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1199);

  const toggleSidebar = () => {
    setSidebarOpen((prev) => !prev);
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
  };

  const settingsDropdown = () => {
    setSettingsOpen((prev) => !prev);
  };

  const svgStyle = {
    fill: "black",
    border: "2px solid black",
    borderRadius: "50%",
    padding: "5px",
    cursor: "pointer",
    marginLeft: "2.5rem",
    width: "2.5rem",
    scrollBehavior: "smooth",
  };

  useEffect(() => {
    // Add a listener for window resize
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1199);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleLogin = () => {
    // Create dropdown container
    const createDropdown = document.createElement("div");
    createDropdown.classList.add("dropdown");

    // Create dropdown button
    const dropdownButton = document.createElement("button");
    dropdownButton.className = styles.dropbtn;
    dropdownButton.innerHTML = `
        <i className="bi bi-person"></i>
        ${
          token
            ? `<span>${userName}</span>`
            : `<span class="${styles.link}">Login</span>`
        }
    `;
  };

  const token = localStorage.getItem("token");

  return (
    <header
      id="header"
      className={`header fixed-top d-flex align-items-center ${
        sidebarOpen && !isMobile ? "sidebar-is-open" : ""
      }`}
    >
      <div className="d-flex align-items-center justify-content-between">
        <NavLink className="logo d-flex align-items-center" to="#">
          <span className="d-none d-lg-block">Perfect Invoicing</span>
        </NavLink>
        <i className="bi bi-list toggle-sidebar-btn" onClick={toggleSidebar}>
          <a
            className="nav-link collapsed"
            data-bs-target="#forms-nav"
            data-bs-toggle="collapse"
            href="#"
          >
            <i className="bi bi-bar-chart"></i>
            <span>
              <i
                className="material-icons"
                style={{ fontSize: "24px", color: "black" }}
              >
                list
              </i>
            </span>
            <i className="bi bi-chevron-down ms-auto"></i>
          </a>
        </i>
        <span className={styles.profile} onClick={handleLogin}>
          {/* add a dropdown for the login  */}
          <div className={styles.dropdown} id="dropDown">
            <button className={styles.dropbtn}>
              <i className="bi bi-person"></i>
              {token ? ( // if the user is logged in, display the user's name
                <span>
                  <i className="bi bi-person"></i>
                  <span>{userName}</span>
                </span>
              ) : (
                // if the user is not logged in, display the login link
                <NavLink to="/Login">
                  <i className="bi bi-box-arrow-in-right"></i>
                  <span className={styles.link}>Login</span>
                </NavLink>
              )}
            </button>
          </div>
        </span>
      </div>

      {isMobile && (
        <>
          {sidebarOpen && (
            <aside
              id="sidebar"
              className={`sidebar sidebar-is-open`}
              style={{
                width: "95%",
                maxWidth: "300px",
                height: "90%",
                top: "4rem",
                left: sidebarOpen ? "0px" : "450px",
                transition: "left 3s ease-in-out",
                zIndex: "1000",
              }}
            >
              <ul
                className="sidebar-nav"
                id="sidebar-nav"
                onClick={closeSidebar}
              >
                <li className="nav-item">
                  <NavLink className="nav-link collapsed" to="/Dashboard">
                    <i className="bi bi-grid"></i>
                    <span>Dashboard</span>
                  </NavLink>
                </li>
                {/* pos section */}
                <li className="nav-heading">POS</li>
                <li className="nav-item">
                  <NavLink className="nav-link collapsed" to="/pos">
                    <i className="bi bi-file-earmark"></i>
                    <span>POS</span>
                  </NavLink>
                </li>
                {/* place an order */}
                <li className="nav-heading">Place order</li>
                <li className="nav-item">
                  <NavLink className="nav-link collapsed" to="/PlaceAnOrder">
                    <i className="bi bi-file-earmark"></i>
                    <span>Place order</span>
                  </NavLink>
                </li>
                {/* invoices section */}
                <li className="nav-heading">Invoices & views</li>
                <li className="nav-item">
                  <NavLink className="nav-link collapsed" to="/ProformaInvoice">
                    <i className="bi bi-file-earmark"></i>
                    <span>Proforma Invoice</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link collapsed"
                    to="/ProformaDraftsView"
                  >
                    <i className="bi bi-file-earmark"></i>
                    <span>Proforma Drafts list</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link collapsed"
                    to="/ProformaInvoices"
                  >
                    <i className="bi bi-file-earmark"></i>
                    <span>Proforma invoices</span>
                  </NavLink>
                  <NavLink className="nav-link collapsed" to="/CreateInvoice">
                    <i className="bi bi-file-earmark"></i>
                    <span>New Invoice</span>
                  </NavLink>
                  <NavLink className="nav-link collapsed" to="/InvoicesDrafts">
                    <i className="bi bi-file-earmark"></i>
                    <span>Invoices Drafts</span>
                  </NavLink>
                  <NavLink className="nav-link collapsed" to="/Invoices">
                    <i className="bi bi-file-earmark"></i>
                    <span>Invoices</span>
                  </NavLink>
                  <NavLink className="nav-link collapsed" to="/ActiveOrders">
                    <i className="bi bi-file-earmark"></i>
                    <span>Active Orders</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link collapsed" to="/MissingItems">
                    <i className="bi bi-file-earmark"></i>
                    <span>Missing Items</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link collapsed"
                    to="/ImportInvoicesExcelTable"
                  >
                    <i className="bi bi-file-earmark"></i>
                    <span>Import Excel Table To Draft Invoices</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link collapsed" to="/Bank">
                    <i className="bi bi-file-earmark"></i>
                    <span>Bank</span>
                  </NavLink>
                </li>
                {/* products section */}
                <li className="nav-heading">Products</li>
                <li className="nav-item">
                  <NavLink className="nav-link collapsed" to="/AddProduct">
                    <i className="bi bi-file-earmark"></i>
                    <span>Add Product</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link collapsed" to="/Catalog">
                    <i className="bi bi-file-earmark"></i>
                    <span>Catalog</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link collapsed"
                    to="/ImportProductsFromExcel"
                  >
                    <i className="bi bi-file-earmark"></i>
                    <span>Import Products From Excel</span>
                  </NavLink>
                </li>
                {/* contacts section */}
                <li className="nav-heading">Contacts/Customer</li>
                <li className="nav-item">
                  <NavLink className="nav-link collapsed" to="/AddCustomer">
                    <i className="bi bi-file-earmark"></i>
                    <span>Add Contact/Customer</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link collapsed" to="/ContactsView">
                    <i className="bi bi-file-earmark"></i>
                    <span>Contacts View</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link collapsed"
                    to="/ImportExcelContacts"
                  >
                    <i className="bi bi-file-earmark"></i>
                    <span>Import Excel Contacts</span>
                  </NavLink>
                </li>
                <li className="nav-heading">Pages</li>
                <li className="nav-item">
                  <a className="nav-link collapsed" href="#">
                    <i className="bi bi-person"></i>
                    <span>Profile</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link collapsed" href="#">
                    <i className="bi bi-envelope"></i>
                    <span>Contact</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link collapsed"
                    onClick={() => {
                      localStorage.removeItem("token");
                      localStorage.removeItem("user");
                      navigate("/login");
                    }}
                  >
                    <i className="bi bi-box-arrow-in-right"></i>
                    <span>Logout</span>
                  </a>
                </li>
              </ul>
              {/* settings section */}

              <li className="sidebar-nav" id="sidebar-nav">
                Admin settings
              </li>
              <li className="sidebar-nav">
                <button
                  className="btn btn-primary dropdown-toggle sidebar-nav"
                  type="button"
                  data-toggle="dropdown-toggle"
                  role="button"
                  aria-expanded={dropdownOpen}
                  onClick={settingsDropdown}
                  style={{ backgroundColor: "transparent", border: "none" }}
                >
                  <i className="bi bi-file-earmark"></i>
                  <svg viewBox="0 -960 960 960" style={svgStyle}>
                    <path d="m370-80-16-128q-13-5-24.5-12T307-235l-119 50L78-375l103-78q-1-7-1-13.5v-27q0-6.5 1-13.5L78-585l110-190 119 50q11-8 23-15t24-12l16-128h220l16 128q13 5 24.5 12t22.5 15l119-50 110 190-103 78q1 7 1 13.5v27q0 6.5-2 13.5l103 78-110 190-118-50q-11 8-23 15t-24 12L590-80H370Zm112-260q58 0 99-41t41-99q0-58-41-99t-99-41q-59 0-99.5 41T342-480q0 58 40.5 99t99.5 41Zm0-80q-25 0-42.5-17.5T422-480q0-25 17.5-42.5T482-540q25 0 42.5 17.5T542-480q0 25-17.5 42.5T482-420Zm-2-60Zm-40 320h79l14-106q31-8 57.5-23.5T639-327l99 41 39-68-86-65q5-14 7-29.5t2-31.5q0-16-2-31.5t-7-29.5l86-65-39-68-99 42q-22-23-48.5-38.5T533-694l-13-106h-79l-14 106q-31 8-57.5 23.5T321-633l-99-41-39 68 86 64q-5 15-7 30t-2 32q0 16 2 31t7 30l-86 65 39 68 99-42q22 23 48.5 38.5T427-266l13 106Z" />
                  </svg>
                  <span className="caret"></span>
                  <li className="dropdown">
                    <ul
                      className={`dropdown-menu ${settingsOpen ? "show" : ""}`}
                      onClick={closeSidebar}
                    >
                      <li>
                        <NavLink to="/CompanyDetails">Company Details</NavLink>
                      </li>
                      <li>
                        <NavLink to="/ProformaNumberSetting">
                          Proforma Number Setting
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/InvoiceNumberSetting">
                          Invoice Number Setting
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/FooterProformaInvoice">
                          Footer Proforma Invoice
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/AddLogo">Add Logo</NavLink>
                      </li>
                      <li>
                        <NavLink to="/TableHeaderBackgroundColor">
                          Set invoice table header Background Color
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/AdminSettings">Setings</NavLink>
                      </li>
                    </ul>
                  </li>
                </button>
              </li>
            </aside>
          )}
        </>
      )}

      {!isMobile && (
        <>
          {sidebarOpen && (
            <aside
              id="sidebar"
              className={`sidebar sidebar-is-open`}
              style={{
                width: "95%",
                maxWidth: "300px",
                height: "90%",
                top: "4rem",
                left: sidebarOpen ? "0px" : "450px",
                transition: "left 3s ease-in-out",
                zIndex: "1000",
              }}
            >
              <ul className="sidebar-nav" id="sidebar-nav">
                <li className="nav-item">
                  <NavLink className="nav-link collapsed" to="/Dashboard">
                    <i className="bi bi-grid"></i>
                    <span>Dashboard</span>
                  </NavLink>
                </li>
                {/* pos section */}
                <li className="nav-heading">POS</li>
                <li className="nav-item">
                  <NavLink className="nav-link collapsed" to="/pos">
                    <i className="bi bi-file-earmark"></i>
                    <span>POS</span>
                  </NavLink>
                </li>
                {/* place an order */}
                <li className="nav-heading">Place order</li>
                <li className="nav-item">
                  <NavLink className="nav-link collapsed" to="/PlaceAnOrder">
                    <i className="bi bi-file-earmark"></i>
                    <span>Place order</span>
                  </NavLink>
                </li>
                {/* invoices section */}
                <li className="nav-heading">Invoices & views</li>
                <li className="nav-item">
                  <NavLink className="nav-link collapsed" to="/ProformaInvoice">
                    <i className="bi bi-file-earmark"></i>
                    <span>Proforma Invoice</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link collapsed"
                    to="/ProformaDraftsView"
                  >
                    <i className="bi bi-file-earmark"></i>
                    <span>Proforma Drafts list</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link collapsed"
                    to="/ProformaInvoices"
                  >
                    <i className="bi bi-file-earmark"></i>
                    <span>Proforma invoices</span>
                  </NavLink>
                  <NavLink className="nav-link collapsed" to="/CreateInvoice">
                    <i className="bi bi-file-earmark"></i>
                    <span>New Invoice</span>
                  </NavLink>
                  <NavLink className="nav-link collapsed" to="/InvoicesDrafts">
                    <i className="bi bi-file-earmark"></i>
                    <span>Invoices Drafts</span>
                  </NavLink>
                  <NavLink className="nav-link collapsed" to="/Invoices">
                    <i className="bi bi-file-earmark"></i>
                    <span>Invoices</span>
                  </NavLink>
                  <NavLink className="nav-link collapsed" to="/ActiveOrders">
                    <i className="bi bi-file-earmark"></i>
                    <span>Active Orders</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link collapsed" to="/MissingItems">
                    <i className="bi bi-file-earmark"></i>
                    <span>Missing Items</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link collapsed"
                    to="/ImportInvoicesExcelTable"
                  >
                    <i className="bi bi-file-earmark"></i>
                    <span>Import Excel Table To Draft Invoices</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link collapsed" to="/Bank">
                    <i className="bi bi-file-earmark"></i>
                    <span>Bank</span>
                  </NavLink>
                </li>
                {/* products section */}
                <li className="nav-heading">Products</li>
                <li className="nav-item">
                  <NavLink className="nav-link collapsed" to="/AddProduct">
                    <i className="bi bi-file-earmark"></i>
                    <span>Add Product</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link collapsed" to="/Catalog">
                    <i className="bi bi-file-earmark"></i>
                    <span>Catalog</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link collapsed"
                    to="/ImportProductsFromExcel"
                  >
                    <i className="bi bi-file-earmark"></i>
                    <span>Import Products From Excel</span>
                  </NavLink>
                </li>
                {/* contacts section */}
                <li className="nav-heading">Contacts/Customer</li>
                <li className="nav-item">
                  <NavLink className="nav-link collapsed" to="/AddCustomer">
                    <i className="bi bi-file-earmark"></i>
                    <span>Add Contact/Customer</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link collapsed" to="/ContactsView">
                    <i className="bi bi-file-earmark"></i>
                    <span>Contacts View</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link collapsed"
                    to="/ImportExcelContacts"
                  >
                    <i className="bi bi-file-earmark"></i>
                    <span>Import Excel Contacts</span>
                  </NavLink>
                </li>
                {/* settings section */}

                <li className="nav-heading">Admin settings</li>
                <li>
                  <button
                    className="btn btn-primary dropdown-toggle"
                    type="button"
                    data-toggle="dropdown-toggle"
                    role="button"
                    aria-expanded={dropdownOpen}
                    onClick={settingsDropdown}
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      display: "flex",
                      alignItems: "left",
                    }}
                  >
                    <i className="bi bi-file-earmark"></i>
                    <svg viewBox="0 -960 960 960" style={svgStyle}>
                      <path d="m370-80-16-128q-13-5-24.5-12T307-235l-119 50L78-375l103-78q-1-7-1-13.5v-27q0-6.5 1-13.5L78-585l110-190 119 50q11-8 23-15t24-12l16-128h220l16 128q13 5 24.5 12t22.5 15l119-50 110 190-103 78q1 7 1 13.5v27q0 6.5-2 13.5l103 78-110 190-118-50q-11 8-23 15t-24 12L590-80H370Zm112-260q58 0 99-41t41-99q0-58-41-99t-99-41q-59 0-99.5 41T342-480q0 58 40.5 99t99.5 41Zm0-80q-25 0-42.5-17.5T422-480q0-25 17.5-42.5T482-540q25 0 42.5 17.5T542-480q0 25-17.5 42.5T482-420Zm-2-60Zm-40 320h79l14-106q31-8 57.5-23.5T639-327l99 41 39-68-86-65q5-14 7-29.5t2-31.5q0-16-2-31.5t-7-29.5l86-65-39-68-99 42q-22-23-48.5-38.5T533-694l-13-106h-79l-14 106q-31 8-57.5 23.5T321-633l-99-41-39 68 86 64q-5 15-7 30t-2 32q0 16 2 31t7 30l-86 65 39 68 99-42q22 23 48.5 38.5T427-266l13 106Z" />
                    </svg>
                    <span className="caret"></span>
                    <li className="dropdown">
                      <ul
                        className={`dropdown-menu ${
                          settingsOpen ? "show" : ""
                        }`}
                      >
                        <li>
                          <NavLink to="/CompanyDetails">
                            Company Details
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/ProformaNumberSetting">
                            Proforma Number Setting
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/InvoiceNumberSetting">
                            Invoice Number Setting
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/FooterProformaInvoice">
                            Footer Proforma Invoice
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/AddLogo">Add Logo</NavLink>
                        </li>
                        <li>
                          <NavLink to="/TableHeaderBackgroundColor">
                            Set invoice table header Background Color
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/AdminSettings">Setings</NavLink>
                        </li>
                      </ul>
                    </li>
                  </button>
                </li>
                <li className="nav-heading">Pages</li>
                <li className="nav-item">
                  <a className="nav-link collapsed" href="#">
                    <i className="bi bi-person"></i>
                    <span>Profile</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link collapsed" href="#">
                    <i className="bi bi-envelope"></i>
                    <span>Contact</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link collapsed"
                    onClick={() => {
                      localStorage.removeItem("token");
                      localStorage.removeItem("user");
                      navigate("/login");
                    }}
                  >
                    <i className="bi bi-box-arrow-in-right"></i>
                    <span>Logout</span>
                  </a>
                </li>
              </ul>
            </aside>
          )}
        </>
      )}
    </header>
  );
}

export default Header;

// export default function HeaderNavSideNav() {
//   return (
//     <div>
//       <Header />
//     </div>
//   );
// }
