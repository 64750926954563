import React from "react";
import TodoList from "./TodoList";

import "./grid.css";
import "./dashboard.css";
import "./hover-pointer.css";
import "./warning-style.css";

export default function Dashboard() {
  if (!localStorage.getItem("token")) {
    window.location.href = "/login";
  }
  function toggleDropdownDashboard() {
    var dropdownContent = document.getElementById("dropdownContentDashboard");
    if (dropdownContent.style.display === "block") {
      dropdownContent.style.display = "none";
    } else {
      dropdownContent.style.display = "block";
    }
  }

  return (
    <div
      className="d-flex align-items-center justify-content-between"
      style={{ marginLeft: "15%" }}
    >
      <main id="main" className="main">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="text-center">Dashboard</h1>
            </div>
            <div className="col-12">
              <TodoList />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
