import React, { useState } from "react";
import { Link } from "react-router-dom";

import "../../components/buttons.css";
import CheckForToken from "../../checkForToken";

const ProformaNumberSetting = () => {
  CheckForToken();
  const [proformaNumber, setProformaNumber] = useState("");

  const saveHandler = () => {
    const confirm = window.confirm("Are you sure you want to save?");
    if (!confirm) {
      return;
    }
    // save the invoice number to the database
    const invoiceNumber = proformaNumber;

    // save to local storage with key 'invoiceNumber'
    localStorage.setItem("proformaNumber", invoiceNumber);
  };

  return (
    <div>
      <h1>Proforma Number Setting</h1>

      <form>
        <label htmlFor="proformaNumber">
          Proforma Number Prefix:
          <input
            type="number"
            value={proformaNumber}
            onChange={(e) => {
              setProformaNumber(e.target.value);
            }}
            autoFocus
          />
        </label>
        <div className="form-group">
          <button
            disabled={!proformaNumber}
            onClick={saveHandler}
            className="save-button"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default ProformaNumberSetting;
