const generateReceiptPDF = require("./pdfGenerator").generateReceiptPDF;

const generatePDFsForOrders = async (data) => {
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  for (let i = 0; i < data.length; i++) {
    const order = data[i];
    await delay(100); // Add 100 of a second for each order generation
    const alertElement = document.querySelector(".alert-warning");
    alertElement.style.position = "fixed";
    alertElement.style.top = "6rem";
    alertElement.style.display = "block"; // Show the alert

    const alertPDFNumber = document.querySelector(".munber-of-pdf");
    alertPDFNumber.innerHTML = `Generating PDF for order ${i + 1} of ${
      data.length
    }...`;

    generateReceiptPDF(order);

    if (i === data.length - 1) {
      alertElement.style.display = "none"; // Hide the alert after the last PDF is generated
    }
  }

  console.log("All PDFs have been generated");
};

export { generatePDFsForOrders };
