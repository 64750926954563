import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import config from "../config/config";
import styles from "./CreateUser.module.css";

const CreateUser = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");

  const navigate = useNavigate();

  const handleErrorMessage = () => {
    // Clear the error message
    setErrorMessage("");
    setMessage("");
  };

  const handleSuccessMessage = () => {
    // Clear the success message
    setMessage("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here

    const addUser = {
      name,
      email,
      password,
    };

    fetch(`${config.URL_PROD}/auth/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(addUser),
    })
      .then((res) => res.json())
      .then((result) => {
        // set the token in local storage
        localStorage.setItem("token", result.token);

        if (result.token) {
          // redirect to the dashboard page
          return navigate("/dashboard");
        }

        if (result.message) {
          setErrorMessage(result.message.message);
          setMessage(""); // Clear any previous success message
        }

        result?.message?.message
          ? setErrorMessage(result?.message?.message)
          : result?.message
          ? setErrorMessage(result?.message)
          : setMessage("User created successfully!!");
      })
      .catch((error) => {
        console.log(error);
        setErrorMessage("An error occurred while processing your request");
        setMessage(""); // Clear any previous success message
      })
      .finally(() => {
        setName("");
        setEmail("");
        setPassword("");
      });
  };

  return (
    <>
      {errorMessage && (
        <div className={styles.errorMessage}>
          <div className="error-message">
            <p>{errorMessage}</p>
            <button
              className="error-message-button"
              onClick={handleErrorMessage}
            >
              X
            </button>
          </div>
        </div>
      )}
      {message && (
        <div className="success-message-container">
          <div className="success-message">
            <p>{message}</p>
            <button
              className="success-message-button"
              onClick={handleSuccessMessage}
            >
              X
            </button>
          </div>
        </div>
      )}
      <form className={styles.signUp} onSubmit={handleSubmit}>
        <h2>Create User</h2>
        <div className={styles.field}>
          <label htmlFor="name" className={styles.label}>
            Username
          </label>
          <input
            type="text"
            id="name"
            value={name}
            className={styles.input}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className={styles.field}>
          <label htmlFor="email" className={styles.label}>
            Email
          </label>
          <input
            type="email"
            id="email"
            value={email}
            className={styles.input}
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="username"
          />
        </div>
        <div className={styles.field}>
          <label htmlFor="password" className={styles.label}>
            Password
          </label>
          <input
            type="password"
            id="password"
            value={password}
            className={styles.input}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit" className="button-user">
          Submit
        </button>
        <div className="switch-to-login">
          <p>
            Already have an account? <Link to="/login">Login here</Link>
          </p>
        </div>
      </form>
    </>
  );
};

export default CreateUser;
