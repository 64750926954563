import React from "react";

const Discount = ({
  item,
  itemsOnInvoice,
  setItemsOnInvoice,
  setItemsArray,
  setSubTotal,
  setDiscountedPriceTotal,
  setTaxAmount,
  setTotalAmount,
}) => {
  const handleDiscountChange = (itemId, discount) => {
    const parsedDiscount = parseFloat(discount) || 0;

    const updatedItems = itemsOnInvoice.map((invoiceItem) =>
      invoiceItem.id === itemId
        ? {
            ...invoiceItem,
            discount: parsedDiscount,
            total: parseFloat(
              parseFloat(item.sellPrice) * item.quantity +
                ((item.sellPrice * item.quantity * item.btw) / 100 -
                  (item.sellPrice * item.quantity * parsedDiscount) / 100)
            ).toFixed(2),
          }
        : invoiceItem
    );

    const updatedDiscountArray = updatedItems.map(
      (invoiceItem) =>
        parseFloat(invoiceItem.quantity) *
        parseFloat(invoiceItem.sellPrice) *
        (parseFloat(invoiceItem.discount) / 100)
    );

    const sumForSub = updatedItems.reduce((acc, item) => {
      return acc + parseFloat(item.sellPrice) * parseFloat(item.quantity);
    }, 0);

    const sumForTotalDiscount = updatedDiscountArray.reduce(
      (acc, discount) => acc + discount
    );

    const updatedTaxAmount = updatedItems.map(
      (invoiceItem) =>
        parseFloat(invoiceItem.sellPrice) *
        (invoiceItem.btw / 100) *
        parseFloat(invoiceItem.quantity)
    );

    const sumForTax = updatedTaxAmount.reduce(
      (acc, invoiceItem) => acc + parseFloat(invoiceItem),
      0
    );

    // Perform calculations with higher precision
    const sumForTotal = sumForSub - sumForTotalDiscount + sumForTax;

    setSubTotal(parseFloat(sumForSub).toFixed(2));
    setDiscountedPriceTotal(parseFloat(sumForTotalDiscount).toFixed(2));
    setTaxAmount(parseFloat(sumForTax).toFixed(3));
    setTotalAmount(parseFloat(Math.round(sumForTotal * 20) / 20).toFixed(2));

    setItemsOnInvoice(updatedItems);
    setItemsArray(updatedItems);
  };

  return (
    <td>
      <input
        type="number"
        step={0.001}
        style={{ width: "6rem" }}
        value={Number(item.discount) || ""}
        onChange={(e) => handleDiscountChange(item.id, e.target.value)}
        className="form-control col-lg-1"
      />
    </td>
  );
};

export default Discount;
