import React, { useState, forwardRef } from "react";
import "./SearchBar.css";

const SearchBar = forwardRef(({ onSearch }, ref) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
    onSearch(newSearchTerm);
  };

  return (
    <div>
      <input
        type="text"
        className="inputTypeTextSearchBar"
        placeholder="Search By Name..."
        value={searchTerm}
        onChange={handleChange}
        ref={ref}
        autoFocus
      />
    </div>
  );
});

export default SearchBar;
