import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CheckForToken from "../checkForToken";
import { Link } from "react-router-dom";
import styles from "./AddCustomer.module.css";
import config from "./config/config";

function AddCustomer({ contact }) {
  CheckForToken();
  const navigate = useNavigate();

  const initialCustomerState = {
    showTransportCode: false,
    btwNumber: "",
    btwAmount: "",
    name: "",
    saveAs: "",
    address: "",
    zipCode: "",
    city: "",
    state: "",
    country: "",
    contactPerson: "",
    position: "",
    email: "",
    phone: "",
    deliveryAddress: "",
    deliveryZipCode: "",
    deliveryCity: "",
    deliveryState: "",
    deliveryCountry: "",
    notes: "",
  };

  const [customer, setCustomer] = useState(contact || initialCustomerState);
  const [showDeliveryFields, setShowDeliveryFields] = useState(false);
  const [isnameValid, setNameValid] = useState(true);
  const [isSaveAsValid, setSaveAsValid] = useState(true);
  const [isClientNumberValid, setClientNumberValid] = useState(true);

  const checkCustomerDetails = async (btw) => {
    let vat_number = btw;

    // Remove any whitespace from the VAT number and also remove any dashes or dots
    vat_number = vat_number.replace(/\s/g, "").replace(/[-.]/g, "");

    try {
      // Make a request to the API to validate the VAT number
      const response = await fetch(
        `https://controleerbtwnummer.eu/api/validate/${vat_number}.json`
      );

      if (response.ok) {
        // If the API call was successful, get the JSON response
        const data = await response.json();

        const { valid, countryCode, vatNumber, name, address, country } = data;

        if (valid) {
          // input the details into the form
          setCustomer({
            ...customer,
            name: name,
            btwNumber: countryCode + vatNumber,
            address: address.street,
            zipCode: address.zip_code,
            city: address.city,
            state: address.state || "N/A",
            country: address.country,
          });

          // console.log(
          //   `This BTW number Is ${valid}, And is in Country ${countryCode}, BTW number ${vatNumber}, Owner Name Is: ${name}, His Address Is: ${address.street}, ${address.number}, ${address.zip_code},  ${address.city},  ${address.country}`
          // );
        } else {
          // VAT number is not valid
          alert(`This BTW number is NOT valid`);
        }
        /* console.log(data); */
      } else {
        alert("Please Enter a BTW Number");
        throw new Error("Something went wrong");
      }
    } catch (error) {
      /*  console.log(error); */
    }
  };

  const toggleDeliveryFields = () => {
    setShowDeliveryFields(!showDeliveryFields);
  };

  const handleCancel = () => {
    setCustomer(contact || initialCustomerState);
    navigate("/ContactsView");
  };

  const handleInputChange = (field, value) => {
    setCustomer({ ...customer, [field]: value });

    // Validate required fields
    switch (field) {
      case "name":
        setNameValid(!!value);
        break;
      case "saveAs":
        setSaveAsValid(!!value);
        break;
      case "clientNumber":
        setClientNumberValid(!!value);
        break;
      // Add similar cases for other required fields
      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(customer);
    // if contact is true, it means we are editing the contact
    // update the contact in the server
    if (contact) {
      fetch(`${config.URL_PROD}/contacts/${contact._id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(customer),
      })
        .then((response) => {
          console.log(response);
          return response.json();
        })
        .then((data) => {
          console.log(data);
          navigate("/ContactsView");
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          console.log("Finally");
          // Reset the customer state to the initial state
          setCustomer(initialCustomerState);
        });
      return;
    }

    // trim all the values
    for (const key in customer) {
      if (typeof customer[key] === "string") {
        customer[key] = customer[key].trim();
      }
    }

    // Validate required fields
    if (!customer.name || !customer.saveAs || !customer.clientNumber) {
      setNameValid(!!customer.name);
      setSaveAsValid(!!customer.saveAs);
      setClientNumberValid(!!customer.clientNumber);
      return;
    }

    console.log(customer);

    if (customer.email) {
      console.log(customer);
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(customer.email)) {
        alert("Please enter a valid email address");
        return;
      }
    }

    // send the data to the server
    fetch(`${config.URL_PROD}/contacts`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(customer),
    })
      .then((response) => {
        console.log(response);
        return response.json();
      })
      .then((data) => {
        console.log(data);
        navigate("/ContactsView");
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("Finally");
        // Reset the customer state to the initial state
        setCustomer(initialCustomerState);
      });

    /*   // Get existing customer data from localStorage or create an empty array
    const allcontacts = JSON.parse(localStorage.getItem("contacts")) || [];

    // If the contact is true
    if (contact) {
      // Find the index of the contact in the array
      // const index = allcontacts.findIndex((c) => c.clientNumber === contact.clientNumber); only the one does not have deleted property
      const index = allcontacts.findIndex(
        (c) => c.clientNumber === contact.clientNumber && !c.deleted
      );
      // console.log(index);
      // Replace the old contact with the new contact
      allcontacts[index] = customer;
    } else {
      // Add the new contact to the array
      allcontacts.push(customer);
    }

    // Save the updated data back to localStorage
    localStorage.setItem("contacts", JSON.stringify(allcontacts));

    // Reset the customer state to the initial state
    setCustomer(initialCustomerState);
    navigate("/ContactsView"); */
  };

  return (
    <div className={styles.main}>
      <Link to="/ImportExcelContacts" className={styles.inportContactsBtn}>
        Import
      </Link>
      <h1>{contact ? "Edit Contact" : "Add Customer"}</h1>

      {/* BTW Number and related fields */}

      <div className={styles.btwNumberContainer}>
        <label
          htmlFor="btwNumber"
          id={styles.btwNumberLabel}
          className="form-label"
        >
          Check BTW Number and Fill
        </label>

        <input
          type="text"
          className="form-control"
          id="btwNumber"
          value={customer.btwNumber || ""}
          onChange={(e) =>
            setCustomer({ ...customer, btwNumber: e.target.value })
          }
        />
        <button
          type="button"
          id="button-addon2"
          onClick={() => checkCustomerDetails(customer.btwNumber)}
        >
          Get Details
        </button>
      </div>

      <div className={styles.AddCustomerFieldsContainer}>
        {/* General customer information */}
        {/* required fields */}
        <p>* required field</p>
        <label htmlFor="inputName5" className="form-label">
          Name{" "}
          <span className={`${!isnameValid ? styles.redAsterisk : ""}`}>
            {" "}
            *
          </span>
        </label>
        <input
          type="text"
          className={`form-control ${!isnameValid ? styles.invalidInput : ""}`}
          id="inputName5"
          value={customer.name || ""}
          onChange={(e) => handleInputChange("name", e.target.value)}
        />

        <label htmlFor="inputEmail5" className="form-label">
          Email
        </label>
        <input
          type="email"
          className="form-control"
          id="inputEmail5"
          value={customer.email || ""}
          onChange={(e) => setCustomer({ ...customer, email: e.target.value })}
        />

        <label htmlFor="address" className="form-label">
          Address
        </label>
        <input
          type="text"
          className="form-control"
          id="address"
          placeholder="1234 Main St"
          value={customer.address || ""}
          onChange={(e) =>
            setCustomer({ ...customer, address: e.target.value })
          }
        />

        <label htmlFor="city" className="form-label">
          City
        </label>
        <input
          type="text"
          className="form-control"
          id="city"
          value={customer.city || ""}
          onChange={(e) => setCustomer({ ...customer, city: e.target.value })}
        />

        <label htmlFor="state" className="form-label">
          State
        </label>
        <input
          type="text"
          className="form-control"
          id="state"
          value={customer.state || ""}
          onChange={(e) => setCustomer({ ...customer, state: e.target.value })}
        />

        <label htmlFor="country" className="form-label">
          Country
        </label>
        <input
          type="text"
          className="form-control"
          id="country"
          value={customer.country || ""}
          onChange={(e) =>
            setCustomer({ ...customer, country: e.target.value })
          }
        />

        <label htmlFor="zipCode" className="form-label">
          Zip Code
        </label>
        <input
          type="text"
          className="form-control"
          id="zipCode"
          value={customer.zipCode || ""}
          onChange={(e) =>
            setCustomer({ ...customer, zipCode: e.target.value })
          }
        />

        <label htmlFor="inputCity5" className="form-label">
          Contact Person
        </label>
        <input
          type="text"
          className="form-control"
          id="inputCity5"
          value={customer.contactPerson || ""}
          onChange={(e) =>
            setCustomer({ ...customer, contactPerson: e.target.value })
          }
        />

        <label htmlFor="inputCity5" className="form-label">
          Position
        </label>
        <input
          type="text"
          className="form-control"
          id="inputCity5"
          value={customer.position || ""}
          onChange={(e) =>
            setCustomer({ ...customer, position: e.target.value })
          }
        />

        <label htmlFor="phone" className="form-label">
          Phone Number
        </label>
        <input
          type="tel"
          className="form-control"
          id="phone"
          value={customer.phone || ""}
          onChange={(e) =>
            setCustomer({
              ...customer,
              phone: e.target.value,
              clientNumber: e.target.value.slice(-5),
            })
          }
        />
        {/* Delivery Address */}
        <div
          className={styles.deliveryAddressAddIcon}
          onClick={toggleDeliveryFields}
        >
          <svg
            className="addSvg w-6 h-6"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            width={"24px"}
            height={"24px"}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 4.5v15m7.5-7.5h-15"
            />
          </svg>
          <span className={styles.deliveryAddressAddHide}>
            {showDeliveryFields
              ? "Hide Delivery Address"
              : "Add Delivery Address"}
          </span>
        </div>

        {/* Delivery address fields */}
        {showDeliveryFields && (
          <>
            <div className={styles.deliveryAddressFields}>
              <label htmlFor="deliveryAddress" className="form-label">
                Delivery Address
              </label>
              <input
                type="text"
                className="form-control"
                id="deliveryAddress"
                value={customer.deliveryAddress || ""}
                onChange={(e) =>
                  setCustomer({
                    ...customer,
                    deliveryAddress: e.target.value,
                  })
                }
              />
              <label htmlFor="deliveryZipCode" className="form-label">
                Delivery Zip Code
              </label>
              <input
                type="text"
                className="form-control"
                id="deliveryZipCode"
                value={customer.deliveryZipCode || ""}
                onChange={(e) =>
                  setCustomer({ ...customer, deliveryZipCode: e.target.value })
                }
              />

              <label htmlFor="deliveryState" className="form-label">
                Delivery State
              </label>
              <input
                type="text"
                className="form-control"
                id="deliveryState"
                value={customer.deliveryState || ""}
                onChange={(e) => {
                  setCustomer({ ...customer, deliveryState: e.target.value });
                }}
              />

              <label htmlFor="deliveryCity" className="form-label">
                Delivery City
              </label>
              <input
                type="text"
                className="form-control"
                id="deliveryCity"
                value={customer.deliveryCity || ""}
                onChange={(e) =>
                  setCustomer({ ...customer, deliveryCity: e.target.value })
                }
              />
              <label htmlFor="deliveryCountry" className="form-label">
                Delivery Country
              </label>
              <input
                type="text"
                className="form-control"
                id="deliveryCountry"
                value={customer.deliveryCountry || ""}
                onChange={(e) =>
                  setCustomer({
                    ...customer,
                    deliveryCountry: e.target.value,
                  })
                }
              />
            </div>
          </>
        )}

        {/* Remaining form fields */}

        <label htmlFor="btwNumber" className="form-label">
          BTW Number
        </label>
        <input
          type="text"
          className="form-control"
          id="btwNumber"
          value={customer.btwNumber || ""}
          onChange={(e) =>
            setCustomer({
              ...customer,
              btwNumber: e.target.value,
              clientNumber: customer.clientNumber
                ? customer.clientNumber
                : e.target.value.slice(-6).replace(/\s/g, ""),
            })
          }
        />
        <label htmlFor="btwAmount" className="form-label">
          BTW Amount
        </label>
        <input
          type="text"
          className="form-control"
          id="btwAmount"
          value={customer.btwAmount || ""}
          onChange={(e) =>
            setCustomer({ ...customer, btwAmount: e.target.value })
          }
        />

        <label htmlFor="korting" className="form-label">
          Discount
        </label>
        <input
          type="text"
          className="form-control"
          id="korting"
          value={customer.discount || ""}
          onChange={(e) =>
            setCustomer({ ...customer, discount: e.target.value })
          }
        />

        <label htmlFor="notes" className="form-label">
          Notes
        </label>
        <textarea
          type="text"
          className="form-control"
          id="notes"
          value={customer.notes || ""}
          onChange={(e) => setCustomer({ ...customer, notes: e.target.value })}
        />

        <label htmlFor="saveAs" className="form-label">
          Save As{" "}
          <span className={`${!isSaveAsValid ? styles.redAsterisk : ""}`}>
            {" "}
            *
          </span>
        </label>
        <input
          type="text"
          className={`form-control ${
            !isSaveAsValid ? styles.invalidInput : ""
          }`}
          id="saveAs"
          value={customer.saveAs || ""}
          onChange={(e) => handleInputChange("saveAs", e.target.value)}
        />

        {/* show the client number it should be the last 5 digits of the phone number if it exists if not the last 5 digits of the btw number and if does not exsits let the user to enter */}

        <label htmlFor="clientNumber" className="form-label">
          Client Number{" "}
          <span className={`${!isClientNumberValid ? styles.redAsterisk : ""}`}>
            {" "}
            *
          </span>
        </label>
        <input
          type="text"
          className={`form-control ${
            !isClientNumberValid ? styles.invalidInput : ""
          }`}
          id="clientNumber"
          placeholder="Client Number"
          value={customer.clientNumber}
          onChange={(e) => {
            handleInputChange(
              "clientNumber",
              e.target.value.slice(-6).replace(/\s/g, "")
            );
          }}
        />

        {/* Show Transport Code checkbox and submit/reset buttons */}
        <label className={styles.formCheckLabel} htmlFor="gridCheck">
          Show Transport Code {""}
          <input
            className={styles.formCheckInput}
            type="checkbox"
            id="gridCheck"
            checked={customer.showTransportCode}
            onChange={(e) =>
              setCustomer({
                ...customer,
                showTransportCode: e.target.checked,
              })
            }
          />
        </label>

        <div className={styles.btns}>
          <button
            type="submit"
            className={styles.submitBtn}
            onClick={handleSubmit}
          >
            Submit
          </button>
          <button
            type="reset"
            className={styles.resetBtn}
            onClick={handleCancel}
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddCustomer;
