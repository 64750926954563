import React from "react";
import { Link, useNavigate } from "react-router-dom";

import "./Logout.css";

const Logout = () => {
  const navigation = useNavigate();

  React.useEffect(() => {
    localStorage.removeItem("token");
    navigation("/Login");
  }, [navigation]);
  return (
    <div className="logout-container">
      <h2>
        You have been logged out successfully
        <br />
        <Link to="/Login" className="logout-link">
          Login
        </Link>
      </h2>
    </div>
  );
};

export default Logout;
