import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { generatePDFsForOrders } from "../pdf/pdfGeneratorDelayed";
import { generateReceiptPDFCombined } from "../print/combindePDFAndPrint";
import overview from "./icon-overview.gif";
import * as XLSX from "xlsx";

import styles from "./ActiveOrders.module.css";
import viewStyles from "./view.module.css";

const InvoicesDrafts = () => {
  if (!localStorage.getItem("token")) {
    window.location.href = "/login";
  }
  const navigate = useNavigate();
  const [inoices, setInvoices] = useState(
    JSON.parse(localStorage.getItem("invoicesDrafts")) || []
  );

  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedInvoice, setSelectedInvoice] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sortBy, setSortBy] = useState(""); // Initialize with an empty string for no initial sorting
  const [sortOrder, setSortOrder] = useState("asc"); // Initialize with ascending order
  const [invoicesSelected, setInvoicesSelected] = useState(
    selectedInvoice.length > 0
  );
  const [searchInput, setSearchInput] = useState("");

  // State to manage selected dates
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [filteredByDate, setFilteredByDate] = useState([]); // Initialize with an empty array [] for no initial filtering

  const handleSort = (column) => {
    if (column === sortBy) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }

    const sortedOrders = [...inoices]
      .filter((order) => order.status !== "Completed")
      .sort(sortFunction);

    setInvoices([...sortedOrders]); // Update the state with a new array
  };

  // Define a sorting function to sort the orders based on the selected column and order
  const sortFunction = (a, b) => {
    let aValue = a[sortBy];
    let bValue = b[sortBy];

    if (sortBy === "id") {
      aValue = parseInt(a.id || a.proformaNumber);
      bValue = parseInt(b.id || b.proformaNumber);
    }

    if (sortBy === "selectedResultCustomer.companyName") {
      aValue = a.selectedResultCustomer?.companyName || "";
      bValue = b.selectedResultCustomer?.companyName || "";
    }

    if (sortBy === "date") {
      aValue = new Date(a.date);
      bValue = new Date(b.date);
    }

    if (sortBy === "totalAmount") {
      aValue = parseFloat(a.totalAmount);
      bValue = parseFloat(b.totalAmount);
    }

    if (aValue < bValue) return sortOrder === "asc" ? -1 : 1;
    if (aValue > bValue) return sortOrder === "asc" ? 1 : -1;
    return 0;
  };

  const sortedOrders = inoices
    .filter((order) => order.status !== "Completed") // Filter out completed orders
    .sort(sortFunction); // Sort the orders based on the selected column and order

  const openModal = (order) => {
    setSelectedOrder(order);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedOrder(null);
  };

  const handleOnExportInvoices = () => {
    if (selectedInvoice.length === 0) {
      const confirm = window.confirm(
        "Are you sure you want to export all active orders?"
      );
      if (!confirm) return;
    }
    const inoicesTOExport =
      selectedInvoice.length > 0 ? selectedInvoice : inoices;
    // Create an array of objects with the desired structure for export
    const exportData = inoicesTOExport.map((order) => {
      const allItemsQuantities = order.items.map((item) => item.quantity); // Calculate quantities based on the order

      const allItemsNames = order.items.map(
        (item) => item.name || item.description
      );
      const allItemsColumns = allItemsNames.reduce((acc, item, index) => {
        return {
          ...acc,
          [item]: allItemsQuantities[index],
        };
      }, {});

      return {
        "Invoice Number": order.proformaNumber || order.id,
        Date: order.date,
        "Client Number": order.selectedResultCustomer.clientNumber,
        name: order.selectedResultCustomer.companyName,
        Discount: order.discountedPriceTotal,
        Total: order.totalAmount,
        ...allItemsColumns,
      };
    });

    // Create a worksheet from the modified data
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.json_to_sheet(exportData);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workBook, workSheet, "Active Orders");

    // Export the workbook to a file
    XLSX.writeFile(workBook, "Active-Orders.xlsx");
  };

  const generatePDFAll = () => {
    if (selectedInvoice.length === 0) {
      const confirm = window.confirm(
        "Are you sure you want to Generate PDFs for all active orders?"
      );
      if (!confirm) return;
    }
    const data = inoices.filter((order) => order.status !== "Completed");

    switch (true) {
      case selectedInvoice.length > 0:
        generatePDFsForOrders(selectedInvoice);
        break;
      case selectedInvoice.length === 0:
        generatePDFsForOrders(data);
        break;
      default:
        // Default case if none of the conditions is met
        setSelectedInvoice([]); // Clear the selected invoices
        break;
    }
    setSelectedInvoice([]); // Clear the selected invoices
  };

  const generatePDFAndPrintAllInOne = () => {
    if (selectedInvoice.length === 0) {
      const confirm = window.confirm(
        "Are you sure you want to Generate PDFs & Print for all active orders?"
      );
      if (!confirm) return;
    }
    const data = inoices.filter((order) => order.status !== "Completed");

    switch (true) {
      case selectedInvoice.length > 0:
        generateReceiptPDFCombined(selectedInvoice);
        break;
      case selectedInvoice.length === 0:
        generateReceiptPDFCombined(data);
        break;
      default:
        // Default case if none of the conditions is met
        setSelectedInvoice([]); // Clear the selected invoices
        break;
    }
    setSelectedInvoice([]); // Clear the selected invoices
  };

  const handleDeleteSelectedInvoices = () => {
    if (selectedInvoice.length === 0) {
      alert("No active orders selected for deletion.");
      return;
    }

    const filtered = inoices.filter(
      (order) => !selectedInvoice.includes(order)
    );

    const updatCatalog = JSON.parse(localStorage.getItem("products")) || [];
    const selectedInvoiceItems = selectedInvoice.map(
      (invoice) => invoice.items
    );
    const allItems = selectedInvoiceItems.flat();
    const updatedCatalog = updatCatalog.map((catalogItem) => {
      const editItem = allItems.find((item) => item.id === catalogItem.id);
      return {
        ...catalogItem,
        quantity: catalogItem.quantity + (editItem ? editItem.quantity : 0),
        papular: catalogItem.papular,
      };
    });

    localStorage.setItem("products", JSON.stringify(updatedCatalog));

    // update local storage
    const invoices = JSON.parse(localStorage.getItem("invoicesDrafts")) || [];

    // ctive orders pllus all orders with status Completed
    const filteredInvoices = [
      ...filtered,
      ...invoices.filter((order) => order.status === "Completed"),
    ];

    localStorage.setItem("invoicesDrafts", JSON.stringify(filteredInvoices));

    setInvoices([...filteredInvoices]);
    setSelectedInvoice([]);
  };

  const handleSelectedInvoice = (e) => {
    if (e === "all") {
      if (selectedInvoice.length === 0) {
        setSelectedInvoice([...sortedOrders]);
      } else {
        setSelectedInvoice([]);
      }
    } else {
      if (selectedInvoice.includes(e)) {
        const filtered = selectedInvoice.filter((item) => item !== e);
        setSelectedInvoice([...filtered]);
      } else {
        setSelectedInvoice([...selectedInvoice, e]);
      }
    }
  };

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
    const inputValue = String(e.target.value).toLowerCase();

    const allOrders = JSON.parse(localStorage.getItem("invoicesDrafts")) || [];

    const allOrdersByDate =
      filteredByDate.length > 0 ? filteredByDate : allOrders;

    const filtered = allOrdersByDate.filter((order) => {
      return (
        order.id.toString().toLowerCase().includes(inputValue) ||
        order.selectedResultCustomer.companyName
          .toLowerCase()
          .includes(inputValue) ||
        order.selectedResultCustomer.clientNumber
          .toLowerCase()
          .includes(inputValue) ||
        order.date.toLowerCase().includes(inputValue) ||
        order.totalAmount.toString().toLowerCase().includes(inputValue) ||
        order.status.toLowerCase().includes(inputValue)
      );
    });
    console.log(filtered);

    setInvoices(filtered);
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;

    if (name === "dateFrom") {
      setDateFrom(value);
      setDateTo(value);
    } else {
      setDateTo(value);
    }

    if (name && value) {
      const dateFromUpdeted = name === "dateFrom" ? value : dateFrom;
      const dateToUpdeted = name === "dateTo" ? value : dateFromUpdeted;
      const allOrders =
        JSON.parse(localStorage.getItem("invoicesDrafts")) || [];

      const filtered = allOrders.filter((order) => {
        return (
          new Date(order.date) >= new Date(dateFromUpdeted) &&
          new Date(order.date) <= new Date(dateToUpdeted)
        );
      });

      setFilteredByDate(filtered);
      setInvoices(filtered);
    }
  };

  const handleReset = () => {
    // Reset form and clear filtered orders
    setDateFrom("");
    setDateTo("");
    const allOrders = JSON.parse(localStorage.getItem("invoicesDrafts")) || [];

    setInvoices(allOrders);
    setFilteredByDate([]);
  };

  const finalizeHandel = () => {
    if (selectedInvoice.length === 0) {
      alert("No active orders selected for finalization.");
      return;
    }

    console.log(selectedInvoice);

    const invoicesDrafts =
      JSON.parse(localStorage.getItem("invoicesDrafts")) || [];

    // Iterate through selectedInvoice items that are in invoicesDrafts
    selectedInvoice.forEach((invoice) => {
      invoice.dateFinalized = new Date().toLocaleDateString();

      // Update the status if items are missing to "waiting-for-missing-items" else "Pending-fulfillment"
      const products = JSON.parse(localStorage.getItem("products")) || [];
      const items = invoice.items;

      const hasItems = items?.some((item) => {
        const product = products.find((product) => product.id === item.id);
        return product?.quantity < item?.quantity;
      });

      const status = hasItems
        ? "waiting-for-missing-items"
        : "Pending-fulfillment";
      invoice.status = status;
      invoice.payments = [{ amount: 0, date: "", paymentMethod: "" }];
    });

    // Remove finalized orders from invoicesDrafts
    const filtered = invoicesDrafts.filter(
      (order) => order.id !== selectedInvoice[0].id
    );
    /*  console.log(filtered); */
    localStorage.setItem("invoicesDrafts", JSON.stringify(filtered));

    // Update local storage for invoices
    const invoicesUpdated = JSON.parse(localStorage.getItem("invoices")) || [];
    localStorage.setItem(
      "invoices",
      JSON.stringify([...invoicesUpdated, ...selectedInvoice])
    );

    // Update state and reset selectedInvoice
    setInvoices([...filtered]);
    setSelectedInvoice([]);

    // Navigate to invoices page
    // window.location.href = "/invoices";
    navigate("/invoices");
  };

  const editHandler = () => {
    if (selectedInvoice.length === 0) {
      alert("No invoice selected for editing.");
      return;
    }

    if (selectedInvoice.length > 1) {
      alert("Please select only one active order for editing.");
      return;
    }

    // console.log(selectedInvoice[0]);

    const invoiceId = selectedInvoice[0].id;
    // console.log(invoiceId);

    // window.location.href = `/EditInvoice/${invoiceId}`;
    navigate(`/EditInvoice/${invoiceId}`);
    setSelectedInvoice([]);
  };

  useEffect(() => {
    setInvoicesSelected(selectedInvoice.length > 0);
  }, [selectedInvoice]);

  return (
    <div
      style={{
        justifyContent: "space-around",
        marginTop: "8rem",
        marginLeft: "22rem",
        marginRight: "2rem",
      }}
    >
      <h1>Draft Invoices</h1>

      {/* add here the alert */}
      <div
        className={`alert alert-warning alert-dismissible fade show`}
        style={{
          display: "none",
        }}
        role="alert"
      >
        <h4 className="alert-heading">Generate PDFs</h4>
        <p>
          Please wait while we generate the PDFs for all invoices. This might
          take a while depending on the number of invoices.
        </p>
        <hr />
        <p className="mb-0">
          Please do not close the browser or refresh the page until all PDFs
          have been generated.
        </p>
        <p className="munber-of-pdf"></p>
      </div>
      <div>
        <NavLink
          to={"/ImportInvoicesExcelTable"}
          style={{
            padding: "0.07rem 0.5rem",
            margin: "0.2rem",
            marginBottom: "1rem",
            color: "white",
            borderRadius: "5px",
            textDecoration: "none",
            cursor: "pointer",
          }}
          className="btn btn-primary"
        >
          Import Excel Invoices
        </NavLink>
      </div>
      <div>
        {/* download pdf of all invoices */}
        <div>
          <input
            type="text"
            placeholder="Search Any thing, Date (yyyy-mm-dd)"
            className={styles.searchInput}
            value={searchInput}
            onChange={handleSearch}
          />
          {/* input date  */}
          <div className={styles.dateInput}>
            <label htmlFor="dateFrom">
              From:{" "}
              <input
                type="date"
                id="dateFrom"
                name="dateFrom"
                value={dateFrom}
                onChange={handleDateChange}
              />
            </label>

            <label htmlFor="dateTo">
              To:{" "}
              <input
                type="date"
                id="dateTo"
                name="dateTo"
                value={dateTo}
                onChange={handleDateChange}
              />
            </label>
            <button type="button" onClick={handleReset}>
              Reset
            </button>
          </div>
        </div>
        {/* search by id name date total  status */}
        {/* empty div to hold the place for styling butnsActionsNotSelected */}
        <div
          className={`${
            invoicesSelected ? "" : styles.butnsActionsNotSelected
          }`}
        >
          <p
            style={{
              width: "48px",
              height: "48px",
            }}
          ></p>
        </div>
        <div className={`${invoicesSelected ? styles.butnsActions : ""}`}>
          <button
            onClick={generatePDFAndPrintAllInOne}
            style={{ marginRight: "2rem" }}
            title="Generate PDF & Print"
          >
            <svg
              width="24"
              height="24"
              fill="currentColor"
              className="bi bi-printer"
              viewBox="0 0 16 16"
            >
              <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
            </svg>
            <br />
            <span className={styles.textUnderIcon}>Print</span>
          </button>

          <button
            onClick={generatePDFAll}
            style={{ marginRight: "2rem" }}
            title="Generate PDFs"
          >
            <svg
              width="24"
              height="24"
              fill="currentColor"
              className="bi bi-file-earmark-pdf"
              viewBox="0 0 16 16"
            >
              <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
              <path d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
            </svg>
            <br />
            <span className={styles.textUnderIcon}>PDF</span>
          </button>

          {/* download excel of all invoices */}
          <button
            onClick={handleOnExportInvoices}
            style={{ marginRight: "2rem" }}
            title="Export to Excel"
          >
            <svg
              width="24"
              height="24"
              fill="currentColor"
              className="bi bi-filetype-xls"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM6.472 15.29a1.176 1.176 0 0 1-.111-.449h.765a.578.578 0 0 0 .254.384c.07.049.154.087.25.114.095.028.202.041.319.041.164 0 .302-.023.413-.07a.559.559 0 0 0 .255-.193.507.507 0 0 0 .085-.29.387.387 0 0 0-.153-.326c-.101-.08-.255-.144-.462-.193l-.619-.143a1.72 1.72 0 0 1-.539-.214 1.001 1.001 0 0 1-.351-.367 1.068 1.068 0 0 1-.123-.524c0-.244.063-.457.19-.639.127-.181.303-.322.527-.422.225-.1.484-.149.777-.149.305 0 .564.05.78.152.216.102.383.239.5.41.12.17.186.359.2.566h-.75a.56.56 0 0 0-.12-.258.625.625 0 0 0-.247-.181.923.923 0 0 0-.369-.068c-.217 0-.388.05-.513.152a.472.472 0 0 0-.184.384c0 .121.048.22.143.3a.97.97 0 0 0 .405.175l.62.143c.217.05.406.12.566.211a1 1 0 0 1 .375.358c.09.148.135.335.135.56 0 .247-.063.466-.188.656a1.216 1.216 0 0 1-.539.439c-.234.105-.52.158-.858.158-.254 0-.476-.03-.665-.09a1.404 1.404 0 0 1-.478-.252 1.13 1.13 0 0 1-.29-.375Zm-2.945-3.358h-.893L1.81 13.37h-.036l-.832-1.438h-.93l1.227 1.983L0 15.931h.861l.853-1.415h.035l.85 1.415h.908L2.253 13.94l1.274-2.007Zm2.727 3.325H4.557v-3.325h-.79v4h2.487v-.675Z"
              />
            </svg>
            <br />
            <span className={styles.textUnderIcon}>Excel</span>
          </button>

          {/* delete selected invoices */}
          <button
            style={{ marginRight: "2rem" }}
            onClick={() => {
              handleDeleteSelectedInvoices();
            }}
            title="Delete Selected"
          >
            <svg
              height="29"
              id="Layer_1"
              version="1.1"
              viewBox="0 0 512 512"
              fill="white"
              width="30"
            >
              <g>
                <path d="M400,113.3h-80v-20c0-16.2-13.1-29.3-29.3-29.3h-69.5C205.1,64,192,77.1,192,93.3v20h-80V128h21.1l23.6,290.7   c0,16.2,13.1,29.3,29.3,29.3h141c16.2,0,29.3-13.1,29.3-29.3L379.6,128H400V113.3z M206.6,93.3c0-8.1,6.6-14.7,14.6-14.7h69.5   c8.1,0,14.6,6.6,14.6,14.7v20h-98.7V93.3z M341.6,417.9l0,0.4v0.4c0,8.1-6.6,14.7-14.6,14.7H186c-8.1,0-14.6-6.6-14.6-14.7v-0.4   l0-0.4L147.7,128h217.2L341.6,417.9z" />
                <g>
                  <rect height="241" width="14" x="249" y="160" />
                  <polygon points="320,160 305.4,160 294.7,401 309.3,401   " />
                  <polygon points="206.5,160 192,160 202.7,401 217.3,401   " />
                </g>
              </g>
            </svg>
            <br />
            <span className={styles.textUnderIcon}>Trash</span>
          </button>
          {/* button to edit with a icon */}
          <button
            style={{ marginRight: "2rem" }}
            title="Edit"
            onClick={editHandler}
          >
            <svg
              x="0px"
              y="0px"
              width="26"
              height="26"
              viewBox="0 0 50 50"
              fill="white"
            >
              <path d="M 43.125 2 C 41.878906 2 40.636719 2.488281 39.6875 3.4375 L 38.875 4.25 L 45.75 11.125 C 45.746094 11.128906 46.5625 10.3125 46.5625 10.3125 C 48.464844 8.410156 48.460938 5.335938 46.5625 3.4375 C 45.609375 2.488281 44.371094 2 43.125 2 Z M 37.34375 6.03125 C 37.117188 6.0625 36.90625 6.175781 36.75 6.34375 L 4.3125 38.8125 C 4.183594 38.929688 4.085938 39.082031 4.03125 39.25 L 2.03125 46.75 C 1.941406 47.09375 2.042969 47.457031 2.292969 47.707031 C 2.542969 47.957031 2.90625 48.058594 3.25 47.96875 L 10.75 45.96875 C 10.917969 45.914063 11.070313 45.816406 11.1875 45.6875 L 43.65625 13.25 C 44.054688 12.863281 44.058594 12.226563 43.671875 11.828125 C 43.285156 11.429688 42.648438 11.425781 42.25 11.8125 L 9.96875 44.09375 L 5.90625 40.03125 L 38.1875 7.75 C 38.488281 7.460938 38.578125 7.011719 38.410156 6.628906 C 38.242188 6.246094 37.855469 6.007813 37.4375 6.03125 C 37.40625 6.03125 37.375 6.03125 37.34375 6.03125 Z"></path>
            </svg>
            <br />
            <span className={styles.textUnderIcon}>Edit</span>
          </button>
          <button
            title="Finalize"
            onClick={finalizeHandel}
            style={{ marginRight: "2rem" }}
          >
            <svg
              x="0px"
              y="0px"
              width="26"
              height="26"
              viewBox="0 0 24 24"
              fill="white"
            >
              <path d="m17.5,11c-3.584,0-6.5,2.916-6.5,6.5s2.916,6.5,6.5,6.5,6.5-2.916,6.5-6.5-2.916-6.5-6.5-6.5Zm0,12c-3.032,0-5.5-2.468-5.5-5.5s2.468-5.5,5.5-5.5,5.5,2.468,5.5,5.5-2.468,5.5-5.5,5.5Zm2.939-7.451l.701.713-3.456,3.399c-.217.218-.51.339-.822.339s-.604-.121-.825-.343l-1.85-1.793.695-.719,1.856,1.8c.099.099.157.089.24.007l3.46-3.403Zm-8.416-4.549H4v-1h9.497c-.527.282-1.021.618-1.474,1Zm0,13H0V2.5C0,1.121,1.121,0,2.5,0h13c1.379,0,2.5,1.121,2.5,2.5v6.515c-.166-.01-.332-.015-.5-.015s-.334.005-.5.015V2.5c0-.827-.673-1.5-1.5-1.5H2.5c-.827,0-1.5.673-1.5,1.5v20.5h10.02c.306.36.642.695,1.004,1Zm1.977-18H4v-1h10v1ZM4,15h5v1h-5v-1Z" />
            </svg>
            <br />
            <span className={styles.textUnderIcon}>Finalize</span>
          </button>
        </div>
      </div>
      <table className="table table-striped table-bordered table-hover table-sm">
        <thead style={{ backgroundColor: "#A8A8A8" }}>
          <tr>
            <th
              scope="col"
              style={{
                textAlign: "center",
                verticalAlign: "middle",
                backgroundColor: "#A8A8A8",
                cursor: "pointer",
              }}
              onClick={() => handleSort("id")}
            >
              #
              {sortBy === "id" && (
                <i
                  className="material-icons"
                  style={{
                    fontSize: "24px",
                    marginLeft: "0.5rem",
                    verticalAlign: "middle",
                  }}
                >
                  {sortOrder === "asc" ? "arrow_drop_down" : "arrow_drop_up"}
                </i>
              )}
            </th>
            <th
              scope="col"
              style={{
                textAlign: "center",
                verticalAlign: "middle",
                backgroundColor: "#A8A8A8",
                cursor: "pointer",
              }}
              onClick={() => handleSort("selectedResultCustomer.companyName")}
            >
              Customer Name
              {sortBy === "selectedResultCustomer.companyName" && (
                <i
                  className="material-icons"
                  style={{
                    fontSize: "24px",
                    marginLeft: "0.5rem",
                    verticalAlign: "middle",
                  }}
                >
                  {sortOrder === "asc" ? "arrow_drop_down" : "arrow_drop_up"}
                </i>
              )}
            </th>
            <th
              scope="col"
              style={{
                textAlign: "center",
                verticalAlign: "middle",
                backgroundColor: "#A8A8A8",
              }}
              onClick={() => handleSort("date")}
            >
              Order Date
              {sortBy === "date" && (
                <i
                  className="material-icons"
                  style={{
                    fontSize: "24px",
                    marginLeft: "0.5rem",
                    verticalAlign: "middle",
                  }}
                >
                  {sortOrder === "asc" ? "arrow_drop_down" : "arrow_drop_up"}
                </i>
              )}
            </th>
            <th
              scope="col"
              style={{
                textAlign: "center",
                verticalAlign: "middle",
                backgroundColor: "#A8A8A8",
              }}
              onClick={() => handleSort("totalAmount")}
            >
              Total
              {sortBy === "totalAmount" && (
                <i
                  className="material-icons"
                  style={{
                    fontSize: "24px",
                    marginLeft: "0.5rem",
                    verticalAlign: "middle",
                  }}
                >
                  {sortOrder === "asc" ? "arrow_drop_down" : "arrow_drop_up"}
                </i>
              )}
            </th>
            <th
              scope="col"
              style={{
                textAlign: "center",
                verticalAlign: "middle",
                backgroundColor: "#A8A8A8",
              }}
            >
              View Order
            </th>
            <th
              style={{
                textAlign: "center",
                verticalAlign: "middle",
                backgroundColor: "#A8A8A8",
              }}
            >
              <input
                type="checkbox"
                checked={selectedInvoice.length === sortedOrders.length}
                style={{ marginLeft: "1rem", marginTop: "0.5rem" }}
                onClick={(e) => {
                  handleSelectedInvoice("all");
                }}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedOrders.map((order, index) => {
            return (
              <tr key={index}>
                <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                  {order.id || order.proformaNumber}
                </td>
                <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                  {order.selectedResultCustomer.companyName}
                </td>
                <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                  {order.date}
                </td>
                <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                  {order.totalAmount}
                </td>
                <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                  <button onClick={() => openModal(order)}>
                    {/* overview gif */}
                    <img src={overview} alt="overview" width="28" height="28" />
                  </button>
                </td>
                <td className={styles.tdCheckbox}>
                  <input
                    type="checkbox"
                    className={styles.checkbox}
                    checked={selectedInvoice.includes(order)}
                    onClick={(e) => {
                      handleSelectedInvoice(order);
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {isModalOpen && (
        <div id="myModal" className="modal">
          <div className="modal-content" style={{ width: "70%" }}>
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            <div className="modal-header">
              <h2>Order Details</h2>

              {/* invoice number */}
              <div className={viewStyles.invoiceNumber}>
                <p>
                  Invoice Number:{" "}
                  <span>
                    {selectedOrder.id || selectedOrder.proformaNumber}
                  </span>
                </p>
                {/* date */}
                <p>
                  Date: <span>{selectedOrder.date}</span>
                </p>
                {/* client number */}
                <p>
                  Client Number:{" "}
                  <span>
                    {selectedOrder.selectedResultCustomer.clientNumber}
                  </span>
                </p>
                <p>
                  Customer Name:{" "}
                  <span>
                    {selectedOrder.selectedResultCustomer.companyName}
                  </span>
                </p>
              </div>
            </div>
            <div className="modal-body">
              {/* Order details in a table */}
              <table>
                <thead>
                  <tr>
                    <th className={viewStyles.previewTh}>Item Id</th>
                    <th className={viewStyles.previewTh}>Item Name</th>
                    <th className={viewStyles.previewTh}>Item Description</th>
                    <th className={viewStyles.previewTh}>Item Price</th>
                    <th className={viewStyles.previewTh}>Item Quantity</th>

                    {selectedOrder.btw > 0 && (
                      <th className={viewStyles.previewTh}>Item BTW</th>
                    )}
                    <th className={viewStyles.previewTh}>Item Discount</th>
                    <th className={viewStyles.previewTh}>Item Total</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedOrder.items.map((item, index) => (
                    <tr key={index} className={viewStyles.previewTr}>
                      <td className={viewStyles.previewTd}>{item.id}</td>
                      <td className={viewStyles.previewTd}>{item.name}</td>
                      <td className={viewStyles.previewTd}>
                        {item.description}
                      </td>
                      <td className={viewStyles.previewTd}>{item.sellPrice}</td>
                      <td className={viewStyles.previewTd}>{item.quantity}</td>

                      {selectedOrder.btw > 0 && (
                        <td className={viewStyles.previewTd}>{item.btw}</td>
                      )}
                      <td className={viewStyles.previewTd}>
                        {Number(item.discount).toFixed(2)}
                      </td>
                      <td className={viewStyles.previewTd}>
                        {Number(item.total).toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div>
                {/* subtotal */}
                <p>
                  Subtotal: <span>{selectedOrder.subTotal}</span>
                </p>
                {/* discount */}
                <p>
                  Discount: <span>{selectedOrder.discountedPriceTotal}</span>
                </p>
                {/* tax */}
                {selectedOrder.btw > 0 && (
                  <p>
                    Tax: <span>{selectedOrder.btw}</span>
                  </p>
                )}

                {/* total amount */}
                <p>
                  Total Amount: <span>{selectedOrder.totalAmount}</span>
                </p>
              </div>
            </div>
            <div className="modal-footer">
              {/* provided by perfectInvoicing */}
              <p>
                provided by{" "}
                <a
                  href="https://perfectinvoicing.netlify.app/"
                  target="_blank"
                  rel="noreferrer"
                >
                  PerfectInvoicing
                </a>
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InvoicesDrafts;
