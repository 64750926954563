import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import config from "./config/config";

import styles from "./MissingItems.module.css";

const calculateQuantityBoxesAndUnits = (item) => {
  // console.log("item", item);
  switch (item.pricePerUnitOrBox) {
    case "priceOther":
    case "other":
    case "pricePerXAmount":
      if (Math.abs(item.quantity) % item.unitsInBox === 0) {
        return `${Math.floor(
          Math.abs(item.quantity) / item.unitsInBox
        )} box/es (${item.unitsInBox} X  ${item.pricePerXAmount} in a box)`;
      } else {
        return `${Math.floor(
          Math.abs(item.quantity) / item.unitsInBox
        )} boxes ${Math.abs(item.quantity) % item.unitsInBox} units
        (${item.unitsInBox} X ${item.pricePerXAmount} in a box)`;
      }
      break;
    case "unit":
    case "pricePerUnit":
      // if the quantity is less than a box return the quantity (units per box) else return the quantity divided by the units per box and add the remainder
      if (Math.abs(item.quantity) < item.unitsInBox) {
        return `${Math.abs(item.quantity)} units (${item.unitsInBox} in a box)`;
      } else {
        return `${Math.floor(
          Math.abs(item.quantity) / item.unitsInBox
        )} box/es ${
          Math.abs(item.quantity) % item.unitsInBox > 0
            ? (Math.abs(item.quantity) % item.unitsInBox) +
              `units (${item.unitsInBox} in a box)`
            : `(${item.unitsInBox} in a box)`
        }`;
      }
      break;
    case "pricePerBox":
      return `${Math.abs(item.quantity)} box/es (${
        item.unitsInBox > 1
          ? item.unitsInBox + " single units"
          : item.unitsInBox + " single unit"
      }) (${item.unitsInBox} in a box)`;
      break;
    default:
      return Math.abs(item.quantity) * item.pricePerXAmount;
  }
};

const MissingItems = () => {
  // if the token is not available the user will not be able to access the page
  if (!localStorage.getItem("token")) {
    window.location.href = "/login";
  }
  // State variables
  const [products, setProducts] = useState([]);
  const [missingItems, setMissingItems] = useState([]);
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [selectedFilters, setSelectedFilters] = useState([]);

  // Fetch products from the server
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${config.URL_PROD}/products`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        if (!response.ok) {
          throw new Error("Failed to fetch products");
        }
        const data = await response.json();
        setProducts(data.products);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    if (localStorage.getItem("token")) {
      fetchProducts();
    } else {
      console.log("Token not available");
    }
  }, []);

  // Transform product data
  useEffect(() => {
    const transformedItems = products
      .filter((item) => item.quantity < 1)
      .map(transformProductToItem);
    setMissingItems(transformedItems);
  }, [products]);

  // Function to transform product data to item
  const transformProductToItem = (item) => ({
    id: item.id,
    product: item.name,
    description: item.description,
    pricePerUnitOrBox: item.pricePerUnitOrBox,
    unitsInBox: item.unitsInBox,
    pricePerXAmount: item.pricePerXAmount,
    quantityUnits: item.quantity,
    quantityBoxesAndUnits: calculateQuantityBoxesAndUnits(item),
    wholesaler: item.manufacturer,
    // totalPriceToPayOrder: item.buyPrice * item.quantity, make the quantity positive
    totalPriceToPayOrder: Math.abs(item.quantity) * item.buyPrice,
  });

  let listOfWholesaler = [];

  const items = missingItems;

  for (const object of items) {
    if (
      !listOfWholesaler.includes(object.wholesaler) &&
      object.wholesaler !== "" &&
      object.wholesaler !== null &&
      object.wholesaler !== undefined
    ) {
      listOfWholesaler.push(object.wholesaler);
    }
  }
  for (const object of items) {
    // listOfWholesaler is empty string write unknown instead and push it to the array
    if (
      object.wholesaler === "" ||
      object.wholesaler === null ||
      (object.wholesaler === undefined && !listOfWholesaler.includes("Unknown"))
    ) {
      // remove the empty string from the
      // push the unknown to the array
      listOfWholesaler.push("Unknown");
    }
  }

  const filterOptions = [
    "Remove Filters",
    "Quantity Less Than 0",
    ...listOfWholesaler,
  ];

  // No separate state for filteredItems, use a variable
  let filteredItems = [...items];

  // Filter out items based on selected filters
  selectedFilters.forEach((filter) => {
    switch (filter) {
      case "Remove Filters":
        //  rewrite the filteredItems to the items
        filteredItems = [...items];
        setSelectedFilters([]);
        break;
      case "Quantity Less Than 0":
        // remove the items that are not less than 0
        filteredItems = filteredItems.filter((item) => item.quantityUnits < 0);
        break;
      // multiple wholesaler
      case "Unknown":
        // Filter out items for specific wholesaler(s)
        filteredItems = filteredItems.filter(
          (item) =>
            item.wholesaler === "" ||
            item.wholesaler === " " ||
            item.wholesaler === null ||
            item.wholesaler === undefined
        );
        break;
      default:
        // Filter out items for specific wholesaler(s)
        filteredItems = filteredItems.filter((item) =>
          selectedFilters.includes(item.wholesaler)
        );
        break;
    }
  });

  const handleSort = (columnName) => {
    if (sortedColumn === columnName) {
      setSortDirection((prevSortDirection) =>
        prevSortDirection === "asc" ? "desc" : "asc"
      );
    } else {
      setSortedColumn(columnName);
      setSortDirection("asc");
    }
  };

  const handleFilterToggle = (filter) => {
    if (selectedFilters.includes(filter)) {
      // Filter is already selected, remove it
      setSelectedFilters((prevFilters) =>
        prevFilters.filter((prevFilter) => prevFilter !== filter)
      );
    } else {
      // Filter is not selected, add it
      setSelectedFilters((prevFilters) => [...prevFilters, filter]);
    }
  };

  useEffect(() => {
    // Sort the items based on the current sorting criteria
    const sortedItems = filteredItems.sort((a, b) => {
      const aValue = String(a[sortedColumn] || "");
      const bValue = String(b[sortedColumn] || "");

      if (sortedColumn === "wholesaler") {
        if (aValue === bValue) {
          return a.quantity - b.quantity;
        } else {
          return aValue.localeCompare(bValue, undefined, {
            numeric: true,
            sensitivity: "base",
          });
        }
      } else {
        if (sortDirection === "asc") {
          return aValue.localeCompare(bValue, undefined, {
            numeric: true,
            sensitivity: "base",
          });
        } else {
          return bValue.localeCompare(aValue, undefined, {
            numeric: true,
            sensitivity: "base",
          });
        }
      }
    });

    // Update the state with the sorted items
    setMissingItems(sortedItems);
  }, [selectedFilters, sortedColumn, sortDirection]);

  const pdfPrint = () => {
    // Create a new instance of jsPDF
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
      putOnlyUsedFonts: false,
    });

    // Set up initial parameters
    const pageWidth = doc.internal.pageSize.width;
    const margin = 20;
    let y = margin; // Start at the top of the page

    // Add title to the PDF
    doc.setFontSize(26);
    doc.setFont("helvetica", "bold");
    doc.text(`Missing Items`, pageWidth / 2, y, { align: "center" });
    y += 20; // Increase the offset to create space between title and table

    // Set font size for the table
    doc.setFontSize(10);

    // Add margin between title and table
    doc.autoTable({
      head: [
        [
          "Product",
          "Description",
          "Quantity Boxes Units",
          "wholesaler",
          "Order Amount",
        ],
      ],
      // change the head to the column
      headStyles: { fillColor: [84, 82, 75] }, // Apply styles to all column headers
      body: filteredItems.map((item) => [
        item.product,
        item.description,
        item.quantityBoxesAndUnits,
        item.wholesaler,
        item.totalPriceToPayOrder.toFixed(2),
      ]),
      startY: y,
    });

    /*   const dateYearMonthDAy = new Date().toISOString().slice(0, 10);

    const fileName = `Order for ${listOfWholesaler} ${dateYearMonthDAy}.pdf`;
    // Save the PDF with a specific name
    doc.save(fileName); */

    // Print the PDF
    doc.autoPrint();

    // Open the PDF in a new tab
    window.open(doc.output("bloburl"), "_blank");
  };

  const pdfPrintForEach = () => {
    // Create a new instance of jsPDF
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
      putOnlyUsedFonts: false,
    });

    let listOfWholesaler = [];
    filteredItems.forEach((item) => {
      if (!listOfWholesaler.includes(item.wholesaler)) {
        listOfWholesaler.push(item.wholesaler);
      }
    });

    // Check if there are any items at all
    const hasItems = listOfWholesaler.some((wholesaler) => {
      return filteredItems.some((item) => item.wholesaler === wholesaler);
    });

    if (hasItems) {
      listOfWholesaler.forEach((wholesaler, index) => {
        if (index > 0) {
          doc.addPage();
        }

        // Filter items for the current wholesaler
        const wholesalerItems = filteredItems.filter(
          (item) => item.wholesaler === wholesaler
        );

        // Only add a new page if there are items for the wholesaler
        if (wholesalerItems.length > 0) {
          // Add a new page for each wholesaler

          // Set up initial parameters
          const pageWidth = doc.internal.pageSize.width;
          const margin = 20;
          let y = margin; // Start at the top of the page

          // Add title to the PDF
          doc.setFontSize(26);
          doc.setFont("helvetica", "bold");
          doc.text(`${wholesaler}`, pageWidth / 2, y, { align: "center" });
          y += 20; // Increase the offset to create space between title and table

          // Set font size for the table
          doc.setFontSize(10);

          // Add margin between title and table
          doc.autoTable({
            head: [
              [
                "Product",
                "Description",
                "Quantity Boxes Units",
                "Order Amount",
              ],
            ],
            headStyles: { fillColor: [84, 82, 75] },
            body: wholesalerItems.map((item) => [
              item.product,
              item.description,
              item.quantityBoxesAndUnits,
              item.totalPriceToPayOrder.toFixed(2),
            ]),
            startY: y,

            // add a footer to the table with the total amount first one can be used to display the total amount nicely with a bit more style
            /*   foot: [
              ["", "", "Total Amount", ""],
              [
                "",
                "",
                wholesalerItems
                  .reduce((total, item) => total + item.totalPriceToPayOrder, 0)
                  .toFixed(2),
                "",
              ],
            ],
            showFoot: "lastPage", */
            didDrawPage: (hookData) => {
              // Add the total amount to the footer
              doc.setFontSize(10);
              doc.text(
                `Total Amount: ${wholesalerItems
                  .reduce((total, item) => total + item.totalPriceToPayOrder, 0)
                  .toFixed(2)}`,
                hookData.settings.margin.left,
                hookData.cursor.y + 10
              );
            },
          });

          // add a footer to the page
          doc.setFontSize(10);
          doc.text(
            `Powered by: PerfectInvoicing, 0486789633`,
            pageWidth / 2,
            doc.internal.pageSize.height - 10,
            { align: "center" }
          );
        }
      });
    }
    // print the PDF with a specific name
    doc.autoPrint();
    // Open the PDF in a new tab when the print view is opened in a new tab and close the tab after printing
    window.open(doc.output("bloburl"), "_blank");

    /* const dateYearMonthDAy = new Date().toISOString().slice(0, 10); */
    /* const fileName = `Order ${dateYearMonthDAy}.pdf`; */
    // Save the PDF with a specific name
    /* doc.save(fileName); */
    // Save the PDF with a specific name
    /* doc.save(fileName); */
  };

  const handleOnExportMissingItems = () => {
    const data = filteredItems.map((item) => ({
      Product: item.product,
      Description: item.description,
      Quantity_Units: item.quantityUnits || "NaN",
      Quantity_Boxes_Units: item.quantityBoxesAndUnits || "NaN",
      Wholesaler: item.wholesaler,
      totalPriceToPayOrder: item.totalPriceToPayOrder.toFixed(2),
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, "Missing Items");

    const dateYearMonthDAy = new Date().toISOString().slice(0, 10);
    const fileName = `Missing Items ${dateYearMonthDAy}.xlsx`;

    XLSX.writeFile(workbook, fileName);
  };

  return (
    <div
      className={styles.missingItemsContainer}
      style={{
        marginTop: "8rem",
      }}
    >
      <h1>Missing Items</h1>

      <div className={styles.missingItemsInfo}>
        <button
          onClick={pdfPrintForEach}
          style={{ marginRight: "2rem" }}
          title="Generate PDF & Print"
        >
          <svg
            width="24"
            height="24"
            fill="currentColor"
            className="bi bi-printer"
            viewBox="0 0 16 16"
          >
            <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
          </svg>
          <br />
          <span className={styles.textUnderIcon}>Print each</span>
        </button>
        <button
          onClick={pdfPrint}
          style={{ marginRight: "2rem" }}
          title="Generate PDF & Print"
        >
          <svg
            width="24"
            height="24"
            fill="currentColor"
            className="bi bi-printer"
            viewBox="0 0 16 16"
          >
            <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
          </svg>
          <br />
          <span className={styles.textUnderIcon}>Print in 1</span>
        </button>
        <button onClick={handleOnExportMissingItems} title="Export to Excel">
          <svg
            width="24"
            height="24"
            fill="currentColor"
            className="bi bi-filetype-xls"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM6.472 15.29a1.176 1.176 0 0 1-.111-.449h.765a.578.578 0 0 0 .254.384c.07.049.154.087.25.114.095.028.202.041.319.041.164 0 .302-.023.413-.07a.559.559 0 0 0 .255-.193.507.507 0 0 0 .085-.29.387.387 0 0 0-.153-.326c-.101-.08-.255-.144-.462-.193l-.619-.143a1.72 1.72 0 0 1-.539-.214 1.001 1.001 0 0 1-.351-.367 1.068 1.068 0 0 1-.123-.524c0-.244.063-.457.19-.639.127-.181.303-.322.527-.422.225-.1.484-.149.777-.149.305 0 .564.05.78.152.216.102.383.239.5.41.12.17.186.359.2.566h-.75a.56.56 0 0 0-.12-.258.625.625 0 0 0-.247-.181.923.923 0 0 0-.369-.068c-.217 0-.388.05-.513.152a.472.472 0 0 0-.184.384c0 .121.048.22.143.3a.97.97 0 0 0 .405.175l.62.143c.217.05.406.12.566.211a1 1 0 0 1 .375.358c.09.148.135.335.135.56 0 .247-.063.466-.188.656a1.216 1.216 0 0 1-.539.439c-.234.105-.52.158-.858.158-.254 0-.476-.03-.665-.09a1.404 1.404 0 0 1-.478-.252 1.13 1.13 0 0 1-.29-.375Zm-2.945-3.358h-.893L1.81 13.37h-.036l-.832-1.438h-.93l1.227 1.983L0 15.931h.861l.853-1.415h.035l.85 1.415h.908L2.253 13.94l1.274-2.007Zm2.727 3.325H4.557v-3.325h-.79v4h2.487v-.675Z"
            />
          </svg>
          <br />
          <span className={styles.textUnderIcon}>Excel</span>
        </button>
      </div>

      <div className={styles.rapper}>
        <div className={styles.filterContainer}>
          <h2>Filters</h2>
          <ul>
            {filterOptions.map((option) => (
              <li key={option}>
                <input
                  type="checkbox"
                  id={option}
                  checked={selectedFilters.includes(option)}
                  onChange={() => handleFilterToggle(option)}
                />
                <label htmlFor={option}>{option}</label>
              </li>
            ))}
          </ul>
        </div>

        <table className={styles.tableMissingItems}>
          <thead className={styles.tableheader}>
            <tr>
              <SortableHeader
                column="id"
                label="Product ID"
                sortedColumn={sortedColumn}
                sortDirection={sortDirection}
                onSort={handleSort}
              />
              <SortableHeader
                column="product"
                label="Products Missing"
                sortedColumn={sortedColumn}
                sortDirection={sortDirection}
                onSort={handleSort}
              />
              <SortableHeader
                column="description"
                label="Description"
                sortedColumn={sortedColumn}
                sortDirection={sortDirection}
                onSort={handleSort}
              />
              <SortableHeader
                column="quantityUnits"
                label="Quantity (Pack/Unit)"
                sortedColumn={sortedColumn}
                sortDirection={sortDirection}
                onSort={handleSort}
              />
              <SortableHeader
                column="quantityBoxesAndUnits"
                label="Quantity Boxes & Units"
                sortedColumn={sortedColumn}
                sortDirection={sortDirection}
                onSort={handleSort}
              />
              <SortableHeader
                column="wholesaler"
                label="wholesaler"
                sortedColumn={sortedColumn}
                sortDirection={sortDirection}
                onSort={handleSort}
              />
              <SortableHeader
                column="totalPriceToPayOrder"
                label="Order Amount"
                sortedColumn={sortedColumn}
                sortDirection={sortDirection}
                onSort={handleSort}
              />
            </tr>
          </thead>
          <tbody>
            {missingItems.map((item, index) => (
              <React.Fragment key={item.id}>
                {/* Display group header if the current item is the first in the group */}
                {index === 0 ||
                item.wholesaler !== missingItems[index - 1].wholesaler ? (
                  <tr
                    key={`groupHeader-${item.wholesaler}`}
                    className={styles.groupHeader}
                  >
                    <td colSpan="6">{`Grouped by Supplier: ${item.wholesaler}`}</td>
                  </tr>
                ) : null}

                {/* Display the item */}
                <tr key={item.id}>
                  <td className={styles.tdMissingItemsContent}>{item.id}</td>
                  <td className={styles.tdMissingItemsContent}>
                    {item.product}
                  </td>
                  <td className={styles.tdMissingItemsContent}>
                    {item.description}
                  </td>
                  <td className={styles.tdMissingItemsContent}>
                    {item.quantityUnits}
                  </td>
                  <td className={styles.tdMissingItemsContent}>
                    {item.quantityBoxesAndUnits}
                  </td>
                  <td className={styles.tdMissingItemsContent}>
                    {item.wholesaler}
                  </td>
                  <td className={styles.tdMissingItemsContent}>
                    {item.totalPriceToPayOrder.toFixed(2)}
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const SortableHeader = ({
  column,
  label,
  sortedColumn,
  sortDirection,
  onSort,
}) => {
  const handleClick = () => {
    onSort(column);
  };

  const isSorted = sortedColumn === column;
  const arrow = isSorted ? (sortDirection === "asc" ? "▲" : "▼") : "↕";

  return (
    <th onClick={handleClick} className={styles.tableheader}>
      {label} {arrow}
    </th>
  );
};

export default MissingItems;
