import React from "react";
import { Link } from "react-router-dom";
import CheckForToken from "../checkForToken";

const AdminSettings = () => {
  CheckForToken();
  return (
    <div>
      <h1>Admin Settings</h1>
      <nav>
        <ul>
          <li>
            <Link to="/CompanyDetails">Company Details</Link>
          </li>
          <li>
            <Link to="/ProformaNumberSetting">Set Proforma Invoice Number</Link>
          </li>
          <li>
            <Link to="/InvoiceNumberSetting">Set Invoice Number</Link>
          </li>
          <li>
            <Link to="/QuoteNumberSetting">Set Quote Number</Link>
          </li>
          <li>
            <Link to="/FooterProformaInvoice">Footer Proforma Invoice</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default AdminSettings;
