import React, { useState, useEffect } from "react";

import styles from "./pos.module.css";
import stylesForLoading from "../loading.module.css";

import config from "../../components/config/config";

const Checkout = ({
  total,
  setTotal,
  setPopupCheckout,
  popupCheckout,
  cart,
  setCart,
  setQuantityBox,
  setQuantity,
  costumers,
}) => {
  const [settings, setSettings] = useState();
  const [loading, setLoading] = useState(false);
  const [clientInfo, setClientInfo] = useState("");
  const [search, setSearch] = useState("");
  const [products, setProducts] = useState([]);

  const fetchSettings = async () => {
    try {
      const response = await fetch(`${config.URL_PROD}/settings`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.ok) {
        const responseJson = await response.json();
        setSettings(responseJson.data);
        console.log("success fetching settings");
      } else {
        console.error("Error fetching settings:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching settings:", error);
    }
  };
  useEffect(() => {
    fetchSettings();
  }, []);

  const invoiceNumber = JSON.parse(localStorage.getItem("invoiceNumber") || 1);

  async function generateInvoiceNumberWithPrefix(
    invoiceNumber,
    serverEndpoint
  ) {
    console.log("invoiceNumber", invoiceNumber);
    // Define the prefix for the invoice number
    // split the invoiceNumber by the first digit even if it is  0
    const prefix = invoiceNumber.toString().split(/(\d)/)[0] || "";

    // Determine the number of leading zeros in the existing invoice number
    const leadingZerosCount = invoiceNumber.toString().match(/^0*/)[0].length;

    // Extract only the numeric part of the invoiceNumber
    const numericPart = invoiceNumber.replace(/[^\d]/g, ""); // This removes all non-digit characters

    // Parse the numeric part as an integer
    const invoiceNumberDigits = parseInt(numericPart, 10);

    let invoiceNumberWithPrefix = "";

    if (!isNaN(invoiceNumberDigits)) {
      // Increment the invoice number
      const incrementedNumber = invoiceNumberDigits + 1;

      // Pad the incremented number with leading zeros
      const paddedNumber = incrementedNumber
        .toString()
        .padStart(leadingZerosCount + incrementedNumber.toString().length, "0");

      // Form the complete invoice number dynamically
      invoiceNumberWithPrefix = `${prefix}${paddedNumber}`;

      // Send the generated invoice number to the server
      try {
        const response = await fetch(serverEndpoint, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ invoiceNumber: invoiceNumberWithPrefix }),
        });

        if (response.ok) {
          console.log("Invoice number sent to the server successfully!");
          setLoading(false);
        } else {
          console.error(
            "Failed to send invoice number to the server:",
            response.statusText
          );
        }
      } catch (error) {
        console.error("Error sending invoice number to the server:", error);
      }
    } else {
      console.error("Failed to parse invoiceNumber:", invoiceNumber);
    }

    return invoiceNumberWithPrefix;
  }

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${config.URL_PROD}/products`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        const data = await response.json();
        const { count } = data;
        const { products } = data;
        /*  console.log(count);
        console.log(products); */
        setProducts(products);
      } catch (error) {
        console.log(error);
      }
    };
    fetchProducts(products);
  }, []);

  const handleSearch = (e) => {
    const inputValue = e.target.value;
    const filteredResults = costumers.filter((contact) => {
      const contactWords = [
        (contact.name || "").toLowerCase(),
        (contact.saveAs || "").toLowerCase(),
        (contact.address || "").toLowerCase(),
        (contact.zipCode || "").toLowerCase(),
        (contact.city || "").toLowerCase(),
        (contact.country || "").toLowerCase(),
        (contact.contactPerson || "").toLowerCase(),
        (contact.email || "").toLowerCase(),
        (contact.phone || "").replace(/-| /g, ""), // Remove hyphens and spaces from phone number
        (contact.deliveryAddress || "").toLowerCase(),
        (contact.deliveryZip || "").toLowerCase(),
        (contact.deliveryCity || "").toLowerCase(),
        (contact.deliveryCountry || "").toLowerCase(),
        (contact.position || "").toLowerCase(),
        (contact.notes || "").toLowerCase(),
        ((contact.btwNumber || "").slice(2) || "").toLowerCase(),
        contact.clientNumber || "",
        contact.phone || "",
      ];

      return contactWords.some((word) =>
        word.includes(inputValue.toLowerCase())
      );
    });

    if (filteredResults.length > 0) {
      let clientInfo = "";
      const properties = [
        "name",
        "address",
        "zipCode",
        "city",
        "country",
        "contactPerson",
        "email",
        "phone",
        "deliveryAddress",
        "deliveryZip",
        "deliveryCity",
        "deliveryCountry",
        "position",
        "notes",
        "btwNumber",
        "clientNumber",
      ];

      for (const property of properties) {
        const value = filteredResults[0][property];
        if (value) {
          clientInfo += value + "\n";
        }
      }

      setClientInfo(clientInfo.trim()); // trim() to remove any leading/trailing whitespace
    } else {
      setClientInfo(""); // Clear clientInfo if no results found
    }
  };

  const handleNextClient = async () => {
    setLoading(true);
    const invoiceNumber =
      JSON.parse(localStorage.getItem("invoiceNumber")) ||
      localStorage.setItem("invoiceNumber", JSON.stringify(1));
    const companyDetails = JSON.parse(localStorage.getItem("companyDetails"));
    const clientInfoSplit = clientInfo.split("\n");
    // search the client in the costumers array by clientInfo[0] and return the client object
    const client = costumers.find(
      (contact) => contact.name === clientInfoSplit[0]
    );

    const calculatequantityBoxToQuantityAndSaveItOnEachItem = (items) => {
      return items.map((item) => {
        /*    console.log("item", item);
        console.log("item.quantityBox", item.quantityBox);
        console.log("item.quantity", item.quantity);
        console.log("item.unitsInBox", item.unitsInBox);
        console.log("item.quantityBox", item.quantityBox * item.unitsInBox); */
        if (item.quantityBox > 0 && !item.quantity > 0) {
          item.quantity = item.quantityBox * item.unitsInBox;
          delete item.quantityBox;
        } else if (item.quantityBox > 0 && item.quantity > 0) {
          item.quantity += item.quantityBox * item.unitsInBox;
          delete item.quantityBox;
        } else if (item.quantityBox < 0 && item.quantity > 0) {
          item.quantity += item.quantityBox * item.unitsInBox;
          delete item.quantityBox;
        } else if (item.quantityBox < 0 && item.quantity < 0) {
          item.quantity = item.quantityBox * item.unitsInBox;
          delete item.quantityBox;
        }
        return item;
      });
    };

    const modifiedCart =
      calculatequantityBoxToQuantityAndSaveItOnEachItem(cart);

    // Define the calculateSubTotal function
    const calculateSubTotal = (items) => {
      let subTotal = 0;
      for (const item of items) {
        subTotal +=
          (Number(item.sellPrice) || Number(item.salePrice) || 0) *
          Number(item.quantity);
      }
      return subTotal;
    };

    // Calculate the discountedPriceTotal
    const calculateDiscountedPriceTotal = (subTotal, totalAmount) => {
      const discountedPriceTotal = subTotal - totalAmount;

      return discountedPriceTotal >= 0 ? Number(discountedPriceTotal) : 0;
    };

    // Usage:

    const invoiceData = {
      companyDetails: {
        ...companyDetails,
      },
      // Set other fields to default values
      proformaNumber: 0,
      selectedResultCustomer: {
        ...client,
      },
      items: modifiedCart,
      subTotal: calculateSubTotal(modifiedCart),
      totalAmount: total,
      discountedPriceTotal: calculateDiscountedPriceTotal(
        calculateSubTotal(modifiedCart),
        total
      ),
      btw: 0,
      date: new Date().toISOString().split("T")[0],
      id: invoiceNumber,
      status: "",
      dateFinalized: new Date().toISOString().split("T")[0],
      payments: [],
    };

    // Filter the products in the cart from the products in the catalog
    const productsInCart = products.filter((product) =>
      modifiedCart.some((cartItem) => cartItem.id === product.id)
    );

    // update the products in the catalog with reduced quantity but dont loop through the other products when one product is updated
    const updatedProducts = productsInCart.map((product) => {
      const cartItem = modifiedCart.find(
        (cartItem) => cartItem.id === product.id
      );
      if (cartItem) {
        product.quantity = product.quantity - cartItem.quantity;
        // popular products if it is now undefined set it to 0
        product.popular = product.popular || 0;
        // add the quantity to the popular products
        product.popular += cartItem.quantity;
      }
      return product;
    });

    console.log("updatedProducts", updatedProducts);

    try {
      // Update products
      const productsResponse = await fetch(`${config.URL_PROD}/products`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(
          updatedProducts.map((product) => ({
            ...product,
            id: Number(product.id),
          }))
        ),
      });

      if (!productsResponse.ok) {
        throw new Error("Failed to update products");
      }

      // Send invoice data
      const invoicesResponse = await fetch(`${config.URL_PROD}/invoices`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(invoiceData),
      });

      if (!invoicesResponse.ok) {
        throw new Error("Failed to send invoice data");
      }

      // Process response data for invoices
      const data = await invoicesResponse.json();
      setPopupCheckout(!popupCheckout);

      const invoiceNumber = JSON.parse(localStorage.getItem("invoiceNumber"));
      const id = settings[0]._id;

      // If the invoice was successfully created, increment the invoice number
      if (settings) {
        const serverEndpoint = `${config.URL_PROD}/settings/${id}`;
        const generatedInvoiceNumber = await generateInvoiceNumberWithPrefix(
          invoiceNumber,
          serverEndpoint
        );

        localStorage.setItem(
          "invoiceNumber",
          JSON.stringify(generatedInvoiceNumber)
        );
      } else {
        console.error("Settings not found!");
      }
      setTotal(0);
      setCart([]);

      // Third operation
      // Your code for the third operation goes here...
    } catch (error) {
      console.error("Error:", error);
    }

    document.getElementById("id").focus();
    setQuantityBox("");
    setQuantity("");
  };

  if (loading) {
    return <div className={styles.stylesForLoading}>Loading...</div>;
  }

  return (
    // overlay modal popup
    <div
      className={styles.overlay}
      // on click don't close the modal pagenation should not work
      onClick={(e) => e.stopPropagation()}
    >
      <div className={styles.modal}>
        <button
          className={styles.closeModal}
          onClick={() => setPopupCheckout(!popupCheckout)}
        >
          Close
        </button>
        <h2>Checkout</h2>

        <h3>Total: {Number(total).toFixed(2)}</h3>
        {/* file in a textearea a name or phone number or email btw number */}
        <textarea
          placeholder="Name, client number, btw number, address, phone number or email"
          rows={1}
          cols={25}
          className={styles.textarea}
          value={search}
          onChange={(e) => {
            handleSearch(e);
            setSearch(e.target.value);
          }}
          // set the focus on the textarea
          autoFocus
          // on key press enter call the handleEnterClick function
          onKeyDown={(e) => {
            if (e.key === "Enter" && clientInfo) {
              handleNextClient();
            }
          }}
        ></textarea>
        <br />
        <textarea
          placeholder="Name, client"
          rows={4}
          cols={30}
          className={styles.textarea}
          value={clientInfo}
          id="clientInfo"
        ></textarea>
        <br />
        <button onClick={handleNextClient}>Next Client</button>
      </div>
    </div>
  );
};

export default Checkout;
