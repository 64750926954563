import React, { useState } from "react";
import { Link } from "react-router-dom";

import "./FooterProformaInvoice.css";
import "../components/buttons.css";

import CheckForToken from "../checkForToken";
const Footer = () => {
  CheckForToken();
  const [accountInfo, setAccountInfo] = useState({
    bic: "",
    iban: "",
  });

  const [paymentInfo, setPaymentInfo] = useState({
    beneficiary: "",
    bankAddress: "",
    city: "",
  });

  const [goodsInfo, setGoodsInfo] = useState({
    message: "",
  });

  return (
    <div
      className="d-flex align-items-center justify-content-between"
      style={{ marginLeft: "10%" }}
    >
      <main id="main" className="main">
        <div className="footer">
          <h1>Footer</h1>
          <div className="footer__left">
            <label htmlFor="bicInput">BIC:</label>
            <input
              type="text"
              id="bicInput"
              value={accountInfo.bic}
              onChange={(e) =>
                setAccountInfo({ ...accountInfo, bic: e.target.value })
              }
            />
            <label htmlFor="ibanInput">IBAN:</label>
            <input
              type="text"
              id="ibanInput"
              value={accountInfo.iban}
              onChange={(e) =>
                setAccountInfo({ ...accountInfo, iban: e.target.value })
              }
            />
          </div>
          <div className="footer__right">
            <label htmlFor="beneficiaryInput">Beneficiary:</label>
            <input
              type="text"
              id="beneficiaryInput"
              value={paymentInfo.beneficiary}
              onChange={(e) =>
                setPaymentInfo({ ...paymentInfo, beneficiary: e.target.value })
              }
            />
            <label htmlFor="bankAddressInput">Bank Address:</label>
            <input
              type="text"
              id="bankAddressInput"
              value={paymentInfo.bankAddress}
              onChange={(e) =>
                setPaymentInfo({ ...paymentInfo, bankAddress: e.target.value })
              }
            />
            <label htmlFor="cityInput">City:</label>
            <input
              type="text"
              id="cityInput"
              value={paymentInfo.city}
              onChange={(e) =>
                setPaymentInfo({ ...paymentInfo, city: e.target.value })
              }
            />
          </div>
          <div className="footer__center">
            <label htmlFor="goodsInfoInput">Message:</label>
            <textarea
              id="goodsInfoInput"
              value={goodsInfo.message}
              onChange={(e) =>
                setGoodsInfo({ ...goodsInfo, message: e.target.value })
              }
            />
          </div>
          {/* buttons save  to local storage and go to proforma invoice or cancel */}
          <div className="footer__buttons">
            <button
              className="button__save"
              onClick={() => {
                localStorage.setItem(
                  "accountInfo",
                  JSON.stringify(accountInfo)
                );
                localStorage.setItem(
                  "paymentInfo",
                  JSON.stringify(paymentInfo)
                );
                localStorage.setItem("goodsInfo", JSON.stringify(goodsInfo));
              }}
            >
              Save
            </button>
            <button className="button__cancel">Cancel</button>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Footer;
