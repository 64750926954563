import jsPDF from "jspdf";
import "jspdf-autotable";
import base64Rubic from "../components/base64Rubic";
import config from "../components/config/config";

let productList = [];
const fetchProducts = async () => {
  try {
    const response = await fetch(`${config.URL_PROD}/products`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      const { count } = data;
      const { products } = data;
      productList = products;
    }
  } catch (error) {
    console.log(error);
  }
};

fetchProducts();

const generatePDFPackingListByProduct = async (groupedByProduct) => {
  try {
    // Call fetchProducts and wait for it to complete
    await fetchProducts();
    // Now productList should be populated
    // Proceed with generating PDF using productList
  } catch (error) {
    console.log(error);
  }
  const companyPerferdHeaderBackgroundColor = localStorage.getItem(
    "tableHeaderBackgroundColor"
  );

  const showClientNumberInsteadOfName =
    localStorage.getItem("showIdInsteadOfName") === "true";

  // find the product in the array of products and return an array of objects with product name, id, quantity, pricePerUnitOrBox, unitsInBox, pricePerXAmount

  const doc = new jsPDF();

  const isYiddish = (text) => {
    return /[א-ת]/.test(text);
  };

  // Load custom font
  doc.addFileToVFS("Rubik", base64Rubic);
  doc.addFont("Rubik", "Rubik", "normal");
  doc.setFont("helvetica"); // Set default font

  let currentProduct = null;

  Object.entries(groupedByProduct).forEach(
    ([productName, productData], index) => {
      // destructuring the productData array and returning the company name and quantity
      const { companyName, quantity, id, itemName } = productData[0];
      // console.log("productName", productName);

      // console.log("companyName", companyName);
      // console.log("companyQuantity", quantity);
      // console.log("productId", id);

      // get the group of the product quantity
      const companyQuantity = productData.map((company) => {
        return company.quantity;
      });

      let productData2;
      try {
        productData2 = productList.find((item) => item.id === id);
      } catch (error) {
        console.log("Error finding product in productList:", error);
        return; // Skip processing this product if an error occurs
      }

      // Ensure productData2 is valid before proceeding
      if (!productData2) {
        console.log("Product not found in productList:", id);
        return; // Skip processing this product if it's not found in productList
      }

      // map the productData2 and return the quantity of the product
      const pricePerUnitOrBox = productData2?.pricePerUnitOrBox;
      const unitsInBox = productData2?.unitsInBox;
      const pricePerXAmount = productData2?.pricePerXAmount;

      // console.log("pricePerUnitOrBox", pricePerUnitOrBox);
      // console.log("unitsInBox", unitsInBox);
      // console.log("pricePerXAmount", pricePerXAmount);

      const quantityByBoxOrSingle = companyQuantity.map((quantity) => {
        if (
          pricePerUnitOrBox === "priceOther" ||
          pricePerUnitOrBox === "other" ||
          pricePerUnitOrBox === "pricePerXAmount"
        ) {
          if (quantity % unitsInBox === 0) {
            return `${Math.floor(quantity / unitsInBox)} boxes`; //. (${quantity} X ${pricePerXAmount} in a box)
          } else if (quantity > unitsInBox) {
            return `${Math.floor(quantity / unitsInBox)} boxes. ${
              quantity % unitsInBox
            } singles`; // (${unitsInBox} in a box)
          } else {
            return `.${quantity} singles`; // Handle case where unitsInBox is not defined
          }
        }
        if (
          pricePerUnitOrBox === "pricePerUnit" ||
          pricePerUnitOrBox === "unit"
        ) {
          if (
            unitsInBox &&
            quantity > unitsInBox &&
            quantity % unitsInBox > 0
          ) {
            return `${Math.floor(quantity / unitsInBox)} boxes. ${Math.floor(
              quantity % unitsInBox
            )} singles`;
          } else if (
            unitsInBox &&
            quantity > unitsInBox &&
            (quantity % quantity) % unitsInBox < 1
          ) {
            return `${Math.floor(quantity / unitsInBox)} boxes`;
          } else if (quantity % unitsInBox === 0) {
            return `${quantity / unitsInBox} boxes`;
          } else {
            return `.${quantity} singles`; // Handle case where unitsInBox is not defined
          }
        }
        if (pricePerUnitOrBox === "pricePerBox") {
          return `${quantity} boxes`;
        }
        return ""; // Handle other cases
      });

      const quantityByBoxOrSingleArray = quantityByBoxOrSingle.map((item) => {
        if (item) {
          return item.split(".");
        } else {
          return [null, null]; // Handle the case where item is undefined
        }
      });

      // Check if any Yiddish name exists
      if (productData.some((company) => isYiddish(company.companyName))) {
        doc.setFont("Rubik"); // Set the font for Yiddish names
      } else {
        doc.setFont("helvetica"); // Set default font
      }

      // page dimensions
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();

      // Calculate the center of the page
      const centerX = pageWidth / 2;
      const centerY = pageHeight / 2;

      const hexToRgb = (hex, lighter) => {
        // Remove the '#' if it's present
        hex = hex.replace("#", "");

        // Extract the individual components
        let r = parseInt(hex.substring(0, 2), 16);
        let g = parseInt(hex.substring(2, 4), 16);
        let b = parseInt(hex.substring(4, 6), 16);

        // Increase the values for a lighter color by the lighter value
        r += lighter;
        g += lighter;
        b += lighter;

        // Ensure values stay within 0-255 range
        r = Math.min(Math.max(0, r), 255);
        g = Math.min(Math.max(0, g), 255);
        b = Math.min(Math.max(0, b), 255);

        // Return the new color as a valid string
        return `rgb(${r}, ${g}, ${b})`;
      };

      // companyPerferdHeaderBackgroundColor = #1ea10c make the rows color a bit lighter
      const lighterColor = hexToRgb(companyPerferdHeaderBackgroundColor, 180); // 50 is the lighter value

      // Adjust the startY value to add margin from the top of the table
      let marginFromTop = 10;

      // Add product id if it's different from the current product id
      const productNameToDisplay = () => {
        if (currentProduct !== productName) {
          currentProduct = productName;
          doc.setFontSize(20);
          doc.text(
            `Product ID ${id} - ${itemName}`,
            centerX,
            marginFromTop + 10,
            {
              align: "center",
            }
          );
        } else {
          //  if the product is the same as the current product, don't add the product id
          doc.setFontSize(20);
          doc.text(
            `Product ID ${id} - ${itemName}`,
            centerX,
            marginFromTop + 0,
            {
              align: "center",
            }
          );
        }
      };

      productNameToDisplay();

      const columns = ["Customer", "Qty Box", "Qty Single"];
      const rows = productData.map((company) => {
        const companyIndex = productData.indexOf(company);
        const quantityArray = quantityByBoxOrSingleArray[companyIndex];

        // Check if quantityArray is null or undefined before accessing its elements
        const qtyBox =
          quantityArray && quantityArray[0] !== null ? quantityArray[0] : " ";
        const qtySingle =
          quantityArray && quantityArray[1] !== null ? quantityArray[1] : " ";

        return [
          !showClientNumberInsteadOfName
            ? isYiddish(company.companyName)
              ? company.companyName.split("").reverse().join("")
              : company.companyName
            : company.companyID,
          qtyBox,
          qtySingle,
        ];
      });

      // Use the didParseCell hook to set font for specific cells
      doc.autoTable({
        styles: {
          font: "Rubik",
          fontStyle: "normal",
          fontSize: 16,
          fontStyle: "bold",
        },
        headStyles: {
          fontStyle: "bold",
          fillColor: companyPerferdHeaderBackgroundColor || [41, 41, 41],
          textColor: 240,
        },
        head: [columns],
        body: rows,
        startY: (marginFromTop += 20),
        didParseCell: function (data) {
          if (data.section === "head") {
            data.cell.styles.fillColor =
              companyPerferdHeaderBackgroundColor || [41, 41, 41];
            data.cell.styles.textColor = 240;
          }
        },
        alternateRowStyles: {
          fillColor: [lighterColor, "rgb(255, 255, 255)"],
        },
        startX: 50,
        theme: "grid",
        didDrawPage: function (data) {
          if (data.pageCount > 1) {
            productNameToDisplay();
            doc.text("Page " + data.pageCount, 10, 10);
          }
        },
        // move the table down to make space for the next product
        margin: { top: 35 },
      });

      //if it's the last product, don't add a page break
      if (index !== Object.entries(groupedByProduct).length - 1) {
        doc.addPage();
      }
    }
  );

  // print using bloburl
  const blobURL = doc.output("bloburl");
  window.open(blobURL, "_blank");
};

export default generatePDFPackingListByProduct;
