import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import config from "./config/config";
import * as XLSX from "xlsx";
import styles from "./ContactsView.module.css";

function ContactsView() {
  if (!localStorage.getItem("token")) {
    window.location.href = "/login";
  }

  const navigate = useNavigate();

  const [contacts, setContacts] = useState([]);
  const [originalContacts, setOriginalContacts] = useState([]);
  const [expandedRow, setExpandedRow] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const lastSortKey = useRef(null);
  const sortOrder = useRef("asc");

  useEffect(() => {
    // get the contacts from the server
    fetch(`${config.URL_PROD}/contacts`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setOriginalContacts(data || []);
        setContacts(data || []);
      })
      .catch((error) => console.error("Error:", error));
  }, []);

  const toggleRow = (index) => {
    if (index === expandedRow) {
      setExpandedRow(null);
    } else {
      setExpandedRow(index);
    }
  };

  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    const filteredContacts = originalContacts.filter((contact) => {
      return Object.values(contact).some((value) => {
        if (typeof value === "string") {
          const trimmedValue = value.replace(/\s/g, ""); // Remove spaces from the field
          const trimmedSearchTerm = searchTerm.replace(/\s/g, ""); // Remove spaces from the search term
          return trimmedValue
            .toLowerCase()
            .includes(trimmedSearchTerm.toLowerCase());
        }
        return false;
      });
    });

    setContacts(filteredContacts);
    setSearchTerm(searchTerm); // Store the search term as is
  };

  const clearSearch = () => {
    setSearchTerm("");
    setContacts(originalContacts);
  };

  const handleEdit = (contact) => {
    navigate(`/EditCustomer/${contact.clientNumber}`);
  };

  const handleOnExportInvoices = () => {
    const data = contacts.map((contact) => ({
      "Saved As": contact.saveAs,
      "Client Number": contact.clientNumber,
      Email: contact.email,
      Tel: contact.phone,
      Discount: `${contact.discount} %`,
      "BTW Amount": `${contact.btwAmount} %`,
      position: contact.position,
      name: contact.name,
      contactPerson: contact.contactPerson,
      notes: contact.notes,
      btwNumber: contact.btwNumber,
      showTransportCode: contact.showTransportCode ? "Show" : "Hide",
      address: contact.address,
      city: contact.city,
      country: contact.country,
      zipCode: contact.zipCode,
      deliveryAddress: contact.deliveryAddress,
      deliveryCity: contact.deliveryCity,
      deliveryCountry: contact.deliveryCountry,
      deliveryZipCode: contact.deliveryZipCode,
    }));

    // create a new excel file
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Contacts");
    XLSX.writeFile(wb, "contacts.xlsx");

    // window.location.href = "/contacts.xlsx";
  };

  const handleDelete = (contact) => {
    const answer = window.confirm(
      `Are you sure you want to remove ${contact.saveAs}?`
    );

    if (answer === true) {
      // make a soft delete just add a deleted property to the contact
      /*  contact.deleted = true;

      // get the contacts from local storage
      const storedContacts = JSON.parse(localStorage.getItem("contacts")) || [];

      // find the index of the contact to be soft deleted
      const index = storedContacts.findIndex(
        (c) => c.clientNumber === contact.clientNumber
      );

      // replace the contact with the soft deleted contact
      storedContacts[index] = contact; */

      /*    // save the contacts back to local storage
      localStorage.setItem("contacts", JSON.stringify(storedContacts)); */
      // save to the server
      fetch(`${config.URL_PROD}/contacts/${contact.clientNumber}`, {
        method: "delete",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(contact),
      })
        .then((response) => response.json())
        .then((data) => {
          // remove the data from the state
          const newContacts = contacts.filter(
            (c) => c.clientNumber !== data.clientNumber
          );
          setContacts(newContacts);
          window.location.href = "/ContactsView";
        })
        .catch((error) => console.error("Error:", error));
    } else {
      return;
    }
  };

  const handleDeleteAll = () => {
    // Assuming contacts is an array of objects with _id properties
    const contactIds = contacts.map((contact) => ({ _id: contact._id }));
    const answer = window.confirm(
      `Are you sure you want to remove all contacts?`
    );

    if (answer === true) {
      fetch(`${config.URL_PROD}/contacts`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(contactIds),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setContacts([]);
        })
        .catch((error) => console.error("Error:", error));
    }

    window.location.href = "/ContactsView";
  };

  const renderSortIcon = (columnKey) => {
    if (lastSortKey.current === columnKey) {
      return sortOrder.current === "asc" ? "↓" : "↑";
    } else {
      return "↕";
    }
  };

  const handleSort = (key) => {
    if (key === lastSortKey.current) {
      sortOrder.current = sortOrder.current === "asc" ? "desc" : "asc";
    }

    lastSortKey.current = key;

    const sortedContacts = [...contacts].sort((a, b) => {
      if (key === "Saved As") {
        if (a.saveAs < b.saveAs) {
          return sortOrder.current === "asc" ? -1 : 1;
        }
        if (a.saveAs > b.saveAs) {
          return sortOrder.current === "asc" ? 1 : -1;
        }
        return 0;
      }

      if (key === "Client Number") {
        if (a.clientNumber < b.clientNumber) {
          return sortOrder.current === "asc" ? -1 : 1;
        }
        if (a.clientNumber > b.clientNumber) {
          return sortOrder.current === "asc" ? 1 : -1;
        }
        return 0;
      }

      if (key === "Email") {
        if (a.email < b.email) {
          return sortOrder.current === "asc" ? -1 : 1;
        }
        if (a.email > b.email) {
          return sortOrder.current === "asc" ? 1 : -1;
        }
        return 0;
      }

      if (key === "Tel") {
        if (a.phone < b.phone) {
          return sortOrder.current === "asc" ? -1 : 1;
        }
        if (a.phone > b.phone) {
          return sortOrder.current === "asc" ? 1 : -1;
        }
        return 0;
      }

      if (key === "Discount") {
        if (a.discount < b.discount) {
          return sortOrder.current === "asc" ? -1 : 1;
        }
        if (a.discount > b.discount) {
          return sortOrder.current === "asc" ? 1 : -1;
        }
        return 0;
      }

      if (key === "BTW Amount") {
        if (a.btwAmount < b.btwAmount) {
          return sortOrder.current === "asc" ? -1 : 1;
        }
        if (a.btwAmount > b.btwAmount) {
          return sortOrder.current === "asc" ? 1 : -1;
        }
        return 0;
      }

      return 0;
    });

    setContacts(sortedContacts);
  };

  return (
    <div className={styles.mainBox}>
      <h1>Contact List</h1>

      {/* export to excel */}
      <button
        onClick={handleOnExportInvoices}
        style={{ marginRight: "2rem" }}
        title="Export to Excel"
      >
        <svg
          width="24"
          height="24"
          fill="currentColor"
          className="bi bi-filetype-xls"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM6.472 15.29a1.176 1.176 0 0 1-.111-.449h.765a.578.578 0 0 0 .254.384c.07.049.154.087.25.114.095.028.202.041.319.041.164 0 .302-.023.413-.07a.559.559 0 0 0 .255-.193.507.507 0 0 0 .085-.29.387.387 0 0 0-.153-.326c-.101-.08-.255-.144-.462-.193l-.619-.143a1.72 1.72 0 0 1-.539-.214 1.001 1.001 0 0 1-.351-.367 1.068 1.068 0 0 1-.123-.524c0-.244.063-.457.19-.639.127-.181.303-.322.527-.422.225-.1.484-.149.777-.149.305 0 .564.05.78.152.216.102.383.239.5.41.12.17.186.359.2.566h-.75a.56.56 0 0 0-.12-.258.625.625 0 0 0-.247-.181.923.923 0 0 0-.369-.068c-.217 0-.388.05-.513.152a.472.472 0 0 0-.184.384c0 .121.048.22.143.3a.97.97 0 0 0 .405.175l.62.143c.217.05.406.12.566.211a1 1 0 0 1 .375.358c.09.148.135.335.135.56 0 .247-.063.466-.188.656a1.216 1.216 0 0 1-.539.439c-.234.105-.52.158-.858.158-.254 0-.476-.03-.665-.09a1.404 1.404 0 0 1-.478-.252 1.13 1.13 0 0 1-.29-.375Zm-2.945-3.358h-.893L1.81 13.37h-.036l-.832-1.438h-.93l1.227 1.983L0 15.931h.861l.853-1.415h.035l.85 1.415h.908L2.253 13.94l1.274-2.007Zm2.727 3.325H4.557v-3.325h-.79v4h2.487v-.675Z"
          />
        </svg>
        <br />
        <span className={styles.textUnderIcon}>Excel</span>
      </button>

      {/* search box */}
      <div className={styles.searchBox}>
        <input
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={handleSearch}
        />
        <button className={styles.clearButton} onClick={clearSearch}>
          Clear Search
        </button>
      </div>

      <div className={styles.addContact}>
        <Link to="/AddCustomer">
          <button className={styles.addContactsBtn}>Add Contact</button>
        </Link>
        <button className={styles.removeContactsBtn} onClick={handleDeleteAll}>
          Delete All
        </button>
      </div>

      <table className={styles.ContactsViewTable}>
        <thead>
          <tr>
            <th onClick={() => handleSort("Saved As")}>
              Saved As {renderSortIcon("Saved As")}
            </th>
            <th onClick={() => handleSort("Client Number")}>
              Client Number {renderSortIcon("Client Number")}
            </th>
            <th onClick={() => handleSort("Email")}>
              Email {renderSortIcon("Email")}
            </th>
            <th onClick={() => handleSort("Tel")}>
              Tel {renderSortIcon("Tel")}
            </th>
            <th onClick={() => handleSort("Discount")}>
              Discount {renderSortIcon("Discount")}
            </th>
            <th onClick={() => handleSort("BTW Amount")}>
              BTW Amount {renderSortIcon("BTW Amount")}
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {contacts.map((contact, index) =>
            contact.deleted ? null : (
              <React.Fragment key={index}>
                <tr
                  onDoubleClick={() => toggleRow(index)}
                  onClick={() => toggleRow(null)}
                >
                  <td className={styles.ContactsViewHover}>{contact.saveAs}</td>
                  <td className={styles.ContactsViewHover}>
                    {contact.clientNumber}
                  </td>
                  <td className={styles.ContactsViewHover}>{contact.email}</td>
                  <td className={styles.ContactsViewHover}>{contact.phone}</td>
                  <td className={styles.ContactsViewHover}>
                    {contact.discount} %
                  </td>
                  <td className={styles.ContactsViewHover}>
                    {contact.btwAmount} %
                  </td>
                  <td className={styles.ContactsViewHover}>
                    <button
                      className={styles.ContactsViewButtonEdit}
                      onClick={() => handleEdit(contact)}
                    >
                      Edit
                    </button>
                    <button
                      className={styles.ContactsViewButtonRemove}
                      onClick={() => handleDelete(contact)}
                    >
                      Remove
                    </button>
                  </td>
                </tr>
                {expandedRow === index && (
                  //  make a nice expandable row
                  <td colSpan="9">
                    <div
                      className={`${styles.tableView} ${styles.expandedRow}`}
                    >
                      <div>
                        <strong>Position:</strong> {contact.position}
                      </div>
                      <div>
                        <strong>Company Name:</strong> {contact.name}
                      </div>
                      <div>
                        <strong>Contact Person:</strong> {contact.contactPerson}
                      </div>
                      <div>
                        <strong>Notes:</strong> {contact.notes}
                      </div>
                      <div>
                        <strong>BTW Number:</strong> {contact.btwNumber}
                      </div>
                      <div>
                        <strong>Transport Code:</strong>{" "}
                        {contact.showTransportCode ? "Show" : "Hide"}
                      </div>
                      <div>
                        <strong>Address:</strong> {contact.address}
                      </div>
                      <div>
                        <strong>City:</strong> {contact.city}
                      </div>
                      <div>
                        <strong>Country:</strong> {contact.country}
                      </div>
                      <div>
                        <strong>Delivery Zip Code:</strong> {contact.zipCode}
                      </div>
                      <div>
                        <strong>Delivery Address:</strong>{" "}
                        {contact.deliveryAddress}
                      </div>
                      <div>
                        <strong>Delivery City:</strong> {contact.deliveryCity}
                      </div>
                      <div>
                        <strong>Delivery Country:</strong>{" "}
                        {contact.deliveryCountry}
                      </div>
                      <div>
                        <strong>Delivery Zip Code:</strong>{" "}
                        {contact.deliveryZipCode}
                      </div>
                    </div>
                  </td>
                )}
              </React.Fragment>
            )
          )}
        </tbody>
      </table>
    </div>
  );
}

export default ContactsView;
