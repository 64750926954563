import React from "react";
import { useEffect } from "react";

const HandleQuantity = ({
  searchResults,
  onEnterPress,
  onQuantityChange,
  onQuantityBoxChange,
}) => {
  const handleKeyPresses = (e) => {
    // If the key is Tab, then move to the next input field
    if (e.key === "Tab" || e.key === "ArrowRight") {
      e.preventDefault(); // Prevent default tab behavior
      document.getElementById("quantityBox").focus();
    }

    if (e.key === "Enter") {
      onEnterPress(e);
    }
  };

  const handleQuantityChange = (e) => {
    const amount = Number(e.target.value);
    // Callback to parent component with updated quantity
    onQuantityChange(amount);
  };

  const handleQuantityBoxChange = (e) => {
    const amount = Number(e.target.value);
    // Callback to parent component with updated quantityBox
    onQuantityBoxChange(amount);
  };

  return (
    <>
      <td>
        <input
          type="text"
          id="quantity"
          value={searchResults.quantity || ""}
          onChange={(e) => {
            handleQuantityChange(e);
          }}
          onKeyDown={handleKeyPresses}
          style={{ width: "6rem" }}
        />
      </td>
      <td>
        <input
          type="text"
          id="quantityBox"
          value={searchResults.quantityBox || ""}
          onChange={handleQuantityBoxChange}
          onKeyDown={(e) => {
            onEnterPress(e);
          }}
          style={{ width: "6rem" }}
        />
      </td>
    </>
  );
};

export default HandleQuantity;
