import React, { useState, useEffect } from "react";

import CheckForToken from "../../checkForToken";
const TableHeaderBackgroundColor = () => {
  CheckForToken();
  const [color, setColor] = useState("");

  useEffect(() => {
    if (color !== "") {
      localStorage.setItem("tableHeaderBackgroundColor", color);
    }
  }, [color]);

  const handleColorChange = () => {
    const colorInput = document.getElementById("colorInput");
    setColor(colorInput.value);
  };

  return (
    <div>
      <h1>Table Header Background Color</h1>
      <div>
        <input type="color" id="colorInput" />
        <button onClick={handleColorChange}>Save Color</button>
      </div>
    </div>
  );
};

export default TableHeaderBackgroundColor;
