import React, { useState, useEffect, useCallback } from "react";
import SearchResults from "./SearchResults";
import SearchNoResults from "./SearchNoResults";

import Quantity from "./invoiceQuantity";
import Price from "./invoicePrice";
import Discount from "./invoiceDiscount";
import Btw from "./invoiceBtw";

import config from "./config/config";

import "./invoiceTable.css";

const generateUniqueId = () => {
  const timestamp = Date.now().toString(36);
  const random = Math.random().toString(36).substr(2, 5);
  return `${timestamp}-${random}`;
};

const ProformaInvoiceTable = ({
  invoiceToEdit,
  invoiceDraftData,
  invoice,
  setInvoice,
  itemsOnInvoice,
  setItemsOnInvoice,
  selectedResultCustomer,
  subTotal: initialSubTotal,
  subTotal,
  setSubTotal,
  setTotal,
  setDiscountedPriceTotal,
  discountedPriceTotal,
  taxRate,
  setTaxRate,
  discount,
  setTaxAmount,
  setTotalAmount,
  totalAmount,
  taxAmount,
  date,
  expirationDate,
  remarks,
  internolCommand,
  selectedCurrency,
}) => {
  // console.log("invoiceDraftData", invoiceDraftData);
  const initialNewItemState = {
    id: "",
    name: "",
    description: "",
    transportCode: "",
    price: 0,
    discount: 0,
    quantity: 0,
    total: 0,
    papular: 0,
  };

  const [addNewRow, setAddNewRow] = useState(false);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);
  const [searchInput, setSearchItem] = useState("");
  const [customerDetails, setCustomerDetails] = useState([]);
  const [totalDiscount, setTotalDiscount] = useState([]);
  const [isNewRowAdded, setNewRowAdded] = useState(false);
  const [alertShown, setAlertShown] = useState(false);
  const [itemsArray, setItemsArray] = useState([]);
  const [newItem, setNewItem] = useState(initialNewItemState);
  const [products, setProducts] = useState([]);
  const [costumers, setCostumers] = useState([]);

  // const items = JSON.parse(localStorage.getItem("items")) || [];

  const getCostumers = async () => {
    try {
      const response = await fetch(`${config.URL_PROD}/contacts`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setCostumers(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProducts = async () => {
    try {
      const response = await fetch(`${config.URL_PROD}/products`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const { count } = data;
        console.log("count", count);
        const { products } = data;
        setProducts(products);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProducts();
    getCostumers();
  }, []);

  const localStorageDubbelDiscount =
    JSON.parse(localStorage.getItem("dubbleDiscount")) || false;
  useEffect(() => {
    setIsSearchFocused(false);
    setCustomerDetails(costumers);
    const invoiceDraftDatainfo = invoiceDraftData;
    // console.log("invoiceDraftDatainfo", invoiceDraftDatainfo);
    const invoiceToEditinfo = invoiceToEdit;
    const editProforma = JSON.parse(localStorage.getItem("editProforma"));
    if (invoiceDraftDatainfo) {
      setItemsOnInvoice(invoiceDraftDatainfo.items || []);
      setSubTotal(Number(invoiceDraftDatainfo.subTotal).toFixed(2));
      setDiscountedPriceTotal(Number(invoiceDraftDatainfo.discount).toFixed(2));
      setTaxAmount(Number(invoiceDraftDatainfo.btwAmount).toFixed(3) || 0);
      setTotalAmount(Number(invoiceDraftDatainfo.total).toFixed(2));
    }
    if (invoiceToEditinfo) {
      setItemsOnInvoice(invoiceToEditinfo.items || []);
      setSubTotal(Number(invoiceToEditinfo.subTotal).toFixed(2));
      setDiscountedPriceTotal(Number(invoiceToEditinfo.discount).toFixed(2));
      setTaxAmount(Number(invoiceToEditinfo.btwAmount).toFixed(3) || 0);
      setTotalAmount(Number(invoiceToEditinfo.total).toFixed(2));
    }
    if (editProforma) {
      setItemsOnInvoice(editProforma.items || []);
      setSubTotal(editProforma.subTotal);
      setDiscountedPriceTotal(editProforma.discount);
      setTaxAmount(editProforma.tax);
      setTotalAmount(editProforma.total);
    }
  }, [setCustomerDetails]);

  useEffect(() => {
    const proformaNumber = JSON.parse(localStorage.getItem("proformaNumber"));
    localStorage.setItem("itemsOnInvoice", JSON.stringify(itemsOnInvoice));

    localStorage.setItem(
      "invoice",
      JSON.stringify(invoiceToEdit) ||
        JSON.stringify(invoiceDraftData) ||
        JSON.stringify({
          proformaNumber: proformaNumber,
          companyDetails: JSON.parse(localStorage.getItem("companyDetails")),
          selectedResultCustomer: selectedResultCustomer,
          items: itemsOnInvoice,
          subTotal: subTotal,
          totalAmount: totalAmount,
          discountedPriceTotal: discountedPriceTotal,
          totalDiscount: totalDiscount,
          taxRate: taxRate,
          btw: taxAmount,
          date: date || invoice.date || new Date().toISOString().split("T")[0],
          expirationDate: expirationDate || invoice.expirationDate,
          remarks,
          internolCommand,
          selectedCurrency,
        })
    );
  }, [
    itemsOnInvoice,
    setInvoice,
    subTotal,
    taxRate,
    totalAmount,
    totalDiscount,
    discountedPriceTotal,
    selectedResultCustomer,
  ]);

  const calculateItemTotal = useCallback((item, customerDiscount) => {
    const discountedPrice = (
      parseFloat(item.quantity) *
      parseFloat(item.sellPrice) *
      (1 - parseFloat(item.discount) / 100)
    ).toFixed(2);
    const priceIncludedBw = parseFloat(
      discountedPrice + (discountedPrice * item.btw) / 100
    ).toFixed(2);
    const itemTotal = parseFloat(priceIncludedBw * item.quantity).toFixed(2);
    return parseFloat(itemTotal).toFixed(2);
  }, []);

  const handleSearchItem = (event) => {
    const inputValue = event.target.value;
    setSearchItem(inputValue);

    const allProducts = products;

    // Loop through the products array and check if the search input is included in the product array
    const filteredItems = allProducts.filter((item) => {
      const searchTerms = inputValue.toLowerCase().split(" ");

      // Check if any of the search terms match any property of the item
      return searchTerms.every(
        (term) =>
          (item.name && item.name.toLowerCase().includes(term)) ||
          (item.id && item.id.toString().toLowerCase().includes(term)) ||
          (item.barcode &&
            item.barcode.toString().toLowerCase().includes(term)) ||
          (item.description && item.description.toLowerCase().includes(term)) ||
          (item.transportCode &&
            item.transportCode.toLowerCase().includes(term))
      );
    });

    setFilteredItems(filteredItems);
  };

  const handleAlertOKClick = () => {
    setAlertShown(false);
    handleAddItem();
  };

  const handleCloseNoResults = () => {
    setIsSearchFocused(false);
  };

  const handleIdChange = (itemId, id) => {
    setItemsOnInvoice((prevItems) =>
      prevItems.map((item) => (item.id === itemId ? { ...item, id } : item))
    );
  };

  const handleNameChange = (itemId, name) => {
    setItemsOnInvoice((prevItems) =>
      prevItems.map((item) => (item.id === itemId ? { ...item, name } : item))
    );
  };

  const handleDescriptionChange = (itemId, description) => {
    setItemsOnInvoice((prevItems) =>
      prevItems.map((item) =>
        item.id === itemId ? { ...item, description } : item
      )
    );
  };

  const handleTransportCodeChange = (itemId, transportCode) => {
    setItemsOnInvoice((prevItems) =>
      prevItems.map((item) =>
        item.id === itemId ? { ...item, transportCode } : item
      )
    );
  };

  const handleItemSelection = (itemId) => {
    console.log("itemId", itemId);
    // Find the item in the products array
    const filteredItems = products;
    const item = filteredItems.find(
      (item) => item.id === itemId.id || itemId.id === item.barcode
    );

    console.log("item", item);

    // Clear the search input
    setSearchItem("");

    const alertNoQnty = JSON.parse(localStorage.getItem("alertNoQnty"));

    // Check if the item is already in the itemsOnInvoice array
    const itemExists = itemsOnInvoice.some(
      (existingItem) => existingItem.id === item.id
    );

    if (itemExists) {
      alert("Item already exists in the invoice");
      return;
    }

    // Check if the quantity in the catalog is 0
    let itemQuantity = parseFloat(item.quantity) || 0;

    if (itemQuantity < 1 && alertNoQnty) {
      const answer = window.confirm(
        `Not enough items in stock! Do you want to continue? (Quantity will be set to ${
          itemQuantity > 0 ? "1" : "0"
        })`
      );

      if (!answer) {
        return;
      } else {
        itemQuantity = 1; // Set the quantity to 1
      }
    }

    // Check if the price in the catalog is 0
    let itemPrice = parseFloat(item.sellPrice) || 0.01;

    if (itemPrice < 0.01) {
      const confirmPrice = window.confirm(
        "The price of this item is 0. Do you want to add it anyway? (Price will be set to 0.01)"
      );

      if (!confirmPrice) {
        return;
      }
    }

    // Calculate the discounted price based on the provided formula
    const discountedPrice = (
      parseFloat(item.sellPrice) -
      (parseFloat(item.sellPrice) -
        parseFloat(itemPrice) *
          (parseFloat(
            item.percentageDiscount
              ? item.percentageDiscount
              : item.discount
              ? item.discount
              : 0
          ) /
            100))
    ).toFixed(2);

    const newItemObj = {
      id: item.id || generateUniqueId(),
      name: item.name,
      description: item.description,
      transportCode: item.transportCode,
      sellPrice: itemPrice.toFixed(2),
      discount: localStorageDubbelDiscount
        ? (Number(item?.percentageDiscount) || 0) +
          Number(selectedResultCustomer?.discount)
        : 0 ||
          item?.percentageDiscount ||
          item?.discount ||
          selectedResultCustomer?.discount ||
          0,
      quantity: 1,
      btw: parseFloat(item.btw) || 0,
      totalDiscounted: discountedPrice,
      // calc the total for the item
      total: (
        parseFloat(item.sellPrice) -
        parseFloat(itemPrice) *
          (parseFloat(
            localStorageDubbelDiscount
              ? (Number(item?.percentageDiscount) || 0) +
                  Number(selectedResultCustomer?.discount)
              : item.percentageDiscount
              ? item.percentageDiscount
              : item.discount
              ? item.discount
              : selectedResultCustomer?.discount || 0
          ) /
            100) +
        (parseFloat(item.sellPrice) * (parseFloat(item.btw) || 0)) / 100
      ).toFixed(2),
      papular: item.papular || 1,
    };

    // console.log(item?.percentageDiscount  , selectedResultCustomer?.discount, newItemObj, item.discount);

    // Update the itemsOnInvoice state with the new item
    const updatedItemsOnInvoice = [...itemsOnInvoice, newItemObj];

    // Update calculations
    const updatedCalculations = updateCalculations(updatedItemsOnInvoice);

    // Set the updated calculations in the state
    setSubTotal(updatedCalculations.subTotal);
    setDiscountedPriceTotal(updatedCalculations.discountedPriceTotal);
    setTaxAmount(updatedCalculations.taxAmount.toFixed(3));
    setTotalAmount(updatedCalculations.totalAmount);

    // Set the itemsOnInvoice and itemsArray states
    setItemsOnInvoice(updatedItemsOnInvoice);
    setItemsArray(updatedItemsOnInvoice);
    setNewItem(newItemObj);

    // Hide the search results
    setIsSearchFocused(false);
  };

  const updateCalculations = (items) => {
    const updatedDiscountArray = items.map(
      (item) =>
        (parseFloat(item.quantity) *
          parseFloat(item.sellPrice) *
          item.discount) /
        100
    );

    const sumForSub = items.reduce(
      (acc, item) =>
        acc + parseFloat(item.quantity) * parseFloat(item.sellPrice),
      0
    );

    const sumForTotalDiscount = updatedDiscountArray.reduce(
      (acc, item) => acc + item,
      0
    );

    const updatedTaxAmount = items.map(
      (item) =>
        (parseFloat(item.quantity) * parseFloat(item.sellPrice) * item.btw) /
        100
    );

    const sumForTax = updatedTaxAmount.reduce((acc, item) => acc + item, 0);

    // Perform calculations with higher precision
    const sumForTotal =
      sumForSub - parseFloat(sumForTotalDiscount) + parseFloat(sumForTax);

    return {
      subTotal: sumForSub.toFixed(2),
      discountedPriceTotal: sumForTotalDiscount.toFixed(2),
      taxAmount: sumForTax,
      totalAmount: Math.round(parseFloat(sumForTotal) * 20) / 20,
    };
  };

  const handleAddItem = () => {
    setAddNewRow(true);
    setSearchItem("");

    // Clear the newItem state for the next item
    setNewItem(initialNewItemState);
  };

  const saveItem = () => {
    // check if the id is already in the items array if it is then alert the user saying the id already exists
    // const products = JSON.parse(localStorage.getItem("products")) || [];
    // get the items from the server
    const getProducts = async () => {
      try {
        const response = await fetch("http://localhost:5000/products");
        if (response.ok) {
          const data = await response.json();
          localStorage.setItem("products", JSON.stringify(data));
        }
      } catch (error) {}
    };
    const itemExists = products.some((item) => {
      return item.id === newItem.id;
    });
    if (itemExists) {
      alert("Item already exists in the catalog");
      return;
    }
    // check if the quantity is < 1 if it is then alert the user saying the quantity is less than 1 do please add a quantity
    const itemQuantityLessThanOne = newItem.quantity;
    if (itemQuantityLessThanOne < 1) {
      alert("The quantity of this item is less than 1, please add a quantity");
      return;
    }

    // check if the price is < 0.01 if it is then alert the user saying the price is less than 0.01 do please add a price
    const itemPriceLessThanOneCent = newItem.sellPrice;
    if (itemPriceLessThanOneCent < 0.01) {
      alert("The price of this item is less than 0.01, please add a price");
      return;
    }

    setAddNewRow(false);

    const newItemObj = {
      id: newItem.id,
      name: newItem.name,
      description: newItem.description,
      transportCode: newItem.transportCode || "",
      sellPrice: parseFloat(newItem.sellPrice),
      discount:
        parseFloat(newItem.discount || selectedResultCustomer.discount || 0) ||
        0,
      quantity: parseFloat(newItem.quantity),
      btw: parseFloat(taxRate || newItem.btw || 0) || 0,
      total: (
        parseFloat(newItem.sellPrice) * parseFloat(newItem.quantity) -
        (parseFloat(newItem.quantity) *
          parseFloat(newItem.sellPrice) *
          parseFloat(
            newItem.discount || selectedResultCustomer.discount || 0
          )) /
          100 +
        (parseFloat(newItem.quantity) *
          parseFloat(newItem.sellPrice) *
          parseFloat(taxRate || newItem.btw || 0)) /
          100
      ).toFixed(2),
      totalDiscounted: (
        parseFloat(newItem.quantity) *
        (parseFloat(newItem.sellPrice) -
          (parseFloat(newItem.sellPrice) *
            parseFloat(
              newItem.discount || selectedResultCustomer.discount || 0
            )) /
            100)
      ).toFixed(2),
      papular: newItem.papular || 1,
    };

    // Update the itemsOnInvoice state with the new item
    const updatedItemsOnInvoice = [...itemsOnInvoice, newItemObj];
    calculateTheTotal(updatedItemsOnInvoice);

    setItemsOnInvoice(updatedItemsOnInvoice);
    setNewItem(initialNewItemState);
  };

  const calculateTheTotal = (updatedItemsOnInvoice) => {
    const sumForSubTotal = updatedItemsOnInvoice.reduce((acc, item) => {
      return acc + parseFloat(item.sellPrice) * parseFloat(item.quantity);
    }, 0);

    const sumForDiscountedPriceTotal = updatedItemsOnInvoice.reduce(
      (acc, item) => {
        // Calculate Discounted Price for each item
        const discountedPrice =
          parseFloat(item.sellPrice) -
          parseFloat(item.sellPrice) * (parseFloat(item.discount) / 100);

        // Add Discounted Price to the accumulator
        return acc + discountedPrice * parseFloat(item.quantity);
      },
      0
    );

    const sumForTaxAmount = updatedItemsOnInvoice.reduce((acc, item) => {
      return (
        acc +
        (parseFloat(item.sellPrice) * parseFloat(item.quantity) * item.btw) /
          100
      );
    }, 0);

    const sumForTotalAmount = updatedItemsOnInvoice.reduce((acc, item) => {
      // Calculate Discounted Price for each item
      const discountedPrice =
        parseFloat(item.sellPrice) -
        parseFloat(item.sellPrice) * (parseFloat(item.discount) / 100);

      return (
        acc +
        discountedPrice * parseFloat(item.quantity) +
        (discountedPrice * parseFloat(item.quantity) * item.btw) / 100
      );
    }, 0);

    setSubTotal(sumForSubTotal.toFixed(2));
    setDiscountedPriceTotal(sumForDiscountedPriceTotal.toFixed(2));
    setTaxAmount(sumForTaxAmount.toFixed(3));
    setTotalAmount(parseFloat(sumForTotalAmount.toFixed(2)));
  };

  const handleRemoveItem = (id) => {
    // Find the item to be removed from itemsOnInvoice
    const removedItemIndex = itemsOnInvoice.findIndex((item) => item.id === id);

    if (removedItemIndex === -1) {
      console.error("Item not found in the itemsOnInvoice array.");
      return;
    }

    // Remove the item from itemsOnInvoice
    const updatedItemsOnInvoice = [
      ...itemsOnInvoice.slice(0, removedItemIndex),
      ...itemsOnInvoice.slice(removedItemIndex + 1),
    ];

    console.log("updatedItemsOnInvoice", updatedItemsOnInvoice);
    // log all  till here
    // console.log("updatedItemsOnInvoice", updatedItemsOnInvoice);

    // Calculate the updated subTotal
    const updatedSubTotal = updatedItemsOnInvoice.reduce((acc, item) => {
      return acc + parseFloat(item.sellPrice) * parseFloat(item.quantity);
    }, 0);

    const updatedDiscountArray = updatedItemsOnInvoice.map(
      (item) =>
        (parseFloat(item.quantity) *
          parseFloat(item.sellPrice) *
          item.discount) /
          100 || 0
    );

    const sumForTotalDiscount = updatedDiscountArray.reduce((a, b) => a + b, 0);

    const updatedTaxAmount = updatedItemsOnInvoice.map(
      (item) =>
        (parseFloat(item.quantity) *
          parseFloat(item.sellPrice) *
          (item.btw || 0)) /
          100 || 0
    );

    const sumForTaxAmount = updatedTaxAmount.reduce((a, b) => a + b, 0);

    const sumForTotalAmount = updatedItemsOnInvoice.reduce((acc, item) => {
      // Calculate Discounted Price for each item
      const discountedPrice =
        parseFloat(item.sellPrice) -
        parseFloat(item.sellPrice) * (parseFloat(item.discount) / 100);

      return (
        acc +
        discountedPrice * parseFloat(item.quantity) +
        (discountedPrice * parseFloat(item.quantity) * (item.btw || 0)) / 100
      );
    }, 0);

    setSubTotal(updatedSubTotal.toFixed(2));
    setDiscountedPriceTotal(sumForTotalDiscount.toFixed(2));
    setTaxAmount(sumForTaxAmount.toFixed(2));
    setTotalAmount(sumForTotalAmount.toFixed(2));

    setItemsOnInvoice(updatedItemsOnInvoice);
    setItemsArray(updatedItemsOnInvoice);
  };

  return (
    <div>
      <table className="mt-5">
        <thead>
          <tr className="mb-4">
            <th className="thStyle">Item</th>
            <th className="thDiscriptionStyle">Name</th>
            <th className="thDiscriptionStyle">Description</th>
            <th className="thTransportStyle">Transport Code</th>
            <th className="thStyle">Quantity</th>
            <th className="thStyle">Price</th>
            <th className="thStyle">Discount</th>
            <th className="thStyle">BTW</th>
            <th className="thStyle">Total</th>
          </tr>
        </thead>
        <tbody>
          {/* Render the existing items */}
          {itemsOnInvoice &&
            itemsOnInvoice.length > 0 &&
            itemsOnInvoice.map((item, index) => (
              <tr key={item.id}>
                {/* Render input fields for each item property */}
                <td>
                  <input
                    type="text"
                    value={item.id || item.itemId}
                    onChange={(e) => handleIdChange(item.id, e.target.value)}
                    className="form-control"
                    style={{ width: "5rem" }}
                  />
                </td>
                <td>
                  <textarea
                    type="text"
                    value={item.name}
                    onChange={(e) => handleNameChange(item.id, e.target.value)}
                    className="form-control"
                    style={{ width: "12rem" }}
                    rows={1}
                  />
                </td>
                <td>
                  <textarea
                    type="text"
                    value={item.description}
                    onChange={(e) =>
                      handleDescriptionChange(item.id, e.target.value)
                    }
                    className="form-control"
                    style={{ width: "12rem" }}
                    rows={1}
                  />
                </td>
                <td>
                  <textarea
                    type="text"
                    value={item.transportCode}
                    onChange={(e) =>
                      handleTransportCodeChange(item.id, e.target.value)
                    }
                    className="form-control"
                    rows={1}
                    style={{ width: "6rem" }}
                  />
                </td>
                <Quantity
                  quantityAmount={item.quantity}
                  itemsOnInvoice={itemsOnInvoice}
                  setSubTotal={setSubTotal}
                  setDiscountedPriceTotal={setDiscountedPriceTotal}
                  setTaxAmount={setTaxAmount}
                  setItemsOnInvoice={setItemsOnInvoice}
                  setItemsArray={setItemsArray}
                  setTotalAmount={setTotalAmount}
                  setTotal={setTotal}
                  setTaxRate={setTaxRate}
                  taxRate={taxRate}
                  setTotalDiscount={setTotalDiscount}
                  item={item}
                />

                <Price
                  price={item.sellPrice}
                  itemsOnInvoice={itemsOnInvoice}
                  setSubTotal={setSubTotal}
                  setDiscountedPriceTotal={setDiscountedPriceTotal}
                  setTaxAmount={setTaxAmount}
                  setItemsOnInvoice={setItemsOnInvoice}
                  setItemsArray={setItemsArray}
                  setTotalAmount={setTotalAmount}
                  setTotal={setTotal}
                  setTaxRate={setTaxRate}
                  taxRate={taxRate}
                  setTotalDiscount={setTotalDiscount}
                  item={item}
                />

                <Discount
                  item={item}
                  itemsOnInvoice={itemsOnInvoice}
                  setItemsOnInvoice={setItemsOnInvoice}
                  setItemsArray={setItemsArray}
                  setTaxAmount={setTaxAmount}
                  setTotalAmount={setTotalAmount}
                  setSubTotal={setSubTotal}
                  setDiscountedPriceTotal={setDiscountedPriceTotal}
                  setTotalDiscount={setTotalDiscount}
                />

                <Btw
                  item={item}
                  itemsOnInvoice={itemsOnInvoice}
                  setItemsOnInvoice={setItemsOnInvoice}
                  setTaxAmount={setTaxAmount}
                  setItemsArray={setItemsArray}
                  setTotalAmount={setTotalAmount}
                  setSubTotal={setSubTotal}
                />
                <td>
                  <input
                    type="text"
                    value={Number(item.total).toFixed(2)}
                    className="form-control col-lg-2"
                    disabled
                    style={{
                      width: "5rem",
                    }} /*  */
                  />
                </td>
                <td>
                  <button
                    className="btn btn-danger"
                    onClick={() => handleRemoveItem(item.id)}
                  >
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          {/* Add new row */}
          {addNewRow && (
            <tr>
              <td>
                <input
                  type="text"
                  value={newItem.id}
                  placeholder="Id"
                  onChange={(e) =>
                    setNewItem({ ...newItem, id: e.target.value })
                  }
                  className="form-control col-lg-2"
                  autoFocus
                  style={{
                    width: "5rem",
                  }}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={newItem.name}
                  placeholder="Name"
                  onChange={(e) =>
                    setNewItem({ ...newItem, name: e.target.value })
                  }
                  className="form-control col-lg-2"
                  style={{
                    width: "5rem",
                  }}
                />
              </td>
              <td>
                <textarea
                  type="text"
                  value={newItem.description}
                  placeholder="Description"
                  onChange={(e) =>
                    setNewItem({ ...newItem, description: e.target.value })
                  }
                  className="form-control col-lg-2"
                  rows={1}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={newItem.transportCode}
                  placeholder="TransportCode"
                  onChange={(e) =>
                    setNewItem({ ...newItem, transportCode: e.target.value })
                  }
                  className="form-control col-lg-2"
                />
              </td>
              <td>
                <input
                  type="number"
                  value={newItem.quantity || 0}
                  placeholder="Quantity"
                  onChange={(e) => {
                    setNewItem({ ...newItem, quantity: e.target.value });
                  }}
                  className="form-control col-lg-2"
                />
              </td>
              <td>
                <input
                  type="number"
                  value={newItem.sellPrice || ""}
                  placeholder="Price"
                  onChange={(e) =>
                    setNewItem({ ...newItem, sellPrice: e.target.value })
                  }
                  className="form-control col-lg-2"
                  style={{
                    width: "5rem",
                  }}
                />
              </td>
              <td>
                <input
                  type="number"
                  value={newItem.discount || selectedResultCustomer?.discount}
                  placeholder="Default Discount"
                  onChange={(e) =>
                    setNewItem({ ...newItem, discount: e.target.value })
                  }
                  className="form-control col-lg-2"
                />
              </td>
              <td>
                <input
                  type="number"
                  value={newItem.btw || selectedResultCustomer?.btwAmount}
                  placeholder="Default BTW"
                  onChange={(e) =>
                    setNewItem({ ...newItem, btw: e.target.value })
                  }
                  className="form-control col-lg-2"
                />
              </td>
              <td className="form-control">{calculateItemTotal(newItem)}</td>
              <td>
                <button
                  className="btn btn-success"
                  onClick={() => {
                    saveItem();
                  }}
                >
                  Add
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => {
                    setAddNewRow(false);
                  }}
                >
                  Remove
                </button>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Search and other elements */}
      <div className="row">
        <div>
          <textarea
            className="w-50 form-control col-lg-4 mt-2"
            type="text"
            placeholder="Search by name or id..."
            value={searchInput}
            onChange={handleSearchItem}
            rows={2}
            onFocus={() => setIsSearchFocused(true)}
          />
          {searchInput !== "" && !filteredItems && !alertShown && (
            <div>
              <p>No Products, please add!</p>
              <button className="centered-element" onClick={handleAlertOKClick}>
                Add New Line
              </button>
            </div>
          )}
          {searchInput !== "" &&
            isSearchFocused &&
            filteredItems &&
            filteredItems.length === 0 && (
              <SearchNoResults
                onClose={handleCloseNoResults}
                addNewLine={handleAddItem}
                source="invoice"
              />
            )}
          {searchInput !== "" &&
            filteredItems &&
            filteredItems.length > 0 &&
            isSearchFocused && (
              <SearchResults
                filteredItems={filteredItems}
                handleResultSelection={handleItemSelection}
              />
            )}
        </div>
        <div>
          <button
            className="centered-element"
            style={{
              backgroundColor: "blue",
              color: "white",
              borderRadius: "8px",
              cursor: "pointer",
              float: "right",
              marginRight: "15px",
              paddingRight: "8px",
              paddingLeft: "8px",
            }}
            onClick={handleAddItem}
          >
            Add New Line
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProformaInvoiceTable;
