import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AddProduct from "./AddProduct";
import CheckForToken from "../checkForToken";
import config from "./config/config";

const EditProduct = () => {
  const [productData, setProductData] = useState(false);
  CheckForToken();
  const { index } = useParams();
  useEffect(() => {
    // Get the product from the server with the token
    fetch(`${config.URL_PROD}/products/${index}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setProductData(data);
      });
  }, [index]);

  return (
    <div>
      <h1>Edit Product</h1>
      <AddProduct productData={productData} />
    </div>
  );
};

export default EditProduct;
