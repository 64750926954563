import React, { useState, useEffect } from "react";
import { generatePDFsForOrders } from "../pdf/pdfGeneratorDelayed";
import { generateReceiptPDFCombined } from "../print/combindePDFAndPrint";
import overview from "./icon-overview.gif";
import * as XLSX from "xlsx";

import styles from "./ActiveOrders.module.css";
import viewStyles from "./view.module.css";

const ActiveOrders = () => {
  if (!localStorage.getItem("token")) {
    window.location.href = "/login";
  }
  const [activeOrders, setActiveOrders] = useState(
    JSON.parse(localStorage.getItem("invoices")) || []
  );

  const [products, setProducts] = useState(
    JSON.parse(localStorage.getItem("products")) || []
  );
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedInvoice, setSelectedInvoice] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sortBy, setSortBy] = useState(""); // Initialize with an empty string for no initial sorting
  const [sortOrder, setSortOrder] = useState("asc"); // Initialize with ascending order
  const [actionSelected, setActionSelected] = useState(
    selectedInvoice.length > 0
  );
  const [searchInput, setSearchInput] = useState("");

  // State to manage selected dates
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [filteredByDate, setFilteredByDate] = useState([]); // Initialize with an empty array [] for no initial filtering

  const handleSort = (column) => {
    if (column === sortBy) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }

    const sortedOrders = [...activeOrders]
      .filter((order) => order.status !== "Completed")
      .sort(sortFunction);

    setActiveOrders([...sortedOrders]); // Update the state with a new array
  };

  // Define a sorting function to sort the orders based on the selected column and order
  const sortFunction = (a, b) => {
    let aValue = a[sortBy];
    let bValue = b[sortBy];

    if (sortBy === "id") {
      aValue = parseInt(a.id || a.proformaNumber);
      bValue = parseInt(b.id || b.proformaNumber);
    }

    if (sortBy === "selectedResultCustomer.companyName") {
      aValue = a.selectedResultCustomer?.companyName || "";
      bValue = b.selectedResultCustomer?.companyName || "";
    }

    if (sortBy === "date") {
      aValue = new Date(a.date);
      bValue = new Date(b.date);
    }

    if (sortBy === "totalAmount") {
      aValue = parseFloat(a.totalAmount);
      bValue = parseFloat(b.totalAmount);
    }

    if (sortBy === "status") {
      aValue = aValue;
      bValue = bValue;
    }

    if (aValue < bValue) return sortOrder === "asc" ? -1 : 1;
    if (aValue > bValue) return sortOrder === "asc" ? 1 : -1;
    return 0;
  };

  const sortedOrders = activeOrders
    .filter((order) => order.status !== "Completed") // Filter out completed orders
    .sort(sortFunction); // Sort the orders based on the selected column and order

  const openModal = (order) => {
    setSelectedOrder(order);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedOrder(null);
  };

  const handleOnExportActiveOrders = () => {
    if (selectedInvoice.length === 0) {
      const confirm = window.confirm(
        "Are you sure you want to export all active orders?"
      );
      if (!confirm) return;
    }
    const activeOrdersTOExport =
      selectedInvoice.length > 0 ? selectedInvoice : activeOrders;
    // Create an array of objects with the desired structure for export
    const exportData = activeOrdersTOExport.map((order) => {
      const allItemsQuantities = order.items.map((item) => item.quantity); // Calculate quantities based on the order

      const allItemsNames = order.items.map(
        (item) => item.name || item.description
      );
      const allItemsColumns = allItemsNames.reduce((acc, item, index) => {
        return {
          ...acc,
          [item]: allItemsQuantities[index],
        };
      }, {});

      return {
        "Invoice Number": order.proformaNumber || order.id,
        Date: order.date,
        "Client Number": order.selectedResultCustomer.clientNumber,
        name: order.selectedResultCustomer.companyName,
        Discount: order.discountedPriceTotal,
        Total: order.totalAmount,
        ...allItemsColumns,
      };
    });

    // Create a worksheet from the modified data
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.json_to_sheet(exportData);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workBook, workSheet, "Active Orders");

    // Export the workbook to a file
    XLSX.writeFile(workBook, "Active-Orders.xlsx");
  };

  const generatePDFAll = () => {
    if (selectedInvoice.length === 0) {
      const confirm = window.confirm(
        "Are you sure you want to Generate PDFs for all active orders?"
      );
      if (!confirm) return;
    }
    const data = activeOrders.filter((order) => order.status !== "Completed");

    switch (true) {
      case selectedInvoice.length > 0:
        generatePDFsForOrders(selectedInvoice);
        break;
      case selectedInvoice.length === 0:
        generatePDFsForOrders(data);
        break;
      default:
        // Default case if none of the conditions is met
        setSelectedInvoice([]); // Clear the selected invoices
        break;
    }
    setSelectedInvoice([]); // Clear the selected invoices
  };

  const generatePDFAndPrintAllInOne = () => {
    if (selectedInvoice.length === 0) {
      const confirm = window.confirm(
        "Are you sure you want to Generate PDFs & Print for all active orders?"
      );
      if (!confirm) return;
    }
    const data = activeOrders.filter((order) => order.status !== "Completed");

    switch (true) {
      case selectedInvoice.length > 0:
        generateReceiptPDFCombined(selectedInvoice);
        break;
      case selectedInvoice.length === 0:
        generateReceiptPDFCombined(data);
        break;
      default:
        // Default case if none of the conditions is met
        setSelectedInvoice([]); // Clear the selected invoices
        break;
    }
    setSelectedInvoice([]); // Clear the selected invoices
  };

  const handleDeleteSelectedInvoices = () => {
    if (selectedInvoice.length === 0) {
      alert("No active orders selected for deletion.");
      return;
    }

    const confirm = window.confirm(
      `Are you sure you want to delete the selected active orders? This action cannot be undone. (the "invoices" will be Deleted)`
    );
    if (!confirm) {
      setSelectedInvoice([]);
      return;
    }

    const filtered = activeOrders.filter(
      (order) => !selectedInvoice.includes(order)
    );

    // update local storage
    const invoices = JSON.parse(localStorage.getItem("invoices")) || [];

    // ctive orders pllus all orders with status Completed
    const filteredInvoices = [
      ...filtered,
      ...invoices.filter((order) => order.status === "Completed"),
    ];

    localStorage.setItem("invoices", JSON.stringify(filteredInvoices));

    setActiveOrders([...filteredInvoices]);
    setSelectedInvoice([]);
  };

  const handleSelectedInvoice = (e) => {
    if (e === "all") {
      if (selectedInvoice.length === 0) {
        setSelectedInvoice([...sortedOrders]);
      } else {
        setSelectedInvoice([]);
      }
    } else {
      if (selectedInvoice.includes(e)) {
        const filtered = selectedInvoice.filter((item) => item !== e);
        setSelectedInvoice([...filtered]);
      } else {
        setSelectedInvoice([...selectedInvoice, e]);
      }
    }
  };

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
    const inputValue = String(e.target.value).toLowerCase();

    const allOrders = JSON.parse(localStorage.getItem("invoices")) || [];
    const activeOrders = allOrders.filter((order) => {
      return order.status !== "Completed" && order.status !== "Cancelled";
    });

    const allOrdersByDate =
      filteredByDate.length > 0 ? filteredByDate : activeOrders;

    const filtered = allOrdersByDate.filter((order) => {
      return (
        order.id.toString().toLowerCase().includes(inputValue) ||
        order.selectedResultCustomer.companyName
          .toLowerCase()
          .includes(inputValue) ||
        order.selectedResultCustomer.clientNumber
          .toLowerCase()
          .includes(inputValue) ||
        order.date.toLowerCase().includes(inputValue) ||
        order.totalAmount.toString().toLowerCase().includes(inputValue) ||
        order.status.toLowerCase().includes(inputValue)
      );
    });
    console.log(filtered);

    setActiveOrders(filtered);
  };

  // Function to handle date changes
  const handleDateChange = (e) => {
    const { name, value } = e.target;

    // Update the corresponding state based on the input name
    if (name === "dateFrom") {
      setDateFrom(value);
      setDateTo(value);
    } else if (name === "dateTo") {
      setDateTo(value);
    }

    if (name && value) {
      console.log(name, value);
      const dateFromUpdeted = name === "dateFrom" ? value : dateFrom;
      console.log(dateFromUpdeted);
      const dateToUpdeted = name === "dateTo" ? value : dateFromUpdeted;
      console.log(dateToUpdeted);
      // Filter active orders based on the selected dates
      const allOrders = JSON.parse(localStorage.getItem("invoices")) || [];
      const activeOrders = allOrders.filter((order) => {
        return order.status !== "Completed" && order.status !== "Cancelled";
      });
      const filtered = activeOrders.filter((order) => {
        return order.date >= dateFromUpdeted && order.date <= dateToUpdeted;
      });

      // Update the state with the filtered orders
      setActiveOrders(filtered);
      setFilteredByDate(filtered);

      // Perform your logic with the filtered orders
      console.log("Filtered Orders:", filtered);
    }
  };

  const handleReset = () => {
    // Reset form and clear filtered orders
    setDateFrom("");
    setDateTo("");

    const allOrders = JSON.parse(localStorage.getItem("invoices")) || [];
    const activeOrders = allOrders.filter((order) => {
      return order.status !== "Completed" && order.status !== "Cancelled";
    });
    setActiveOrders(activeOrders);
    setFilteredByDate([]);
  };

  useEffect(() => {
    setActionSelected(selectedInvoice.length > 0);
  }, [selectedInvoice]);

  return (
    <div
      style={{
        justifyContent: "space-around",
        marginTop: "8rem",
        marginLeft: "22rem",
        marginRight: "2rem",
      }}
    >
      <h1>Active Orders</h1>

      {/* add here the alert */}
      <div
        className={`alert alert-warning alert-dismissible fade show`}
        style={{
          display: "none",
        }}
        role="alert"
      >
        <h4 className="alert-heading">Generate PDFs</h4>
        <p>
          Please wait while we generate the PDFs for all active orders. This
          might take a while depending on the number of active orders.
        </p>
        <hr />
        <p className="mb-0">
          Please do not close the browser or refresh the page until all PDFs
          have been generated.
        </p>
        <p className="munber-of-pdf"></p>
      </div>
      <div>
        {/* download pdf of all active invoices */}
        <div>
          <input
            type="text"
            placeholder="Search Any thing, Date (yyyy-mm-dd)"
            className={styles.searchInput}
            value={searchInput}
            onChange={handleSearch}
          />
          {/* input date  */}
          <div className={styles.dateInput}>
            <label htmlFor="dateFrom">
              From:{" "}
              <input
                type="date"
                id="dateFrom"
                name="dateFrom"
                value={dateFrom}
                onChange={handleDateChange}
              />
            </label>

            <label htmlFor="dateTo">
              To:{" "}
              <input
                type="date"
                id="dateTo"
                name="dateTo"
                value={dateTo}
                onChange={handleDateChange}
              />
            </label>
            <button type="button" onClick={handleReset}>
              Reset
            </button>
          </div>
        </div>
        {/* search by id name date total  status */}
        {/* empty div to hold the place for styling butnsActionsNotSelected */}
        <div
          className={`${actionSelected ? "" : styles.butnsActionsNotSelected}`}
        >
          <p
            style={{
              width: "48px",
              height: "48px",
            }}
          ></p>
        </div>
        <div className={`${actionSelected ? styles.butnsActions : ""}`}>
          <button
            onClick={generatePDFAndPrintAllInOne}
            style={{ marginRight: "2rem" }}
            title="Generate PDF & Print"
          >
            <svg
              width="24"
              height="24"
              fill="currentColor"
              className="bi bi-printer"
              viewBox="0 0 16 16"
            >
              <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
            </svg>
            <br />
            <span className={styles.textUnderIcon}>Print</span>
          </button>

          <button
            onClick={generatePDFAll}
            style={{ marginRight: "2rem" }}
            title="Generate PDFs"
          >
            <svg
              width="24"
              height="24"
              fill="currentColor"
              className="bi bi-file-earmark-pdf"
              viewBox="0 0 16 16"
            >
              <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
              <path d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
            </svg>
            <br />
            <span className={styles.textUnderIcon}>PDF</span>
          </button>

          {/* download excel of all active invoices */}
          <button
            onClick={handleOnExportActiveOrders}
            style={{ marginRight: "2rem" }}
            title="Export to Excel"
          >
            <svg
              width="24"
              height="24"
              fill="currentColor"
              className="bi bi-filetype-xls"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM6.472 15.29a1.176 1.176 0 0 1-.111-.449h.765a.578.578 0 0 0 .254.384c.07.049.154.087.25.114.095.028.202.041.319.041.164 0 .302-.023.413-.07a.559.559 0 0 0 .255-.193.507.507 0 0 0 .085-.29.387.387 0 0 0-.153-.326c-.101-.08-.255-.144-.462-.193l-.619-.143a1.72 1.72 0 0 1-.539-.214 1.001 1.001 0 0 1-.351-.367 1.068 1.068 0 0 1-.123-.524c0-.244.063-.457.19-.639.127-.181.303-.322.527-.422.225-.1.484-.149.777-.149.305 0 .564.05.78.152.216.102.383.239.5.41.12.17.186.359.2.566h-.75a.56.56 0 0 0-.12-.258.625.625 0 0 0-.247-.181.923.923 0 0 0-.369-.068c-.217 0-.388.05-.513.152a.472.472 0 0 0-.184.384c0 .121.048.22.143.3a.97.97 0 0 0 .405.175l.62.143c.217.05.406.12.566.211a1 1 0 0 1 .375.358c.09.148.135.335.135.56 0 .247-.063.466-.188.656a1.216 1.216 0 0 1-.539.439c-.234.105-.52.158-.858.158-.254 0-.476-.03-.665-.09a1.404 1.404 0 0 1-.478-.252 1.13 1.13 0 0 1-.29-.375Zm-2.945-3.358h-.893L1.81 13.37h-.036l-.832-1.438h-.93l1.227 1.983L0 15.931h.861l.853-1.415h.035l.85 1.415h.908L2.253 13.94l1.274-2.007Zm2.727 3.325H4.557v-3.325h-.79v4h2.487v-.675Z"
              />
            </svg>
            <br />
            <span className={styles.textUnderIcon}>Excel</span>
          </button>

          {/* delete selected invoices */}
          <button
            style={{ marginRight: "2rem" }}
            onClick={() => {
              handleDeleteSelectedInvoices();
            }}
            title="Delete Selected"
          >
            <svg
              x="0px"
              y="0px"
              width="26"
              height="26"
              fill="white"
              viewBox="0 0 24 24"
            >
              <path d="M 10 2 L 9 3 L 4 3 L 4 5 L 5 5 L 5 20 C 5 20.522222 5.1913289 21.05461 5.5683594 21.431641 C 5.9453899 21.808671 6.4777778 22 7 22 L 17 22 C 17.522222 22 18.05461 21.808671 18.431641 21.431641 C 18.808671 21.05461 19 20.522222 19 20 L 19 5 L 20 5 L 20 3 L 15 3 L 14 2 L 10 2 z M 7 5 L 17 5 L 17 20 L 7 20 L 7 5 z M 9 7 L 9 18 L 11 18 L 11 7 L 9 7 z M 13 7 L 13 18 L 15 18 L 15 7 L 13 7 z"></path>
            </svg>
            <br />
            <span className={styles.textUnderIcon}>Trash</span>
          </button>
        </div>
      </div>
      <table className="table table-striped table-bordered table-hover table-sm">
        <thead style={{ backgroundColor: "#A8A8A8" }}>
          <tr>
            <th
              scope="col"
              style={{
                textAlign: "center",
                verticalAlign: "middle",
                backgroundColor: "#A8A8A8",
                cursor: "pointer",
              }}
              onClick={() => handleSort("id")}
            >
              #
              {sortBy === "id" && (
                <i
                  className="material-icons"
                  style={{
                    fontSize: "24px",
                    marginLeft: "0.5rem",
                    verticalAlign: "middle",
                  }}
                >
                  {sortOrder === "asc" ? "arrow_drop_down" : "arrow_drop_up"}
                </i>
              )}
            </th>
            <th
              scope="col"
              style={{
                textAlign: "center",
                verticalAlign: "middle",
                backgroundColor: "#A8A8A8",
                cursor: "pointer",
              }}
              onClick={() => handleSort("selectedResultCustomer.companyName")}
            >
              Customer Name
              {sortBy === "selectedResultCustomer.companyName" && (
                <i
                  className="material-icons"
                  style={{
                    fontSize: "24px",
                    marginLeft: "0.5rem",
                    verticalAlign: "middle",
                  }}
                >
                  {sortOrder === "asc" ? "arrow_drop_down" : "arrow_drop_up"}
                </i>
              )}
            </th>
            <th
              scope="col"
              style={{
                textAlign: "center",
                verticalAlign: "middle",
                backgroundColor: "#A8A8A8",
              }}
              onClick={() => handleSort("date")}
            >
              Order Date
              {sortBy === "date" && (
                <i
                  className="material-icons"
                  style={{
                    fontSize: "24px",
                    marginLeft: "0.5rem",
                    verticalAlign: "middle",
                  }}
                >
                  {sortOrder === "asc" ? "arrow_drop_down" : "arrow_drop_up"}
                </i>
              )}
            </th>
            <th
              scope="col"
              style={{
                textAlign: "center",
                verticalAlign: "middle",
                backgroundColor: "#A8A8A8",
              }}
              onClick={() => handleSort("totalAmount")}
            >
              Total
              {sortBy === "totalAmount" && (
                <i
                  className="material-icons"
                  style={{
                    fontSize: "24px",
                    marginLeft: "0.5rem",
                    verticalAlign: "middle",
                  }}
                >
                  {sortOrder === "asc" ? "arrow_drop_down" : "arrow_drop_up"}
                </i>
              )}
            </th>
            <th
              scope="col"
              style={{
                textAlign: "center",
                verticalAlign: "middle",
                backgroundColor: "#A8A8A8",
              }}
              onClick={() => handleSort("status")}
            >
              Status
              {sortBy === "status" && (
                <i
                  className="material-icons"
                  style={{
                    fontSize: "24px",
                    marginLeft: "0.5rem",
                    verticalAlign: "middle",
                  }}
                >
                  {sortOrder === "asc" ? "arrow_drop_down" : "arrow_drop_up"}
                </i>
              )}
            </th>
            <th
              scope="col"
              style={{
                textAlign: "center",
                verticalAlign: "middle",
                backgroundColor: "#A8A8A8",
              }}
            >
              View Order
            </th>
            <th
              style={{
                textAlign: "center",
                verticalAlign: "middle",
                backgroundColor: "#A8A8A8",
              }}
            >
              <input
                type="checkbox"
                checked={selectedInvoice.length === sortedOrders.length}
                style={{ marginLeft: "1rem", marginTop: "0.5rem" }}
                onClick={(e) => {
                  handleSelectedInvoice("all");
                }}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedOrders.map((order, index) => {
            return (
              <tr key={index}>
                <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                  {order.id || order.proformaNumber}
                </td>
                <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                  {order.selectedResultCustomer.companyName}
                </td>
                <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                  {order.date}
                </td>
                <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                  {order.totalAmount}
                </td>
                <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                  {order.status}
                </td>
                <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                  <button onClick={() => openModal(order)}>
                    {/* overview gif */}
                    <img src={overview} alt="overview" width="28" height="28" />
                  </button>
                </td>
                <td className={styles.tdCheckbox}>
                  <input
                    type="checkbox"
                    className={styles.checkbox}
                    checked={selectedInvoice.includes(order)}
                    onClick={(e) => {
                      handleSelectedInvoice(order);
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {isModalOpen && (
        <div id="myModal" className="modal">
          <div className="modal-content" style={{ width: "70%" }}>
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            <div className="modal-header">
              <h2>Order Details</h2>

              {/* invoice number */}
              <div className={viewStyles.invoiceNumber}>
                <p>
                  Invoice Number:{" "}
                  <span>
                    {selectedOrder.id || selectedOrder.proformaNumber}
                  </span>
                </p>
                {/* date */}
                <p>
                  Date: <span>{selectedOrder.date}</span>
                </p>
                {/* client number */}
                <p>
                  Client Number:{" "}
                  <span>
                    {selectedOrder.selectedResultCustomer.clientNumber}
                  </span>
                </p>
                <p>
                  Customer Name:{" "}
                  <span>
                    {selectedOrder.selectedResultCustomer.companyName}
                  </span>
                </p>
              </div>
            </div>
            <div className="modal-body">
              {/* Order details in a table */}
              <table>
                <thead>
                  <tr>
                    <th className={viewStyles.previewTh}>Item Name</th>
                    <th className={viewStyles.previewTh}>Item Description</th>
                    <th className={viewStyles.previewTh}>Item Price</th>
                    <th className={viewStyles.previewTh}>Item Quantity</th>

                    {selectedOrder.btw > 0 && (
                      <th className={viewStyles.previewTh}>Item BTW</th>
                    )}
                    <th className={viewStyles.previewTh}>Item Discount</th>
                    <th className={viewStyles.previewTh}>Item Total</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedOrder.items.map((item, index) => (
                    <tr key={index} className={viewStyles.previewTr}>
                      <td className={viewStyles.previewTd}>{item.name}</td>
                      <td className={viewStyles.previewTd}>
                        {item.description}
                      </td>
                      <td className={viewStyles.previewTd}>{item.price}</td>
                      <td className={viewStyles.previewTd}>{item.quantity}</td>

                      {selectedOrder.btw > 0 && (
                        <td className={viewStyles.previewTd}>{item.btw}</td>
                      )}
                      <td className={viewStyles.previewTd}>{item.discount}</td>
                      <td className={viewStyles.previewTd}>{item.total}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div>
                {/* subtotal */}
                <p>
                  Subtotal: <span>{selectedOrder.subTotal}</span>
                </p>
                {/* discount */}
                <p>
                  Discount: <span>{selectedOrder.discountedPriceTotal}</span>
                </p>
                {/* tax */}
                {selectedOrder.btw > 0 && (
                  <p>
                    Tax: <span>{selectedOrder.btw}</span>
                  </p>
                )}

                {/* total amount */}
                <p>
                  Total Amount: <span>{selectedOrder.totalAmount}</span>
                </p>
              </div>
            </div>
            <div className="modal-footer">
              {/* provided by perfectInvoicing */}
              <p>
                provided by{" "}
                <a
                  href="https://perfectinvoicing.netlify.app/"
                  target="_blank"
                  rel="noreferrer"
                >
                  PerfectInvoicing
                </a>
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ActiveOrders;
