import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import config from "./config/config";

import "./header.css";
import style from "./toggle.module.css";
import styles from "./AddProduct.module.css";

const AddProduct = ({ productData }) => {
  if (!localStorage.getItem("token")) {
    window.location.href = "/login";
  }
  const navigate = useNavigate();
  const initialState = {
    barcode: "",
    id: "",
    productName: "",
    description: "",
    image: "",
    manufacturer: "",
    transportCode: "",
    buyPriceForBox: 0,
    buyPrice: 0,
    sellPrice: 0,
    quantity: 0,
    btw: "",
    category: "", // Corrected the spelling of "category"
    papular: 0,
    inventoryLowAmount: 0,
    alertLowInventory: false,
    expirationDate: "",
    notifyXAmountOfDaysBeforeExpiringDate: 0,
    alertExpiringDate: false,
    percentageDiscount: 0,
    discountAmount: 0,
    amountDiscount: 0,
    salePrice: 0,
    pricePerUnitOrBox: 0,
    unitsInBox: 0,
    pricePerXAmount: 0,
  };
  // get from local storage the default % to add on buy price to get the sell price
  const defaultPercentage = localStorage.getItem("defaultPercentage");

  const [product, setProduct] = useState(initialState);
  const [file, setFile] = useState("");
  const [alertLowInventory, setAlertLowInventory] = useState(false);
  const [lowInventoryAlert, setLowInventoryAlert] = useState(false);
  const [expiringDateAlert, setExpiringDateAlert] = useState(false);
  const [alertExpiringDate, setAlertExpiringDate] = useState(false);
  const [percentageDiscount, setPercentageDiscount] = useState(
    product.percentageDiscount || 0
  );
  const [salePrice, setSalePrice] = useState(product.salePrice || 0);
  const [discountAmount, setDiscountAmount] = useState(
    product.discountAmount || 0
  );
  const [pricePerUnitOrBox, setPricePerUnitOrBox] = useState(
    product.pricePerUnitOrBox || productData?.pricePerUnitOrBox || 0
  );

  const handleFileUpload = (id) => {
    /*    console.log("Product ID:", id);
    console.log("Selected file:", file); */

    // Create a new FormData object
    const formData = new FormData();

    // Append the file and additional data to the FormData
    /*   formData.append("file", file);
    formData.append("productID", id); */

    // Make a POST request using axios
    /* axios
      .post("https://perfectinvoicing-server.onrender.com/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Important to set the content type for file uploads
        },
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      }); */
  };

  const handleChange = (e, checkboxType) => {
    const { checked, value, name } = e.target || false;
    let updatedProperty;

    switch (checkboxType || e) {
      case "alertExpiringDate":
      case "alertLowInventory":
      case "category":
        updatedProperty = { [checkboxType]: checked ? value : e.target.value };
        break;

      case "pricePerUnit":
      case "pricePerBox":
      case "priceOther":
        updatedProperty = { pricePerUnitOrBox: e };
        break;

      case "buyPriceForBox":
        const buyPriceForBox = Number(value);
        const unitsInBox = Number(
          product.unitsInBox || productData?.unitsInBox || 0
        );
        updatedProperty = {
          buyPriceForBox: buyPriceForBox,
          buyPrice: unitsInBox !== 0 ? buyPriceForBox / unitsInBox : 0,
        };
        break;

      case "buyPrice":
        const buyPrice = Number(value);
        const unitsInBox2 = Number(
          product.unitsInBox || productData?.unitsInBox || 0
        );
        updatedProperty = {
          buyPrice: buyPrice,
          buyPriceForBox: unitsInBox2 !== 0 ? buyPrice * unitsInBox2 : 0,
        };
        console.log("Buy Price:", updatedProperty);
        break;

      case "image": // handle image change separately
        updatedProperty = { image: e.target.files[0].name };
        break;

      default:
        updatedProperty = { [name]: value };
        break;
    }

    // if it is a price related convert it to a number
    if (name === "sellPrice" || name === "salePrice") {
      updatedProperty = { [name]: Number(value) };
    }

    // Update state
    setProduct((prevState) => ({
      ...prevState,
      ...updatedProperty,
    }));
  };

  const handleSubmit = (e) => {
    console.log("Product:", product);
    e.preventDefault();
    handleFileUpload();
    if (!product.id && !productData?.id) {
      alert("Please fill in the barcode");
      return;
    }

    if (
      // productData is not empty and product is not empty and is defined
      productData &&
      product &&
      Object.keys(productData).length !== 0 &&
      Object.keys(product).length !== 0
    ) {
      console.log("Product:", product);
      // get the difference between the product and the productData and is not "" or 0
      const productDataEntries = Object.entries(productData);
      const productEntries = Object.entries(product);

      const productDataEntriesFiltered = productDataEntries.filter(
        (entry) => entry[1] !== "" && entry[1] !== 0
      );
      const productEntriesFiltered = productEntries.filter(
        (entry) => entry[1] !== "" && entry[1] !== 0
      );
      // find the product in the server and update it productData.id
      // convert productEntriesFiltered to an object the first element is the key and the second is the value
      const productDataObject = Object.fromEntries(productEntriesFiltered);
      fetch(`${config.URL_PROD}/products/${productData.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(productDataObject),
      }).then(
        (response) => {
          console.log("Response:", response);
          // Go to catalog page
          window.location.href = "/Catalog";
        },
        (error) => {
          console.log("Error:", error);
        }
      );
    } else {
      console.log("Product 2 new", product);
      // send the product to the server
      fetch(`${config.URL_PROD}/products`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify([product]),
      })
        .then((response) => {
          if (response.status === 201) {
            return response.json();
          } else {
            response.json().then((data) => {
              console.log("Msg:", data);
              // Go to catalog page
              window.location.href = "/Catalog";
            });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
    setProduct(initialState); // Clear the form after submission
  };

  const handlePercentageChange = (value) => {
    setPercentageDiscount(value);

    const amount = product.sellPrice
      ? (product.sellPrice * value) / 100
      : (productData?.sellPrice * value) / 100;
    setDiscountAmount(amount);

    const salePrice = product.sellPrice
      ? product.sellPrice - (product.sellPrice * value) / 100
      : productData?.sellPrice - (productData?.sellPrice * value) / 100;
    setSalePrice(salePrice.toFixed(2));

    setProduct((prevState) => ({
      ...prevState,
      percentageDiscount: value,
      discountAmount: amount,
      salePrice: salePrice,
    }));
  };

  function handleAmountChange(value) {
    const percentage = product.sellPrice
      ? ((product?.sellPrice - value) / product?.sellPrice) * 100
      : ((productData?.sellPrice - value) / productData?.sellPrice) * 100;
    const amount = product.sellPrice
      ? product?.sellPrice - value
      : productData?.sellPrice - value;

    setPercentageDiscount(percentage);
    setDiscountAmount(amount);
    setProduct((prevState) => ({
      ...prevState,
      percentageDiscount: percentage,
      discountAmount: amount,
      salePrice: value,
    }));
  }

  return (
    <div className="d-flex align-items-center justify-content-between">
      <main id="main" className="main">
        <div className="row g-3" style={{ width: "80%", marginLeft: "10%" }}>
          <div className="col-md-12">
            <Link to="/ImportProductsFromExcel">
              <button className="btn btn-info">
                Import Products From Excel
              </button>
            </Link>
          </div>
          <br />
          <h1>Add A Product</h1>
          <div className="col-md-4">
            <label htmlFor="id" className="form-label">
              Barcode/ID
            </label>
            <input
              id="id"
              name="id"
              className="form-control"
              placeholder="Barcode/ID"
              value={product?.barcode || product.id || productData?.id || ""}
              onChange={handleChange}
              autoFocus
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="name" className="form-label">
              Product Name
            </label>
            <input
              id="name"
              name="name"
              className="form-control"
              placeholder="Product Name"
              value={product.productName || product.name || productData?.name}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="description" className="form-label">
              Description
            </label>
            <input
              id="description"
              name="description"
              className="form-control"
              placeholder="Description"
              value={product.description || productData?.description}
              onChange={handleChange}
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="image" className="form-label">
              Image
            </label>
            <input
              id="image"
              name="image"
              className="form-control"
              onChange={(e) => {
                setFile(e.target.files[0]);
                handleChange(e, "image");
              }}
              type="file"
              accept="image/*"
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="manufacturer" className="form-label">
              Supplier
            </label>
            <input
              id="manufacturer"
              name="manufacturer"
              placeholder="Manufacturer"
              className="form-control"
              value={
                product.manufacturer ||
                product.supplier ||
                productData?.supplier ||
                productData?.manufacturer ||
                ""
              }
              onChange={handleChange}
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="transportCode" className="form-label">
              Transport Code
            </label>
            <input
              type="text"
              id="transportCode"
              name="transportCode"
              placeholder="Transport Code"
              className="form-control"
              value={product.transportCode || productData?.transportCode || ""}
              onChange={handleChange}
            />
          </div>

          {/* buy price on a box */}
          <div className="col-md-4">
            <label htmlFor="buyPriceForBox" className="form-label">
              Buy Price For Box
            </label>
            <input
              id="buyPriceForBox"
              name="buyPriceForBox"
              value={product.buyPriceForBox || productData?.buyPriceForBox || 0}
              onChange={(e) => {
                handleChange(e, "buyPriceForBox");
                console.log("Buy Price For Box:", e.target.value);
              }}
              placeholder="Buy Price For Box"
              className="form-control"
              type="number"
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="buyPrice" className="form-label">
              Buy Price
            </label>
            <input
              id="buyPrice"
              name="buyPrice"
              value={
                product.buyPrice ||
                productData?.buyPrice ||
                productData?.buyPriceForBox ||
                0
              }
              onChange={(e) => {
                handleChange(e, "buyPrice");
                console.log("Buy Price:", e.target.value);
              }}
              placeholder="Buy Price for a box"
              className="form-control"
              type="number"
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="unitsInBox" className="form-label">
              Units in Box
            </label>
            <input
              id="unitsInBox"
              name="unitsInBox"
              value={product.unitsInBox || productData?.unitsInBox || 0}
              onChange={handleChange}
              placeholder="Units in Box"
              className="form-control"
              type="number"
            />
          </div>

          {/* radio button price per box or per unit or other with a input field */}

          <div className={`col-md-4 ${styles.radioBtnPricePerUnitOrBox}`}>
            {/* a radio button price per unit or per box or other */}
            <label htmlFor="pricePerUnit" className="form-label">
              <input
                type="radio"
                id="pricePerUnit"
                name="pricePerUnitOrBox"
                onChange={() => {
                  setPricePerUnitOrBox("pricePerUnit");
                  handleChange("pricePerUnit");
                }}
                checked={
                  product.pricePerUnitOrBox === "pricePerUnit" ||
                  product.pricePerUnitOrBox === "unit" ||
                  product.pricePerUnitOrBox === "Unit" ||
                  product.pricePerUnitOrBox === "units" ||
                  productData?.pricePerUnitOrBox === "pricePerUnit" ||
                  productData?.pricePerUnitOrBox === "unit" ||
                  productData?.pricePerUnitOrBox === "Unit" ||
                  productData?.pricePerUnitOrBox === "units"
                }
              />
              Price Per Unit
            </label>
            <label htmlFor="pricePerBox" className="form-label">
              <input
                type="radio"
                id="pricePerBox"
                name="pricePerUnitOrBox"
                onChange={() => {
                  setPricePerUnitOrBox("pricePerBox");
                  handleChange("pricePerBox");
                }}
                checked={
                  product.pricePerUnitOrBox === "pricePerBox" ||
                  productData?.pricePerUnitOrBox === "pricePerBox"
                }
              />
              Price Per Box
            </label>
            <label htmlFor="priceOther" className="form-label">
              <input
                type="radio"
                id="priceOther"
                name="pricePerUnitOrBox"
                onChange={() => {
                  setPricePerUnitOrBox("priceOther");
                  handleChange("priceOther");
                }}
                checked={
                  product.pricePerUnitOrBox === "priceOther" ||
                  product.pricePerUnitOrBox === "other" ||
                  product.pricePerUnitOrBox === "Other" ||
                  productData?.pricePerUnitOrBox === "priceOther" ||
                  productData?.pricePerUnitOrBox === "other" ||
                  productData?.pricePerUnitOrBox === "Other"
                }
              />
              Price Per Other Amount
            </label>
            <input
              id="otherAmount"
              name="pricePerXAmount"
              value={
                product.pricePerXAmount || productData?.pricePerXAmount || 0
              }
              onChange={handleChange}
              placeholder="Price Other Amount"
              className="form-control"
              type="number"
              style={{
                display:
                  product.pricePerUnitOrBox === "priceOther" ||
                  product.pricePerUnitOrBox === "Other" ||
                  product.pricePerUnitOrBox === "other" ||
                  productData?.pricePerUnitOrBox === "priceOther" ||
                  productData?.pricePerUnitOrBox === "Other" ||
                  productData?.pricePerUnitOrBox === "other"
                    ? "block"
                    : "none",
              }}
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="sellPrice" className="form-label">
              Sell Price
            </label>
            <input
              id="sellPrice"
              name="sellPrice"
              value={product.sellPrice || productData?.sellPrice || 0}
              onChange={(e) => {
                handleChange(e);
                setSalePrice(e.target.value);
              }}
              placeholder="Sell Price"
              className="form-control"
              type="number"
            />
          </div>

          <div className="col-md-4">
            <label>
              Percentage Discount (%):
              <input
                className="form-control"
                type="number"
                step={0.001}
                value={
                  product.percentageDiscount ||
                  productData?.percentageDiscount ||
                  0
                }
                onChange={(e) => handlePercentageChange(e.target.value)}
              />
            </label>

            <label>
              Discount Amount (€):
              <input
                className="form-control"
                value={
                  product.discountAmount || productData?.discountAmount || 0
                }
                onChange={(e) => setDiscountAmount(parseFloat(e.target.value))}
              />
            </label>

            <label>
              Sale Price (€):
              <input
                className="form-control"
                type="number"
                step={0.01}
                // value also can empty string if i press backspace
                value={product.salePrice || productData?.salePrice || 0}
                onChange={(e) => {
                  handleAmountChange(e.target.value);
                }}
              />
            </label>
          </div>

          <div className="col-md-2">
            <label htmlFor="quantity" className="form-label">
              Quantity
            </label>
            <input
              id="quantity"
              name="quantity"
              value={product.quantity || productData?.quantity || 0}
              onChange={handleChange}
              className="form-control"
              placeholder="Quantity"
              type="number"
            />
          </div>

          <div className="col-md-2">
            <label htmlFor="btw" className="form-label">
              BTW
            </label>
            <input
              id="btw"
              name="btw"
              value={
                product.btw ||
                product.tax ||
                product.taxRate ||
                productData?.btw ||
                productData?.tax ||
                productData?.taxRate ||
                0
              }
              onChange={handleChange}
              className="form-control"
              placeholder="BTW"
              type="number"
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="catagory" className="form-label">
              Add Catagory (seperate with comma)
            </label>
            <input
              id="catagory"
              name="category"
              value={product.category || productData?.category || ""}
              onChange={handleChange}
              className="form-control"
              placeholder="Catagory"
            />
          </div>
          <div className="col-md-3">
            <label htmlFor="papular" className="form-label">
              Papular (per month sold)
            </label>
            <input
              id="papular"
              name="papular"
              value={product.papular || productData?.papular || 0}
              onChange={handleChange}
              className="form-control"
              placeholder="Papular"
              type="number"
            />
          </div>

          {/* inventory low amount */}
          <div className="col-md-3">
            <label htmlFor="inventoryLowAmount" className="form-label">
              Inventory Low Amount
            </label>
            <input
              id="inventoryLowAmount"
              name="inventoryLowAmount"
              className="form-control"
              placeholder="Inventory Low Amount"
              type="number"
              value={
                product.inventoryLowAmount ||
                productData?.inventoryLowAmount ||
                0
              }
              onChange={handleChange}
            />
          </div>

          <div className="col-md-3">
            <span>
              Alert Low Qnty:{" "}
              {product.alertLowInventory === true ||
              productData?.alertLowInventory === true ||
              lowInventoryAlert
                ? "ON"
                : "OFF"}
            </span>
            <input
              style={{ display: "none" }}
              checked={
                product.alertLowInventory === true ||
                productData?.alertLowInventory === true ||
                lowInventoryAlert
              }
              onChange={(e) => handleChange(e, "alertLowInventory")}
              className={style.switchCheckbox}
              id={`switchLowInventory`}
              type="checkbox"
            />
            <label
              className={`${style.switchLabel} ${
                product.alertLowInventory === true ||
                productData?.alertLowInventory === true ||
                lowInventoryAlert
                  ? style.colorOne
                  : style.colorTwo
              }`}
              htmlFor={`switchLowInventory`}
            >
              <span
                className={style.switchButton}
                data-on="✔"
                data-off="✖"
                data-content={
                  product.alertLowInventory === true ||
                  productData?.alertLowInventory === true ||
                  lowInventoryAlert
                    ? "✔"
                    : "✖"
                }
              />
            </label>
          </div>

          {/* expiration date */}
          <div className="col-md-3">
            <label htmlFor="expirationDate" className="form-label">
              Expiration Date
            </label>
            <input
              id="expirationDate"
              name="expirationDate"
              className="form-control"
              placeholder="Expiration Date"
              type="date"
              value={
                product.expirationDate || productData?.expirationDate || ""
              }
              onChange={handleChange}
            />
          </div>
          {/* add how long before expiration date to notify */}
          <div className="col-md-3">
            <label
              htmlFor="notifyXAmountOfDaysBeforeExpiringDate"
              className="form-label"
            >
              Alert x days before expiring
            </label>
            <input
              id="notifyXAmountOfDaysBeforeExpiringDate"
              name="notifyXAmountOfDaysBeforeExpiringDate"
              className="form-control"
              placeholder="How many days before expiring"
              type="number"
              value={
                product.notifyXAmountOfDaysBeforeExpiringDate ||
                productData?.notifyXAmountOfDaysBeforeExpiringDate ||
                0
              }
              onChange={handleChange}
            />
          </div>

          <div className="col-md-3">
            Alert Expiring:{" "}
            {product.alertExpiringDate === true ||
            productData?.alertExpiringDate === true ||
            expiringDateAlert
              ? "ON"
              : "OFF"}
            <input
              style={{ display: "none" }}
              checked={
                product.alertExpiringDate === true ||
                productData?.alertExpiringDate ||
                expiringDateAlert
              }
              onChange={(e) => handleChange(e, "alertExpiringDate")}
              className={style.switchCheckbox}
              id={`switchExpiring`}
              type="checkbox"
            />
            <label
              className={`${style.switchLabel} ${
                product.alertExpiringDate === true ||
                productData?.alertExpiringDate === true ||
                expiringDateAlert
                  ? style.colorOne
                  : style.colorTwo
              }`}
              htmlFor={`switchExpiring`}
            >
              <span
                className={style.switchButton}
                data-on="✔"
                data-off="✖"
                data-content={
                  product.alertExpiringDate === true ||
                  productData?.alertExpiringDate === true ||
                  expiringDateAlert
                    ? "✔"
                    : "✖"
                }
              />
            </label>
          </div>

          <div className={styles.allButtons}>
            <button
              className="btn btn-info"
              onClick={handleSubmit} /* does not work now  to="/Catalog" */
            >
              Save & Close
            </button>
            <button className="btn btn-info" onClick={handleSubmit}>
              <NavLink to="/addProduct">Save & Add Another</NavLink>
            </button>
            <button className="btn btn-secondary" type="reset">
              <NavLink to="/Catalog">Cancel</NavLink>
            </button>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AddProduct;
