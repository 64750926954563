import CheckForToken from "../../checkForToken";
const QuoteNumberSetting = () => {
  CheckForToken();
  return (
    <div>
      <h1>Quote Number Setting</h1>
    </div>
  );
};

export default QuoteNumberSetting;
