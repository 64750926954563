const calculateQuantityBoxesAndUnits = (item) => {
  switch (item.pricePerUnitOrBox) {
    case "priceOther" || "other" || "pricePerXAmount":
      if (
        (Math.abs(item.quantity) * item.pricePerXAmount) % item.unitsInBox ===
        0
      ) {
        return `${Math.floor(
          Math.abs(item.quantity) / item.unitsInBox
        )} box/es (${item.unitsInBox} X  ${item.pricePerXAmount} in a box)`;
      } else {
        return `${Math.floor(
          Math.abs(item.quantity) / item.unitsInBox
        )} boxes ${Math.abs(item.quantity) % item.unitsInBox} units ( ${
          item.unitsInBox
        } X ${item.pricePerXAmount} in a box)`;
      }
    case "unit" || "pricePerUnit":
      // if the quantity is less than a box return the quantity (units per box) else return the quantity divided by the units per box and add the remainder
      if (Math.abs(item.quantity) < item.unitsInBox) {
        return `${Math.abs(item.quantity)} units (${item.unitsInBox} in a box)`;
      } else {
        return `${Math.floor(
          Math.abs(item.quantity) / item.unitsInBox
        )} box/es ${
          Math.abs(item.quantity) % item.unitsInBox > 0
            ? (Math.abs(item.quantity) % item.unitsInBox) +
              `units (${item.unitsInBox} in a box)`
            : `(${item.unitsInBox} in a box)`
        }`;
      }
    case "pricePerBox":
      return `${Math.abs(item.quantity)} box/es (${
        item.unitsInBox
      } single units) (${item.unitsInBox} in a box))`;
    default:
      return Math.abs(item.quantity) * item.pricePerXAmount;
  }
};

export default calculateQuantityBoxesAndUnits;
