import { useNavigate } from "react-router-dom";
const token = localStorage.getItem("token");

const CheckForToken = () => {
  const navigate = useNavigate();
  window.onload = function () {
    if (!token) {
      navigate("/login");
    }
  };
};

export default CheckForToken;
