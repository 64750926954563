import Invoice from "./invoice";
import Preview from "./preview";
import CheckForToken from "../checkForToken";

const EditInvoices = () => {
  CheckForToken();
  const invoiceNumberNotParsed = localStorage.getItem("editProforma");
  const invoiceNumber = JSON.parse(
    invoiceNumberNotParsed
  ).performaInvoiceNumber;
  const positionOfEdit = JSON.parse(invoiceNumberNotParsed).positionOfEdit;

  const proformaInvoiceNumber = JSON.parse(
    localStorage.getItem("proformaNumber")
  );

  const saveHandler = () => {
    const editProformaNumber = JSON.parse(localStorage.getItem("editProforma"));
    const editProforma = JSON.parse(localStorage.getItem("editProforma"));

    if (editProforma.positionOfEdit === "Proforma Invoice Draft") {
      // delete the draft proforma invoice from the savedProformaInvoicesDraft array local storage
      const savedProformaInvoicesDraftArray =
        JSON.parse(localStorage.getItem("savedProformaInvoicesDraft")) || [];
      const savedProformaInvoicesDraftArrayFiltered =
        savedProformaInvoicesDraftArray.filter(
          (item) =>
            item.performaInvoiceNumber !==
            editProformaNumber.performaInvoiceNumber
        );
      localStorage.setItem(
        "savedProformaInvoicesDraft",
        JSON.stringify(savedProformaInvoicesDraftArrayFiltered)
      );
    } else {
      // delete the proforma invoice from the savedProformaInvoices array local storage
      const savedProformaInvoicesArray = JSON.parse(
        localStorage.getItem("savedProformaInvoices")
      );
      const savedProformaInvoicesArrayFiltered =
        savedProformaInvoicesArray.filter(
          (item) =>
            item.performaInvoiceNumber !==
            editProformaNumber.performaInvoiceNumber
        );
      localStorage.setItem(
        "savedProformaInvoices",
        JSON.stringify(savedProformaInvoicesArrayFiltered)
      );
    }

    // clear the local storage edit proforma to empty object
    localStorage.setItem("editProforma", JSON.stringify({}));

    // add the edited proforma invoice to the savedProformaInvoicesDraft array local storage or to the savedProformaInvoices array local storage depending on the status of the proforma invoice
    if (editProforma.positionOfEdit === "Proforma Invoice Draft") {
      const savedProformaInvoicesDraftArray =
        JSON.parse(localStorage.getItem("savedProformaInvoicesDraft")) || [];
      const savedProformaInvoicesDraftArrayFiltered =
        savedProformaInvoicesDraftArray.filter(
          (item) =>
            item.performaInvoiceNumber !==
            editProformaNumber.performaInvoiceNumber
        );
      localStorage.setItem(
        "savedProformaInvoicesDraft",
        JSON.stringify([
          ...savedProformaInvoicesDraftArrayFiltered,
          editProforma,
        ])
      );
    } else {
      const savedProformaInvoicesArray = JSON.parse(
        localStorage.getItem("savedProformaInvoices")
      );
      const savedProformaInvoicesArrayFiltered =
        savedProformaInvoicesArray.filter(
          (item) =>
            item.performaInvoiceNumber !==
            editProformaNumber.performaInvoiceNumber
        );
      localStorage.setItem(
        "savedProformaInvoices",
        JSON.stringify([...savedProformaInvoicesArrayFiltered, editProforma])
      );
    }

    // clear the local storage edit proforma to empty object
    localStorage.setItem("editProforma", JSON.stringify({}));
  };

  return (
    <div className="d-flex align-items-center justify-content-between">
      <main id="main" className="main">
        <h1>Edit {positionOfEdit || "invoice"}</h1>
        <Invoice
          invoiceNumber={invoiceNumber}
          positionOfEdit={positionOfEdit}
        />
        <div className="all-buttons">
          <button className="btn btn-primary" onClick={saveHandler}>
            Save
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => {
              localStorage.setItem("editProforma", JSON.stringify({}));
            }}
          >
            Cancel
          </button>
        </div>
      </main>
    </div>
  );
};

export default EditInvoices;
