import React, { useState } from "react";
import ReactDOM from "react-dom";

import PrintComponent from "./PrintComponent";

function Preview({
  indexNumber = " ",
  date,
  performaInvoiceNumber,
  expDate,
  companyInfoText = {},
  customerInfoText = {},
  items,
  remarks,
  subTotal,
  discount,
  tax,
  total,
  currencySymbol,
  discountedPriceTotal,
  btw,
  totalAmount,
}) {
  const [printing, setPrinting] = useState(false);

  const handlePrintClick = () => {
    setPrinting(true);

    // Open a new window
    const printWindow = window.open("", "_blank");

    // Create a new document within the new window
    const printDocument = printWindow.document;

    // Write the HTML content to the new document
    printDocument.write("<html><head><title>Print</title></head><body>");

    // Render the PrintComponent inside the new document
    ReactDOM.render(
      <PrintComponent
        indexNumber={indexNumber}
        date={date}
        performaInvoiceNumber={performaInvoiceNumber || proformaNumber}
        expDate={
          expDate ||
          `${new Date(
            new Date(date).getTime() + 30 * 24 * 60 * 60 * 1000
          ).toLocaleDateString("en-US")}`
        }
        companyInfoText={
          companyInfoText
            ? // destructuring the companyInfoText object
              {
                companyName: companyInfoText.companyName || companyName,
                address: companyInfoText.address || companyAddress,
                city: companyInfoText.city || companyCity,
                zipCode: companyInfoText.zipCode || companyZipCode,
                country: companyInfoText.country || companyCountry,
                email: companyInfoText.email || companyEmail,
                phone: companyInfoText.phone || companyPhone,
                btwNumber: companyInfoText.btwNumber || companyBtwNumber,
              }
            : {
                companyName,
                address: companyAddress,
                city: companyCity,
                zipCode: companyZipCode,
                country: companyCountry,
                email: companyEmail,
                phone: companyPhone,
                btwNumber: companyBtwNumber,
              }
        }
        customerInfoText={
          customerInfoText
            ? // destructuring the customerInfoText object
              {
                companyName: customerInfoText.companyName || customerName,
                address: customerInfoText.address || address,
                city: customerInfoText.city || city,
                zipCode: customerInfoText.zipCode || zipCode,
                country: customerInfoText.country || country,
                email: customerInfoText.email || email,
                phone: customerInfoText.phone || " ",
                btwNumber: customerInfoText.btwNumber || btwNumber,
                showTransportCode: customerInfoText.showTransportCode,
              }
            : {
                companyName: customerName,
                address,
                city,
                zipCode,
                country,
                email,
                btwNumber,
                showTransportCode: false,
              }
        }
        items={items}
        remarks={remarks}
        subTotal={subTotal}
        discount={discount || discountedPriceTotal}
        tax={tax || btw}
        total={total || totalAmount}
        currencySymbol={currencySymbol}
      />,
      printDocument.body
    );

    // Close the HTML document and display the print dialog
    printDocument.write("</body></html>");
    printDocument.close();
    printWindow.print();
    printWindow.close();
    setPrinting(false);
  };

  const itemsRows = (items) => {
    return items.map((item) => {
      return (
        <tr key={item.id}>
          <td>{item.name}</td>
          <td>{item.description}</td>
          {customerInfoText.showTransportCode === true ? (
            <td>{item.transportCode}</td>
          ) : (
            ""
          )}
          <td>{item.quantity}</td>
          <td>{item.price}</td>
          {item.discount ? <td>{item.discount}</td> : ""}
          <td>{item.btw}</td>
          <td>
            {item.total} {currencySymbol}
          </td>
        </tr>
      );
    });
  };

  const invoicesNotParsed = localStorage.getItem("invoices") || "[]"; // Ensure invoices is an array
  const invoices = JSON.parse(invoicesNotParsed);

  // Check if indexNumber is within bounds
  if (
    indexNumber < 0 ||
    (indexNumber >= invoices.length && !performaInvoiceNumber)
  ) {
    return (
      <div
        style={{
          textAlign: "center",
          fontSize: "2rem",
          fontWeight: "bold",
          marginTop: "2rem",
        }}
      >
        Invoice not found
      </div>
    );
  }

  // Get the invoice based on indexNumber
  const invoice = invoices[indexNumber];

  // const [discountedPriceTotal, btw, totalAmount] = [invoice.discountedPriceTotal, invoice.btw, invoice.totalAmount];

  // Check if invoice is defined
  if (!invoice && !performaInvoiceNumber) {
    return (
      <div
        style={{
          textAlign: "center",
          fontSize: "2rem",
          fontWeight: "bold",
          marginTop: "2rem",
        }}
      >
        Invoice not found
      </div>
    );
  }

  // Destructure the invoice object's companyDetails and customerDetails
  const { proformaNumber, companyDetails, selectedResultCustomer } =
    invoice || {};

  // Destructure the companyDetails object
  const {
    companyName,
    companyAddress,
    companyCity,
    companyZipCode,
    companyCountry,
    companyEmail,
    companyPhone,
    companyBtwNumber,
  } = companyDetails || {};

  // Destructure the customerDetails object
  const {
    companyName: customerName,
    address,
    city,
    zipCode,
    country,
    email,
    // customerPhone,
    btwNumber,
  } = selectedResultCustomer || {};

  return (
    <div>
      <table
        style={{
          border: "none",
          boxShadow: "none",
        }}
      >
        <thead>
          <tr>
            <td>
              <div className="invoice">
                <div
                  className="invoice-title"
                  style={{
                    textAlign: "center",
                    paddingTop: "2rem",
                    marginBottom: "2rem",
                  }}
                >
                  {`${performaInvoiceNumber ? "Proforma Invoice" : "Invoice"}`}{" "}
                  Invoice
                </div>
                <div style={{ float: "right" }}>
                  <div>
                    {`${
                      performaInvoiceNumber ? "Proforma Invoice" : "Invoice"
                    }`}{" "}
                    Number: {performaInvoiceNumber || proformaNumber}
                  </div>
                  <div>
                    {`${
                      performaInvoiceNumber ? "Proforma Invoice" : "Invoice"
                    }`}{" "}
                    Date: {new Date(date).toLocaleDateString("en-US")}
                  </div>
                  <div>
                    {`${
                      performaInvoiceNumber ? "Proforma Invoice" : "Invoice"
                    }`}{" "}
                    Exp Date:{" "}
                    {expDate ||
                      `${new Date(
                        new Date(date).getTime() + 30 * 24 * 60 * 60 * 1000
                      ).toLocaleDateString("en-US")}`}
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div className="invoice-details">
                <div>
                  <strong>From:</strong>
                  <br />
                  {companyInfoText.companyName || companyName}
                  <br />
                  {companyInfoText.address || companyAddress}
                  <br />
                  {companyInfoText.city || companyCity}
                  <br />
                  {companyInfoText.zipCode || companyZipCode}
                  <br />
                  {companyInfoText.country || companyCountry}
                  {/*  <br />
                  Email: {companyInfoText.email || companyEmail}
                  <br />
                  Phone: {companyInfoText.phone || companyPhone} */}
                  <br />
                  BTW Number: {companyInfoText.btwNumber || companyBtwNumber}
                  <br />
                </div>
                <div>
                  <strong>To:</strong>
                  <br />
                  {customerInfoText.companyName || customerName}
                  <br />
                  {customerInfoText.address || address}
                  <br />
                  {customerInfoText.city || city}
                  <br />
                  {customerInfoText.zipCode || zipCode}
                  <br />
                  {customerInfoText.country || country}
                  <br />
                  {/* Email: {customerInfoText.email}<br />
                  Phone: {customerInfoText.phone}<br /> 
                  BTW Number: {customerInfoText.btwNumber}<br /> */}
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <table className="invoice-items">
                <thead>
                  <tr>
                    <th>Item Name</th>
                    <th>Item Description</th>
                    {customerInfoText.showTransportCode === true && (
                      <th>Transport Code</th>
                    )}
                    <th>Quantity</th>
                    <th>Unit Price</th>
                    {items.some((item) => item.discount) && <th>Discount</th>}
                    <th>Btw</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>{itemsRows(items)}</tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              {remarks ? (
                <strong>
                  Remarks: {remarks}
                  <br />
                </strong>
              ) : (
                ""
              )}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>
              <div className="invoice-total footer">
                Sub Total: {subTotal}
                <br />
                {items.some((item) => item.discount) && (
                  <>
                    Discount: {discount || discountedPriceTotal}
                    <br />
                  </>
                )}
                Tax: {tax || btw}
                <br />
                <strong>
                  Total: {total || totalAmount} {currencySymbol}
                </strong>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <button
          onClick={handlePrintClick}
          disabled={printing}
        >
          <i class="material-icons">print</i>
        </button>
      </div>
    </div>
  );
}

export default Preview;
