import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import ProformaInvoiceHeader from "./ProformaInvoiceHeader";
import Invoice from "./invoice";
/* import FooterProformaInvoice from "../admin/FooterProformaInvoice";
import preview from "./preview"; */

import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useReactToPrint } from "react-to-print";

import styles from "./ProformaInvoice.module.css";
import "./invoice.css";
import "./buttons.css";
import "./print.css";

function ProformaInvoice() {
  if (!localStorage.getItem("token")) {
    window.location.href = "/login";
  }
  const [changes, setChanges] = useState(true);
  const [htmlContent, setHtmlContent] = useState("");
  const componentRef = useRef();
  const toDownloadRef = useRef(null); // Create a ref
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const [storedCustomerDetails, setStoredCustomerDetails] = useState("");
  let proformaNumber = useMemo(
    () => JSON.parse(localStorage.getItem("proformaNumber")) || "",
    []
  );

  let storedInvoice;
  let accountInfo;
  let paymentInfo;
  let goodsInfo;
  let invoiceDone =
    JSON.parse(
      localStorage.getItem("editProforma") || localStorage.getItem("invoice")
    ) || {};
  useEffect(() => {
    // Load data from localStorage when the component mounts
    storedInvoice = JSON.parse(localStorage.getItem("invoice")) || {};
    const editProformaNumber = JSON.parse(localStorage.getItem("editProforma"));
    const proformaNumberGet = JSON.parse(
      localStorage.getItem("proformaNumber")
    );
    proformaNumber =
      editProformaNumber?.performaInvoiceNumber || proformaNumberGet;
    accountInfo = JSON.parse(localStorage.getItem("accountInfo")) || "";
    paymentInfo = JSON.parse(localStorage.getItem("paymentInfo")) || "";
    goodsInfo = JSON.parse(localStorage.getItem("goodsInfo")) || "";

    invoiceDone = JSON.parse(
      localStorage.getItem("invoice") ||
        JSON.stringify({
          date: "",
          proformaNumber,
          expirationDate: "",
          selectedResultCustomer: {
            companyName: "",
            address: "",
            city: "",
            country: "",
            zipCode: "",
            deliveryAddress: "",
            deliveryCity: "",
            deliveryCountry: "",
            email: "",
            phone: "",
            btwNumber: "",
            btwAmount: "",
            discount: "",
            tax: "",
            notes: "",
            savedAs: "",
            contactPerson: "",
            position: "",
            showTransportCode: "",
          },
          selectedCurrency: "",
          items: [
            {
              name: "",
              description: "",
              transportCode: "",
              quantity: "",
              price: "",
              discount: "",
              btw: "",
              total: "",
            },
          ],
          remarks: "",
          subTotal: "",
          discountedPriceTotal: "",
          btw: "",
          totalAmount: "",
        })
    );

    let companyDetails = JSON.parse(localStorage.getItem("companyDetails"));

    if (!companyDetails) {
      // Set default company details if not found in localStorage
      companyDetails = {
        companyName: "",
        companyAddress: "",
        companyCity: "",
        companyCountry: "",
        companyZipCode: "",
        companyEmail: "",
        companyPhone: "",
        companyBtwNumber: "",
      };

      localStorage.setItem("companyDetails", JSON.stringify(companyDetails));
    }

    const companyInfoText = {
      companyName: companyDetails.companyName,
      address: companyDetails.companyAddress,
      city: companyDetails.companyCity,
      country: companyDetails.companyCountry,
      zipCode: companyDetails.companyZipCode,
      email: companyDetails.companyEmail,
      phone: companyDetails.companyPhone,
      btwNumber: companyDetails.companyBtwNumber,
    };

    proformaNumber = JSON.parse(localStorage.getItem("proformaNumber")) || "";

    const date = invoiceDone.date || "";
    const expDate = invoiceDone.expDate || invoiceDone.expirationDate || "";

    const { selectedResultCustomer } = invoiceDone;

    let customerInfoText = {
      companyName: selectedResultCustomer
        ? selectedResultCustomer.companyName || ""
        : "",
      address: selectedResultCustomer
        ? selectedResultCustomer.address || ""
        : "",
      city: selectedResultCustomer ? selectedResultCustomer.city || "" : "",
      country: selectedResultCustomer
        ? selectedResultCustomer.country || ""
        : "",
      zipCode: selectedResultCustomer
        ? selectedResultCustomer.zipCode || ""
        : "",
      deliveryAddress: selectedResultCustomer
        ? selectedResultCustomer.deliveryAddress || ""
        : "",
      deliveryCity: selectedResultCustomer
        ? selectedResultCustomer.deliveryCity || ""
        : "",
      deliveryCountry: selectedResultCustomer
        ? selectedResultCustomer.deliveryCountry || ""
        : "",
      email: selectedResultCustomer ? selectedResultCustomer.email || "" : "",
      phone: selectedResultCustomer ? selectedResultCustomer.phone || "" : "",
      btwNumber: selectedResultCustomer
        ? selectedResultCustomer.btwNumber || ""
        : "",
      btwAmount: selectedResultCustomer
        ? selectedResultCustomer.btwAmount || ""
        : "",
      discount: selectedResultCustomer
        ? selectedResultCustomer.discount || ""
        : "",
      tax: selectedResultCustomer ? selectedResultCustomer.tax || "" : "",
      notes: selectedResultCustomer ? selectedResultCustomer.notes || "" : "",
      savedAs: selectedResultCustomer
        ? selectedResultCustomer.saveAs || ""
        : "",
      contactPerson: selectedResultCustomer
        ? selectedResultCustomer.contactPerson || ""
        : "",
      position: selectedResultCustomer
        ? selectedResultCustomer.position || ""
        : "",
      showTransportCode: selectedResultCustomer
        ? selectedResultCustomer.showTransportCode || ""
        : "",
    };

    // baseed on selectedCurrency, get the currency symbol
    const selectedCurrency = invoiceDone.selectedCurrency;
    let currencySymbol = "";
    if (selectedCurrency === "eur") {
      currencySymbol = "€";
    } else if (selectedCurrency === "usd") {
      currencySymbol = "$";
    } else if (selectedCurrency === "gbp") {
      currencySymbol = "£";
    } else if (selectedCurrency === "ils") {
      currencySymbol = "₪";
    }

    const items = invoiceDone.items;
    // Generate the invoice items rows
    const itemsRows = (items) => {
      return items
        .map((item) => {
          return `
              <tr>
                <td>${item.name}</td>
                <td>${item.description}</td>
                ${
                  customerInfoText.showTransportCode === true
                    ? `<td>${item.transportCode}</td>`
                    : ""
                }
                <td>${item.quantity}</td>
                <td>${item.price}</td>
                ${
                  items.some((item) => item.discount)
                    ? `<td>${item.discount}</td>`
                    : ""
                }
                <td>${item.btw}</td>
                <td>${item.total} ${currencySymbol}</td>
              </tr>
            `;
        })
        .join("");
    };

    const remarks = invoiceDone.remarks;
    const subTotal = invoiceDone.subTotal || "";
    const discount = invoiceDone.discountedPriceTotal || "";
    const tax = invoiceDone.btw || "";
    const total = invoiceDone.totalAmount || "";

    // Convert the generated HTML string into a JSX structure
    const jsxContent = (
      <div
        className="invoice-for-downloading"
        style={{
          flexDirection: "column",
          marginTop: "2rem",
          color: "black",
          maxWidth: "800px", // Max width
          margin: "0 auto", // Centered
        }}
      >
        <div className="invoice-header">
          <h2
            className="invoice-title"
            style={{
              textAlign: "center",
              marginTop: "2rem",
            }}
          >
            <strong>Proforma Invoice</strong>
          </h2>
          <div
            style={{
              float: "right",
            }}
          >
            <p>
              <strong>Proforma Invoice Number: </strong> {proformaNumber}
            </p>
            <p>
              <strong>Proforma Invoice Date: </strong> {date}
            </p>
            <p>
              <strong>Proforma Invoice Exp Date: </strong> {expDate}
            </p>
          </div>
        </div>
        <br />
        <br />
        <div
          style={{
            marginTop: "3rem",
          }}
        >
          {/* From */}
          <div
            style={{
              float: "left",
            }}
          >
            <strong>From:</strong>
            <br />
            {companyInfoText.companyName}
            <br />
            {companyInfoText.address}
            <br />
            {companyInfoText.city}
            <br />
            {companyInfoText.zipCode}
            <br />
            {companyInfoText.country}
            <br />
            Email: {companyInfoText.email}
            <br />
            Phone: {companyInfoText.phone}
            <br />
            BTW Number: {companyInfoText.btwNumber}
            <br />
          </div>

          {/* To */}
          <div
            style={{
              float: "right",
              paddingLeft: ".5rem",
            }}
          >
            <strong>To:</strong>
            <br />
            {customerInfoText.companyName}
            <br />
            {customerInfoText.address}
            <br />
            {customerInfoText.city}
            <br />
            {customerInfoText.zipCode}
            <br />
            {customerInfoText.country}
            {/* 
            Email: {customerInfoText.email}
            <br />
            Phone: {customerInfoText.phone}
            <br />
            BTW Number: {customerInfoText.btwNumber}
            <br /> */}
          </div>
        </div>

        {/* Invoice Items */}
        <br />
        <div style={{ width: "95%", marginTop: "10rem" }}>
          <br />
        </div>
        <br />
        <br />
        <table style={{ width: "95%", marginTop: "0", height: "fit-content" }}>
          <thead>
            <tr>
              <th style={{ width: "8%" }}>Barcode / ID</th>
              <th style={{ width: "8%" }}>Item Name</th>
              <th style={{ width: "8%" }}>Description</th>
              {!customerInfoText.showTransportCode != true && (
                <th style={{ width: "8%" }}>Transport Code</th>
              )}
              <th style={{ width: "8%" }}>Qty</th>
              <th style={{ width: "8%" }}>Price</th>
              {items?.some((item) => item.discount) && (
                <th style={{ width: "8%" }}>Discount</th>
              )}
              <th style={{ width: "8%" }}>Btw</th>
              <th style={{ width: "8%" }}>Total</th>
            </tr>
          </thead>
          <tbody>
            {items?.map((product) => (
              <tr key={product.id}>
                <td style={{ width: "15%" }}>
                  {product.id ? product.id.toString() : ""}
                </td>
                <td style={{ width: "15%" }}>
                  {product.name ? product.name.toString() : ""}
                </td>
                <td style={{ width: "15%" }}>
                  {product.description ? product.description.toString() : ""}
                </td>
                {customerInfoText.showTransportCode ? (
                  <td style={{ width: "15%" }}>
                    {product.transportCode
                      ? product.transportCode.toString()
                      : ""}
                  </td>
                ) : (
                  ""
                )}
                <td style={{ width: "15%" }}>
                  {product.quantity ? product.quantity.toString() : ""}
                </td>
                <td style={{ width: "15%" }}>
                  {product.price ? product.price.toString() : ""}
                </td>
                {items.some((item) => item.discount) ? (
                  <td style={{ width: "15%" }}>
                    {product.discount ? product.discount.toString() : ""}
                  </td>
                ) : (
                  ""
                )}
                <td style={{ width: "15%" }}>
                  {product.btw ? product.btw.toString() : ""}
                </td>
                <td style={{ width: "15%" }}>
                  {product.total ? product.total.toString() : ""}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Remarks */}
        {remarks && (
          <div>
            <strong>Remarks:</strong> {remarks}
            <br />
          </div>
        )}
        <div
          // style that the footer is on the bottom of the page
          style={{
            width: "95%",
          }}
        >
          <div
            style={{
              float: "right",
              // move all the way down
            }}
          >
            <div>
              <strong>Sub Total: </strong>
              {subTotal} {currencySymbol}
            </div>
            {items?.some((item) => item.discount) && (
              <div>
                <strong>Discount: </strong>
                {discount} {currencySymbol}
              </div>
            )}
            <div>
              <strong>BTW: </strong> {tax} {currencySymbol}
            </div>
            <div>
              <strong>Total: </strong> {total} {currencySymbol}
            </div>
          </div>
        </div>
      </div>
    );

    setChanges(!changes);

    // Set the JSX content as the HTML content
    setHtmlContent(jsxContent);
  }, [storedCustomerDetails, changes]);

  const printPage = () => {
    // Get the element to capture
    const elementToCapture = document.querySelector(".invoice-for-downloading");

    if (elementToCapture) {
      console.log("Element found.");
      // Create a new window for printing
      html2canvas(elementToCapture, { dpi: 300 }).then((canvas) => {
        // Convert the canvas to an image
        const imgData = canvas.toDataURL("image/png");

        // Create an image element to display or further process the captured content
        const img = new Image();
        img.src = imgData;

        // print the image directly from the browser.
        const printWindow = window.open("", "Print", "width=700,height=500");
        printWindow.document.write(elementToCapture.innerHTML);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
      });
    } else {
      console.log("Element not found.");
    }
  };

  const saveHandler = () => {
    const editProformaNumber = JSON.parse(localStorage.getItem("editProforma"));
    const editProforma = JSON.parse(localStorage.getItem("editProforma"));

    if (editProforma.positionOfEdit === "Proforma Invoice Draft") {
      // delete the draft proforma invoice from the savedProformaInvoicesDraft array local storage
      const savedProformaInvoicesDraftArray =
        JSON.parse(localStorage.getItem("savedProformaInvoicesDraft")) || [];
      const savedProformaInvoicesDraftArrayFiltered =
        savedProformaInvoicesDraftArray.filter(
          (item) =>
            item.performaInvoiceNumber !==
            editProformaNumber.performaInvoiceNumber
        );
      localStorage.setItem(
        "savedProformaInvoicesDraft",
        JSON.stringify(savedProformaInvoicesDraftArrayFiltered)
      );
    } else {
      // delete the proforma invoice from the savedProformaInvoices array local storage
      const savedProformaInvoicesArray = JSON.parse(
        localStorage.getItem("savedProformaInvoices")
      );
      const savedProformaInvoicesArrayFiltered =
        savedProformaInvoicesArray.filter(
          (item) =>
            item.performaInvoiceNumber !==
            editProformaNumber.performaInvoiceNumber
        );
      localStorage.setItem(
        "savedProformaInvoices",
        JSON.stringify(savedProformaInvoicesArrayFiltered)
      );
    }
    // clear the local storage edit proforma to empty object
    localStorage.setItem("editProforma", JSON.stringify({}));
    const invoiceDone = JSON.parse(
      localStorage.getItem("invoice") ||
        JSON.stringify({
          date: "",
          proformaNumber,
          expirationDate: "",
          selectedResultCustomer: {
            companyName: "",
            address: "",
            city: "",
            country: "",
            zipCode: "",
            deliveryAddress: "",
            deliveryCity: "",
            deliveryCountry: "",
            email: "",
            phone: "",
            btwNumber: "",
            btwAmount: "",
            discount: "",
            tax: "",
            notes: "",
            savedAs: "",
            contactPerson: "",
            position: "",
            showTransportCode: "",
          },
          selectedCurrency: "",
          items: [
            {
              name: "",
              description: "",
              transportCode: "",
              quantity: "",
              price: "",
              discount: "",
              btw: "",
              total: "",
            },
          ],
          remarks: "",
          subTotal: "",
          discountedPriceTotal: "",
          btw: "",
          totalAmount: "",
        })
    );

    let companyDetails = JSON.parse(localStorage.getItem("companyDetails"));

    if (!companyDetails) {
      // Set default company details if not found in localStorage
      companyDetails = {
        companyName: "",
        companyAddress: "",
        companyCity: "",
        companyCountry: "",
        companyZipCode: "",
        companyEmail: "",
        companyPhone: "",
        companyBtwNumber: "",
      };

      localStorage.setItem("companyDetails", JSON.stringify(companyDetails));
    }

    const companyInfoText = {
      companyName: companyDetails.companyName,
      address: companyDetails.companyAddress,
      city: companyDetails.companyCity,
      country: companyDetails.companyCountry,
      zipCode: companyDetails.companyZipCode,
      email: companyDetails.companyEmail,
      phone: companyDetails.companyPhone,
      btwNumber: companyDetails.companyBtwNumber,
    };

    const date = invoiceDone.date || "";
    const performaInvoiceNumber =
      JSON.parse(localStorage.getItem("proformaNumber")) || "";
    const expDate = invoiceDone.expirationDate || "";

    const { selectedResultCustomer } = invoiceDone;

    console.log("selectedResultCustomer", selectedResultCustomer);
    let customerInfoText = "";
    if (!selectedResultCustomer === undefined) {
      customerInfoText = {
        companyName: selectedResultCustomer.companyName,
        address: selectedResultCustomer.address,
        city: selectedResultCustomer.city,
        country: selectedResultCustomer.country,
        zipCode: selectedResultCustomer.zipCode,
        deliveryAddress: selectedResultCustomer.deliveryAddress,
        deliveryCity: selectedResultCustomer.deliveryCity,
        deliveryCountry: selectedResultCustomer.deliveryCountry,
        email: selectedResultCustomer.email,
        phone: selectedResultCustomer.phone,
        btwNumber: selectedResultCustomer.btwNumber,
        btwAmount: selectedResultCustomer.btwAmount,
        discount: selectedResultCustomer.discount,
        tax: selectedResultCustomer.tax,
        notes: selectedResultCustomer.notes,
        savedAs: selectedResultCustomer.saveAs,
        contactPerson: selectedResultCustomer.contactPerson,
        position: selectedResultCustomer.position,
        showTransportCode: selectedResultCustomer.showTransportCode,
      };
    } else {
      customerInfoText = {
        companyName: "",
        address: "",
        city: "",
        country: "",
        zipCode: "",
        deliveryAddress: "",
        deliveryCity: "",
        deliveryCountry: "",
        email: "",
        phone: "",
        btwNumber: "",
        btwAmount: "",
        discount: "",
        tax: "",
        notes: "",
        savedAs: "",
        contactPerson: "",
        position: "",
        showTransportCode: "",
      };
    }

    console.log("customerInfoText", customerInfoText);
    // baseed on selectedCurrency, get the currency symbol
    const selectedCurrency = invoiceDone.selectedCurrency;
    let currencySymbol = "";
    if (selectedCurrency === "eur") {
      currencySymbol = "€";
    } else if (selectedCurrency === "usd") {
      currencySymbol = "$";
    } else if (selectedCurrency === "gbp") {
      currencySymbol = "£";
    } else if (selectedCurrency === "ils") {
      currencySymbol = "₪";
    }

    const items = invoiceDone.items;
    console.log("items", items);
    // Generate the invoice items rows
    const itemsRows = (items) => {
      return items
        .map((item) => {
          return `
            <tr>
              <td>${item.name}</td>
              <td>${item.description}</td>
              ${
                customerInfoText.showTransportCode === true
                  ? `<td>${item.transportCode}</td>`
                  : ""
              }
              <td>${item.quantity}</td>
              <td>${item.price}</td>
              ${
                items.some((item) => item.discount)
                  ? `<td>${item.discount}</td>`
                  : ""
              }
              <td>${item.btw}</td>
              <td>${item.total} ${currencySymbol}</td>
            </tr>
          `;
        })
        .join("");
    };

    const remarks = invoiceDone.remarks;
    const subTotal = invoiceDone.subTotal || "";
    const discount = invoiceDone.discountedPriceTotal || "";
    const tax = invoiceDone.btw || "";
    const total = invoiceDone.totalAmount || "";

    const accountInfo = JSON.parse(localStorage.getItem("accountInfo")) || "";
    const accountInfoText = {
      bic: accountInfo.bic,
      iban: accountInfo.iban,
    };
    const paymentInfo = JSON.parse(localStorage.getItem("paymentInfo")) || "";
    const paymentInfoText = {
      beneficiary: paymentInfo.beneficiary,
      bankAddress: paymentInfo.bankAddress,
      bankCity: paymentInfo.city,
    };

    const goodsInfo = JSON.parse(localStorage.getItem("goodsInfo")) || "";
    const message = {
      message: goodsInfo.message,
    };

    // save everything in local storage to savedProformaInvoices an array of objects
    const savedProformaInvoices = {
      date,
      performaInvoiceNumber:
        editProformaNumber.performaInvoiceNumber || performaInvoiceNumber,
      expDate,
      companyInfoText,
      customerInfoText,
      selectedCurrency,
      items,
      remarks,
      subTotal,
      discount,
      tax,
      total,
      footer: {
        accountInfo,
        paymentInfo,
        message: goodsInfo,
      },
    };

    // update the performaInvoiceNumber by 1
    if (!editProformaNumber.performaInvoiceNumber) {
      // Increment the Proforma Invoice number
      const InvoiceNumber = parseFloat(proformaNumber) + 1;
      localStorage.setItem(
        "proformaNumber",
        JSON.stringify(InvoiceNumber) || 1
      );
    }

    const savedProformaInvoicesArray =
      JSON.parse(localStorage.getItem("savedProformaInvoices")) || [];
    savedProformaInvoicesArray.push(savedProformaInvoices);
    localStorage.setItem(
      "savedProformaInvoices",
      JSON.stringify(savedProformaInvoicesArray)
    );

    console.log(invoiceDone);
    clearAllHandler();

    // remove the items quantity from the catalog items quantity in local storage "products"
    const catalog = JSON.parse(localStorage.getItem("products"));
    const updatedCatalog = catalog.map((item) => {
      const invoiceItem = items.find(
        (invoiceItem) => item.id === invoiceItem.id
      );
      if (invoiceItem) {
        return {
          ...item,
          quantity: item.quantity - invoiceItem.quantity,
        };
      }
      return item;
    });
    localStorage.setItem("products", JSON.stringify(updatedCatalog));
  };

  const saveDraftHandler = () => {
    const editProforma = JSON.parse(localStorage.getItem("editProforma")) || {};

    if (editProforma?.positionOfEdit === "draftProformaInvoiceEdit") {
      const editProformaNumber = editProforma.proformaNumber;
      // Delete the draft proforma invoice from the savedProformaInvoicesDraft array in local storage
      const savedProformaInvoicesDraftArray =
        JSON.parse(localStorage.getItem("savedProformaInvoicesDraft")) || [];
      const savedProformaInvoicesDraftArrayFiltered =
        savedProformaInvoicesDraftArray.filter(
          (item) => item.performaInvoiceNumber !== editProformaNumber
        );
      localStorage.setItem(
        "savedProformaInvoicesDraft",
        JSON.stringify(savedProformaInvoicesDraftArrayFiltered)
      );
    } else {
      // Delete the proforma invoice from the savedProformaInvoices array in local storage
      const editProformaNumber = editProforma?.proformaNumber;
      // const savedProformaInvoicesArray =  if savedProformaInvoices exsists in local storeage
      const savedProformaInvoicesArray =
        JSON.parse(localStorage.getItem("savedProformaInvoices")) || [];

      const savedProformaInvoicesArrayFiltered =
        savedProformaInvoicesArray?.filter(
          (item) => item?.performaInvoiceNumber !== editProformaNumber
        );
      localStorage.setItem(
        "savedProformaInvoices",
        JSON.stringify(savedProformaInvoicesArrayFiltered)
      );
    }

    // Clear the local storage edit proforma to an empty object
    localStorage.setItem("editProforma", JSON.stringify({}));

    try {
      const invoiceDone = JSON.parse(
        localStorage.getItem("invoice") ||
          JSON.stringify({
            date: "",
            proformaNumber: 1,
            expirationDate: "",
            selectedResultCustomer: {
              companyName: "",
              address: "",
              city: "",
              country: "",
              zipCode: "",
              deliveryAddress: "",
              deliveryCity: "",
              deliveryCountry: "",
              email: "",
              phone: "",
              btwNumber: "",
              btwAmount: "",
              discount: "",
              tax: "",
              notes: "",
              savedAs: "",
              contactPerson: "",
              position: "",
              showTransportCode: "",
            },
            selectedCurrency: "",
            items: [
              {
                name: "",
                description: "",
                transportCode: "",
                quantity: "",
                price: "",
                discount: "",
                btw: "",
                total: "",
              },
            ],
            remarks: "",
            subTotal: "",
            discountedPriceTotal: "",
            btw: "",
            totalAmount: "",
          })
      );

      let companyDetails = JSON.parse(localStorage.getItem("companyDetails"));

      if (!companyDetails) {
        // Set default company details if not found in localStorage
        companyDetails = {
          companyName: "",
          companyAddress: "",
          companyCity: "",
          companyCountry: "",
          companyZipCode: "",
          companyEmail: "",
          companyPhone: "",
          companyBtwNumber: "",
        };

        localStorage.setItem("companyDetails", JSON.stringify(companyDetails));
      }

      const companyInfoText = {
        companyName: companyDetails.companyName,
        address: companyDetails.companyAddress,
        city: companyDetails.companyCity,
        country: companyDetails.companyCountry,
        zipCode: companyDetails.companyZipCode,
        email: companyDetails.companyEmail,
        phone: companyDetails.companyPhone,
        btwNumber: companyDetails.companyBtwNumber,
      };

      const date = invoiceDone.date || "";
      const performaInvoiceNumber = editProforma.proformaNumber || "";
      const expDate = invoiceDone.expirationDate || "";

      const selectedResultCustomer = invoiceDone.selectedResultCustomer;

      let customerInfoText = {
        companyName: selectedResultCustomer.companyName,
        address: selectedResultCustomer.address,
        city: selectedResultCustomer.city,
        country: selectedResultCustomer.country,
        zipCode: selectedResultCustomer.zipCode,
        deliveryAddress: selectedResultCustomer.deliveryAddress,
        deliveryCity: selectedResultCustomer.deliveryCity,
        deliveryCountry: selectedResultCustomer.deliveryCountry,
        email: selectedResultCustomer.email,
        phone: selectedResultCustomer.phone,
        btwNumber: selectedResultCustomer.btwNumber,
        btwAmount: selectedResultCustomer.btwAmount,
        discount: selectedResultCustomer.discount,
        tax: selectedResultCustomer.tax,
        notes: selectedResultCustomer.notes,
        savedAs: selectedResultCustomer.savedAs,
        contactPerson: selectedResultCustomer.contactPerson,
        position: selectedResultCustomer.position,
        showTransportCode: selectedResultCustomer.showTransportCode,
      };

      // Based on selectedCurrency, get the currency symbol
      const selectedCurrency = invoiceDone.selectedCurrency;
      let currencySymbol = "";
      if (selectedCurrency === "eur") {
        currencySymbol = "€";
      } else if (selectedCurrency === "usd") {
        currencySymbol = "$";
      } else if (selectedCurrency === "gbp") {
        currencySymbol = "£";
      } else if (selectedCurrency === "ils") {
        currencySymbol = "₪";
      }

      const items = invoiceDone.items;

      // Generate the invoice items rows
      const itemsRows = items
        .map(
          (item) => `
        <tr>
          <td>${item.name}</td>
          <td>${item.description}</td>
          ${
            customerInfoText.showTransportCode === true
              ? `<td>${item.transportCode}</td>`
              : ""
          }
          <td>${item.quantity}</td>
          <td>${item.price}</td>
          ${item.discount ? `<td>${item.discount}</td>` : ""}
          <td>${item.btw}</td>
          <td>${item.total} ${currencySymbol}</td>
        </tr>
      `
        )
        .join("");

      const remarks = invoiceDone.remarks;
      const subTotal = invoiceDone.subTotal || "";
      const discount = invoiceDone.discountedPriceTotal || "";
      const tax = invoiceDone.btw || "";
      const total = invoiceDone.totalAmount || "";

      const accountInfo = JSON.parse(localStorage.getItem("accountInfo")) || "";
      const accountInfoText = {
        bic: accountInfo.bic,
        iban: accountInfo.iban,
      };

      const paymentInfo = JSON.parse(localStorage.getItem("paymentInfo")) || "";
      const paymentInfoText = {
        beneficiary: paymentInfo.beneficiary,
        bankAddress: paymentInfo.bankAddress,
        bankCity: paymentInfo.city,
      };

      const goodsInfo = JSON.parse(localStorage.getItem("goodsInfo")) || "";
      const message = {
        message: goodsInfo.message,
      };

      // Save everything in local storage to savedProformaInvoices as an array of objects
      const savedProformaInvoicesDraft = {
        date,
        performaInvoiceNumber,
        expDate,
        companyInfoText,
        customerInfoText,
        selectedCurrency,
        items,
        remarks,
        subTotal,
        discount,
        tax,
        total,
        footer: {
          accountInfo,
          paymentInfo,
          message: goodsInfo,
        },
      };

      // Retrieve the existing drafts from localStorage or initialize an empty array
      const savedProformaInvoicesDraftArray =
        JSON.parse(localStorage.getItem("savedProformaInvoicesDraft")) || [];

      // Add the new draft to the array
      savedProformaInvoicesDraftArray.push(savedProformaInvoicesDraft);

      // Save the updated array back to localStorage
      localStorage.setItem(
        "savedProformaInvoicesDraft",
        JSON.stringify(savedProformaInvoicesDraftArray)
      );
      // Check if items is defined and not empty before using find
      if (items && items.length > 0) {
        // Remove the items' quantity from the catalog items' quantity in local storage "products"
        const catalog = JSON.parse(localStorage.getItem("products"));
        const updatedCatalog = catalog.map((item) => {
          const invoiceItem = items.find(
            (invoiceItem) => item.id === invoiceItem.id
          );
          if (invoiceItem) {
            return {
              ...item,
              quantity: item.quantity - invoiceItem.quantity,
            };
          }
          return item;
        });
        localStorage.setItem("products", JSON.stringify(updatedCatalog));
      }
    } catch (error) {
      console.error("Error while saving the draft:", error);
      // Handle the error gracefully, such as displaying an error message to the user.
    }

    if (!editProforma?.proformaNumber) {
      // Increment the Proforma Invoice number
      const proformaNumber =
        parseFloat(localStorage.getItem("proformaNumber")) + 1;
      localStorage.setItem(
        "proformaNumber",
        JSON.stringify(proformaNumber || 1)
      );
    }
  };

  const clearAllHandler = () => {
    // window.location.reload();
  };

  const handleDownload = () => {
    const proformaNumber =
      JSON.parse(localStorage.getItem("proformaNumber")) || 1;

    let jsPdf = new jsPDF("p", "pt", "letter");
    const htmlElement = document.querySelector(".invoice-for-downloading");

    // you need to load html2canvas (and dompurify if you pass a string to html)
    const opt = {
      margin: [50, 30, 50, 30],
      html2canvas: {
        allowTaint: true,
        dpi: 300,
        letterRendering: true,
        logging: false,
        scale: 0.6,
      },
    };

    // Function to add a header and footer to each page
    const addPageWithHeaderAndFooter = (htmlElement) => {
      jsPdf.html(htmlElement, {
        ...opt,
        autoPaging: true, // Enable auto-paging to handle multiple pages
        // dont cut off a word top and bottom

        x: 0,
        y: 0, // Adjust the Y position to leave space for the header
        callback: (pdf) => {
          const totalPageCount = pdf.getNumberOfPages(); // Get the total page count

          // Loop through each page and add headers and footers
          for (let pageNumber = 1; pageNumber <= totalPageCount; pageNumber++) {
            jsPdf.setPage(pageNumber); // Set the current page

            // Customize the header content
            const headerText =
              pageNumber === 1
                ? `Page ${pageNumber} of ${totalPageCount}`
                : `Page ${pageNumber} of ${totalPageCount} - continuation from Proforma invoice #${proformaNumber}`;

            jsPdf.text(headerText, 40, 20);

            // Calculate the Y position for the footer based on page size
            const pageHeight = pdf.internal.pageSize.getHeight();
            const footerY = pageHeight - 20; // Adjust as needed

            // if (pageNumber === 1) {
            //   // Add content specific to the first page
            jsPdf.text(
              `Date: ${new Date().toLocaleDateString()}`,
              400,
              footerY
            );
            // }

            // Add the footer text
            jsPdf.setFontSize(10);
            jsPdf.text(
              `This is a footer that will appear on every page.`,
              40,
              footerY
            );
          }

          // Save and open the generated PDF in the browser window (outside of the loop)
          pdf.save(`proforma-${proformaNumber}.pdf`);
          window.open(pdf.output("bloburl"));
        },
      });
    };

    addPageWithHeaderAndFooter(htmlElement);
  };

  const printTest = () => {
    const content = document.querySelector(
      ".invoice-for-downloading"
    ).innerHTML;

    if (content) {
      console.log("Element found.");

      var mywindow = window.open("", "Print", "height=600,width=800");

      mywindow.document.write(
        '<html><head><title>Print</title><link media="all" type="text/css" rel="stylesheet" href="./invoice.css"/>'
      );
      mywindow.document.write("</head><body >");
      mywindow.document.write(content);
      mywindow.document.write("</body></html>");

      mywindow.document.close();
      mywindow.focus();
      mywindow.print();
      mywindow.close();
      return true;
    } else {
      console.log("Element not found.");
    }
  };

  return (
    <div className="d-flex align-items-center justify-content-between">
      <main id="main" className="main">
        <div>
          <ProformaInvoiceHeader />
          <div className="inserted-invoice" ref={toDownloadRef} />
          <p className="text-align-center">Preview</p>
          {htmlContent}
        </div>
        <Invoice
          invoiceNumber={
            JSON.parse(localStorage.getItem("editProforma"))?.proformaNumber ||
            proformaNumber ||
            1
          }
        />
        <div className="invoice-footer">
          <Link to="/ProformaDraftsView">
            <button
              onClick={() => {
                if (invoiceDone.items?.length === 0) {
                  alert("Please Add Items First");
                }
                saveDraftHandler();
              }}
              disabled={invoiceDone.items?.length === 0}
              className="custom-btn btn-13"
            >
              Save Draft
            </button>
          </Link>
          <Link to="/ProformaInvoices">
            <button
              disabled={
                invoiceDone.items?.length === 0 ||
                invoiceDone.selectedResultCustomer?.length === 0
              }
              onClick={() => {
                if (
                  invoiceDone.items?.length === 0 ||
                  invoiceDone.selectedResultCustomer?.length === 0
                ) {
                  alert("Please Add A Costumer And Items First");
                }
                // printTest();
                // printPage();
                saveHandler();
              }}
              className="btn btn-print"
            >
              Save Print
            </button>
          </Link>
          <Link to="/ProformaInvoices">
            <button
              style={{
                backgroundColor: "#f0ad4e",
                borderColor: "#f0ad4e",
                color: "white",
              }}
              disabled={
                invoiceDone.items?.length === 0 ||
                invoiceDone.selectedResultCustomer?.length === 0
              }
              onClick={() => {
                if (
                  invoiceDone.items?.length === 0 ||
                  invoiceDone.selectedResultCustomer?.length === 0
                ) {
                  alert("Please Add A Costumer And Items First");
                }
                handleDownload();
                saveHandler();
              }}
              className="btn btn-send"
            >
              Save Print Send
            </button>
          </Link>
        </div>
      </main>
    </div>
  );
}

export default ProformaInvoice;
