import React from "react";
import { Link, NavLink } from "react-router-dom";
import CheckForToken from "../../checkForToken";

const InvoiceNumberSetting = () => {
  CheckForToken();
  const saveHandler = () => {
    const confirm = window.confirm("Are you sure you want to save?");
    if (!confirm) {
      return;
    }
    // save the invoice number to the database
    const invoiceNumber = document.getElementById("invoiceNumber").value;

    // save to local storage with key 'invoiceNumber'
    localStorage.setItem("invoiceNumber", invoiceNumber);
  };

  const cancelHandler = () => {
    // do nothing
  };

  return (
    <div className="d-flex align-items-center justify-content-center">
      <main id="main" className="main">
        <h1>Invoice Number Setting</h1>
        {/* add a one line form to enter a Invoice Number and a button to save or cancel */}
        <label htmlFor="invoiceNumber" className="mt-4">
          Invoice Number
        </label>
        <input
          type="number"
          className="form-control mt-2 mb-2"
          id="invoiceNumber"
          placeholder="Enter Invoice Number"
        />

        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: "4rem",
          }}
        >
          <button className="btn btn-primary" onClick={saveHandler}>
            Save
          </button>
          <button className="btn btn-primary" onClick={cancelHandler}>
            Cancel
          </button>
        </div>
      </main>
    </div>
  );
};

export default InvoiceNumberSetting;
