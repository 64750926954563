import React, { useState } from "react";
import { Link } from "react-router-dom";
import CheckForToken from "../checkForToken";

import "../components/buttons.css";

const CompanyDetails = () => {
  CheckForToken();
  const whenCompanyDetailsISNotDefined = {
    companyName: "perfectInvoicing",
    companyAddress: "",
    companyCity: "",
    companyZipCode: "",
    companyCountry: "",
    companyEmail: "",
    companyPhone: "",
    companyBtwNumber: "",
  };
  const [companyDetails, setCompanyDetails] = useState(
    whenCompanyDetailsISNotDefined
  );

  // When handling input changes
  const handleInputChange = (field, value) => {
    setCompanyDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
    localStorage.setItem(field, value); // Store only the specific field
  };

  const checkCustomerDetails = async (companyBtwNumber) => {
    let vat_number = companyBtwNumber;

    // Remove any whitespace from the VAT number and also remove any dashes or dots
    vat_number = vat_number.replace(/\s/g, "").replace(/[-.]/g, "");

    try {
      // Make a request to the API to validate the VAT number
      const response = await fetch(
        `https://controleerbtwnummer.eu/api/validate/${vat_number}.json`
      );

      if (response.ok) {
        // If the API call was successful, get the JSON response
        const data = await response.json();

        const { valid, countryCode, vatNumber, name, address, country } = data;

        if (valid) {
          // input the details into the form
          setCompanyDetails({
            companyName: name,
            companyAddress: address.street,
            companyCity: address.city,
            companyZipCode: address.zip_code,
            companyCountry: address.country,
            companyEmail: "",
            companyPhone: "",
            companyBtwNumber: countryCode + vatNumber,
          });

          console.log(
            `This BTW number Is ${valid}, And is in Country ${countryCode}, BTW number ${vatNumber}, Owner Name Is: ${name}, His Address Is: ${address.street}, ${address.number}, ${address.zip_code},  ${address.city},  ${address.country}`
          );
        } else {
          // VAT number is not valid
          alert(`This BTW number is NOT valid`);
        }
        console.log(data);
      } else {
        throw new Error("Something went wrong");
      }
    } catch (error) {
      console.log("API Request Error:", error);
      console.log(
        "API Request URL:",
        `https://controleerbtwnummer.eu/api/validate/${vat_number}.json`
      );
      alert("An error occurred while fetching data. Please try again later.");
    }
  };

  return (
    <div>
      <h1>Company Details</h1>
      <div
        className="d-flex align-items-center justify-content-between"
        style={{
          width: "40%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
          marginLeft: "30%",
          marginRight: "auto",
        }}
      >
        <input
          type="text"
          name="BTW-number"
          id="BTW"
          className="form-control"
          style={{ marginRight: "10px", flex: 1 }}
          placeholder="Insert VAT Number"
          value={companyDetails.companyBtwNumber || ""}
          onChange={(e) => {
            setCompanyDetails({
              ...companyDetails,
              companyBtwNumber: e.target.value,
            });
          }}
          // set here the focus on the input
          autoFocus
        />
        <button
          type="button"
          className="btn btn-info"
          onClick={() => {
            checkCustomerDetails(companyDetails.companyBtwNumber);
          }}
          style={{ minWidth: "100px" }}
        >
          Get Details
        </button>
      </div>

      <div
        className="align-items-center justify-content-between"
        style={{
          width: "40%",
          margin: "0 auto",
        }}
      >
        <label htmlFor="companyName">Company Name</label>
        <input
          type="text"
          className="form-control"
          id="companyName"
          placeholder="Enter Company Name"
          value={companyDetails.companyName}
          onChange={(e) => {
            setCompanyDetails({
              ...companyDetails,
              companyName: e.target.value,
            });
          }}
        />
        <label htmlFor="companyAddress">Company Address</label>
        <input
          type="text"
          className="form-control"
          id="companyAddress"
          placeholder="Enter Company Address"
          value={companyDetails.companyAddress}
          onChange={(e) => {
            setCompanyDetails({
              ...companyDetails,
              companyAddress: e.target.value,
            });
          }}
        />
        <label htmlFor="companyCity">Company City</label>
        <input
          type="text"
          className="form-control"
          id="companyCity"
          placeholder="Enter Company City"
          value={companyDetails.companyCity}
          onChange={(e) => {
            setCompanyDetails({
              ...companyDetails,
              companyCity: e.target.value,
            });
          }}
        />
        <label htmlFor="companyZipCode">Company Zip Code</label>
        <input
          type="text"
          className="form-control"
          id="companyZipCode"
          placeholder="Enter Company Zip Code"
          value={companyDetails.companyZipCode}
          onChange={(e) => {
            setCompanyDetails({
              ...companyDetails,
              companyZipCode: e.target.value,
            });
          }}
        />
        <label htmlFor="companyCountry">Company Country</label>
        <input
          type="text"
          className="form-control"
          id="companyCountry"
          placeholder="Enter Company Country"
          value={companyDetails.companyCountry}
          onChange={(e) => {
            setCompanyDetails({
              ...companyDetails,
              companyCountry: e.target.value,
            });
          }}
        />
        <label htmlFor="companyEmail">Company Email</label>
        <input
          type="text"
          className="form-control"
          id="companyEmail"
          placeholder="Enter Company Email"
          value={companyDetails.companyEmail}
          onChange={(e) => {
            setCompanyDetails({
              ...companyDetails,
              companyEmail: e.target.value,
            });
          }}
        />
        <label htmlFor="companyPhone">Company Phone</label>
        <input
          type="text"
          className="form-control"
          id="companyPhone"
          placeholder="Enter Company Phone"
          value={companyDetails.companyPhone}
          onChange={(e) => {
            setCompanyDetails({
              ...companyDetails,
              companyPhone: e.target.value,
            });
          }}
        />
        <label htmlFor="companyBtwNumber">Company BTW Number</label>
        <input
          type="text"
          className="form-control"
          id="companyBtwNumber"
          placeholder="Enter Company BTW Number"
          value={companyDetails.companyBtwNumber}
          onChange={(e) => {
            setCompanyDetails({
              ...companyDetails,
              companyBtwNumber: e.target.value,
            });
          }}
        />
      </div>
      <div
        className="form-group"
        style={{
          width: "40%",
          margin: "0 auto",
          marginTop: "20px",
        }}
      >
        <button
          disabled={!companyDetails.companyName}
          onClick={() => {
            localStorage.setItem(
              "companyDetails",
              JSON.stringify({
                companyName: companyDetails.companyName,
                companyAddress: companyDetails.companyAddress || "",
                companyCity: companyDetails.companyCity || "",
                companyZipCode: companyDetails.companyZipCode || "",
                companyCountry: companyDetails.companyCountry || "",
                companyEmail: companyDetails.companyEmail || "",
                companyPhone: companyDetails.companyPhone || "",
                companyBtwNumber: companyDetails.companyBtwNumber || "",
              })
            );
            console.log(companyDetails);
            console.log(JSON.parse(localStorage.getItem("companyDetails")));
          }}
          type="submit"
          className="btn btn-primary save-button"
        >
          Save
        </button>
        <button
          type="button" /* Changed "type" to "button" for the Cancel button */
          className="btn btn-danger cancel-button"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default CompanyDetails;
