import React, { useEffect, useState } from "react";

import styles from "./ProformaInvoice.module.css";
import { Link } from "react-router-dom";

import Preview from "./preview"; // Import the Preview component

const ProformaInvoices = () => {
  if (!localStorage.getItem("token")) {
    window.location.href = "/login";
  }
  const [openModalIndex, setOpenModalIndex] = useState(-1);
  const invoices = localStorage.getItem("savedProformaInvoices");
  const parsedInvoices = JSON.parse(invoices);
  const [proformaInvoices, setProformaInvoices] = useState(
    parsedInvoices || []
  );
  const [search, setSearch] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [selectPerforma, setSelectPerforma] = useState([]);
  const [viewMoreAction, setViewMoreAction] = useState(false);

  const changeStatus = (index, newStatus) => {
    // Create a copy of the proforma invoices array
    const updatedProformaInvoices = [...proformaInvoices];

    // Update the status of the selected proforma invoice
    updatedProformaInvoices[index].status = newStatus;

    // Update the state or any other storage mechanism you are using
    setProformaInvoices(updatedProformaInvoices); // Assuming you are using state

    // You can also send the updated status to the server if necessary.

    // Save the updated proforma invoices array to local storage
    localStorage.setItem(
      "savedProformaInvoices",
      JSON.stringify(updatedProformaInvoices)
    );
  };

  useEffect(() => {
    const testingData = [
      {
        date: "2023-09-06",
        performaInvoiceNumber: 53261274,
        expDate: "2023-10-06",
        companyInfoText: {
          companyName: "CVBA ANTWERP OUD PAPIER CENTRALE C.V.B.A.",
          address: "Smallandlaan",
          city: "Antwerpen",
          country: "België",
          zipCode: "2660",
          email: "be0436339157@.com",
          phone: "+324",
          btwNumber: "BE0436339157",
        },
        customerInfoText: {
          companyName: "SA DACHSER Belgium",
          address: "Avenue de l'eau vive(l)",
          city: "Mouscron",
          country: "België",
          zipCode: "7700",
          deliveryAddress: "",
          deliveryCity: "",
          deliveryCountry: "",
          email: "shlomakleinman@gmail.com",
          phone: "+32486789633",
          btwNumber: "0415394184",
          btwAmount: "20",
          discount: "20",
          tax: "20",
          notes: 'b"h we are on good track the eibershter should help futher',
          savedAs: "kleinman",
          contactPerson: "shloma",
          position: "kleinman",
          showTransportCode: "",
        },
        selectedCurrency: "eur",
        items: [
          {
            id: "20",
            name: "20",
            description: "20",
            transportCode: "20",
            price: 20,
            discount: "20",
            quantity: 1,
            btw: "20",
            total: "20.00",
            totalDiscounted: 4,
          },
          {
            id: "3",
            name: "3",
            description: "3",
            transportCode: "3",
            price: 3,
            discount: "20",
            quantity: 1,
            btw: "20",
            total: "3.00",
            totalDiscounted: 0.6,
          },
        ],
        remarks: "",
        subTotal: 23,
        discount: 4.6,
        tax: "4.600",
        total: "23.00",
        footer: {
          accountInfo: { bic: "", iban: "" },
          paymentInfo: { beneficiary: "", bankAddress: "", city: "" },
          message: { message: "add some Message" },
        },
      },
      {
        date: "2023-09-06",
        performaInvoiceNumber: 53261276,
        expDate: "2023-10-06",
        companyInfoText: {
          companyName: "CVBA ANTWERP OUD PAPIER CENTRALE C.V.B.A.",
          address: "Smallandlaan",
          city: "Antwerpen",
          country: "België",
          zipCode: "2660",
          email: "be0436339157@.com",
          phone: "+324",
          btwNumber: "BE0436339157",
        },
        customerInfoText: {
          companyName: "BV J.M.W. PRINTING",
          address: "Mechelsesteenweg",
          city: "Kontich",
          country: "België",
          zipCode: "2550",
          deliveryAddress: "",
          deliveryCity: "",
          deliveryCountry: "",
          email: "info@jmwprinting.be",
          phone: "+32 3 458 00 05",
          btwNumber: "0455376396",
          btwAmount: "21",
          discount: "10",
          tax: "21",
          notes: "good",
          savedAs: "printing",
          contactPerson: "guy",
          position: "maklar",
          showTransportCode: "",
        },
        selectedCurrency: "eur",
        items: [
          {
            id: "10",
            name: "101",
            description: "0",
            transportCode: "10",
            price: 10,
            discount: "10",
            quantity: 1,
            btw: "21",
            total: 11.1,
            totalDiscounted: 1,
          },
          {
            id: "3",
            name: "3",
            description: "3",
            transportCode: "3",
            price: 3,
            discount: "10",
            quantity: 1,
            btw: "21",
            total: "3.33",
            totalDiscounted: 0.3,
          },
        ],
        remarks: "",
        subTotal: 13,
        discount: 1.3,
        tax: 2.73,
        total: "14.43",
        footer: {
          accountInfo: { bic: "", iban: "" },
          paymentInfo: { beneficiary: "", bankAddress: "", city: "" },
          message: { message: "add some Message" },
        },
      },
      {
        date: "2023-09-11",
        performaInvoiceNumber: 53261305,
        expDate: "2023-10-11",
        companyInfoText: {
          companyName: "CVBA ANTWERP OUD PAPIER CENTRALE C.V.B.A.",
          address: "Smallandlaan",
          city: "Antwerpen",
          country: "België",
          zipCode: "2660",
          email: "be0436339157@.com",
          phone: "+324",
          btwNumber: "BE0436339157",
        },
        customerInfoText: {
          companyName: "SA DACHSER Belgium",
          address: "Avenue de l'eau vive(l)",
          city: "Mouscron",
          country: "België",
          zipCode: "7700",
          deliveryAddress: "",
          deliveryCity: "",
          deliveryCountry: "",
          email: "sk@example.com",
          phone: "+32",
          btwNumber: "BE0415394184",
          btwAmount: 10,
          discount: "10",
          tax: 10,
          notes: "sk",
          savedAs: "sk",
          contactPerson: "sk",
          position: "sk",
        },
        selectedCurrency: "eur",
        items: [
          {
            id: "2",
            name: "2",
            description: "2",
            transportCode: "2",
            price: 2,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "2.00",
            totalDiscounted: 0.2,
          },
          {
            id: "60",
            name: "60",
            description: "60",
            price: 60,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "60.00",
            totalDiscounted: 6,
          },
          {
            id: "4",
            name: "4",
            description: "4",
            price: 4,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "4.00",
            totalDiscounted: 0.4,
          },
        ],
        remarks: "",
        subTotal: 66,
        discount: 6.6000000000000005,
        tax: "6.600",
        total: "66.00",
        footer: {
          accountInfo: { bic: "", iban: "" },
          paymentInfo: { beneficiary: "", bankAddress: "", city: "" },
          message: { message: "add some Message" },
        },
      },
      {
        date: "2023-09-11",
        performaInvoiceNumber: 53261306,
        expDate: "2023-10-11",
        companyInfoText: {
          companyName: "CVBA ANTWERP OUD PAPIER CENTRALE C.V.B.A.",
          address: "Smallandlaan",
          city: "Antwerpen",
          country: "België",
          zipCode: "2660",
          email: "be0436339157@.com",
          phone: "+324",
          btwNumber: "BE0436339157",
        },
        customerInfoText: {
          companyName: "David, Mayer",
          address: "Antoon van dyckstraat 21/c",
          city: "Antwerpen",
          country: "België",
          zipCode: "2018",
          deliveryAddress: "",
          deliveryCity: "",
          deliveryCountry: "",
          email: "",
          btwNumber: "BE0644685457",
          btwAmount: null,
          discount: 0,
          tax: 0,
          notes: "",
          savedAs: "",
          contactPerson: "",
          position: "",
          showTransportCode: true,
        },
        selectedCurrency: "eur",
        items: [
          {
            id: "60",
            name: "60",
            description: "60",
            price: 60,
            discount: 0,
            quantity: 10,
            btw: 0,
            total: 600,
            totalDiscounted: 0,
          },
        ],
        remarks: "",
        subTotal: 600,
        discount: "",
        tax: "0.000",
        total: "600.00",
        footer: {
          accountInfo: { bic: "", iban: "" },
          paymentInfo: { beneficiary: "", bankAddress: "", city: "" },
          message: { message: "add some Message" },
        },
      },
      {
        date: "2023-09-14",
        performaInvoiceNumber: 53261307,
        expDate: "2023-10-14",
        companyInfoText: {
          companyName: "CVBA ANTWERP OUD PAPIER CENTRALE C.V.B.A.",
          address: "Smallandlaan",
          city: "Antwerpen",
          country: "België",
          zipCode: "2660",
          email: "be0436339157@.com",
          phone: "+324",
          btwNumber: "BE0436339157",
        },
        customerInfoText: {
          companyName: "BV J.M.W. PRINTING",
          address: "Mechelsesteenweg",
          city: "Kontich",
          country: "België",
          zipCode: "2550",
          deliveryAddress: "",
          deliveryCity: "",
          deliveryCountry: "",
          email: "info@jmwprinting.be",
          phone: "+32 3 458 00 05",
          btwNumber: "0455376396",
          btwAmount: "21",
          discount: "10",
          tax: "21",
          notes: "good",
          savedAs: "printing",
          contactPerson: "guy",
          position: "maklar",
        },
        selectedCurrency: "eur",
        items: [
          {
            id: "1",
            name: "1",
            description: "1",
            price: 1,
            discount: "10",
            quantity: 1,
            btw: "21",
            total: "1.11",
            totalDiscounted: 0.1,
          },
          {
            id: "10",
            name: "10",
            description: "10",
            transportCode: "10",
            price: 10,
            discount: 10,
            quantity: 1,
            btw: "21",
            total: "11.10",
            totalDiscounted: 1,
          },
          {
            id: "2",
            name: "2",
            description: "2",
            price: 2,
            discount: "10",
            quantity: 1,
            btw: "21",
            total: "2.22",
            totalDiscounted: 0.2,
          },
          {
            id: "33",
            name: "33",
            description: "33",
            price: 50,
            discount: "10",
            quantity: 1,
            btw: "21",
            total: "55.50",
            totalDiscounted: 5,
          },
          {
            id: "45",
            name: "45",
            description: "45",
            transportCode: "hk:456562626",
            price: 52,
            discount: "10",
            quantity: 1,
            btw: "21",
            total: "57.72",
            totalDiscounted: 5.2,
          },
          {
            id: "4",
            name: "4",
            description: "4",
            transportCode: "4",
            price: 4,
            discount: 10,
            quantity: 4,
            btw: 21,
            total: "17.76",
            totalDiscounted: "1.60",
          },
          {
            id: "5",
            name: "5",
            description: "5",
            transportCode: "5",
            price: 5,
            discount: 10,
            quantity: 5,
            btw: 21,
            total: "27.75",
            totalDiscounted: "2.50",
          },
          {
            id: "6",
            name: "6",
            description: "6",
            transportCode: "6",
            price: 6,
            discount: 10,
            quantity: 6,
            btw: 21,
            total: "39.96",
            totalDiscounted: "3.60",
          },
          {
            id: "7",
            name: "7",
            description: "7",
            transportCode: "7",
            price: 7,
            discount: 10,
            quantity: 7,
            btw: 21,
            total: "54.39",
            totalDiscounted: "4.90",
          },
          {
            id: "8",
            name: "8",
            description: "8",
            transportCode: "8",
            price: 8,
            discount: 10,
            quantity: 8,
            btw: 21,
            total: "71.04",
            totalDiscounted: "6.40",
          },
        ],
        remarks: "",
        subTotal: "305.00",
        discount: "30.50",
        tax: "64.050",
        total: 338.55,
        footer: {
          accountInfo: { bic: "", iban: "" },
          paymentInfo: "",
          message: { message: "add some Message" },
        },
      },
      {
        date: "2023-09-15",
        performaInvoiceNumber: 53261308,
        expDate: "2023-10-15",
        companyInfoText: {
          companyName: "CVBA ANTWERP OUD PAPIER CENTRALE C.V.B.A.",
          address: "Smallandlaan",
          city: "Antwerpen",
          country: "België",
          zipCode: "2660",
          email: "be0436339157@.com",
          phone: "+324",
          btwNumber: "BE0436339157",
        },
        customerInfoText: {
          companyName: "SA DACHSER Belgium",
          address: "Avenue de l'eau vive(l)",
          city: "Mouscron",
          country: "België",
          zipCode: "7700",
          deliveryAddress: "",
          deliveryCity: "",
          deliveryCountry: "",
          email: "sk@example.com",
          phone: "+32",
          btwNumber: "BE0415394184",
          btwAmount: 10,
          discount: "10",
          tax: 10,
          notes: "sk",
          savedAs: "sk",
          contactPerson: "sk",
          position: "sk",
        },
        selectedCurrency: "eur",
        items: [
          {
            id: "1",
            name: "1",
            description: "1",
            price: 1,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "1.00",
            totalDiscounted: 0.1,
          },
          {
            id: "10",
            name: "10",
            description: "10",
            transportCode: "10",
            price: 10,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "10.00",
            totalDiscounted: 1,
          },
          {
            id: "11",
            name: "11",
            description: "11",
            transportCode: "11",
            price: 11,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "11.00",
            totalDiscounted: 1.1,
          },
          {
            id: "2",
            name: "2",
            description: "2",
            price: 2,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "2.00",
            totalDiscounted: 0.2,
          },
          {
            id: "33",
            name: "33",
            description: "33",
            price: 50,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "50.00",
            totalDiscounted: 5,
          },
          {
            id: "4",
            name: "4",
            description: "4",
            transportCode: "4",
            price: 4,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "4.00",
            totalDiscounted: 0.4,
          },
          {
            id: "45",
            name: "45",
            description: "45",
            transportCode: "hk:456562626",
            price: 52,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "52.00",
            totalDiscounted: 5.2,
          },
          {
            id: "6",
            name: "6",
            description: "6",
            transportCode: "6",
            price: 6,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "6.00",
            totalDiscounted: 0.6,
          },
          {
            id: "5",
            name: "5",
            description: "5",
            transportCode: "5",
            price: 5,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "5.00",
            totalDiscounted: 0.5,
          },
        ],
        remarks:
          "\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen",
        subTotal: 141,
        discount: 14.1,
        tax: "14.100",
        total: "141.00",
        footer: {
          accountInfo: { bic: "", iban: "" },
          paymentInfo: "",
          message: { message: "add some Message" },
        },
      },
      {
        date: "2023-09-15",
        performaInvoiceNumber: 53261309,
        expDate: "2023-10-15",
        companyInfoText: {
          companyName: "CVBA ANTWERP OUD PAPIER CENTRALE C.V.B.A.",
          address: "Smallandlaan",
          city: "Antwerpen",
          country: "België",
          zipCode: "2660",
          email: "be0436339157@.com",
          phone: "+324",
          btwNumber: "BE0436339157",
        },
        customerInfoText: {
          companyName: "SA DACHSER Belgium",
          address: "Avenue de l'eau vive(l)",
          city: "Mouscron",
          country: "België",
          zipCode: "7700",
          deliveryAddress: "",
          deliveryCity: "",
          deliveryCountry: "",
          email: "sk@example.com",
          phone: "+32",
          btwNumber: "BE0415394184",
          btwAmount: 10,
          discount: "10",
          tax: 10,
          notes: "sk",
          savedAs: "sk",
          contactPerson: "sk",
          position: "sk",
        },
        selectedCurrency: "eur",
        items: [
          {
            id: "1",
            name: "1",
            description: "1",
            price: 1,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "1.00",
            totalDiscounted: 0.1,
          },
          {
            id: "10",
            name: "10",
            description: "10",
            transportCode: "10",
            price: 10,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "10.00",
            totalDiscounted: 1,
          },
          {
            id: "11",
            name: "11",
            description: "11",
            transportCode: "11",
            price: 11,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "11.00",
            totalDiscounted: 1.1,
          },
          {
            id: "2",
            name: "2",
            description: "2",
            price: 2,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "2.00",
            totalDiscounted: 0.2,
          },
          {
            id: "33",
            name: "33",
            description: "33",
            price: 50,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "50.00",
            totalDiscounted: 5,
          },
          {
            id: "4",
            name: "4",
            description: "4",
            transportCode: "4",
            price: 4,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "4.00",
            totalDiscounted: 0.4,
          },
          {
            id: "45",
            name: "45",
            description: "45",
            transportCode: "hk:456562626",
            price: 52,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "52.00",
            totalDiscounted: 5.2,
          },
          {
            id: "6",
            name: "6",
            description: "6",
            transportCode: "6",
            price: 6,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "6.00",
            totalDiscounted: 0.6,
          },
          {
            id: "5",
            name: "5",
            description: "5",
            transportCode: "5",
            price: 5,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "5.00",
            totalDiscounted: 0.5,
          },
        ],
        remarks:
          "\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen",
        subTotal: 141,
        discount: 14.1,
        tax: "14.100",
        total: "141.00",
        footer: {
          accountInfo: { bic: "", iban: "" },
          paymentInfo: "",
          message: { message: "add some Message" },
        },
      },
      {
        date: "2023-09-15",
        performaInvoiceNumber: 53261310,
        expDate: "2023-10-15",
        companyInfoText: {
          companyName: "CVBA ANTWERP OUD PAPIER CENTRALE C.V.B.A.",
          address: "Smallandlaan",
          city: "Antwerpen",
          country: "België",
          zipCode: "2660",
          email: "be0436339157@.com",
          phone: "+324",
          btwNumber: "BE0436339157",
        },
        customerInfoText: {
          companyName: "SA DACHSER Belgium",
          address: "Avenue de l'eau vive(l)",
          city: "Mouscron",
          country: "België",
          zipCode: "7700",
          deliveryAddress: "",
          deliveryCity: "",
          deliveryCountry: "",
          email: "sk@example.com",
          phone: "+32",
          btwNumber: "BE0415394184",
          btwAmount: 10,
          discount: "10",
          tax: 10,
          notes: "sk",
          savedAs: "sk",
          contactPerson: "sk",
          position: "sk",
        },
        selectedCurrency: "eur",
        items: [
          {
            id: "1",
            name: "1",
            description: "1",
            price: 1,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "1.00",
            totalDiscounted: 0.1,
          },
          {
            id: "10",
            name: "10",
            description: "10",
            transportCode: "10",
            price: 10,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "10.00",
            totalDiscounted: 1,
          },
          {
            id: "11",
            name: "11",
            description: "11",
            transportCode: "11",
            price: 11,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "11.00",
            totalDiscounted: 1.1,
          },
          {
            id: "2",
            name: "2",
            description: "2",
            price: 2,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "2.00",
            totalDiscounted: 0.2,
          },
          {
            id: "33",
            name: "33",
            description: "33",
            price: 50,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "50.00",
            totalDiscounted: 5,
          },
          {
            id: "4",
            name: "4",
            description: "4",
            transportCode: "4",
            price: 4,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "4.00",
            totalDiscounted: 0.4,
          },
          {
            id: "45",
            name: "45",
            description: "45",
            transportCode: "hk:456562626",
            price: 52,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "52.00",
            totalDiscounted: 5.2,
          },
          {
            id: "6",
            name: "6",
            description: "6",
            transportCode: "6",
            price: 6,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "6.00",
            totalDiscounted: 0.6,
          },
          {
            id: "5",
            name: "5",
            description: "5",
            transportCode: "5",
            price: 5,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "5.00",
            totalDiscounted: 0.5,
          },
        ],
        remarks:
          "\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen",
        subTotal: 141,
        discount: 14.1,
        tax: "14.100",
        total: "141.00",
        footer: {
          accountInfo: { bic: "", iban: "" },
          paymentInfo: "",
          message: { message: "add some Message" },
        },
      },
      {
        date: "2023-09-15",
        performaInvoiceNumber: 53261310,
        expDate: "2023-10-15",
        companyInfoText: {
          companyName: "CVBA ANTWERP OUD PAPIER CENTRALE C.V.B.A.",
          address: "Smallandlaan",
          city: "Antwerpen",
          country: "België",
          zipCode: "2660",
          email: "be0436339157@.com",
          phone: "+324",
          btwNumber: "BE0436339157",
        },
        customerInfoText: {
          companyName: "SA DACHSER Belgium",
          address: "Avenue de l'eau vive(l)",
          city: "Mouscron",
          country: "België",
          zipCode: "7700",
          deliveryAddress: "",
          deliveryCity: "",
          deliveryCountry: "",
          email: "sk@example.com",
          phone: "+32",
          btwNumber: "BE0415394184",
          btwAmount: 10,
          discount: "10",
          tax: 10,
          notes: "sk",
          savedAs: "sk",
          contactPerson: "sk",
          position: "sk",
        },
        selectedCurrency: "eur",
        items: [
          {
            id: "1",
            name: "1",
            description: "1",
            price: 1,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "1.00",
            totalDiscounted: 0.1,
          },
          {
            id: "10",
            name: "10",
            description: "10",
            transportCode: "10",
            price: 10,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "10.00",
            totalDiscounted: 1,
          },
          {
            id: "11",
            name: "11",
            description: "11",
            transportCode: "11",
            price: 11,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "11.00",
            totalDiscounted: 1.1,
          },
          {
            id: "2",
            name: "2",
            description: "2",
            price: 2,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "2.00",
            totalDiscounted: 0.2,
          },
          {
            id: "33",
            name: "33",
            description: "33",
            price: 50,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "50.00",
            totalDiscounted: 5,
          },
          {
            id: "4",
            name: "4",
            description: "4",
            transportCode: "4",
            price: 4,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "4.00",
            totalDiscounted: 0.4,
          },
          {
            id: "45",
            name: "45",
            description: "45",
            transportCode: "hk:456562626",
            price: 52,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "52.00",
            totalDiscounted: 5.2,
          },
          {
            id: "6",
            name: "6",
            description: "6",
            transportCode: "6",
            price: 6,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "6.00",
            totalDiscounted: 0.6,
          },
          {
            id: "5",
            name: "5",
            description: "5",
            transportCode: "5",
            price: 5,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "5.00",
            totalDiscounted: 0.5,
          },
        ],
        remarks:
          "\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen",
        subTotal: 141,
        discount: 14.1,
        tax: "14.100",
        total: "141.00",
        footer: {
          accountInfo: { bic: "", iban: "" },
          paymentInfo: "",
          message: { message: "add some Message" },
        },
      },
      {
        date: "2023-09-15",
        performaInvoiceNumber: 53261311,
        expDate: "2023-10-15",
        companyInfoText: {
          companyName: "CVBA ANTWERP OUD PAPIER CENTRALE C.V.B.A.",
          address: "Smallandlaan",
          city: "Antwerpen",
          country: "België",
          zipCode: "2660",
          email: "be0436339157@.com",
          phone: "+324",
          btwNumber: "BE0436339157",
        },
        customerInfoText: {
          companyName: "SA DACHSER Belgium",
          address: "Avenue de l'eau vive(l)",
          city: "Mouscron",
          country: "België",
          zipCode: "7700",
          deliveryAddress: "",
          deliveryCity: "",
          deliveryCountry: "",
          email: "sk@example.com",
          phone: "+32",
          btwNumber: "BE0415394184",
          btwAmount: 10,
          discount: "10",
          tax: 10,
          notes: "sk",
          savedAs: "sk",
          contactPerson: "sk",
          position: "sk",
        },
        selectedCurrency: "eur",
        items: [
          {
            id: "1",
            name: "1",
            description: "1",
            price: 1,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "1.00",
            totalDiscounted: 0.1,
          },
          {
            id: "10",
            name: "10",
            description: "10",
            transportCode: "10",
            price: 10,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "10.00",
            totalDiscounted: 1,
          },
          {
            id: "11",
            name: "11",
            description: "11",
            transportCode: "11",
            price: 11,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "11.00",
            totalDiscounted: 1.1,
          },
          {
            id: "2",
            name: "2",
            description: "2",
            price: 2,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "2.00",
            totalDiscounted: 0.2,
          },
          {
            id: "33",
            name: "33",
            description: "33",
            price: 50,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "50.00",
            totalDiscounted: 5,
          },
          {
            id: "4",
            name: "4",
            description: "4",
            transportCode: "4",
            price: 4,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "4.00",
            totalDiscounted: 0.4,
          },
          {
            id: "45",
            name: "45",
            description: "45",
            transportCode: "hk:456562626",
            price: 52,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "52.00",
            totalDiscounted: 5.2,
          },
          {
            id: "6",
            name: "6",
            description: "6",
            transportCode: "6",
            price: 6,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "6.00",
            totalDiscounted: 0.6,
          },
          {
            id: "5",
            name: "5",
            description: "5",
            transportCode: "5",
            price: 5,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "5.00",
            totalDiscounted: 0.5,
          },
        ],
        remarks:
          "\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen",
        subTotal: 141,
        discount: 14.1,
        tax: "14.100",
        total: "141.00",
        footer: {
          accountInfo: { bic: "", iban: "" },
          paymentInfo: "",
          message: { message: "add some Message" },
        },
      },
      {
        date: "2023-09-15",
        performaInvoiceNumber: 53261311,
        expDate: "2023-10-15",
        companyInfoText: {
          companyName: "CVBA ANTWERP OUD PAPIER CENTRALE C.V.B.A.",
          address: "Smallandlaan",
          city: "Antwerpen",
          country: "België",
          zipCode: "2660",
          email: "be0436339157@.com",
          phone: "+324",
          btwNumber: "BE0436339157",
        },
        customerInfoText: {
          companyName: "SA DACHSER Belgium",
          address: "Avenue de l'eau vive(l)",
          city: "Mouscron",
          country: "België",
          zipCode: "7700",
          deliveryAddress: "",
          deliveryCity: "",
          deliveryCountry: "",
          email: "sk@example.com",
          phone: "+32",
          btwNumber: "BE0415394184",
          btwAmount: 10,
          discount: "10",
          tax: 10,
          notes: "sk",
          savedAs: "sk",
          contactPerson: "sk",
          position: "sk",
        },
        selectedCurrency: "eur",
        items: [
          {
            id: "1",
            name: "1",
            description: "1",
            price: 1,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "1.00",
            totalDiscounted: 0.1,
          },
          {
            id: "10",
            name: "10",
            description: "10",
            transportCode: "10",
            price: 10,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "10.00",
            totalDiscounted: 1,
          },
          {
            id: "11",
            name: "11",
            description: "11",
            transportCode: "11",
            price: 11,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "11.00",
            totalDiscounted: 1.1,
          },
          {
            id: "2",
            name: "2",
            description: "2",
            price: 2,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "2.00",
            totalDiscounted: 0.2,
          },
          {
            id: "33",
            name: "33",
            description: "33",
            price: 50,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "50.00",
            totalDiscounted: 5,
          },
          {
            id: "4",
            name: "4",
            description: "4",
            transportCode: "4",
            price: 4,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "4.00",
            totalDiscounted: 0.4,
          },
          {
            id: "45",
            name: "45",
            description: "45",
            transportCode: "hk:456562626",
            price: 52,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "52.00",
            totalDiscounted: 5.2,
          },
          {
            id: "6",
            name: "6",
            description: "6",
            transportCode: "6",
            price: 6,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "6.00",
            totalDiscounted: 0.6,
          },
          {
            id: "5",
            name: "5",
            description: "5",
            transportCode: "5",
            price: 5,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "5.00",
            totalDiscounted: 0.5,
          },
        ],
        remarks:
          "\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen",
        subTotal: 141,
        discount: 14.1,
        tax: "14.100",
        total: "141.00",
        footer: {
          accountInfo: { bic: "", iban: "" },
          paymentInfo: "",
          message: { message: "add some Message" },
        },
      },
      {
        date: "2023-09-15",
        performaInvoiceNumber: 53261312,
        expDate: "2023-10-15",
        companyInfoText: {
          companyName: "CVBA ANTWERP OUD PAPIER CENTRALE C.V.B.A.",
          address: "Smallandlaan",
          city: "Antwerpen",
          country: "België",
          zipCode: "2660",
          email: "be0436339157@.com",
          phone: "+324",
          btwNumber: "BE0436339157",
        },
        customerInfoText: {
          companyName: "SA DACHSER Belgium",
          address: "Avenue de l'eau vive(l)",
          city: "Mouscron",
          country: "België",
          zipCode: "7700",
          deliveryAddress: "",
          deliveryCity: "",
          deliveryCountry: "",
          email: "sk@example.com",
          phone: "+32",
          btwNumber: "BE0415394184",
          btwAmount: 10,
          discount: "10",
          tax: 10,
          notes: "sk",
          savedAs: "sk",
          contactPerson: "sk",
          position: "sk",
        },
        selectedCurrency: "eur",
        items: [
          {
            id: "1",
            name: "1",
            description: "1",
            price: 1,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "1.00",
            totalDiscounted: 0.1,
          },
          {
            id: "10",
            name: "10",
            description: "10",
            transportCode: "10",
            price: 10,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "10.00",
            totalDiscounted: 1,
          },
          {
            id: "11",
            name: "11",
            description: "11",
            transportCode: "11",
            price: 11,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "11.00",
            totalDiscounted: 1.1,
          },
          {
            id: "2",
            name: "2",
            description: "2",
            price: 2,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "2.00",
            totalDiscounted: 0.2,
          },
          {
            id: "33",
            name: "33",
            description: "33",
            price: 50,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "50.00",
            totalDiscounted: 5,
          },
          {
            id: "4",
            name: "4",
            description: "4",
            transportCode: "4",
            price: 4,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "4.00",
            totalDiscounted: 0.4,
          },
          {
            id: "45",
            name: "45",
            description: "45",
            transportCode: "hk:456562626",
            price: 52,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "52.00",
            totalDiscounted: 5.2,
          },
          {
            id: "6",
            name: "6",
            description: "6",
            transportCode: "6",
            price: 6,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "6.00",
            totalDiscounted: 0.6,
          },
          {
            id: "5",
            name: "5",
            description: "5",
            transportCode: "5",
            price: 5,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "5.00",
            totalDiscounted: 0.5,
          },
        ],
        remarks:
          "\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen",
        subTotal: 141,
        discount: 14.1,
        tax: "14.100",
        total: "141.00",
        footer: {
          accountInfo: { bic: "", iban: "" },
          paymentInfo: "",
          message: { message: "add some Message" },
        },
      },
      {
        date: "2023-09-15",
        performaInvoiceNumber: 53261313,
        expDate: "2023-10-15",
        companyInfoText: {
          companyName: "CVBA ANTWERP OUD PAPIER CENTRALE C.V.B.A.",
          address: "Smallandlaan",
          city: "Antwerpen",
          country: "België",
          zipCode: "2660",
          email: "be0436339157@.com",
          phone: "+324",
          btwNumber: "BE0436339157",
        },
        customerInfoText: {
          companyName: "SA DACHSER Belgium",
          address: "Avenue de l'eau vive(l)",
          city: "Mouscron",
          country: "België",
          zipCode: "7700",
          deliveryAddress: "",
          deliveryCity: "",
          deliveryCountry: "",
          email: "sk@example.com",
          phone: "+32",
          btwNumber: "BE0415394184",
          btwAmount: 10,
          discount: "10",
          tax: 10,
          notes: "sk",
          savedAs: "sk",
          contactPerson: "sk",
          position: "sk",
        },
        selectedCurrency: "eur",
        items: [
          {
            id: "1",
            name: "1",
            description: "1",
            price: 1,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "1.00",
            totalDiscounted: 0.1,
          },
          {
            id: "10",
            name: "10",
            description: "10",
            transportCode: "10",
            price: 10,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "10.00",
            totalDiscounted: 1,
          },
          {
            id: "11",
            name: "11",
            description: "11",
            transportCode: "11",
            price: 11,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "11.00",
            totalDiscounted: 1.1,
          },
          {
            id: "2",
            name: "2",
            description: "2",
            price: 2,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "2.00",
            totalDiscounted: 0.2,
          },
          {
            id: "33",
            name: "33",
            description: "33",
            price: 50,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "50.00",
            totalDiscounted: 5,
          },
          {
            id: "4",
            name: "4",
            description: "4",
            transportCode: "4",
            price: 4,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "4.00",
            totalDiscounted: 0.4,
          },
          {
            id: "45",
            name: "45",
            description: "45",
            transportCode: "hk:456562626",
            price: 52,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "52.00",
            totalDiscounted: 5.2,
          },
          {
            id: "6",
            name: "6",
            description: "6",
            transportCode: "6",
            price: 6,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "6.00",
            totalDiscounted: 0.6,
          },
          {
            id: "5",
            name: "5",
            description: "5",
            transportCode: "5",
            price: 5,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "5.00",
            totalDiscounted: 0.5,
          },
        ],
        remarks:
          "\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen",
        subTotal: 141,
        discount: 14.1,
        tax: "14.100",
        total: "141.00",
        footer: {
          accountInfo: { bic: "", iban: "" },
          paymentInfo: "",
          message: { message: "add some Message" },
        },
      },
      {
        date: "2023-09-15",
        performaInvoiceNumber: 53261314,
        expDate: "2023-10-15",
        companyInfoText: {
          companyName: "CVBA ANTWERP OUD PAPIER CENTRALE C.V.B.A.",
          address: "Smallandlaan",
          city: "Antwerpen",
          country: "België",
          zipCode: "2660",
          email: "be0436339157@.com",
          phone: "+324",
          btwNumber: "BE0436339157",
        },
        customerInfoText: {
          companyName: "SA DACHSER Belgium",
          address: "Avenue de l'eau vive(l)",
          city: "Mouscron",
          country: "België",
          zipCode: "7700",
          deliveryAddress: "",
          deliveryCity: "",
          deliveryCountry: "",
          email: "sk@example.com",
          phone: "+32",
          btwNumber: "BE0415394184",
          btwAmount: 10,
          discount: "10",
          tax: 10,
          notes: "sk",
          savedAs: "sk",
          contactPerson: "sk",
          position: "sk",
        },
        selectedCurrency: "eur",
        items: [
          {
            id: "1",
            name: "1",
            description: "1",
            price: 1,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "1.00",
            totalDiscounted: 0.1,
          },
          {
            id: "10",
            name: "10",
            description: "10",
            transportCode: "10",
            price: 10,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "10.00",
            totalDiscounted: 1,
          },
          {
            id: "11",
            name: "11",
            description: "11",
            transportCode: "11",
            price: 11,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "11.00",
            totalDiscounted: 1.1,
          },
          {
            id: "2",
            name: "2",
            description: "2",
            price: 2,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "2.00",
            totalDiscounted: 0.2,
          },
          {
            id: "33",
            name: "33",
            description: "33",
            price: 50,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "50.00",
            totalDiscounted: 5,
          },
          {
            id: "4",
            name: "4",
            description: "4",
            transportCode: "4",
            price: 4,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "4.00",
            totalDiscounted: 0.4,
          },
          {
            id: "45",
            name: "45",
            description: "45",
            transportCode: "hk:456562626",
            price: 52,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "52.00",
            totalDiscounted: 5.2,
          },
          {
            id: "6",
            name: "6",
            description: "6",
            transportCode: "6",
            price: 6,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "6.00",
            totalDiscounted: 0.6,
          },
          {
            id: "5",
            name: "5",
            description: "5",
            transportCode: "5",
            price: 5,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "5.00",
            totalDiscounted: 0.5,
          },
        ],
        remarks:
          "\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen",
        subTotal: 141,
        discount: 14.1,
        tax: "14.100",
        total: "141.00",
        footer: {
          accountInfo: { bic: "", iban: "" },
          paymentInfo: "",
          message: { message: "add some Message" },
        },
      },
      {
        date: "2023-09-15",
        performaInvoiceNumber: 53261315,
        expDate: "2023-10-15",
        companyInfoText: {
          companyName: "CVBA ANTWERP OUD PAPIER CENTRALE C.V.B.A.",
          address: "Smallandlaan",
          city: "Antwerpen",
          country: "België",
          zipCode: "2660",
          email: "be0436339157@.com",
          phone: "+324",
          btwNumber: "BE0436339157",
        },
        customerInfoText: {
          companyName: "SA DACHSER Belgium",
          address: "Avenue de l'eau vive(l)",
          city: "Mouscron",
          country: "België",
          zipCode: "7700",
          deliveryAddress: "",
          deliveryCity: "",
          deliveryCountry: "",
          email: "sk@example.com",
          phone: "+32",
          btwNumber: "BE0415394184",
          btwAmount: 10,
          discount: "10",
          tax: 10,
          notes: "sk",
          savedAs: "sk",
          contactPerson: "sk",
          position: "sk",
        },
        selectedCurrency: "eur",
        items: [
          {
            id: "1",
            name: "1",
            description: "1",
            price: 1,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "1.00",
            totalDiscounted: 0.1,
          },
          {
            id: "10",
            name: "10",
            description: "10",
            transportCode: "10",
            price: 10,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "10.00",
            totalDiscounted: 1,
          },
          {
            id: "11",
            name: "11",
            description: "11",
            transportCode: "11",
            price: 11,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "11.00",
            totalDiscounted: 1.1,
          },
          {
            id: "2",
            name: "2",
            description: "2",
            price: 2,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "2.00",
            totalDiscounted: 0.2,
          },
          {
            id: "33",
            name: "33",
            description: "33",
            price: 50,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "50.00",
            totalDiscounted: 5,
          },
          {
            id: "4",
            name: "4",
            description: "4",
            transportCode: "4",
            price: 4,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "4.00",
            totalDiscounted: 0.4,
          },
          {
            id: "45",
            name: "45",
            description: "45",
            transportCode: "hk:456562626",
            price: 52,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "52.00",
            totalDiscounted: 5.2,
          },
          {
            id: "6",
            name: "6",
            description: "6",
            transportCode: "6",
            price: 6,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "6.00",
            totalDiscounted: 0.6,
          },
          {
            id: "5",
            name: "5",
            description: "5",
            transportCode: "5",
            price: 5,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "5.00",
            totalDiscounted: 0.5,
          },
        ],
        remarks:
          "\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen",
        subTotal: 141,
        discount: 14.1,
        tax: "14.100",
        total: "141.00",
        footer: {
          accountInfo: { bic: "", iban: "" },
          paymentInfo: "",
          message: { message: "add some Message" },
        },
      },
      {
        date: "2023-09-15",
        performaInvoiceNumber: 53261316,
        expDate: "2023-10-15",
        companyInfoText: {
          companyName: "CVBA ANTWERP OUD PAPIER CENTRALE C.V.B.A.",
          address: "Smallandlaan",
          city: "Antwerpen",
          country: "België",
          zipCode: "2660",
          email: "be0436339157@.com",
          phone: "+324",
          btwNumber: "BE0436339157",
        },
        customerInfoText: {
          companyName: "SA DACHSER Belgium",
          address: "Avenue de l'eau vive(l)",
          city: "Mouscron",
          country: "België",
          zipCode: "7700",
          deliveryAddress: "",
          deliveryCity: "",
          deliveryCountry: "",
          email: "sk@example.com",
          phone: "+32",
          btwNumber: "BE0415394184",
          btwAmount: 10,
          discount: "10",
          tax: 10,
          notes: "sk",
          savedAs: "sk",
          contactPerson: "sk",
          position: "sk",
        },
        selectedCurrency: "eur",
        items: [
          {
            id: "1",
            name: "1",
            description: "1",
            price: 1,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "1.00",
            totalDiscounted: 0.1,
          },
          {
            id: "10",
            name: "10",
            description: "10",
            transportCode: "10",
            price: 10,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "10.00",
            totalDiscounted: 1,
          },
          {
            id: "11",
            name: "11",
            description: "11",
            transportCode: "11",
            price: 11,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "11.00",
            totalDiscounted: 1.1,
          },
          {
            id: "2",
            name: "2",
            description: "2",
            price: 2,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "2.00",
            totalDiscounted: 0.2,
          },
          {
            id: "33",
            name: "33",
            description: "33",
            price: 50,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "50.00",
            totalDiscounted: 5,
          },
          {
            id: "4",
            name: "4",
            description: "4",
            transportCode: "4",
            price: 4,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "4.00",
            totalDiscounted: 0.4,
          },
          {
            id: "45",
            name: "45",
            description: "45",
            transportCode: "hk:456562626",
            price: 52,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "52.00",
            totalDiscounted: 5.2,
          },
          {
            id: "6",
            name: "6",
            description: "6",
            transportCode: "6",
            price: 6,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "6.00",
            totalDiscounted: 0.6,
          },
          {
            id: "5",
            name: "5",
            description: "5",
            transportCode: "5",
            price: 5,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "5.00",
            totalDiscounted: 0.5,
          },
        ],
        remarks:
          "\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen",
        subTotal: 141,
        discount: 14.1,
        tax: "14.100",
        total: "141.00",
        footer: {
          accountInfo: { bic: "", iban: "" },
          paymentInfo: "",
          message: { message: "add some Message" },
        },
      },
      {
        date: "2023-09-15",
        performaInvoiceNumber: 53261317,
        expDate: "2023-10-15",
        companyInfoText: {
          companyName: "CVBA ANTWERP OUD PAPIER CENTRALE C.V.B.A.",
          address: "Smallandlaan",
          city: "Antwerpen",
          country: "België",
          zipCode: "2660",
          email: "be0436339157@.com",
          phone: "+324",
          btwNumber: "BE0436339157",
        },
        customerInfoText: {
          companyName: "SA DACHSER Belgium",
          address: "Avenue de l'eau vive(l)",
          city: "Mouscron",
          country: "België",
          zipCode: "7700",
          deliveryAddress: "",
          deliveryCity: "",
          deliveryCountry: "",
          email: "sk@example.com",
          phone: "+32",
          btwNumber: "BE0415394184",
          btwAmount: 10,
          discount: "10",
          tax: 10,
          notes: "sk",
          savedAs: "sk",
          contactPerson: "sk",
          position: "sk",
        },
        selectedCurrency: "eur",
        items: [
          {
            id: "1",
            name: "1",
            description: "1",
            price: 1,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "1.00",
            totalDiscounted: 0.1,
          },
          {
            id: "10",
            name: "10",
            description: "10",
            transportCode: "10",
            price: 10,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "10.00",
            totalDiscounted: 1,
          },
          {
            id: "11",
            name: "11",
            description: "11",
            transportCode: "11",
            price: 11,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "11.00",
            totalDiscounted: 1.1,
          },
          {
            id: "2",
            name: "2",
            description: "2",
            price: 2,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "2.00",
            totalDiscounted: 0.2,
          },
          {
            id: "33",
            name: "33",
            description: "33",
            price: 50,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "50.00",
            totalDiscounted: 5,
          },
          {
            id: "4",
            name: "4",
            description: "4",
            transportCode: "4",
            price: 4,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "4.00",
            totalDiscounted: 0.4,
          },
          {
            id: "45",
            name: "45",
            description: "45",
            transportCode: "hk:456562626",
            price: 52,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "52.00",
            totalDiscounted: 5.2,
          },
          {
            id: "6",
            name: "6",
            description: "6",
            transportCode: "6",
            price: 6,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "6.00",
            totalDiscounted: 0.6,
          },
          {
            id: "5",
            name: "5",
            description: "5",
            transportCode: "5",
            price: 5,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "5.00",
            totalDiscounted: 0.5,
          },
        ],
        remarks:
          "\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen",
        subTotal: 141,
        discount: 14.1,
        tax: "14.100",
        total: "141.00",
        footer: {
          accountInfo: { bic: "", iban: "" },
          paymentInfo: "",
          message: { message: "add some Message" },
        },
      },
      {
        date: "2023-09-15",
        performaInvoiceNumber: 53261318,
        expDate: "2023-10-15",
        companyInfoText: {
          companyName: "CVBA ANTWERP OUD PAPIER CENTRALE C.V.B.A.",
          address: "Smallandlaan",
          city: "Antwerpen",
          country: "België",
          zipCode: "2660",
          email: "be0436339157@.com",
          phone: "+324",
          btwNumber: "BE0436339157",
        },
        customerInfoText: {
          companyName: "SA DACHSER Belgium",
          address: "Avenue de l'eau vive(l)",
          city: "Mouscron",
          country: "België",
          zipCode: "7700",
          deliveryAddress: "",
          deliveryCity: "",
          deliveryCountry: "",
          email: "sk@example.com",
          phone: "+32",
          btwNumber: "BE0415394184",
          btwAmount: 10,
          discount: "10",
          tax: 10,
          notes: "sk",
          savedAs: "sk",
          contactPerson: "sk",
          position: "sk",
        },
        selectedCurrency: "eur",
        items: [
          {
            id: "1",
            name: "1",
            description: "1",
            price: 1,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "1.00",
            totalDiscounted: 0.1,
          },
          {
            id: "10",
            name: "10",
            description: "10",
            transportCode: "10",
            price: 10,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "10.00",
            totalDiscounted: 1,
          },
          {
            id: "11",
            name: "11",
            description: "11",
            transportCode: "11",
            price: 11,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "11.00",
            totalDiscounted: 1.1,
          },
          {
            id: "2",
            name: "2",
            description: "2",
            price: 2,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "2.00",
            totalDiscounted: 0.2,
          },
          {
            id: "33",
            name: "33",
            description: "33",
            price: 50,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "50.00",
            totalDiscounted: 5,
          },
          {
            id: "4",
            name: "4",
            description: "4",
            transportCode: "4",
            price: 4,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "4.00",
            totalDiscounted: 0.4,
          },
          {
            id: "45",
            name: "45",
            description: "45",
            transportCode: "hk:456562626",
            price: 52,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "52.00",
            totalDiscounted: 5.2,
          },
          {
            id: "6",
            name: "6",
            description: "6",
            transportCode: "6",
            price: 6,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "6.00",
            totalDiscounted: 0.6,
          },
          {
            id: "5",
            name: "5",
            description: "5",
            transportCode: "5",
            price: 5,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "5.00",
            totalDiscounted: 0.5,
          },
        ],
        remarks:
          "\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen",
        subTotal: 141,
        discount: 14.1,
        tax: "14.100",
        total: "141.00",
        footer: {
          accountInfo: { bic: "", iban: "" },
          paymentInfo: "",
          message: { message: "add some Message" },
        },
      },
      {
        date: "2023-09-15",
        performaInvoiceNumber: 53261319,
        expDate: "2023-10-15",
        companyInfoText: {
          companyName: "CVBA ANTWERP OUD PAPIER CENTRALE C.V.B.A.",
          address: "Smallandlaan",
          city: "Antwerpen",
          country: "België",
          zipCode: "2660",
          email: "be0436339157@.com",
          phone: "+324",
          btwNumber: "BE0436339157",
        },
        customerInfoText: {
          companyName: "SA DACHSER Belgium",
          address: "Avenue de l'eau vive(l)",
          city: "Mouscron",
          country: "België",
          zipCode: "7700",
          deliveryAddress: "",
          deliveryCity: "",
          deliveryCountry: "",
          email: "sk@example.com",
          phone: "+32",
          btwNumber: "BE0415394184",
          btwAmount: 10,
          discount: "10",
          tax: 10,
          notes: "sk",
          savedAs: "sk",
          contactPerson: "sk",
          position: "sk",
        },
        selectedCurrency: "eur",
        items: [
          {
            id: "1",
            name: "1",
            description: "1",
            price: 1,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "1.00",
            totalDiscounted: 0.1,
          },
          {
            id: "10",
            name: "10",
            description: "10",
            transportCode: "10",
            price: 10,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "10.00",
            totalDiscounted: 1,
          },
          {
            id: "11",
            name: "11",
            description: "11",
            transportCode: "11",
            price: 11,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "11.00",
            totalDiscounted: 1.1,
          },
          {
            id: "2",
            name: "2",
            description: "2",
            price: 2,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "2.00",
            totalDiscounted: 0.2,
          },
          {
            id: "33",
            name: "33",
            description: "33",
            price: 50,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "50.00",
            totalDiscounted: 5,
          },
          {
            id: "4",
            name: "4",
            description: "4",
            transportCode: "4",
            price: 4,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "4.00",
            totalDiscounted: 0.4,
          },
          {
            id: "45",
            name: "45",
            description: "45",
            transportCode: "hk:456562626",
            price: 52,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "52.00",
            totalDiscounted: 5.2,
          },
          {
            id: "6",
            name: "6",
            description: "6",
            transportCode: "6",
            price: 6,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "6.00",
            totalDiscounted: 0.6,
          },
          {
            id: "5",
            name: "5",
            description: "5",
            transportCode: "5",
            price: 5,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "5.00",
            totalDiscounted: 0.5,
          },
        ],
        remarks:
          "\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen",
        subTotal: 141,
        discount: 14.1,
        tax: "14.100",
        total: "141.00",
        footer: {
          accountInfo: { bic: "", iban: "" },
          paymentInfo: "",
          message: { message: "add some Message" },
        },
      },
      {
        date: "2023-09-15",
        performaInvoiceNumber: 53261320,
        expDate: "2023-10-15",
        companyInfoText: {
          companyName: "CVBA ANTWERP OUD PAPIER CENTRALE C.V.B.A.",
          address: "Smallandlaan",
          city: "Antwerpen",
          country: "België",
          zipCode: "2660",
          email: "be0436339157@.com",
          phone: "+324",
          btwNumber: "BE0436339157",
        },
        customerInfoText: {
          companyName: "SA DACHSER Belgium",
          address: "Avenue de l'eau vive(l)",
          city: "Mouscron",
          country: "België",
          zipCode: "7700",
          deliveryAddress: "",
          deliveryCity: "",
          deliveryCountry: "",
          email: "sk@example.com",
          phone: "+32",
          btwNumber: "BE0415394184",
          btwAmount: 10,
          discount: "10",
          tax: 10,
          notes: "sk",
          savedAs: "sk",
          contactPerson: "sk",
          position: "sk",
        },
        selectedCurrency: "eur",
        items: [
          {
            id: "1",
            name: "1",
            description: "1",
            price: 1,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "1.00",
            totalDiscounted: 0.1,
          },
          {
            id: "10",
            name: "10",
            description: "10",
            transportCode: "10",
            price: 10,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "10.00",
            totalDiscounted: 1,
          },
          {
            id: "11",
            name: "11",
            description: "11",
            transportCode: "11",
            price: 11,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "11.00",
            totalDiscounted: 1.1,
          },
          {
            id: "2",
            name: "2",
            description: "2",
            price: 2,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "2.00",
            totalDiscounted: 0.2,
          },
          {
            id: "33",
            name: "33",
            description: "33",
            price: 50,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "50.00",
            totalDiscounted: 5,
          },
          {
            id: "4",
            name: "4",
            description: "4",
            transportCode: "4",
            price: 4,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "4.00",
            totalDiscounted: 0.4,
          },
          {
            id: "45",
            name: "45",
            description: "45",
            transportCode: "hk:456562626",
            price: 52,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "52.00",
            totalDiscounted: 5.2,
          },
          {
            id: "6",
            name: "6",
            description: "6",
            transportCode: "6",
            price: 6,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "6.00",
            totalDiscounted: 0.6,
          },
          {
            id: "5",
            name: "5",
            description: "5",
            transportCode: "5",
            price: 5,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "5.00",
            totalDiscounted: 0.5,
          },
        ],
        remarks:
          "\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen",
        subTotal: 141,
        discount: 14.1,
        tax: "14.100",
        total: "141.00",
        footer: {
          accountInfo: { bic: "", iban: "" },
          paymentInfo: "",
          message: { message: "add some Message" },
        },
      },
      {
        date: "2023-09-15",
        performaInvoiceNumber: 53261321,
        expDate: "2023-10-15",
        companyInfoText: {
          companyName: "CVBA ANTWERP OUD PAPIER CENTRALE C.V.B.A.",
          address: "Smallandlaan",
          city: "Antwerpen",
          country: "België",
          zipCode: "2660",
          email: "be0436339157@.com",
          phone: "+324",
          btwNumber: "BE0436339157",
        },
        customerInfoText: {
          companyName: "SA DACHSER Belgium",
          address: "Avenue de l'eau vive(l)",
          city: "Mouscron",
          country: "België",
          zipCode: "7700",
          deliveryAddress: "",
          deliveryCity: "",
          deliveryCountry: "",
          email: "sk@example.com",
          phone: "+32",
          btwNumber: "BE0415394184",
          btwAmount: 10,
          discount: "10",
          tax: 10,
          notes: "sk",
          savedAs: "sk",
          contactPerson: "sk",
          position: "sk",
        },
        selectedCurrency: "eur",
        items: [
          {
            id: "1",
            name: "1",
            description: "1",
            price: 1,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "1.00",
            totalDiscounted: 0.1,
          },
          {
            id: "10",
            name: "10",
            description: "10",
            transportCode: "10",
            price: 10,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "10.00",
            totalDiscounted: 1,
          },
          {
            id: "11",
            name: "11",
            description: "11",
            transportCode: "11",
            price: 11,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "11.00",
            totalDiscounted: 1.1,
          },
          {
            id: "2",
            name: "2",
            description: "2",
            price: 2,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "2.00",
            totalDiscounted: 0.2,
          },
          {
            id: "33",
            name: "33",
            description: "33",
            price: 50,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "50.00",
            totalDiscounted: 5,
          },
          {
            id: "4",
            name: "4",
            description: "4",
            transportCode: "4",
            price: 4,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "4.00",
            totalDiscounted: 0.4,
          },
          {
            id: "45",
            name: "45",
            description: "45",
            transportCode: "hk:456562626",
            price: 52,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "52.00",
            totalDiscounted: 5.2,
          },
          {
            id: "6",
            name: "6",
            description: "6",
            transportCode: "6",
            price: 6,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "6.00",
            totalDiscounted: 0.6,
          },
          {
            id: "5",
            name: "5",
            description: "5",
            transportCode: "5",
            price: 5,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "5.00",
            totalDiscounted: 0.5,
          },
        ],
        remarks:
          "\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen",
        subTotal: 141,
        discount: 14.1,
        tax: "14.100",
        total: "141.00",
        footer: {
          accountInfo: { bic: "", iban: "" },
          paymentInfo: "",
          message: { message: "add some Message" },
        },
      },
      {
        date: "2023-09-15",
        performaInvoiceNumber: 53261322,
        expDate: "2023-10-15",
        companyInfoText: {
          companyName: "CVBA ANTWERP OUD PAPIER CENTRALE C.V.B.A.",
          address: "Smallandlaan",
          city: "Antwerpen",
          country: "België",
          zipCode: "2660",
          email: "be0436339157@.com",
          phone: "+324",
          btwNumber: "BE0436339157",
        },
        customerInfoText: {
          companyName: "SA DACHSER Belgium",
          address: "Avenue de l'eau vive(l)",
          city: "Mouscron",
          country: "België",
          zipCode: "7700",
          deliveryAddress: "",
          deliveryCity: "",
          deliveryCountry: "",
          email: "sk@example.com",
          phone: "+32",
          btwNumber: "BE0415394184",
          btwAmount: 10,
          discount: "10",
          tax: 10,
          notes: "sk",
          savedAs: "sk",
          contactPerson: "sk",
          position: "sk",
        },
        selectedCurrency: "eur",
        items: [
          {
            id: "1",
            name: "1",
            description: "1",
            price: 1,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "1.00",
            totalDiscounted: 0.1,
          },
          {
            id: "10",
            name: "10",
            description: "10",
            transportCode: "10",
            price: 10,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "10.00",
            totalDiscounted: 1,
          },
          {
            id: "11",
            name: "11",
            description: "11",
            transportCode: "11",
            price: 11,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "11.00",
            totalDiscounted: 1.1,
          },
          {
            id: "2",
            name: "2",
            description: "2",
            price: 2,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "2.00",
            totalDiscounted: 0.2,
          },
          {
            id: "33",
            name: "33",
            description: "33",
            price: 50,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "50.00",
            totalDiscounted: 5,
          },
          {
            id: "4",
            name: "4",
            description: "4",
            transportCode: "4",
            price: 4,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "4.00",
            totalDiscounted: 0.4,
          },
          {
            id: "45",
            name: "45",
            description: "45",
            transportCode: "hk:456562626",
            price: 52,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "52.00",
            totalDiscounted: 5.2,
          },
          {
            id: "6",
            name: "6",
            description: "6",
            transportCode: "6",
            price: 6,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "6.00",
            totalDiscounted: 0.6,
          },
          {
            id: "5",
            name: "5",
            description: "5",
            transportCode: "5",
            price: 5,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "5.00",
            totalDiscounted: 0.5,
          },
        ],
        remarks:
          "\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen",
        subTotal: 141,
        discount: 14.1,
        tax: "14.100",
        total: "141.00",
        footer: {
          accountInfo: { bic: "", iban: "" },
          paymentInfo: "",
          message: { message: "add some Message" },
        },
      },
      {
        date: "2023-09-15",
        performaInvoiceNumber: 53261323,
        expDate: "2023-10-15",
        companyInfoText: {
          companyName: "CVBA ANTWERP OUD PAPIER CENTRALE C.V.B.A.",
          address: "Smallandlaan",
          city: "Antwerpen",
          country: "België",
          zipCode: "2660",
          email: "be0436339157@.com",
          phone: "+324",
          btwNumber: "BE0436339157",
        },
        customerInfoText: {
          companyName: "SA DACHSER Belgium",
          address: "Avenue de l'eau vive(l)",
          city: "Mouscron",
          country: "België",
          zipCode: "7700",
          deliveryAddress: "",
          deliveryCity: "",
          deliveryCountry: "",
          email: "sk@example.com",
          phone: "+32",
          btwNumber: "BE0415394184",
          btwAmount: 10,
          discount: "10",
          tax: 10,
          notes: "sk",
          savedAs: "sk",
          contactPerson: "sk",
          position: "sk",
        },
        selectedCurrency: "eur",
        items: [
          {
            id: "1",
            name: "1",
            description: "1",
            price: 1,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "1.00",
            totalDiscounted: 0.1,
          },
          {
            id: "10",
            name: "10",
            description: "10",
            transportCode: "10",
            price: 10,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "10.00",
            totalDiscounted: 1,
          },
          {
            id: "11",
            name: "11",
            description: "11",
            transportCode: "11",
            price: 11,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "11.00",
            totalDiscounted: 1.1,
          },
          {
            id: "2",
            name: "2",
            description: "2",
            price: 2,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "2.00",
            totalDiscounted: 0.2,
          },
          {
            id: "33",
            name: "33",
            description: "33",
            price: 50,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "50.00",
            totalDiscounted: 5,
          },
          {
            id: "4",
            name: "4",
            description: "4",
            transportCode: "4",
            price: 4,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "4.00",
            totalDiscounted: 0.4,
          },
          {
            id: "45",
            name: "45",
            description: "45",
            transportCode: "hk:456562626",
            price: 52,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "52.00",
            totalDiscounted: 5.2,
          },
          {
            id: "6",
            name: "6",
            description: "6",
            transportCode: "6",
            price: 6,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "6.00",
            totalDiscounted: 0.6,
          },
          {
            id: "5",
            name: "5",
            description: "5",
            transportCode: "5",
            price: 5,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "5.00",
            totalDiscounted: 0.5,
          },
        ],
        remarks:
          "\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen",
        subTotal: 141,
        discount: 14.1,
        tax: "14.100",
        total: "141.00",
        footer: {
          accountInfo: { bic: "", iban: "" },
          paymentInfo: "",
          message: { message: "add some Message" },
        },
      },
      {
        date: "2023-09-15",
        performaInvoiceNumber: 53261324,
        expDate: "2023-10-15",
        companyInfoText: {
          companyName: "CVBA ANTWERP OUD PAPIER CENTRALE C.V.B.A.",
          address: "Smallandlaan",
          city: "Antwerpen",
          country: "België",
          zipCode: "2660",
          email: "be0436339157@.com",
          phone: "+324",
          btwNumber: "BE0436339157",
        },
        customerInfoText: {
          companyName: "SA DACHSER Belgium",
          address: "Avenue de l'eau vive(l)",
          city: "Mouscron",
          country: "België",
          zipCode: "7700",
          deliveryAddress: "",
          deliveryCity: "",
          deliveryCountry: "",
          email: "sk@example.com",
          phone: "+32",
          btwNumber: "BE0415394184",
          btwAmount: 10,
          discount: "10",
          tax: 10,
          notes: "sk",
          savedAs: "sk",
          contactPerson: "sk",
          position: "sk",
        },
        selectedCurrency: "eur",
        items: [
          {
            id: "1",
            name: "1",
            description: "1",
            price: 1,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "1.00",
            totalDiscounted: 0.1,
          },
          {
            id: "10",
            name: "10",
            description: "10",
            transportCode: "10",
            price: 10,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "10.00",
            totalDiscounted: 1,
          },
          {
            id: "11",
            name: "11",
            description: "11",
            transportCode: "11",
            price: 11,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "11.00",
            totalDiscounted: 1.1,
          },
          {
            id: "2",
            name: "2",
            description: "2",
            price: 2,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "2.00",
            totalDiscounted: 0.2,
          },
          {
            id: "33",
            name: "33",
            description: "33",
            price: 50,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "50.00",
            totalDiscounted: 5,
          },
          {
            id: "4",
            name: "4",
            description: "4",
            transportCode: "4",
            price: 4,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "4.00",
            totalDiscounted: 0.4,
          },
          {
            id: "45",
            name: "45",
            description: "45",
            transportCode: "hk:456562626",
            price: 52,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "52.00",
            totalDiscounted: 5.2,
          },
          {
            id: "6",
            name: "6",
            description: "6",
            transportCode: "6",
            price: 6,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "6.00",
            totalDiscounted: 0.6,
          },
          {
            id: "5",
            name: "5",
            description: "5",
            transportCode: "5",
            price: 5,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "5.00",
            totalDiscounted: 0.5,
          },
        ],
        remarks:
          "\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen",
        subTotal: 141,
        discount: 14.1,
        tax: "14.100",
        total: "141.00",
        footer: {
          accountInfo: { bic: "", iban: "" },
          paymentInfo: "",
          message: { message: "add some Message" },
        },
      },
      {
        date: "2023-09-15",
        performaInvoiceNumber: 53261325,
        expDate: "2023-10-15",
        companyInfoText: {
          companyName: "CVBA ANTWERP OUD PAPIER CENTRALE C.V.B.A.",
          address: "Smallandlaan",
          city: "Antwerpen",
          country: "België",
          zipCode: "2660",
          email: "be0436339157@.com",
          phone: "+324",
          btwNumber: "BE0436339157",
        },
        customerInfoText: {
          companyName: "SA DACHSER Belgium",
          address: "Avenue de l'eau vive(l)",
          city: "Mouscron",
          country: "België",
          zipCode: "7700",
          deliveryAddress: "",
          deliveryCity: "",
          deliveryCountry: "",
          email: "sk@example.com",
          phone: "+32",
          btwNumber: "BE0415394184",
          btwAmount: 10,
          discount: "10",
          tax: 10,
          notes: "sk",
          savedAs: "sk",
          contactPerson: "sk",
          position: "sk",
        },
        selectedCurrency: "eur",
        items: [
          {
            id: "1",
            name: "1",
            description: "1",
            price: 1,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "1.00",
            totalDiscounted: 0.1,
          },
          {
            id: "10",
            name: "10",
            description: "10",
            transportCode: "10",
            price: 10,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "10.00",
            totalDiscounted: 1,
          },
          {
            id: "11",
            name: "11",
            description: "11",
            transportCode: "11",
            price: 11,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "11.00",
            totalDiscounted: 1.1,
          },
          {
            id: "2",
            name: "2",
            description: "2",
            price: 2,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "2.00",
            totalDiscounted: 0.2,
          },
          {
            id: "33",
            name: "33",
            description: "33",
            price: 50,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "50.00",
            totalDiscounted: 5,
          },
          {
            id: "4",
            name: "4",
            description: "4",
            transportCode: "4",
            price: 4,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "4.00",
            totalDiscounted: 0.4,
          },
          {
            id: "45",
            name: "45",
            description: "45",
            transportCode: "hk:456562626",
            price: 52,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "52.00",
            totalDiscounted: 5.2,
          },
          {
            id: "6",
            name: "6",
            description: "6",
            transportCode: "6",
            price: 6,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "6.00",
            totalDiscounted: 0.6,
          },
          {
            id: "5",
            name: "5",
            description: "5",
            transportCode: "5",
            price: 5,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "5.00",
            totalDiscounted: 0.5,
          },
        ],
        remarks:
          "\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen",
        subTotal: 141,
        discount: 14.1,
        tax: "14.100",
        total: "141.00",
        footer: {
          accountInfo: { bic: "", iban: "" },
          paymentInfo: "",
          message: { message: "add some Message" },
        },
      },
      {
        date: "2023-09-15",
        performaInvoiceNumber: 53261326,
        expDate: "2023-10-15",
        companyInfoText: {
          companyName: "CVBA ANTWERP OUD PAPIER CENTRALE C.V.B.A.",
          address: "Smallandlaan",
          city: "Antwerpen",
          country: "België",
          zipCode: "2660",
          email: "be0436339157@.com",
          phone: "+324",
          btwNumber: "BE0436339157",
        },
        customerInfoText: {
          companyName: "SA DACHSER Belgium",
          address: "Avenue de l'eau vive(l)",
          city: "Mouscron",
          country: "België",
          zipCode: "7700",
          deliveryAddress: "",
          deliveryCity: "",
          deliveryCountry: "",
          email: "sk@example.com",
          phone: "+32",
          btwNumber: "BE0415394184",
          btwAmount: 10,
          discount: "10",
          tax: 10,
          notes: "sk",
          savedAs: "sk",
          contactPerson: "sk",
          position: "sk",
        },
        selectedCurrency: "eur",
        items: [
          {
            id: "1",
            name: "1",
            description: "1",
            price: 1,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "1.00",
            totalDiscounted: 0.1,
          },
          {
            id: "10",
            name: "10",
            description: "10",
            transportCode: "10",
            price: 10,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "10.00",
            totalDiscounted: 1,
          },
          {
            id: "11",
            name: "11",
            description: "11",
            transportCode: "11",
            price: 11,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "11.00",
            totalDiscounted: 1.1,
          },
          {
            id: "2",
            name: "2",
            description: "2",
            price: 2,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "2.00",
            totalDiscounted: 0.2,
          },
          {
            id: "33",
            name: "33",
            description: "33",
            price: 50,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "50.00",
            totalDiscounted: 5,
          },
          {
            id: "4",
            name: "4",
            description: "4",
            transportCode: "4",
            price: 4,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "4.00",
            totalDiscounted: 0.4,
          },
          {
            id: "45",
            name: "45",
            description: "45",
            transportCode: "hk:456562626",
            price: 52,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "52.00",
            totalDiscounted: 5.2,
          },
          {
            id: "6",
            name: "6",
            description: "6",
            transportCode: "6",
            price: 6,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "6.00",
            totalDiscounted: 0.6,
          },
          {
            id: "5",
            name: "5",
            description: "5",
            transportCode: "5",
            price: 5,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "5.00",
            totalDiscounted: 0.5,
          },
        ],
        remarks:
          "\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen",
        subTotal: 141,
        discount: 14.1,
        tax: "14.100",
        total: "141.00",
        footer: {
          accountInfo: { bic: "", iban: "" },
          paymentInfo: "",
          message: { message: "add some Message" },
        },
      },
      {
        date: "2023-09-15",
        performaInvoiceNumber: 53261327,
        expDate: "2023-10-15",
        companyInfoText: {
          companyName: "CVBA ANTWERP OUD PAPIER CENTRALE C.V.B.A.",
          address: "Smallandlaan",
          city: "Antwerpen",
          country: "België",
          zipCode: "2660",
          email: "be0436339157@.com",
          phone: "+324",
          btwNumber: "BE0436339157",
        },
        customerInfoText: {
          companyName: "SA DACHSER Belgium",
          address: "Avenue de l'eau vive(l)",
          city: "Mouscron",
          country: "België",
          zipCode: "7700",
          deliveryAddress: "",
          deliveryCity: "",
          deliveryCountry: "",
          email: "sk@example.com",
          phone: "+32",
          btwNumber: "BE0415394184",
          btwAmount: 10,
          discount: "10",
          tax: 10,
          notes: "sk",
          savedAs: "sk",
          contactPerson: "sk",
          position: "sk",
        },
        selectedCurrency: "eur",
        items: [
          {
            id: "1",
            name: "1",
            description: "1",
            price: 1,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "1.00",
            totalDiscounted: 0.1,
          },
          {
            id: "10",
            name: "10",
            description: "10",
            transportCode: "10",
            price: 10,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "10.00",
            totalDiscounted: 1,
          },
          {
            id: "11",
            name: "11",
            description: "11",
            transportCode: "11",
            price: 11,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "11.00",
            totalDiscounted: 1.1,
          },
          {
            id: "2",
            name: "2",
            description: "2",
            price: 2,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "2.00",
            totalDiscounted: 0.2,
          },
          {
            id: "33",
            name: "33",
            description: "33",
            price: 50,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "50.00",
            totalDiscounted: 5,
          },
          {
            id: "4",
            name: "4",
            description: "4",
            transportCode: "4",
            price: 4,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "4.00",
            totalDiscounted: 0.4,
          },
          {
            id: "45",
            name: "45",
            description: "45",
            transportCode: "hk:456562626",
            price: 52,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "52.00",
            totalDiscounted: 5.2,
          },
          {
            id: "6",
            name: "6",
            description: "6",
            transportCode: "6",
            price: 6,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "6.00",
            totalDiscounted: 0.6,
          },
          {
            id: "5",
            name: "5",
            description: "5",
            transportCode: "5",
            price: 5,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "5.00",
            totalDiscounted: 0.5,
          },
        ],
        remarks:
          "\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen",
        subTotal: 141,
        discount: 14.1,
        tax: "14.100",
        total: "141.00",
        footer: {
          accountInfo: { bic: "", iban: "" },
          paymentInfo: "",
          message: { message: "add some Message" },
        },
      },
      {
        date: "2023-09-15",
        performaInvoiceNumber: 53261328,
        expDate: "2023-10-15",
        companyInfoText: {
          companyName: "CVBA ANTWERP OUD PAPIER CENTRALE C.V.B.A.",
          address: "Smallandlaan",
          city: "Antwerpen",
          country: "België",
          zipCode: "2660",
          email: "be0436339157@.com",
          phone: "+324",
          btwNumber: "BE0436339157",
        },
        customerInfoText: {
          companyName: "SA DACHSER Belgium",
          address: "Avenue de l'eau vive(l)",
          city: "Mouscron",
          country: "België",
          zipCode: "7700",
          deliveryAddress: "",
          deliveryCity: "",
          deliveryCountry: "",
          email: "sk@example.com",
          phone: "+32",
          btwNumber: "BE0415394184",
          btwAmount: 10,
          discount: "10",
          tax: 10,
          notes: "sk",
          savedAs: "sk",
          contactPerson: "sk",
          position: "sk",
        },
        selectedCurrency: "eur",
        items: [
          {
            id: "1",
            name: "1",
            description: "1",
            price: 1,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "1.00",
            totalDiscounted: 0.1,
          },
          {
            id: "10",
            name: "10",
            description: "10",
            transportCode: "10",
            price: 10,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "10.00",
            totalDiscounted: 1,
          },
          {
            id: "11",
            name: "11",
            description: "11",
            transportCode: "11",
            price: 11,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "11.00",
            totalDiscounted: 1.1,
          },
          {
            id: "2",
            name: "2",
            description: "2",
            price: 2,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "2.00",
            totalDiscounted: 0.2,
          },
          {
            id: "33",
            name: "33",
            description: "33",
            price: 50,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "50.00",
            totalDiscounted: 5,
          },
          {
            id: "4",
            name: "4",
            description: "4",
            transportCode: "4",
            price: 4,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "4.00",
            totalDiscounted: 0.4,
          },
          {
            id: "45",
            name: "45",
            description: "45",
            transportCode: "hk:456562626",
            price: 52,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "52.00",
            totalDiscounted: 5.2,
          },
          {
            id: "6",
            name: "6",
            description: "6",
            transportCode: "6",
            price: 6,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "6.00",
            totalDiscounted: 0.6,
          },
          {
            id: "5",
            name: "5",
            description: "5",
            transportCode: "5",
            price: 5,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "5.00",
            totalDiscounted: 0.5,
          },
        ],
        remarks:
          "\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen",
        subTotal: 141,
        discount: 14.1,
        tax: "14.100",
        total: "141.00",
        footer: {
          accountInfo: { bic: "", iban: "" },
          paymentInfo: "",
          message: { message: "add some Message" },
        },
      },
      {
        date: "2023-09-15",
        performaInvoiceNumber: 53261329,
        expDate: "2023-10-15",
        companyInfoText: {
          companyName: "CVBA ANTWERP OUD PAPIER CENTRALE C.V.B.A.",
          address: "Smallandlaan",
          city: "Antwerpen",
          country: "België",
          zipCode: "2660",
          email: "be0436339157@.com",
          phone: "+324",
          btwNumber: "BE0436339157",
        },
        customerInfoText: {
          companyName: "SA DACHSER Belgium",
          address: "Avenue de l'eau vive(l)",
          city: "Mouscron",
          country: "België",
          zipCode: "7700",
          deliveryAddress: "",
          deliveryCity: "",
          deliveryCountry: "",
          email: "sk@example.com",
          phone: "+32",
          btwNumber: "BE0415394184",
          btwAmount: 10,
          discount: "10",
          tax: 10,
          notes: "sk",
          savedAs: "sk",
          contactPerson: "sk",
          position: "sk",
        },
        selectedCurrency: "eur",
        items: [
          {
            id: "1",
            name: "1",
            description: "1",
            price: 1,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "1.00",
            totalDiscounted: 0.1,
          },
          {
            id: "10",
            name: "10",
            description: "10",
            transportCode: "10",
            price: 10,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "10.00",
            totalDiscounted: 1,
          },
          {
            id: "11",
            name: "11",
            description: "11",
            transportCode: "11",
            price: 11,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "11.00",
            totalDiscounted: 1.1,
          },
          {
            id: "2",
            name: "2",
            description: "2",
            price: 2,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "2.00",
            totalDiscounted: 0.2,
          },
          {
            id: "33",
            name: "33",
            description: "33",
            price: 50,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "50.00",
            totalDiscounted: 5,
          },
          {
            id: "4",
            name: "4",
            description: "4",
            transportCode: "4",
            price: 4,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "4.00",
            totalDiscounted: 0.4,
          },
          {
            id: "45",
            name: "45",
            description: "45",
            transportCode: "hk:456562626",
            price: 52,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "52.00",
            totalDiscounted: 5.2,
          },
          {
            id: "6",
            name: "6",
            description: "6",
            transportCode: "6",
            price: 6,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "6.00",
            totalDiscounted: 0.6,
          },
          {
            id: "5",
            name: "5",
            description: "5",
            transportCode: "5",
            price: 5,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "5.00",
            totalDiscounted: 0.5,
          },
        ],
        remarks:
          "\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen",
        subTotal: 141,
        discount: 14.1,
        tax: "14.100",
        total: "141.00",
        footer: {
          accountInfo: { bic: "", iban: "" },
          paymentInfo: "",
          message: { message: "add some Message" },
        },
      },
      {
        date: "2023-09-15",
        performaInvoiceNumber: 53261330,
        expDate: "2023-10-15",
        companyInfoText: {
          companyName: "CVBA ANTWERP OUD PAPIER CENTRALE C.V.B.A.",
          address: "Smallandlaan",
          city: "Antwerpen",
          country: "België",
          zipCode: "2660",
          email: "be0436339157@.com",
          phone: "+324",
          btwNumber: "BE0436339157",
        },
        customerInfoText: {
          companyName: "SA DACHSER Belgium",
          address: "Avenue de l'eau vive(l)",
          city: "Mouscron",
          country: "België",
          zipCode: "7700",
          deliveryAddress: "",
          deliveryCity: "",
          deliveryCountry: "",
          email: "sk@example.com",
          phone: "+32",
          btwNumber: "BE0415394184",
          btwAmount: 10,
          discount: "10",
          tax: 10,
          notes: "sk",
          savedAs: "sk",
          contactPerson: "sk",
          position: "sk",
        },
        selectedCurrency: "eur",
        items: [
          {
            id: "1",
            name: "1",
            description: "1",
            price: 1,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "1.00",
            totalDiscounted: 0.1,
          },
          {
            id: "10",
            name: "10",
            description: "10",
            transportCode: "10",
            price: 10,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "10.00",
            totalDiscounted: 1,
          },
          {
            id: "11",
            name: "11",
            description: "11",
            transportCode: "11",
            price: 11,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "11.00",
            totalDiscounted: 1.1,
          },
          {
            id: "2",
            name: "2",
            description: "2",
            price: 2,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "2.00",
            totalDiscounted: 0.2,
          },
          {
            id: "33",
            name: "33",
            description: "33",
            price: 50,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "50.00",
            totalDiscounted: 5,
          },
          {
            id: "4",
            name: "4",
            description: "4",
            transportCode: "4",
            price: 4,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "4.00",
            totalDiscounted: 0.4,
          },
          {
            id: "45",
            name: "45",
            description: "45",
            transportCode: "hk:456562626",
            price: 52,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "52.00",
            totalDiscounted: 5.2,
          },
          {
            id: "6",
            name: "6",
            description: "6",
            transportCode: "6",
            price: 6,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "6.00",
            totalDiscounted: 0.6,
          },
          {
            id: "5",
            name: "5",
            description: "5",
            transportCode: "5",
            price: 5,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "5.00",
            totalDiscounted: 0.5,
          },
        ],
        remarks:
          "\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen",
        subTotal: 141,
        discount: 14.1,
        tax: "14.100",
        total: "141.00",
        footer: {
          accountInfo: { bic: "", iban: "" },
          paymentInfo: "",
          message: { message: "add some Message" },
        },
      },
      {
        date: "2023-09-15",
        performaInvoiceNumber: 53261331,
        expDate: "2023-10-15",
        companyInfoText: {
          companyName: "CVBA ANTWERP OUD PAPIER CENTRALE C.V.B.A.",
          address: "Smallandlaan",
          city: "Antwerpen",
          country: "België",
          zipCode: "2660",
          email: "be0436339157@.com",
          phone: "+324",
          btwNumber: "BE0436339157",
        },
        customerInfoText: {
          companyName: "SA DACHSER Belgium",
          address: "Avenue de l'eau vive(l)",
          city: "Mouscron",
          country: "België",
          zipCode: "7700",
          deliveryAddress: "",
          deliveryCity: "",
          deliveryCountry: "",
          email: "sk@example.com",
          phone: "+32",
          btwNumber: "BE0415394184",
          btwAmount: 10,
          discount: "10",
          tax: 10,
          notes: "sk",
          savedAs: "sk",
          contactPerson: "sk",
          position: "sk",
        },
        selectedCurrency: "eur",
        items: [
          {
            id: "1",
            name: "1",
            description: "1",
            price: 1,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "1.00",
            totalDiscounted: 0.1,
          },
          {
            id: "10",
            name: "10",
            description: "10",
            transportCode: "10",
            price: 10,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "10.00",
            totalDiscounted: 1,
          },
          {
            id: "11",
            name: "11",
            description: "11",
            transportCode: "11",
            price: 11,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "11.00",
            totalDiscounted: 1.1,
          },
          {
            id: "2",
            name: "2",
            description: "2",
            price: 2,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "2.00",
            totalDiscounted: 0.2,
          },
          {
            id: "33",
            name: "33",
            description: "33",
            price: 50,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "50.00",
            totalDiscounted: 5,
          },
          {
            id: "4",
            name: "4",
            description: "4",
            transportCode: "4",
            price: 4,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "4.00",
            totalDiscounted: 0.4,
          },
          {
            id: "45",
            name: "45",
            description: "45",
            transportCode: "hk:456562626",
            price: 52,
            discount: "10",
            quantity: 1,
            btw: 10,
            total: "52.00",
            totalDiscounted: 5.2,
          },
          {
            id: "6",
            name: "6",
            description: "6",
            transportCode: "6",
            price: 6,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "6.00",
            totalDiscounted: 0.6,
          },
          {
            id: "5",
            name: "5",
            description: "5",
            transportCode: "5",
            price: 5,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "5.00",
            totalDiscounted: 0.5,
          },
        ],
        remarks:
          "\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumenda placeat explicabo suscipit culpa, cupiditate, excepturi minima praesentium quia a et illum mollitia consequatur perferendis quod repudiandae in, sint dolore!\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen\n    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta assumen",
        subTotal: 141,
        discount: 14.1,
        tax: "14.100",
        total: "141.00",
        footer: {
          accountInfo: { bic: "", iban: "" },
          paymentInfo: "",
          message: { message: "add some Message" },
        },
      },
      {
        date: "2023-09-22",
        performaInvoiceNumber: 53261350,
        expDate: "2023-10-22",
        companyInfoText: {
          companyName: "CVBA ANTWERP OUD PAPIER CENTRALE C.V.B.A.",
          address: "Smallandlaan",
          city: "Antwerpen",
          country: "België",
          zipCode: "2660",
          email: "be0436339157@.com",
          phone: "+324",
          btwNumber: "BE0436339157",
        },
        customerInfoText: {},
        selectedCurrency: "eur",
        items: [
          {
            id: "2",
            name: "2",
            description: "2",
            price: 2,
            discount: 0,
            quantity: 1,
            btw: 0,
            total: "2.00",
            totalDiscounted: 0,
          },
        ],
        remarks: "",
        subTotal: 2,
        discount: "",
        tax: "0.000",
        total: "2.00",
        footer: {
          accountInfo: { bic: "", iban: "" },
          paymentInfo: "",
          message: { message: "add some Message" },
        },
      },
      {
        date: "2023-09-26",
        performaInvoiceNumber: 53261354,
        expDate: "2023-10-26",
        companyInfoText: {
          companyName: "CVBA ANTWERP OUD PAPIER CENTRALE C.V.B.A.",
          address: "Smallandlaan",
          city: "Antwerpen",
          country: "België",
          zipCode: "2660",
          email: "be0436339157@.com",
          phone: "+324",
          btwNumber: "BE0436339157",
        },
        customerInfoText: {},
        selectedCurrency: "eur",
        items: [
          {
            id: "3",
            name: "3",
            description: "3",
            transportCode: "3",
            price: 3,
            discount: 10,
            quantity: 1,
            btw: 50,
            total: 4.2,
            totalDiscounted: 0.30000000000000004,
          },
          {
            id: "5",
            name: "5",
            description: "5",
            transportCode: "5",
            price: 5,
            discount: 10,
            quantity: 1,
            btw: 10,
            total: "5.00",
            totalDiscounted: 0.5,
          },
        ],
        remarks: "",
        subTotal: "8.00",
        discount: "0.80",
        tax: "2.000",
        total: 9.2,
        footer: {
          accountInfo: { bic: "", iban: "" },
          paymentInfo: "",
          message: { message: "add some Message" },
        },
      },
      {
        date: "2023-09-22",
        performaInvoiceNumber: 53261358,
        expDate: "2023-10-22",
        companyInfoText: {
          companyName: "CVBA ANTWERP OUD PAPIER CENTRALE C.V.B.A.",
          address: "Smallandlaan",
          city: "Antwerpen",
          country: "België",
          zipCode: "2660",
          email: "be0436339157@.com",
          phone: "+324",
          btwNumber: "BE0436339157",
        },
        customerInfoText: {
          companyName: "BV J.M.W. PRINTING",
          address: "Mechelsesteenweg",
          city: "Kontich",
          country: "België",
          zipCode: "2550",
          deliveryAddress: "",
          deliveryCity: "",
          deliveryCountry: "",
          email: "info@jmwprinting.be",
          phone: "+32 3 458 00 05",
          btwNumber: "0455376396",
          btwAmount: "21",
          discount: "10",
          tax: "21",
          notes: "good",
          contactPerson: "guy",
          position: "maklar",
        },
        selectedCurrency: "eur",
        items: [
          {
            id: "1",
            name: "Product 1",
            description: "Description for Product 1",
            transportCode: "ABC123",
            price: 19.99,
            discount: 5,
            quantity: 1,
            btw: 10,
            total: "20.99",
            totalDiscounted: 0.9994999999999998,
          },
          {
            id: "2",
            name: "Product 2",
            description: "Description for Product 2",
            transportCode: "DEF456",
            price: 24.99,
            discount: 8,
            quantity: 1,
            btw: 10,
            total: "25.49",
            totalDiscounted: 1.9991999999999999,
          },
          {
            id: "3",
            name: "Product 3",
            description: "Description for Product 3",
            transportCode: "GHI789",
            price: 14.99,
            discount: 3,
            quantity: 1,
            btw: 10,
            total: "16.04",
            totalDiscounted: 0.4497,
          },
        ],
        remarks: "",
        subTotal: 59.97,
        discount: 3.4484,
        tax: "5.997",
        total: "62.52",
        footer: {
          accountInfo: { bic: "", iban: "" },
          paymentInfo: "",
          message: { message: "add some Message" },
        },
      },
    ];

    localStorage.setItem(
      "savedProformaInvoices",
      JSON.stringify(proformaInvoices)
    );
  }, []);

  useEffect(() => {
    // if  selectPerforma length is 0, set viewMoreAction to false
    setViewMoreAction(selectPerforma.length > 0);

    console.log(selectPerforma);
  }, [selectPerforma]);

  const handleSearch = (e) => {
    const searchTerm = e.toLowerCase();
    const performaInvoiceString = localStorage.getItem("savedProformaInvoices");

    if (!performaInvoiceString) {
      return;
    }

    const performaInvoice = JSON.parse(performaInvoiceString);

    const filteredPerformaInvoice = performaInvoice.filter(
      (performaInvoice) => {
        const performaInvoicetring =
          JSON.stringify(performaInvoice).toLowerCase();
        const searchWords = searchTerm.split(" ");
        return searchWords.every((word) => performaInvoicetring.includes(word));
      }
    );

    setProformaInvoices(filteredPerformaInvoice);
  };

  const toggleProductSelection = (event) => {
    if (event === "all") {
      if (selectAll === false) {
        // If "all" is selected or re-selected, add all objects to the array
        setSelectAll(true);
        setSelectPerforma(proformaInvoices);
      } else {
        // If "all" is deselected, set the array to an empty array
        setSelectAll(false);
        setSelectPerforma([]);
      }
    } else {
      // If a single checkbox is selected or deselected, add or remove it from the array
      const newSelectPerforma = [...selectPerforma];
      newSelectPerforma[event] = !newSelectPerforma[event];
      setSelectPerforma(newSelectPerforma);

      //  if selectPerforma the whole array is false set selectAll to false
      if (newSelectPerforma.every((item) => item === false)) {
        setSelectPerforma([]);
      }
    }
  };

  const HandleDelete = (index) => {
    const answer = window.confirm("Are you sure you want to delete this item?");
    if (!answer) {
      setSelectPerforma([]);
      return;
    }

    // remove selectPerforma from the proforma invoices
    const newPerformaInvoice = proformaInvoices.filter(
      (performaInvoice, i) => !selectPerforma[i]
    );

    // save the new array to local storage
    localStorage.setItem(
      "savedProformaInvoices",
      JSON.stringify([...newPerformaInvoice], {
        selectedResultCustomer: newPerformaInvoice.customerInfoText,
      })
    );

    // update the state
    setProformaInvoices(newPerformaInvoice);
    setSelectPerforma([]);
    setSelectAll(false);
  };

  const edit = (index) => {
    console.log("edit", index);

    // find the proforma invoice that matches the index
    const proformaInvoice = proformaInvoices.find((proformaInvoice, i) => {
      return i === index;
    });
    console.log(proformaInvoice);

    const draftProformaInvoiceEdit = {
      ...proformaInvoice,
      positionOfEdit: "Proforma Invoice",
    };

    // save the proforma invoice to local storage
    localStorage.setItem(
      "editProforma",
      JSON.stringify(draftProformaInvoiceEdit)
    );

    // // redirect to the invoice page
    // window.location.href = "/ProformaInvoice";
  };

  // Function to open the modal for a specific proforma invoice
  const openModal = (index) => {
    setOpenModalIndex(index);
  };

  // Function to close the modal
  const closeModal = () => {
    setOpenModalIndex(-1);
  };

  return (
    <div className="d-flex align-items-center justify-content-between">
      <main id="main" className="main">
        <div
          style={{
            marginTop: "1rem",
            // static position
            // top center of the page for a search
            position: "fixed",
            top: "10vh",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <h1 className="mt-4">Proforma Invoices</h1>
          {/* add a search  */}
          <input
            type="text"
            placeholder="Search...."
            className="search"
            style={{
              width: "18rem",
            }}
            value={search}
            onChange={(e) => {
              handleSearch(e.target.value);
              setSearch(e.target.value);
            }}
          />
        </div>
        <div>
          {/* more optoins for selected proformas */}
          {viewMoreAction && (
            <div
              className="selected-items-toolbar"
              style={{
                // fixed the position of the buttons
                position: "fixed",
                top: "10vh",
                left: "60",
                height: "50px",
                backgroundColor: "white",
                zIndex: "1",
              }}
            >
              <button
                style={{
                  padding: "0.2rem",
                  margin: "0.2rem",
                }}
                className="btn btn-danger"
                onClick={(e) => {
                  HandleDelete(e.target.value);
                }}
              >
                Delete
              </button>
              <button
                style={{
                  padding: "0.2rem",
                  margin: "0.2rem",
                }}
                onClick={() =>
                  console.log("Performing action on selected items: Print")
                }
              >
                Print
              </button>
              <button
                style={{
                  padding: "0.2rem",
                  margin: "0.2rem",
                }}
                onClick={() =>
                  console.log("Performing action on selected items: Email")
                }
              >
                Email
              </button>
              <button
                style={{
                  padding: "0.2rem",
                  margin: "0.2rem",
                }}
                onClick={() =>
                  console.log("Performing action on selected items: Download")
                }
              >
                Download
              </button>
            </div>
          )}
        </div>
        {/* add a table */}
        <table
          style={{
            marginTop: "14vh",
            fontSize: "1rem",
          }}
        >
          <thead>
            <tr>
              <th>Proforma Invoice Number</th>
              <th>Customer</th>
              <th>Proforma Invoice Date</th>
              <th>Due Date</th>
              <th>Amount</th>
              <th>Actions</th>
              <th>Status</th>
              <th>
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={selectAll}
                  onChange={() => toggleProductSelection("all")}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {proformaInvoices.map((proformaInvoice, index) => {
              return (
                <tr key={index}>
                  <td>{proformaInvoice.performaInvoiceNumber}</td>
                  <td>{proformaInvoice.customerInfoText?.companyName}</td>
                  <td>{proformaInvoice.date}</td>
                  <td>{proformaInvoice.expDate}</td>
                  <td>{proformaInvoice.total}</td>
                  <td
                    className="buttons"
                    style={{
                      display: "flex",
                    }}
                  >
                    <button
                      style={{
                        padding: "0.2rem",
                        margin: "0.2rem",
                      }}
                      onClick={() => openModal(index)}
                    >
                      review
                    </button>
                    {openModalIndex === index && (
                      <div className="modal">
                        <div className="modal-content">
                          <span className="close" onClick={closeModal}>
                            &times;
                          </span>
                          <Preview {...proformaInvoice} />
                        </div>
                      </div>
                    )}

                    <Link
                      // Disable the link if proformaInvoice.status === "Approved"
                      to={
                        proformaInvoice.status === "Approved"
                          ? "#"
                          : "/EditInvoices"
                      }
                      style={{
                        padding: "0.2rem",
                        margin: "0.2rem",
                        color: "white",
                        borderRadius: "5px",
                        textDecoration: "none",
                        cursor:
                          proformaInvoice.status === "Approved"
                            ? "not-allowed"
                            : "pointer",
                      }}
                      className="btn btn-primary"
                      onClick={() => {
                        if (proformaInvoice.status !== "Approved") {
                          console.log(
                            "Performing action on selected items: Edit"
                          );
                          edit(index);
                        }
                      }}
                    >
                      Edit
                    </Link>
                    <button
                      style={{
                        padding: "0.2rem",
                        margin: "0.2rem",
                        cursor:
                          proformaInvoice.status === "Approved"
                            ? "not-allowed"
                            : "pointer",
                      }}
                      className="btn btn-secondary"
                      onClick={() => {
                        if (proformaInvoice.status !== "Approved") {
                          console.log("convert to invoice Duplicated");
                        }
                      }}
                    >
                      finalise
                    </button>
                  </td>
                  <td>
                    <select
                      value={proformaInvoice.status}
                      onChange={(e) => changeStatus(index, e.target.value)}
                    >
                      <option value="waiting-for-aporvel">
                        Pending Acceptance
                      </option>
                      <option value="Pending-Approval">Pending Approval</option>
                      <option value="Approved">Approved</option>
                    </select>
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={selectPerforma[index]}
                      onChange={() => toggleProductSelection(index)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {/* add a button to add a new proforma invoice */}
        <Link to="/ProformaInvoice">
          <button
            style={{
              padding: "0.2rem",
              margin: "0.2rem",
            }}
            className="btn btn-primary"
          >
            Add New Proforma Invoice
          </button>
        </Link>
      </main>
    </div>
  );
};

export default ProformaInvoices;
