import React, { useState, useEffect } from "react";
import config from "./config/config";

import styels from "./PlaceAnOrder.module.css";

const PlaceAnOrder = () => {
  const [order, setOrder] = useState({
    id: "",
    companyName: "",
    contactName: "",
    email: "",
    phone: "",
    address: "",
    items: [],
    totalPrice: 0,
  });

  const [productList, setProductList] = useState([]);
  const [salersProducts, setSalersProducts] = useState([]);
  const [itemsInvoiced, setItemsInvoiced] = useState([]);
  const [itemstoOrder, setItemsToOrder] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const [boxes, setBoxes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchTheItemsByInvoices = async () => {
      try {
        const response = await fetch(`${config.URL_PROD}/invoices`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          const { invoices } = data;
          setItemsInvoiced(invoices);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchTheItemsByInvoices();
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${config.URL_PROD}/products`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        const data = await response.json();
        if (response.ok) {
          setProductList(data.products);
          // Calculate the number of boxes for each product but the quantity should be calculated by the invoices that are not invoiced
          const totalAmountOfEachProduct = data.products.map((product) => {
            const totalAmount = itemsInvoiced.reduce((acc, item) => {
              if (item.productId === product.id) {
                return acc + item.quantity;
              }
              return acc;
            }, 0);
            return {
              id: product.id,
              quantity: product.quantity - totalAmount,
              unitsInBox: product.unitsInBox,
            };
          });
          setItemsToOrder(totalAmountOfEachProduct);
          const boxes = totalAmountOfEachProduct.map((product) => ({
            id: product.id,
            numBoxes: Math.ceil(
              Math.abs(product.quantity) / Number(product.unitsInBox)
            ),
          }));
          setBoxes(boxes);
        }
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    const getgrandTotalofAllProductsPerSalerSelected = (products) => {
      const total = products.reduce((acc, product) => {
        // const numBoxes = boxes.find((box) => box.id === product.id)?.numBoxes  if undefined then 0;
        const numBoxes =
          boxes.find((box) => box.id === product.id)?.numBoxes || 0;
        const totalPrice =
          numBoxes * Number(product.buyPriceForBox)
            ? numBoxes * Number(product.buyPriceForBox) || 0
            : numBoxes *
                Number(product.buyPrice) *
                Number(product.unitsInBox) || 0;
        return acc + totalPrice;
      }, 0);

      setGrandTotal(total);
      return total;
    };

    getgrandTotalofAllProductsPerSalerSelected(salersProducts || productList);
  }, [salersProducts, productList, boxes]);

  useEffect(() => {
    calculateBoxes(itemsInvoiced || []);
  }, [itemsInvoiced, productList]);

  const handleSelectWholesaler = (e) => {
    const wholesaler = e.target.value;
    const products = productList.filter(
      (product) => product.manufacturer === wholesaler
    );
    const initializedItems = products.map((product) => ({
      id: product.id,
      quantity: Math.floor(Math.abs(product.quantity) / product.unitsInBox),
    }));
    setSalersProducts(products);
    setOrder({
      ...order,
      items: initializedItems,
      totalPrice: calculateTotalPrice(initializedItems),
    });
  };

  const handleCheckboxChange = (productId, checked) => {
    const updatedItems = order.items.map((item) => {
      if (item.id === productId) {
        return {
          ...item,
          quantity: checked
            ? Math.ceil(
                Math.abs(
                  productList.find((prod) => prod.id === productId).quantity
                ) / productList.find((prod) => prod.id === productId).unitsInBox
              )
            : 0,
        };
      }
      return item;
    });

    setOrder({
      ...order,
      items: updatedItems,
      totalPrice: calculateTotalPrice(updatedItems),
    });
  };

  const handleSelectAllChange = (e) => {
    const checked = e.target.checked;
    const updatedItems = checked
      ? salersProducts.map((product) => ({
          id: product.id,
          quantity: product.quantity,
        }))
      : [];

    setOrder({
      ...order,
      items: updatedItems,
      totalPrice: calculateTotalPrice(updatedItems),
    });
  };

  const handleQuantityChange = (e, productId) => {
    const quantity = Number(e.target.value);
    const updatedItems = order.items.map((item) => {
      if (item.id === productId) {
        return {
          ...item,
          quantity: Math.abs(quantity), // Ensure quantity is positive
        };
      }
      return item;
    });

    setOrder({
      ...order,
      items: updatedItems,
      totalPrice: calculateTotalPrice(updatedItems),
    });
  };

  const calculateBoxes = (itemsInvoiced) => {
    // calc the quantity ordered for each product take the quantity from the itemsInvoiced (array of invoices objects) and log it to the console
    const totalAmountOfEachProductfromInvoices = itemsInvoiced.map((item) => {
      // map through the items array in the itemsInvoiced array
      const items = item.items.map((item) => {
        const quantity = item.quantity;

        return {
          id: item.id,
          quantity: quantity,
        };
      });
      return items;
    });

    // calculate the array but on each id one amount
    const totalAmountOfEachProduct =
      totalAmountOfEachProductfromInvoices.reduce((acc, item) => {
        item.forEach((element) => {
          if (acc[element.id]) {
            acc[element.id] += element.quantity;
          } else {
            acc[element.id] = element.quantity;
          }
        });
        return acc;
      }, {});

    // now calc the boxes for each product totalAmountOfEachProduct is an object with the key as the id of the product and the value is the quantity ordered
    const boxes = Object.entries(totalAmountOfEachProduct).map(
      ([productId, quantity]) => {
        const product = productList.find(
          (prod) => prod.id === Number(productId)
        );
        return {
          id: Number(productId),
          numBoxes:
            Math.ceil(Math.abs(quantity) / Number(product?.unitsInBox)) || 0,
        };
      }
    );
    setBoxes(boxes);
  };

  const calculateTotalPrice = (items) => {
    return items.reduce((total, item) => {
      const product = salersProducts.find((prod) => prod.id === item.id);
      const numBoxes = item.quantity;
      const totalPrice =
        numBoxes * Number(product?.buyPrice) * Number(product?.unitsInBox);
      return total + totalPrice;
    }, 0);
  };

  return (
    <div className="d-flex align-items-center justify-content-between">
      <main id="main" className="main">
        <h1>Place an Order</h1>
        <form>
          <label htmlFor="wholesaler">Select Wholesaler:</label>
          <select
            name="wholesaler"
            id="wholesaler"
            onChange={handleSelectWholesaler}
          >
            {[
              "Please select wholesaler",
              ...new Set(productList.map((product) => product.manufacturer)),
            ].map((wholesaler) => (
              <option value={wholesaler} key={wholesaler}>
                {wholesaler}
              </option>
            ))}
          </select>
        </form>
        <label htmlFor="product">Select Product:</label>
        <input
          type="checkbox"
          name="selectAll"
          value="all"
          onChange={handleSelectAllChange}
        />{" "}
        Select All (all selected products will be filled to a full box)
        <ul className={styels.listOfProductses}>
          {salersProducts.map((product) => (
            <li key={product.id} className={styels.listOfProducts}>
              <input
                type="checkbox"
                name="product"
                value={product.id}
                checked={order.items.some((item) => item.id === product.id)}
                onChange={(e) =>
                  handleCheckboxChange(product.id, e.target.checked)
                }
              />
              {product.name}
              <input
                type="number"
                name="quantity"
                className={styels.priceInputField}
                value={
                  boxes.find((box) => box.id === product.id)?.numBoxes || 0
                }
                onChange={(e) => handleQuantityChange(e, product.id)}
              />
              {/* change the price fro calculated */}
              <label htmlFor="pricePerPiece">Buy Price per piece:</label>
              <input
                type="text"
                name="pricePerPiece"
                className={styels.priceInputField}
                onChange={(e) => {
                  const updatedProducts = salersProducts.map((prod) => {
                    if (prod.id === product.id) {
                      return {
                        ...prod,
                        buyPrice: e.target.value,
                      };
                    }
                    return prod;
                  });
                  setSalersProducts(updatedProducts);
                }}
                value={Number(product.buyPrice).toFixed(2)}
              />
              {/* buy price for a box */}
              <label htmlFor="buyPriceForBox">Price Per Box</label>
              <input
                type="number"
                name="buyPriceForBox"
                className={styels.priceInputField}
                value={Number(product.buyPriceForBox).toFixed(2) || "unknown"}
              />
              <label htmlFor="price">Total Price:</label>
              <input
                type="text"
                name="buyPriceForBox"
                className={styels.priceInputField}
                value={
                  Number(product.buyPriceForBox)
                    ? (
                        Number(product.buyPriceForBox) *
                          boxes.find((box) => box.id === product.id)
                            ?.numBoxes || 0
                      ).toFixed(2)
                    : (
                        Number(product.buyPrice) *
                          Number(product.unitsInBox) *
                          boxes.find((box) => box.id === product.id)
                            ?.numBoxes || 0
                      ).toFixed(2)
                }
                onChange={(e) => {
                  const updatedProducts = salersProducts.map((prod) => {
                    if (prod.id === product.id) {
                      return {
                        ...prod,
                        buyPriceForBox: e.target.value,
                      };
                    }
                    return prod;
                  });
                  setSalersProducts(updatedProducts);
                }}
              />
            </li>
          ))}
        </ul>
        {/* caculate the total of all products in the list totals */}
        <label htmlFor="totalPrice">Total Price:</label>
        <input
          type="text"
          name="totalPrice"
          className={styels.priceInputField}
          value={grandTotal.toFixed(2)}
          readOnly
        />
      </main>
    </div>
  );
};

export default PlaceAnOrder;
