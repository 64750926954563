import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";

import styles from "./ActiveOrders.module.css";

import style from "./fileUpload.module.css";

const ImportInvoicesExcelTable = () => {
  if (!localStorage.getItem("token")) {
    window.location.href = "/login";
  }
  const navigate = useNavigate();
  const [commentCopied, setCommentCopied] = useState(false);

  const copyCommentToClipboard = () => {
    const commentText = `Invoice Number	Date Client Number	Discount	Total	status	items names\n`;

    navigator.clipboard.writeText(commentText).then(() => {
      setCommentCopied(true);
      setTimeout(() => setCommentCopied(false), 3000); // Reset copied state after 3 seconds to make the message disappear
    });
  };

  // Get the products from local storage
  const products = JSON.parse(localStorage.getItem("products")) || [];

  const handleOnImportFromExcel = (e) => {
    const fileInput = document.getElementById("file-imported");
    const file = fileInput.files[0];

    if (!file) return;

    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const rawData = XLSX.utils.sheet_to_json(worksheet);

      // Now, you can process the rawData to create invoices
      const invoices = rawData.map((data) => {
        // Extract relevant information from the data object
        const invoiceNumber =
          data["Invoice Number"] ||
          data["Proforma Number"] ||
          data["ID"] ||
          data["id"];
        const date = data["Date"];
        const clientNumber = data["Client Number"];
        const discount = parseFloat(data["Discount"]) || 0; // Parse discount as a float
        const Total = parseFloat(data["Total"]) || 0; // Parse total as a float
        const status = data["status"] || "Pending Fullfilment";

        // Extract item details
        const items = Object.keys(data)
          .filter(
            (key) =>
              ![
                "Invoice Number",
                "Date",
                "Client Number",
                "name",
                "Discount",
                "Total",
              ].includes(key)
          )
          .map((key) => ({
            name: key,
            quantity: data[key],
          }));
        // find the discount from the contact if not find then check the product
        const contact = JSON.parse(localStorage.getItem("contacts")) || [];
        let contactDiscountGeneral = 0;
        let contactName = "";
        let contactDiscount = contact.find(
          (contact) => contact.clientNumber === clientNumber
        );
        if (contactDiscount) {
          contactName = contactDiscount.companyName;
          contactDiscountGeneral = contactDiscount.discount;
        }

        items.forEach((item) => {
          const matchingProduct = products.find(
            (product) =>
              product.name === item.name || product.description === item.name
          );

          if (matchingProduct) {
            item.id = matchingProduct.id;
            item.discount =
              contactDiscountGeneral || matchingProduct.discount || 0;
            item.sellPrice = matchingProduct.sellPrice;
          }
        });

        //order items qnty and price discount then calculate the total
        // Calculate the total for each item and accumulate for overall total
        let invoiceTotal = 0;
        const testItems = items.map((item) => {
          item.quantity = item.quantity || 0;
          item.discount = item.discount || 0;
          item.sellPrice = item.sellPrice || 0;

          // Calculate the total for each item
          item.total = parseFloat(
            (item.quantity * item.price * (100 - item.discount)) / 100
          ).toFixed(2);

          // Accumulate the total for the invoice\
          invoiceTotal += parseFloat(item.total);

          return item; // Return the modified item
        });

        // remove the item qnty from the catalog if item exist in the catalog if not then add the item to the catalog with qnty - qnty and updet the local storage
        // Update the product quantity in the local storage
        const updatedProducts = products.map((product) => {
          const matchingItem = testItems.find((item) => item.id === product.id);
          if (matchingItem) {
            product.quantity -= matchingItem.quantity;
          }
          return product;
        });
        localStorage.setItem("products", JSON.stringify(updatedProducts));

        // Create the invoice object
        const invoice = {
          id: invoiceNumber,
          date: date,
          selectedResultCustomer: {
            clientNumber: clientNumber,
            companyName: contactName || data.name,
          },
          subTotal: parseFloat(invoiceTotal + discount).toFixed(2),
          discountedPriceTotal: discount || 0,
          totalAmount: invoiceTotal.toFixed(2), // Use the accumulated total amount
          items: testItems,
          status: status,
        };
        return invoice;
      });
      console.log(invoices);

      // Update local storage
      const invoicesFromLocalStorage =
        JSON.parse(localStorage.getItem("invoicesDrafts")) || [];
      const updatedInvoices = [...invoicesFromLocalStorage, ...invoices];
      localStorage.setItem("invoicesDrafts", JSON.stringify(updatedInvoices));

      // Clear the input field after processing the file
      fileInput.value = "";
    };

    reader.readAsBinaryString(file);

    // redirect to the invoices page
    window.location.href = "/InvoicesDrafts";
  };

  return (
    <div
      style={{
        justifyContent: "space-around",
        marginTop: "8rem",
        marginLeft: "22rem",
        marginRight: "2rem",
      }}
    >
      {/* add the info that the colunms heads should contain */}
      <h1>Import Invoices Excel Table</h1>

      <b>The excel table should contain the following columns:</b>
      <br />
      <ul>
        <li>Invoice Number</li>
        <li>Date</li>
        <li>Client Number</li>
        <li>Discount</li>
        <li>Total</li>
        <li>status</li>
        <li>items names</li>
        <li>
          And the items names should be the same as the items names in the
          catalog,
        </li>
        <li>
          Also under the items names should be the qnty of the items in the
          invoice if there is no qnty then leave it empty
        </li>
      </ul>
      <button
        style={{
          // center of the page
          position: "absolute",
          left: "80%",
          top: "30%",
          transform: "translate(-50%, -50%)",
        }}
      >
        {/* upload file */}
        <input
          onChange={handleOnImportFromExcel}
          type="File"
          id="file-imported"
          className={style.uploadExcelFile}
        />
      </button>
      <br />
      <br />

      <h4>
        <b>Copy the following Header to the Excel file top row:</b>
      </h4>
      <p>items names Should be replaced with the ectual items names</p>
      <button
        onClick={copyCommentToClipboard}
        style={{
          backgroundColor: commentCopied ? "green" : "blue",
          color: "white",
        }}
      >
        Copy Header
      </button>
      {commentCopied && <p>Header copied to clipboard!</p>}
    </div>
  );
};

export default ImportInvoicesExcelTable;
