// PerformaInvoiceHeader.js
import React from "react";

function PerformaInvoiceHeader() {
  return (
    <div className="ProformaInvoice">
      <h1>Proforma Invoice</h1>
    </div>
  );
}

export default PerformaInvoiceHeader;
