import React from 'react';

import styles from './CreatePackagingList.module.css';

const ConfirmACreatePackageList = ({ createPackageListByProduct, createPackageListByCustomer }) => {
  return (
    <div className={styles.ConfirmACreatePackageListContainer}>
      <h6 className={styles.ConfirmACreatePackageListHeader}>Confirm a Create Package List</h6>
      <button className={styles.ConfirmACreatePackageListBtnPrdct} onClick={createPackageListByProduct}>by Product</button>
      <button onClick={createPackageListByCustomer}>by Customer</button>
    </div>
  );
};

export default ConfirmACreatePackageList;