import React, { useState, useEffect } from "react";

import CheckForToken from "../checkForToken";
const AddLogo = () => {
  CheckForToken();
  const [logo, setLogo] = useState("");
  const [showNotification, setShowNotification] = useState(false);

  const handleImageChange = () => {
    const fileInput = document.getElementById("fileInput");
    const image = fileInput.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onloadend = () => {
      setLogo(reader.result);
    };
  };

  const handleSaveLogo = () => {
    localStorage.setItem("companyLogo", logo);
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 500); // Hide notification after 3 seconds
  };

  useEffect(() => {
    // Check if there is a logo in local storage
    const storedLogo = localStorage.getItem("companyLogo");
    if (storedLogo) {
      setLogo(storedLogo);
    }
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "50vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "50%",
          margin: "0 auto",
        }}
      >
        <h1>Add Logo</h1>
        <div
          style={{
            width: "20%",
            margin: "0 auto",
            marginTop: "20px",
          }}
        >
          <input type="file" id="fileInput" onChange={handleImageChange} />

          {logo !== "" && (
            <div>
              <img
                src={logo}
                alt="Logo"
                style={{
                  width: "200px",
                  height: "200px",
                  objectFit: "contain",
                }}
              />
            </div>
          )}

          <button
            onClick={handleSaveLogo}
            style={{ marginTop: "20px", width: "100%" }}
          >
            Save Logo
          </button>

          {showNotification && (
            <div
              style={{
                marginTop: "20px",
                color: "green",
                textAlign: "center",
                // Responsive style based on device size
                position: "fixed",
                top: "14rem",
                // right: "52rem", make it center
                left: "50%",
                transform: "translate(-30%, -50%)",
                background: "#4154f1",
                color: "#fff",
                padding: "15px",
                borderRadius: "5px",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
              }}
            >
              Logo Saved Successfully!
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddLogo;
