import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CreateInvoice from "./CreateInvoice";
import CheckForToken from "../checkForToken";

import config from "./config/config";

export default function EditInvoice() {
  CheckForToken();
  // Get the index from the URL parameters
  const { id } = useParams();

  console.log("id", id);

  // State to manage the invoice data
  const [invoiceToEdit, setInvoiceToEdit] = useState(null); // Initialize as null
  const [invoiceDraftData, setInvoiceDraftData] = useState(null); // Initialize as null
  const [invoices, setInvoices] = useState([]); // Initialize as empty array
  const getInvoices = async () => {
    try {
      const response = await fetch(`${config.URL_PROD}/invoices`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const { invoices } = data;
        setInvoices(invoices || []);

        const invoiceEditData = invoices.filter((invoice) => invoice.id === id);

        console.log("invoiceEditData", invoiceEditData);

        if (invoiceEditData) {
          setInvoiceToEdit(...invoiceEditData);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getInvoices();
  }, []);

  // Fetch the invoice data based on the index
  useEffect(() => {
    const draftInvoices =
      JSON.parse(localStorage.getItem("invoicesDrafts")) || [];
    // console.log("invoices", invoices);

    const invoiceDraftData = draftInvoices.find(
      (invoice) => invoice.id === parseInt(id)
    );

    if (invoiceDraftData) {
      setInvoiceDraftData(invoiceDraftData);
      return;
    }
  }, [id]);

  if (!invoiceToEdit) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {/* Edit form fields here, assuming you have form fields within CreateInvoice */}
      <CreateInvoice
        invoiceToEdit={invoiceToEdit}
        invoiceDraftData={invoiceDraftData}
      />
    </>
  );
}
