import jsPDF from "jspdf";
import "jspdf-autotable";
import base64Rubic from "../components/base64Rubic";

const generatePDFPackingListByCustomer = (groupedByCompany, productList) => {
  const pdfsize = localStorage.getItem("pdfSize") || "a4";
  const doc = new jsPDF("p", "pt", pdfsize);

  console.log("groupedByCompany", groupedByCompany);

  Object.entries(groupedByCompany).forEach(([companyName, items], index) => {
    const companyID = items[0].companyID;
    const companyPerferdHeaderBackgroundColor = localStorage.getItem(
      "tableHeaderBackgroundColor"
    );

    const showClientNumberInsteadOfName =
      localStorage.getItem("showIdInsteadOfName") === "true";

    const productDataAllMatched = items.map((item) => {
      return productList.find((product) => product.id === item.id);
    });

    const quantityByBoxOrSingleArray = items.map((item, index) => {
      const productData = productDataAllMatched[index];
      if (productData && !isNaN(productData.unitsInBox)) {
        let boxQuantity = Math.floor(item.quantity / productData.unitsInBox);
        if (isNaN(boxQuantity) || boxQuantity === Infinity) {
          boxQuantity = 0;
        }
        let singleQuantity = item.quantity % productData.unitsInBox;
        if (isNaN(singleQuantity) || singleQuantity === Infinity) {
          singleQuantity = 0;
        }
        return {
          quantity: item.quantity,
          singleQuantity: singleQuantity > 0 ? singleQuantity : "",
          boxQuantity: boxQuantity > 0 ? boxQuantity : "",
        };
      } else {
        return {
          quantity: item.quantity,
          boxQuantity: "",
          singleQuantity: item.quantity,
        };
      }
    });

    const columns = [
      "ID",
      "Name",
      "Description",
      "Singles",
      "Boxes",
      // "Quantity",
    ];
    const rows = items.map((item, rowIndex) => [
      item.id,
      item.itemName,
      item.description,
      quantityByBoxOrSingleArray[rowIndex].singleQuantity,
      quantityByBoxOrSingleArray[rowIndex].boxQuantity,
      // item.quantity,
    ]);

    const isYiddish = (text) => {
      return /[א-ת]/.test(text);
    };
    let reversedCompanyName;
    if (!showClientNumberInsteadOfName) {
      if (isYiddish(companyName)) {
        // Add the custom font to the PDF
        doc.addFileToVFS("Rubik", base64Rubic);
        doc.addFont("Rubik", "Rubik", "normal");

        // Set the font for the document
        doc.setFont("Rubik");
        // reverse the string
        reversedCompanyName = companyName.split("").reverse().join("");
      } else {
        reversedCompanyName = null;
        doc.setFont("helvetica");
      }
    }
    if (showClientNumberInsteadOfName) {
      reversedCompanyName = companyID;
    }

    // page dimensions
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    // cell dimensions
    const cellWidth = pageWidth / 4;
    const cellHeight = 20;

    // Calculate the center of the page
    const centerX = pageWidth / 2;
    const centerY = pageHeight / 2;

    // Adjust the startY value to add margin from the top of the table
    let marginFromTop = 30;

    doc.setFontSize(20); // Use center coordinates for text
    doc.text(`${reversedCompanyName}`, centerX, marginFromTop, {
      align: "center",
    });

    doc.setFontSize(12);

    const hexToRgb = (hex, lighter) => {
      // Remove the '#' if it's present
      hex = hex.replace("#", "");

      // Extract the individual components
      let r = parseInt(hex.substring(0, 2), 16);
      let g = parseInt(hex.substring(2, 4), 16);
      let b = parseInt(hex.substring(4, 6), 16);

      // Increase the values for a lighter color by the lighter value
      r += lighter;
      g += lighter;
      b += lighter;

      // Ensure values stay within 0-255 range
      r = Math.min(Math.max(0, r), 255);
      g = Math.min(Math.max(0, g), 255);
      b = Math.min(Math.max(0, b), 255);

      // Return the new color as a valid string
      return `rgb(${r}, ${g}, ${b})`;
    };

    // companyPerferdHeaderBackgroundColor = #1ea10c make the rows color a bit lighter
    const lighterColor = hexToRgb(companyPerferdHeaderBackgroundColor, 180); // 50 is the lighter value

    marginFromTop += 20;
    doc.autoTable({
      styles: {
        fontStyle: "normal",
        fontSize: 14,
        overflow: "ellipsize",
        // col width dynamically
        cellWidth: "wrap",
        // halign: "center",
        // valign: "middle",
      },
      headStyles: { fillColor: [companyPerferdHeaderBackgroundColor] },
      head: [columns],
      body: rows,
      startY: marginFromTop,
      alternateRowStyles: {
        fillColor: [lighterColor, "rgb(255, 255, 255)"],
      },
      startX: 50,
      /* theme: "striped", */
      theme: "grid",
    });

    // Add a new page for the next company (if any)
    if (index < Object.keys(groupedByCompany).length - 1) {
      doc.addPage();
    }
  });

  // print using bloburl
  const blobURL = doc.output("bloburl");
  window.open(blobURL, "_blank");
};

export default generatePDFPackingListByCustomer;
