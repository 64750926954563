import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import config from "./config/config";

import styles from "./fileUpload.module.css";

const ImportProductsFromExcel = () => {
  if (!localStorage.getItem("token")) {
    window.location.href = "/login";
  }
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [commentCopied, setCommentCopied] = useState(false);

  const copyCommentToClipboard = () => {
    /* excel colunm Barcode       Product Name       Description       Buy Price       Sell Price       Quantity   Wolesaler\n*/
    const commentText = `Barcode	Product Name	Description	Buy Price	Sell Price	Sale Price	Quantity	Supplier	Price Per Unit Or Box	Units In Box/Case	Price Per X Amount\n`;

    navigator.clipboard.writeText(commentText).then(() => {
      setCommentCopied(true);
      setTimeout(() => setCommentCopied(false), 3000); // Reset copied state after 3 seconds to make the message disappear
    });
  };

  useEffect(() => {
    if (data) {
      // Do something with the data
    }
    if (!loading) {
      setLoading(false);
    }
  }, [data, loading]);

  const handleFileChange = (e) => {
    // check if the file is an Excel file (.xls or .xlsx) and alert if not
    const fileType = e.target.files[0].type || "";
    if (
      fileType !== "application/vnd.ms-excel" &&
      fileType !==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      alert("Please upload an Excel file.");
      //  clear the input field
      e.target.value = null;
      return;
    }

    const selectedFile = e.target.files[0];

    if (selectedFile) {
      // add a loading indicator
      setLoading(true);
      setFile(selectedFile);
      readExcel(selectedFile, e);
    }
  };

  const readExcel = (file, e) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const binaryData = e.target.result;
      const workbook = XLSX.read(new Uint8Array(binaryData), { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      // Assuming the first row in the Excel sheet contains headers
      const headers = jsonData[0];

      // Assuming each row after the header corresponds to a product
      const products = jsonData.slice(1).map((row) => {
        if (
          row.every(
            (cell) => cell === undefined || cell === null || cell === ""
          )
        ) {
          return null; // Skip empty rows
        }
        const product = {};
        headers.forEach((header, index) => {
          product[header] = row[index];
        });

        // get the sale price and convert it to a number
        const salePrice = product["Sale Price"] || product.salePrice || "";
        // replace comma with dot if exists else return the number
        const parsedSalePrice =
          typeof salePrice === "string" && salePrice.includes(",")
            ? salePrice.replace(",", ".")
            : salePrice;
        // get the sell price and convert it to a number
        const sellPrice = product["Sell Price"] || product.sellPrice || "";
        const parsedSellPrice = sellPrice;
        // calculate the percentageDiscount
        const percentageDiscount =
          parsedSalePrice && parsedSalePrice > 0
            ? Number((1 - parsedSalePrice / parsedSellPrice) * 100).toFixed(2)
            : 0;

        const newProduct = {
          id:
            product["Product Code"] ||
            product.Barcode ||
            product.Product ||
            product["ID"] ||
            product["id"],
          name: product.productName || product["Product Name"],
          description:
            product.description ||
            product.Discription ||
            product.Description ||
            "",
          transportCode:
            product.transportCode ||
            product.TransportCode ||
            product["Transport Code"] ||
            "",
          image:
            product.image ||
            product.imageURL ||
            product.Images ||
            product.Image,
          manufacturer:
            product.wholesaler ||
            product["Supplier"] ||
            product["Wholesaler Name"],
          buyPrice:
            product.buyPrice ||
            product["Price/ Case INC VAT"] ||
            product["Buy Price"],
          sellPrice:
            product.sellPrice ||
            product["Sugg retail price"] ||
            product["Sell Price"],
          salePrice: parsedSalePrice || product["Sale Price"] || "",
          quantity:
            product.quantity ||
            product.Qty ||
            product.QTY ||
            product.Quantity ||
            0, // default to 0 if not provided
          btw: product.btw || product.BTW || "",
          category: product.category || product.Category || "",
          papular: 0,
          // Calculate and add the percentageDiscount field
          // parsedSalePrice  is not NaN then calculate the percentageDiscount
          percentageDiscount:
            parsedSalePrice && parsedSalePrice > 0 ? percentageDiscount : 0,
          pricePerUnitOrBox:
            product["Price Per Unit Or Box"] === "unit" ||
            product["Price Per Unit Or Box"] === "Unit" ||
            product.pricePerUnitOrBox === "pricePerUnit" ||
            product.pricePerUnitOrBox === "unit" ||
            product.pricePerUnitOrBox === "Unit" ||
            product.pricePerUnitOrBox === "units"
              ? "unit"
              : product.pricePerUnitOrBox === "PricePerOther" ||
                product.pricePerUnitOrBox === "pricePerOther" ||
                product["Price Per Unit Or Box"] === "other" ||
                product["Price Per Unit Or Box"] === "Other" ||
                product.pricePerUnitOrBox === "other" ||
                product.pricePerUnitOrBox === "Other"
              ? "other"
              : "pricePerBox",
          unitsInBox:
            product.unitsInBox ||
            product.unitsInCase ||
            product["Units In Box/Case"],
          pricePerXAmount:
            product.pricePerXAmount || product["Price Per X Amount"],
        };

        // Save the newProduct to local storage
        const products = JSON.parse(localStorage.getItem("products")) || [];
        // check if the product already exists if confirm alert to updtae the product with the new data else skip the product
        const existingProduct = products.find(
          (product) => product.id === newProduct.id
        );
        if (existingProduct) {
          const confirmUpdate = window.confirm(
            `Product with id ${newProduct.id} already exists. Do you want to update it?`
          );
          if (confirmUpdate) {
            const updatedProducts = products.map((product) => {
              if (product.id === newProduct.id) {
                return newProduct;
              }
              return product;
            });
          }
          // if the user does not want to update the product, skip the product
          return;
        }
        products.push(newProduct);
        /* localStorage.setItem("products", JSON.stringify(products)); */
        // console.log("newProduct", newProduct);

        const uniqueProducts = products.filter(
          (product, index, self) =>
            index ===
            self.findIndex(
              (t) => t.id === product.id && t.name === product.name
            )
        );

        // save the newProduct to the database
        fetch(`${config.URL_PROD}/products`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(uniqueProducts),
        });

        return newProduct;
      });
    };
    // file input clear after file read
    e.target.value = null;

    setLoading(false);

    reader.readAsArrayBuffer(file);
    setTimeout(() => {
      if (file) {
        navigate("/Catalog");
      } else if (!data) {
        alert("Please upload an Excel file.");
        //  clear the input field
        e.target.value = null;
        return;
      }
    }, 400);
  };

  return (
    <div
      style={{
        justifyContent: "space-around",
        marginTop: "8rem",
        marginLeft: "22rem",
        marginRight: "2rem",
      }}
    >
      <h1>Import Products From Excel</h1>
      <br />
      <h4>
        <b>Make sure the Excel file has the following columnsNamed AS: </b>
      </h4>
      <br />
      <br />
      {/* danger msg that it will change the quantity as well */}
      <h4>
        <b>
          Note:{" "}
          <span
            style={{
              color: "red",
            }}
          >
            {" "}
            If you change the quantity of a product, it will change the quantity
            of the product in the database as well.
          </span>
        </b>
      </h4>
      <br />
      <ul>
        <li>Product Code OR Barcode</li>
        <li>Product Name OR productName</li>
        <li>Description OR description</li>
        <li>Transport Code OR TransportCode OR transportCode</li>
        <li>Image OR image</li>
        <li>Supplier</li>
        <li>Buy Price OR Price/ Case INC VAT OR buyPrice</li>
        <li>Sell Price OR sellPrice OR Sugg retail price</li>
        <li>Sale Price</li>
        <li>Quantity OR quantity OR qty OR QTY</li>
        <li>BTW OR btw</li>
        <li>Category OR category</li>
        <li>Price Per Unit Or Box</li>
        <li>Units In Box / Case OR unitsInBox</li>
        <li>Price Per X Amount OR pricePerXAmount</li>
      </ul>
      <button
        style={{
          // center of the page
          position: "absolute",
          left: "80%",
          top: "30%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <input
          type="file"
          onChange={(e) => {
            handleFileChange(e);
          }}
          className={styles.uploadExcelFile}
        />
      </button>

      <br />
      <br />

      <h4>
        <b>Copy the following Header to the Excel file top row:</b>
      </h4>
      <button
        onClick={copyCommentToClipboard}
        style={{
          backgroundColor: commentCopied ? "green" : "blue",
          color: "white",
        }}
      >
        Copy Header
      </button>
      {commentCopied && <p>Header copied to clipboard!</p>}

      {loading && <h3>Loading...</h3>}
    </div>
  );
};

export default ImportProductsFromExcel;
