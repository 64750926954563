import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import AddCustomer from "./AddCustomer";
import CheckForToken from "../checkForToken";
import styles from "./AddCustomer.module.css";
import config from "./config/config";

export default function EditCustomer() {
  CheckForToken();
  // Get the index from the URL parameters
  const { index } = useParams();
  console.log("Index", index);

  // State to manage the contact data
  const [contact, setContact] = useState(null); // Initialize as null

  // Fetch the contact data based on the index
  useEffect(() => {
    console.log("Fetching contact data...", index);
    // get the contact data from the server
    fetch(`${config.URL_PROD}/contacts/${index}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Data", data);
        setContact(data);
      });
  }, [index]);

  useEffect(() => {
    // console.log("Updated Contact:", contact);
  }, [contact]);

  // Conditional rendering while waiting for data
  if (contact === null) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {/* Edit form fields here, assuming you have form fields within AddCustomer */}
      <AddCustomer contact={contact} />
    </>
  );
}
