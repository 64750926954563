import React, { useState } from "react";
import CheckForToken from "../../checkForToken";

const AlertNoQnty = () => {
  CheckForToken();

  const [alertNoQnty, setAlertNoQnty] = useState(
    localStorage.getItem("alertNoQnty")
      ? localStorage.getItem("alertNoQnty") === "true"
        ? "ALERT"
        : "HIDDEN"
      : "ALERT"
  );

  const handleAlertNoQntyChange = () => {
    const alertNoQnty = document.getElementById("alertNoQnty").checked;
    localStorage.setItem("alertNoQnty", alertNoQnty);
    setAlertNoQnty(alertNoQnty ? "ALERT" : "HIDDEN");
  };

  return (
    <div>
      <h3>Alert on Invoice</h3>
      <div>
        <input
          type="checkbox"
          id="alertNoQnty"
          defaultChecked={alertNoQnty === "ALERT"}
          onChange={handleAlertNoQntyChange}
        />{" "}
        <label htmlFor="alertNoQnty">
          Alert on Invoice is set to <strong>{`${alertNoQnty}`}</strong>
        </label>
      </div>
    </div>
  );
};

export default AlertNoQnty;
