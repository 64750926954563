import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import SearchResults from "./SearchResults";
import SearchNoResults from "./SearchNoResults";
import InvoiceTable from "./invoiceTable";
import "./invoice.css";

import config from "./config/config";

function Invoice({
  invoiceNumber,
  invoiceToEdit,
  invoiceToDuplicate,
  invoiceDraftData,
}) {
  const initialInvoicestate = {
    // default values
    proformaNumber: invoiceNumber,
    companyDetails: {},
    selectedResultCustomer: {},
    items: [],
    subTotal: 0,
    totalAmount: 0,
    discountedPriceTotal: 0,
    btw: 0,
    date: new Date().toISOString().split("T")[0],
    expirationDate: new Date(new Date().setDate(new Date().getDate() + 30))
      .toISOString()
      .split("T")[0],
    remarks: "",
    internalCommand: "",
    selectedCurrency: "eur",
  };
  const proformaNumber = invoiceNumber;
  const [invoice, setInvoice] = useState(
    invoiceDraftData ||
      invoiceToEdit ||
      invoiceToDuplicate ||
      initialInvoicestate
  );
  // console.log("invoice", invoice);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [remarks, setRemarks] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [isSearchFocusedCustomer, setIsSearchFocusedCustomer] = useState(false);
  const [selectedResultCustomer, setSelectedResultCustomer] = useState({});
  const [total, setTotal] = useState(0);
  const [itemsOnInvoice, setItemsOnInvoice] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [taxRate, setTaxRate] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [taxAmount, setTaxAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  // round to the nearest 5 cents
  const totalAmountRound = Math.round(totalAmount * 20) / 20;
  const [discountedPriceTotal, setDiscountedPriceTotal] = useState(0);

  const [selectedCurrency, setSelectedCurrency] = useState("eur");
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [expirationDate, setExpirationDate] = useState(
    new Date(new Date().setDate(new Date().getDate() + 30))
      .toISOString()
      .split("T")[0]
  );
  const [customerinfo, setCustomerinfo] = useState({});
  const [customerAdded, setCustomerAdded] = useState(false);
  const [internolCommand, setInternolCommand] = useState("");

  useEffect(() => {
    const companyDetails = JSON.parse(localStorage.getItem("companyDetails"));
    localStorage.setItem(
      "invoice",
      JSON.stringify({
        proformaNumber:
          invoiceToEdit?.id || invoiceDraftData?.id || invoiceNumber,
        companyDetails: companyDetails,
        selectedResultCustomer: selectedResultCustomer,
        items: itemsOnInvoice,
        subTotal: subTotal,
        totalAmount: totalAmount,
        discountedPriceTotal: discountedPriceTotal,
        btw: taxAmount,
        date: date || invoice.date || new Date().toISOString().split("T")[0],
        expirationDate: expirationDate || invoice.expirationDate,
        remarks,
        internolCommand,
        selectedCurrency: invoice.selectedCurrency,
      })
    );
  }, [
    remarks,
    internolCommand,
    selectedCurrency,
    itemsOnInvoice,
    selectedResultCustomer,
    date,
    expirationDate,
  ]);

  // const customers = JSON.parse(localStorage.getItem("contacts") || "[]");
  // get the data from the server
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchCustomers = useCallback(async () => {
    try {
      const response = await fetch(`${config.URL_PROD}/contacts`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (!response.ok) {
        throw new Error("Something went wrong!");
      }
      const data = await response.json();
      setCustomers(data);
    } catch (error) {
      setError(error.message);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchCustomers();
  }, []);

  useEffect(() => {
    const loadInvoiceData = (data) => {
      setSearchInput("");
      setCustomerinfo({});
      setRemarks("");
      setInternolCommand("");
      setDate("");
      setExpirationDate("");
      setSelectedCurrency("");
      setSelectedResultCustomer(null);
      setInvoice({
        selectedResultCustomer: null,
        remarks: "",
        internolCommand: "",
        date: "",
        expirationDate: "",
        selectedCurrency: "",
      });
      setItemsOnInvoice([]);
      setSubTotal(0);
      setTotalAmount(0);
      setDiscountedPriceTotal(0);
      setTaxAmount(0);

      if (data) {
        setSearchInput(getCustomerInfoText(data.selectedResultCustomer));
        setCustomerinfo({
          name: data.selectedResultCustomer?.name,
          btw: getFormattedBtw(data.selectedResultCustomer),
          email: data.selectedResultCustomer?.email,
        });
        setRemarks(data.remarks);
        setInternolCommand(data.internolCommand);
        setDate(data.date);
        setExpirationDate(data.expDate);
        setSelectedCurrency(data.selectedCurrency);
        setSelectedResultCustomer(data.selectedResultCustomer);
        setInvoice({
          selectedResultCustomer: data.selectedResultCustomer,
          remarks: data.remarks,
          internolCommand: data.internolCommand,
          date: data.date,
          expirationDate: data.expDate,
          selectedCurrency: data.selectedCurrency,
        });
        setItemsOnInvoice(data.items || []);
        setSubTotal(parseFloat(data.subTotal) || 0);
        setTotalAmount(parseFloat(data.totalAmount) || 0);
        setDiscountedPriceTotal(parseFloat(data.discountedPriceTotal) || 0);
        setTaxAmount(parseFloat(data.btw) || 0);
        // set costumerAdded to true if there is a customer
        if (data.selectedResultCustomer) {
          setCustomerAdded(true);
          localStorage.setItem("costumerAdded", "true");
        }
      }
    };

    const getCustomerInfoText = (customer) => {
      if (!customer) return "";

      return (
        `Name: ${customer.name}\n` +
        `${customer.btwNumber ? `BTW: ${customer.btwNumber}\n` : ""}` +
        `${customer.email ? `Email: ${customer.email}\n` : ""}` +
        `${customer.phone ? `Phone: ${customer.phone}\n` : ""}` +
        `${
          customer.clientNumber ? `Client Number: ${customer.clientNumber}` : ""
        }`
      );
    };

    const getFormattedBtw = (customer) => {
      if (!customer) return 0;

      return customer.countryCode + parseFloat(customer.btwAmount || 0);
    };

    if (invoiceToEdit) {
      loadInvoiceData(invoiceToEdit);
    } else if (invoiceToDuplicate) {
      loadInvoiceData(invoiceToDuplicate);
    } else if (invoiceDraftData) {
      loadInvoiceData(invoiceDraftData);
    } else {
      const editFromStorage = JSON.parse(localStorage.getItem("editProforma"));
      if (editFromStorage) {
        loadInvoiceData(editFromStorage);
      }
    }
  }, [invoiceNumber, invoiceToEdit, invoiceToDuplicate, invoiceDraftData]);

  const handleResultSelection = (contact) => {
    setSelectedResultCustomer((prevResult) => ({
      ...prevResult,
      ...contact,
      discount: Number(contact.discount) || 0,
      tax: contact.btwAmount || 0,
    }));
    setCustomerAdded(true);
    localStorage.setItem("costumerAdded", "true");
    setInvoice({
      ...invoice,
      customerinfo: contact,
    });
    localStorage.setItem(
      "invoice",
      JSON.stringify({
        proformaNumber: invoiceNumber,
        selectedResultCustomer: selectedResultCustomer,
        items: itemsOnInvoice,
        subTotal: subTotal,
        totalAmount: totalAmount,
        discountedPriceTotal: discountedPriceTotal,
        btw: taxAmount,
        date,
        expirationDate,
        remarks,
        internolCommand,
        selectedCurrency,
      })
    );
    // Set a default value of 0 for btwAmout if it's undefined
    setTaxRate(contact.btwAmount || 0);
    setDiscount(contact.discount || 0);
    setIsSearchFocusedCustomer(false);
    setCustomerinfo({
      name: contact.name,
      btw: contact.countryCode + parseFloat(contact.btwAmount),
      email: contact.email,
    });
    setSearchInput(
      [
        contact.name && `Name: ${contact.name}`,
        contact.btwNumber && `BTW: ${contact.btwNumber}`,
        contact.email && `Email: ${contact.email}`,
        contact.phone && `Phone: ${contact.phone}`,
        contact.clientNumber && `Client Number: ${contact.clientNumber}`,
      ]
        .filter(Boolean) // Remove falsy values (undefined or empty strings)
        .join("\n")
    );
  };

  const handleSearch = (event) => {
    const searchTerm = event.target.value; // Keep the search term as is
    const storedContacts = customers || [];
    const filteredContacts = storedContacts.filter((contact) => {
      // Split search term by spaces and remove leading/trailing spaces
      const searchTerms = searchTerm.split(/\s+/).filter((term) => term !== "");

      return searchTerms.every((term) => {
        const reversedTerm = term.split("").reverse().join(""); // Reverse the search term

        return Object.values(contact).some((value) => {
          if (typeof value === "string") {
            const trimmedValue = value.trim().replace(/\s+/g, ""); // Remove spaces from the field
            return (
              trimmedValue.toLowerCase().includes(term.toLowerCase()) ||
              trimmedValue.toLowerCase().includes(reversedTerm.toLowerCase())
            );
          }
          return false;
        });
      });
    });

    setFilteredContacts(filteredContacts);
    setSearchInput(searchTerm);
  };

  const handleCloseNoResults = () => {
    setIsSearchFocusedCustomer(false);
  };
  {
    const result =
      (selectedResultCustomer?.btwAmout / 100) * subTotal + subTotal;
    const formattedResult = parseFloat(result).toFixed(2);
  }

  return (
    <div className="container">
      <div className="row pb-4 mt-4 border-bottom border-dark">
        <div>
          <label htmlFor="invoiceNumber">
            Invoice Number
            <br />
            <p className="form-control">
              {invoiceToEdit?.id ||
                invoiceDraftData?.id ||
                proformaNumber ||
                invoice.proformaNumber}
            </p>
          </label>
        </div>
        <div className="search-container">
          <label htmlFor="customerInfo">
            Customer Info
            <textarea
              id="customerInfo"
              className="form-control search-input"
              type="text"
              placeholder="Search by name or id"
              rows={5}
              style={{ width: "18rem" }}
              value={searchInput}
              onChange={(e) => {
                handleSearch(e);
                if (e.target.value === "") {
                  setCustomerAdded(false);
                  localStorage.setItem("costumerAdded", "false");
                } else {
                  setCustomerAdded(true);
                  localStorage.setItem("costumerAdded", "true");
                }
              }}
              onFocus={() => setIsSearchFocusedCustomer(true)}
            />
          </label>
          {searchInput !== "" &&
            filteredContacts.length === 0 &&
            isSearchFocusedCustomer && (
              <SearchNoResults
                onClose={handleCloseNoResults}
                source="customer"
              />
            )}
          {searchInput !== "" &&
            filteredContacts.length > 0 &&
            isSearchFocusedCustomer && (
              <SearchResults
                filteredContacts={filteredContacts}
                handleResultSelection={handleResultSelection}
              />
            )}
          {!customerAdded && (
            <div style={{ margin: 0, padding: 0, width: "18rem" }}>
              <Link
                className="print-x"
                to="/AddCustomer"
                style={{
                  backgroundColor: "blue",
                  color: "white",
                  borderRadius: "8px",
                  cursor: "pointer",
                  float: "right",
                  paddingRight: "8px",
                  paddingLeft: "8px",
                }}
              >
                Add all details
              </Link>
            </div>
          )}
        </div>

        <div style={{ float: "Right", width: "16rem" }}>
          <label htmlFor="invoiceNumber">Internol Comment</label>
          <textarea
            className="form-control"
            type="text"
            placeholder="Internol Comment"
            value={internolCommand}
            onChange={(e) => setInternolCommand(e.target.value)}
          />
        </div>
        <div className="invoice-details mt-4">
          <div className="float-right">
            <label htmlFor="invoiceDate">Invoice Date</label>
            <br />
            <input
              type="date"
              value={date}
              onChange={(e) => {
                const newDate = e.target.value;
                const formattedNewDate = new Date(newDate);

                setDate(formattedNewDate.toISOString().split("T")[0]);

                setInvoice({
                  ...invoice,
                  date: formattedNewDate.toISOString().split("T")[0],
                });

                const expirationDate = new Date(formattedNewDate);
                expirationDate.setDate(expirationDate.getDate() + 30);
                setExpirationDate(expirationDate.toISOString().split("T")[0]);
              }}
              className="form-control"
            />
          </div>
          <div className="float-right">
            <label htmlFor="invoiceDate">Expiration Date</label>
            <br />
            <input
              type="date"
              value={expirationDate}
              onChange={(e) => setExpirationDate(e.target.value)}
              className="form-control"
            />
          </div>
          <div className="float-right">
            <label htmlFor="invoiceDate">Currency</label>
            <br />
            <select
              className="form-control"
              value={selectedCurrency}
              onChange={(e) => {
                setSelectedCurrency(e.target.value);
                setInvoice((prevInvoiceDone) => ({
                  ...prevInvoiceDone,
                  selectedCurrency: e.target.value,
                }));
              }}
            >
              <option value="eur">EUR</option>
              <option value="usd">USD</option>
              <option value="gbp">GBP</option>
              <option value="ils">ILS</option>
            </select>
          </div>
        </div>
      </div>

      <InvoiceTable
        invoiceToEdit={invoiceToEdit}
        invoiceDraftData={invoiceDraftData}
        setInvoice={setInvoice}
        invoice={invoice}
        totalInvoice={total}
        total={total}
        setTotal={setTotal}
        selectedResultCustomer={selectedResultCustomer}
        setSubTotal={setSubTotal}
        subTotal={subTotal}
        setItemsOnInvoice={setItemsOnInvoice}
        itemsOnInvoice={itemsOnInvoice}
        setDiscountedPriceTotal={setDiscountedPriceTotal}
        discountedPriceTotal={discountedPriceTotal}
        setTaxRate={setTaxRate}
        discount={discount}
        taxRate={taxRate}
        setTaxAmount={setTaxAmount}
        taxAmount={taxAmount}
        date={date}
        expirationDate={expirationDate}
        setTotalAmount={setTotalAmount}
        totalAmount={totalAmount}
        remarks={remarks}
        internolCommand={internolCommand}
        selectedCurrency={selectedCurrency}
      />

      <div className=" row mt-5">
        <div className="col-lg-12">
          <label htmlFor="remarks">Some Remarks....</label>
          <textarea
            name="remarks"
            value={remarks}
            placeholder="Some Remarks...."
            onChange={(e) => setRemarks(e.target.value)}
            className={`form-control ${remarks ? "has-value" : ""}`}
            rows={5}
            id="remarks"
          />
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-6">
                  <label htmlFor="subTotal">Sub Total</label>
                </div>
                <div className="col-lg-6">
                  <input
                    type="number"
                    value={subTotal}
                    className="form-control"
                    readOnly
                  />
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-lg-6">
                  <label htmlFor="discount">Discounted Price</label>
                </div>
                <div className="col-lg-6">
                  <input
                    type="number"
                    value={parseFloat(discountedPriceTotal).toFixed(3)}
                    className="form-control"
                    readOnly
                  />
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-lg-6">
                  <label htmlFor="tax">Tax</label>
                </div>
                <div className="col-lg-6">
                  <input
                    type="number"
                    value={taxAmount}
                    readOnly
                    className="form-control"
                  />
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-lg-6">
                  <label htmlFor="total">Total</label>
                </div>
                <div className="col-lg-6">
                  <input
                    type="number"
                    value={parseFloat(totalAmountRound).toFixed(2)}
                    className="form-control"
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Invoice;
