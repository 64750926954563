import React from "react";
import { Link } from "react-router-dom";

function SearchNoResults({ onClose, source, addNewLine }) {
  const handleClick = () => {
    onClose();
  };

  const handleAddItem = () => {
    addNewLine();
    onClose();
  };

  return (
    <div style={{ position: "relative" }}>
      <li
        style={{
          backgroundColor: "lightblue",
          position: "absolute",
          zIndex: "1",
          width: "30%",
          left: "35%",
          margin: "0 auto",
          height: "fit-content",
          marginBottom: "10px",
          listStyle: "none",
        }}
      >
        {/* add an x to remove by setting  isSearchFocused to false*/}
        <button
          style={{
            backgroundColor: "lightblue",
            color: "red",
            cursor: "pointer",
            border: "none",
            float: "right",
          }}
          onClick={handleClick}
        >
          X
        </button>
        No results found!
        {source === "customer" ? (
          <div>
            <Link
              to="/AddCustomer"
              style={{
                backgroundColor: "blue",
                color: "white",
                borderRadius: "8px",
                cursor: "pointer",
                float: "right",
                marginRight: "15px",
                paddingRight: "8px",
                paddingLeft: "8px",
              }}
            >
              Add all details
            </Link>
          </div>
        ) : (
          <div>
            <Link
              to="/AddProduct"
              style={{
                backgroundColor: "blue",
                color: "white",
                borderRadius: "8px",
                cursor: "pointer",
                float: "right",
                marginRight: "15px",
                paddingRight: "8px",
                paddingLeft: "8px",
              }}
            >
              Add all details
            </Link>

            <button
              style={{
                backgroundColor: "blue",
                color: "white",
                borderRadius: "8px",
                cursor: "pointer",
                float: "right",
                marginRight: "15px",
                paddingRight: "8px",
                paddingLeft: "8px",
              }}
              onClick={handleAddItem}
            >
              Add New Line
            </button>
          </div>
        )}
      </li>
    </div>
  );
}

export default SearchNoResults;
