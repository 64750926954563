import React, { useState, useEffect } from "react";
import config from "./config/config";

const Quantity = ({
  quantityAmount,
  itemsOnInvoice,
  setSubTotal,
  setDiscountedPriceTotal,
  setTaxAmount,
  setItemsOnInvoice,
  setItemsArray,
  taxRate,
  item,
  setTotalAmount,
}) => {
  const [isFieldFocused, setIsFieldFocused] = useState(false);
  const [products, setProducts] = useState([]);

  const getProducts = async () => {
    try {
      const response = await fetch(`${config.URL_PROD}/products`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const { count } = data;
        console.log("count", count);
        const { products } = data;
        setProducts(products);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  const handleFocus = () => {
    setIsFieldFocused(true);
    // Disable scrolling
    document.body.style.overflow = "hidden";
  };

  const handleBlur = () => {
    setIsFieldFocused(false);
    // Enable scrolling
    document.body.style.overflow = "auto";
  };

  const handleQuantityChange = (itemId, newQuantity) => {
    // Get the item with the given id from the items array
    const quantityInCatalog = products;
    const itemInCatalog = quantityInCatalog.find((item) => item.id === itemId);
    const { quantity } = itemInCatalog;

    const alertNoQnty = JSON.parse(localStorage.getItem("alertNoQnty"));

    // Check if there is enough quantity in stock
    if (newQuantity > quantity && alertNoQnty) {
      const answer = window.confirm(
        `Not enough items in stock! Do you want to continue? (Quantity will be more then ${
          quantity > 0 ? quantity + " But not in stock." : "0"
        }})`
      );
      if (!answer) {
        return;
      } else {
        // set the newQuantity to the 1
        // newQuantity = quantity; // this is only to set till the max quantity in stock
        newQuantity = newQuantity; // this is to set the quantity to the new value
      }
    }

    // Update the quantity, total, totalDiscounted for the item with the given id
    const updatedItems = itemsOnInvoice.map((item) => {
      if (item.id === itemId) {
        const parsedNewQuantity = parseFloat(newQuantity);
        if (!isNaN(parsedNewQuantity)) {
          const sellPrice = parseFloat(item.sellPrice);
          const btw = parseFloat(item.btw || 0);
          const discount = parseFloat(item.discount);

          // DEBOGGING NaN
          // console.log("sellPrice:", sellPrice);
          // console.log("btw:", btw);
          // console.log("discount:", discount);

          const totalDiscounted =
            (sellPrice * parsedNewQuantity * discount) / 100;
          const total = (
            sellPrice * parsedNewQuantity +
            (sellPrice * parsedNewQuantity * btw) / 100 -
            totalDiscounted
          ).toFixed(2);

          console.log("totalDiscounted:", totalDiscounted);
          console.log("total:", total);

          return {
            ...item,
            quantity: parsedNewQuantity,
            totalDiscounted,
            total,
          };
        } else {
          console.error("Invalid newQuantity value:", newQuantity);
          return item;
        }
      } else {
        return item;
      }
    });

    console.log(updatedItems);

    const sumForSub = updatedItems.reduce((acc, item) => {
      return acc + parseFloat(item.sellPrice) * parseFloat(item.quantity);
    }, 0);

    const updatedDiscountArray = updatedItems.map(
      (item) =>
        parseFloat(item.quantity) *
        parseFloat(item.sellPrice) *
        (parseFloat(item.discount) / 100)
    );

    const sumForTotalDiscount = updatedDiscountArray.reduce((a, b) => a + b, 0);

    const updatedTaxAmount = updatedItems.map(
      (item) =>
        parseFloat(item.sellPrice) * (item.btw / 100) * parseFloat(newQuantity)
    );

    const sumForTax = updatedTaxAmount.reduce(
      (acc, item) => acc + parseFloat(item),
      0
    );

    const sumForTotal = updatedItems.reduce((acc, item) => {
      return acc + parseFloat(item.total);
    }, 0);

    setSubTotal(sumForSub.toFixed(2));
    setDiscountedPriceTotal(sumForTotalDiscount.toFixed(2));
    setTaxAmount(parseFloat(sumForTax).toFixed(3));
    setTotalAmount(parseFloat(Math.round(sumForTotal * 20) / 20).toFixed(2));

    setItemsArray(updatedItems);
    setItemsOnInvoice(updatedItems);
  };

  return (
    <td>
      <input
        className="form-control"
        type="number"
        value={quantityAmount}
        onFocus={handleFocus}
        onBlur={handleBlur}
        style={{ width: "6rem" }}
        onChange={(e) => {
          const newValue = parseInt(e.target.value);

          if (!isNaN(newValue) && newValue >= 0) {
            handleQuantityChange(item.id, newValue);
          } else {
            handleQuantityChange(item.id, 0);
          }
        }}
      />
    </td>
  );
};

export default Quantity;
