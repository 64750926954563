import React, { useState, useEffect } from "react";

import overview from "./icon-overview.gif";

import NewBankEntery from "./newBankEntery";

import styles from "./Bank.module.css";

const Bank = () => {
  if (!localStorage.getItem("token")) {
    window.location.href = "/login";
  }
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState({});
  const [bankHistory, setBankHistory] = useState([]);
  const [bankStartDay, setBankStartDay] = useState(0);
  const [bankEndDay, setBankEndDay] = useState(0);
  const [totalInvoices, setTotalInvoices] = useState(0);
  const [totalPaidInvoices, setTotalPaidInvoices] = useState(0);
  const [totalUnpaidInvoices, setTotalUnpaidInvoices] = useState(0);
  const [totalBank, setTotalBank] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);
  const [totalDebt, setTotalDebt] = useState(0);
  const [totalCash, setTotalCash] = useState(0);
  const [totalExpense, setTotalExpense] = useState(0);
  const [newEntryData, setNewEntryData] = useState({
    id: "",
    bank: "",
    amount: "",
    date: new Date().toISOString().slice(0, 10),
    note: "",
  });
  const [newEntery, setNewEntery] = useState(false);
  const [totalBankContactFromInvoices, setTotalBankContactFromInvoices] =
    useState(0);
  const [totalBankContactFromBank, setTotalBankContactFromBank] = useState(0);
  const [totalBankContact, setTotalBankContact] = useState(0);

  const generateUniqueId = () => {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
  };

  const invoices = JSON.parse(localStorage.getItem("invoices")) || [];
  const bank = JSON.parse(localStorage.getItem("bank")) || [];
  const expenses = JSON.parse(localStorage.getItem("expenses")) || [];

  const bankTotals = () => {
    const bank = JSON.parse(localStorage.getItem("bank")) || [];
    console.log("bankTotals function");

    const invoicesBankContact = invoices
      .flatMap((invoice) =>
        invoice?.payments
          ?.filter(
            (payment) =>
              payment.paymentMethod === "Bank Contact" && payment.amount > 0
          )
          .map((payment) => ({
            bank: payment.paymentMethod,
            amount: payment.amount,
            date: payment.date,
            id: invoice.id,
            finalizedDate: payment?.finalizedDate,
            status: invoice.status,
          }))
      )
      .filter(Boolean);

    const matchingEntries = bank.filter((entry) => {
      return (
        entry.status === "invoice" &&
        entry.finalizedDate &&
        bank.some(
          (otherEntry) =>
            otherEntry.date === entry.finalizedDate &&
            otherEntry.status !== "invoice"
        )
      );
    });

    // console.log(matchingEntries);
    const matchingFinalizedDateAndDate = matchingEntries;

    // Filter entries without corresponding matches in matchingFinalizedDateAndDate
    const matchingWithoutInvoice = bank.filter((entry) => {
      return (
        entry.status !== "invoice" &&
        !entry.finalizedDate &&
        matchingEntries.some(
          (matchingEntry) => matchingEntry.finalizedDate === entry.date
        )
      );
    });

    // with invoice
    const matchingWithInvoice = bank.filter((entry) => {
      return (
        entry.status !== "invoice" &&
        matchingEntries.some(
          (matchingEntry) => matchingEntry.finalizedDate === entry.date
        )
      );
    });
    // console.log(matchingWithoutInvoice,matchingWithInvoice);

    // Calculate totals
    const invoicedFinalizedMatching = calculateSum(
      matchingFinalizedDateAndDate,
      "amount"
    );
    // console.log(matchingFinalizedDateAndDate);

    const entryMatching = calculateSum(matchingWithoutInvoice, "amount");
    // console.log(entryMatching);
    const totalBankContactFromInvoices = calculateSum(
      invoicesBankContact,
      "amount"
    );

    // console.log(entryMatching,matchingFinalizedDateAndDate, invoicedFinalizedMatching);

    let bankContactMatchingCalcAmount = 0;

    const difference = entryMatching - invoicedFinalizedMatching;
    // console.log(difference);

    if (difference === 0) {
      bankContactMatchingCalcAmount = 0;
      // console.log(bankContactMatchingCalcAmount);
    } else if (difference > 0) {
      bankContactMatchingCalcAmount = difference;
      // console.log(bankContactMatchingCalcAmount);
    } else if (difference < 0) {
      bankContactMatchingCalcAmount = Number(
        matchingWithoutInvoice.reduce((acc, curr) => {
          return acc + Number(curr.amount);
        }, 0) -
          matchingWithInvoice.reduce((acc, curr) => {
            return acc + Number(curr.amount);
          }, 0)
      );
      // console.log(bankContactMatchingCalcAmount);
    }
    // console.log(Number(matchingWithoutInvoice.reduce((acc, curr) => { return acc + Number(curr.amount)}, 0), matchingWithInvoice.reduce((acc, curr) => { return acc + Number(curr.amount)}, 0)));
    // console.log();

    const notMatchingEntries = bank.filter(
      (entry) =>
        entry.status !== "invoice" &&
        !entry.finalizedDate &&
        entry.bank === "Bank Contact" &&
        !matchingEntries.some(
          (matchingEntry) => matchingEntry.finalizedDate === entry.date
        )
    );
    // console.log(notMatchingEntries);

    const sumNotMatchingEntries = calculateSum(notMatchingEntries, "amount");
    // console.log(sumNotMatchingEntries);

    let withoutInvoice;
    if (invoicedFinalizedMatching > entryMatching) {
      withoutInvoice = sumNotMatchingEntries - bankContactMatchingCalcAmount;
      // console.log(entryMatching, difference, bankContactMatchingCalcAmount, sumNotMatchingEntries, withoutInvoice,invoicedFinalizedMatching);
    } else if (difference < 0 && entryMatching > 0) {
      withoutInvoice = sumNotMatchingEntries + bankContactMatchingCalcAmount;
      // console.log(withoutInvoice);
    } else if (entryMatching > 0 && difference > 0) {
      withoutInvoice = difference + sumNotMatchingEntries;
    } else {
      withoutInvoice = sumNotMatchingEntries;
      // console.log(withoutInvoice);
    }
    // console.log(withoutInvoice);

    const totalBankContact = withoutInvoice + totalBankContactFromInvoices;

    const totalCash = bank
      .filter((entry) => entry.bank === "Cash/Kassa")
      .reduce((acc, curr) => {
        return acc + Number(curr.amount);
      }, 0);

    const totalInBank = totalBankContact + totalCash;

    setTotalCash(Number(totalCash).toFixed(2));
    setTotalBankContactFromInvoices(
      Number(totalBankContactFromInvoices).toFixed(2)
    );
    setTotalBankContactFromBank(Number(withoutInvoice).toFixed(2));
    setTotalBankContact(Number(totalBankContact).toFixed(2));
    setTotalBank(Number(totalInBank).toFixed(2));

    setBankEndDay(Number(totalInBank).toFixed(2));
  };

  const calculateDifference = (
    matchingWithoutInvoice,
    matchingFinalizedDateAndDate
  ) => {
    const result = { ...matchingWithoutInvoice };
    const transaction1Date = new Date(matchingWithoutInvoice.date);
    const transaction2Date = new Date(
      matchingFinalizedDateAndDate.finalizedDate
    );

    if (transaction1Date.getTime() === transaction2Date.getTime()) {
      const difference =
        matchingWithoutInvoice.amount - matchingFinalizedDateAndDate.amount;
      result.invoiced = difference >= 0 ? 0 : Math.abs(difference);
    }
    return result;
  };

  const calculateSumGroupedByDate = (groupedEntries) => {
    return Object.values(groupedEntries).map((entries) =>
      calculateSum(entries, "amount")
    );
  };

  const calculateSum = (entries, key) => {
    return entries.reduce((acc, curr) => acc + Number(curr[key]), 0);
  };

  const groupBy = (arr, key) => {
    return arr.reduce((acc, obj) => {
      const group = obj[key];
      acc[group] = acc[group] || [];
      acc[group].push(obj);
      return acc;
    }, {});
  };

  useEffect(() => {
    const filterAndMapInvoices = (invoices, paymentMethod) =>
      invoices
        .flatMap((invoice) =>
          invoice?.payments
            ?.filter(
              (payment) =>
                payment.paymentMethod === paymentMethod && payment.amount > 0
            )
            .map((payment) => ({
              bank: payment.paymentMethod,
              amount: payment.amount,
              date: payment.date,
              id: invoice.id,
              finalizedDate: payment?.finalizedDate,
              status: invoice.status,
            }))
        )
        .filter(Boolean);

    const invoicesBankContact = invoices
      .flatMap((invoice) =>
        invoice?.payments
          ?.filter(
            (payment) =>
              payment.paymentMethod === "Bank Contact" && payment.amount > 0
          )
          .map((payment) => ({
            bank: payment.paymentMethod,
            amount: payment.amount,
            date: payment.date,
            id: invoice.id,
            finalizedDate: payment?.finalizedDate,
            status: invoice.status,
          }))
      )
      .filter(Boolean);
    const invoicesBankTransfer = filterAndMapInvoices(
      invoices,
      "Bank Transfer"
    );
    const invoicesCash = filterAndMapInvoices(invoices, "Cash/Kassa");

    const BankContact = bank.filter(
      (invoice) => invoice.bank === "Bank Contact"
    );
    const BankTransfer = bank.filter(
      (invoice) => invoice.bank === "Bank Transfer"
    );
    const Cash = bank.filter((invoice) => invoice.bank === "Cash/Kassa");

    const bankHistory1 = [...BankContact, ...BankTransfer, ...Cash];
    const bankHistory2 = [
      ...invoicesBankContact,
      ...invoicesBankTransfer,
      ...invoicesCash,
    ];

    const bankHistory = Array.from(
      new Set([...bankHistory1, ...bankHistory2].map((bank) => bank.id))
    )
      .map((id) => {
        const bank = [...bankHistory1, ...bankHistory2].find(
          (bank) => bank.id === id
        );
        const invoice = invoices.find((invoice) => invoice.id === id);

        if (bank && invoice) {
          const payments = invoice.payments || []; // Ensure payments is not undefined
          const filteredPayments = payments.filter(
            (payment) => payment.paymentMethod === bank.bank
          );
          const finalizedDate = filteredPayments[0]?.finalizedDate;
          return { ...bank, finalizedDate };
        }
        // If the invoice is not found, return the bank entry without modification
        return bank;
      })
      .filter(Boolean);

    setBankHistory(bankHistory);

    const totalofAllInvoicess = () => {
      const total = invoices
        .reduce((acc, curr) => acc + Number(curr.totalAmount), 0)
        .toFixed(2);

      setTotalInvoices(total);
    };

    const totalOfAllUnpaidInvoices = () => {
      const unpaidInvoices = invoices.reduce(
        (acc, curr) =>
          acc +
          Number(curr.totalAmount - (curr.totalPaid ? curr.totalPaid : 0)),
        0
      );

      setTotalUnpaidInvoices(unpaidInvoices);
    };

    const totalOfAllPaidInvoices = () => {
      // find all paid invoices
      const paidInvoices = invoices
        .reduce(
          (acc, curr) => acc + Number(curr.totalPaid ? curr.totalPaid : 0),
          0
        )
        .toFixed(2);

      setTotalPaidInvoices(paidInvoices);
    };

    const totalOfAllCash = () => {
      const sumOfInvoicesCash = invoicesCash
        .filter((invoice) => invoice?.bank === "Cash/Kassa")
        .reduce((acc, curr) => acc + Number(curr.amount), 0);

      setTotalCash(sumOfInvoicesCash);
    };

    const totalOfAllBank = () => {
      // filter invoices with "Bank" as payment method and sum the amounts
      const sumOfInvoicesBank = invoicesBankContact
        .filter((invoice) => invoice?.bank === "Bank Contact")
        .reduce((acc, curr) => acc + Number(curr.amount), 0);

      const sumOfInvoicesBankTransfer = invoicesBankTransfer
        .filter((invoice) => invoice?.bank === "Bank Transfer")
        .reduce((acc, curr) => acc + Number(curr.amount), 0);

      const sumOfKassaCash = bankHistory
        .filter((invoice) => invoice?.bank === "Cash/Kassa")
        .reduce((acc, curr) => acc + Number(curr.amount), 0);

      setTotalBank(
        sumOfInvoicesBank + sumOfInvoicesBankTransfer + sumOfKassaCash
      );
    };

    const totalOfAllDebt = () => {
      // find all unpaid expenses
      const unpaidExpenses = expenses.filter((expense) => {
        return (
          expense.totalPaid === 0 ||
          expense.totalPaid < expense.total ||
          expense.totalPaid === null ||
          expense.totalPaid === undefined
        );
      });

      // sum all unpaid expenses
      const total = unpaidExpenses.reduce((acc, curr) => {
        return acc + curr.total;
      }, 0);

      setTotalDebt(total);
    };

    const totalOfAllExpenses = () => {
      // find all paid expenses
      const paidExpenses = expenses.filter((expense) => {
        return expense.totalPaid === expense.total;
      });

      // sum all paid expenses
      const total = paidExpenses.reduce((acc, curr) => {
        return acc + curr.total;
      }, 0);

      setTotalExpense(total);
    };

    const totalOfAllBalance = () => {
      const totalInvoices = invoices
        ? invoices.reduce((acc, curr) => {
            return acc + Number(curr.totalAmount);
          }, 0)
        : 0;

      const totalCash = invoicesCash
        ? invoicesCash.reduce((acc, curr) => {
            return acc + Number(curr.amount);
          }, 0)
        : 0;
      const total = totalInvoices + totalBankContact + totalCash - totalExpense;
      setTotalBalance(total);
    };

    totalofAllInvoicess();
    totalOfAllUnpaidInvoices();
    totalOfAllPaidInvoices();
    totalOfAllCash();
    totalOfAllBank();
    totalOfAllDebt();
    totalOfAllExpenses();
    totalOfAllBalance();

    const sortedBankHistory = bankHistory.sort((a, b) => {
      return new Date(b.date) - new Date(a.date);
    });

    const totalInBank = sortedBankHistory.reduce((acc, curr) => {
      return acc + Number(curr.amount);
    }, 0);

    const totalBalance = totalInBank - totalExpense;

    setBankHistory(sortedBankHistory);
    setBankStartDay(0);
    setBankEndDay(totalBalance);

    localStorage.setItem("bank", JSON.stringify(bankHistory));
    bankTotals();
  }, [setBankHistory]);

  const openModal = (id) => {
    const invoices = JSON.parse(localStorage.getItem("invoices")) || [];
    const order = invoices.find((invoice) => invoice.id === id);
    setOrder(order);
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const handleAddBankHistory = (newEntryData) => {
    try {
      const existingBankHistory =
        JSON.parse(localStorage.getItem("bank")) || [];
      // give the new entry an id it should be unique so we use the length of the existingBankHistory array is not working because we are deleting entries and the id is not unique anymore
      const id = generateUniqueId();
      const newBankHistory = [...existingBankHistory, { ...newEntryData, id }];
      localStorage.setItem("bank", JSON.stringify(newBankHistory));
      console.log(existingBankHistory);
      setBankHistory(newBankHistory);
      bankTotals();
      console.log("Data saved successfully!");
    } catch (error) {
      console.error("Error storing data in local storage:", error);
    }

    setNewEntery(!newEntery);

    setNewEntryData({
      id: "",
      bank: "",
      amount: "",
      date: new Date().toISOString().slice(0, 10),
      note: "",
    });
  };

  const handleDeleteBankHistory = (id) => {
    const existingBankHistory = JSON.parse(localStorage.getItem("bank")) || [];
    const newBankHistoryWIthInvoice = existingBankHistory.filter(
      (entry) => entry.id === id && entry.finalizedDate
    );
    if (newBankHistoryWIthInvoice.length > 0) {
      // set the invoice to unpaid
      const invoices = JSON.parse(localStorage.getItem("invoices")) || [];
      const updatedInvoices = invoices.map((invoice) => {
        if (invoice.id === id) {
          return {
            ...invoice,
            totalPaid: 0,
            payments: invoice.payments.map((payment) => {
              if (payment.paymentMethod === newBankHistoryWIthInvoice[0].bank) {
                return {
                  ...payment,
                  amount: 0,
                  finalizedDate: "",
                  paymentMethod: "",
                };
              }
              return payment;
            }),
          };
        }
        return invoice;
      });
      const newBankHistory = existingBankHistory.filter(
        (entry) => entry.id !== id
      );
      localStorage.setItem("invoices", JSON.stringify(updatedInvoices));
      localStorage.setItem("bank", JSON.stringify(newBankHistory));
      setBankHistory(updatedInvoices);
      bankTotals();
    } else {
      const newBankHistory = existingBankHistory.filter(
        (entry) => entry.id !== id
      );
      localStorage.setItem("bank", JSON.stringify(newBankHistory));
      setBankHistory(newBankHistory);
      bankTotals();
    }
  };

  return (
    <div className={styles.mainDiv}>
      <div className={styles.bank}>
        {/* <div className={styles.bankCard}>
          <h4>Total Invoices</h4>
          <p>{Number(totalInvoices).toFixed(2)}</p>
        </div>
        <div className={styles.bankCard}>
          <h4>Total Paid Invoices</h4>
          <p>{Number(totalPaidInvoices).toFixed(2)}</p>
        </div>
        <div className={styles.bankCard}>
          <h4>Total Unpaid Invoices</h4>
          <p>{Number(totalUnpaidInvoices).toFixed(2)}</p>
        </div> */}
        <div className={styles.bankCard}>
          <h4>Total Bank</h4>
          <p>{Number(totalBank).toFixed(2)}</p>
        </div>
        <div className={styles.bankCard}>
          <h4>Total Cash</h4>
          <p>{Number(totalCash).toFixed(2)}</p>
        </div>
        {/* <div className={styles.bankCard}>
          <h4>Total Bank Transfer</h4>
          <p>{"hmmm....."}</p>
        </div> */}
        <div className={styles.bankCard}>
          <h4>Total Bank Contact</h4>
          <p>{Number(totalBankContact).toFixed(2)}</p>
        </div>
        <div className={styles.bankCard}>
          <h4>Bank Contact With Invoices</h4>
          <p>{Number(totalBankContactFromInvoices).toFixed(2)}</p>
        </div>
        <div className={styles.bankCard}>
          <h4>Bank Contact Without Invoices</h4>
          <p>{Number(totalBankContactFromBank).toFixed(2)}</p>
        </div>
        {/* <div className={styles.bankCard}>
          <h4>Total Expenses</h4>
          <p>{Number(totalExpense).toFixed(2)}</p>
        </div>
        <div className={styles.bankCard}>
          <h4>Total Balance</h4>
          <p>{Number(totalBalance).toFixed(2)}</p>
        </div> */}
      </div>
      <div className={styles.bankSection}>
        <h1>Banking</h1>

        <div>
          {/* start the day than end the total */}
          <div className={styles.bankTotalPerDayCard}>
            <div className={styles.bankTotalStartPerDay}>
              <h4>Start the day</h4>
              <p>{Number(bankStartDay).toFixed(2) || 0}</p>
            </div>

            <div className={styles.bankTotalEndDay}>
              <h4>End the day</h4>
              <p>{Number(bankEndDay).toFixed(2) || 0}</p>
            </div>
          </div>
        </div>

        <div className={styles.bankHistory}>
          <h4>Bank History</h4>
          <div className={styles.bankHistoryCard}>
            {/* Enter a new bank entery */}
            {!newEntery ? (
              <button
                className={styles.addBankHistoryBtn}
                onClick={() => setNewEntery(!newEntery)}
              >
                Add Bank History
              </button>
            ) : (
              <div className={styles.modalBank}>
                <div className="modal-content">
                  <span
                    className="close"
                    onClick={() => setNewEntery(!newEntery)}
                  >
                    &times;
                  </span>
                  <div className="modal-header">
                    <h2>New Entery</h2>
                  </div>
                  <div className={styles.modalBankBody}>
                    <div className={styles.addBankHistoryCard}>
                      <div className={styles.addBankHistoryCardInput}>
                        <label htmlFor="bank">Bank</label>
                        <select
                          name="bank"
                          id="bank"
                          value={newEntryData.bank}
                          onChange={(e) =>
                            setNewEntryData((prevData) => ({
                              ...prevData,
                              bank: e.target.value,
                            }))
                          }
                        >
                          <option value="" disabled selected>
                            Select Method
                          </option>
                          <option value="Cash/Kassa">Cash/Kassa</option>
                          <option value="Bank Contact">Bank Contact</option>
                        </select>
                      </div>
                      <div className={styles.addBankHistoryCardInput}>
                        <label htmlFor="amount">Amount</label>
                        <input
                          type="number"
                          name="amount"
                          id="amount"
                          value={newEntryData.amount}
                          onChange={(e) =>
                            setNewEntryData({
                              ...newEntryData,
                              amount: Number(e.target.value),
                            })
                          }
                        />
                      </div>
                      <div className={styles.addBankHistoryCardInput}>
                        <label htmlFor="date">Date</label>
                        <input
                          type="date"
                          name="date"
                          id="date"
                          value={newEntryData.date}
                          onChange={(e) =>
                            setNewEntryData({
                              ...newEntryData,
                              date: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className={styles.addBankHistoryCardInput}>
                        <label htmlFor="note">note</label>
                        <input
                          type="text"
                          name="note"
                          id="note"
                          onChange={(e) =>
                            setNewEntryData({
                              ...newEntryData,
                              note: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      className={styles.addBankHistoryBtn}
                      onClick={() => {
                        handleAddBankHistory(newEntryData);
                      }}
                    >
                      Add
                    </button>
                    {/* provided by perfectInvoicing */}
                    <p>
                      provided by{" "}
                      <a
                        href="https://perfectinvoicing.netlify.app/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        PerfectInvoicing
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            )}
            {/* table */}
            <table className={styles.tableBank}>
              <thead className={styles.tableBankMainHead}>
                <tr className={styles.tableBankHead}>
                  <th className={styles.tableBankHead}>ID / Index</th>
                  <th className={styles.tableBankHead}>Amount</th>
                  <th className={styles.tableBankHead}>Date</th>
                  <th className={styles.tableBankHead}>Finalized Date</th>
                  <th className={styles.tableBankHead}>Payments Method</th>
                  <th className={styles.tableBankHead}>Note</th>
                  <th className={styles.tableBankHead}>View Invoice</th>
                  <th className={styles.tableBankHead}>Delete</th>
                </tr>
              </thead>
              <tbody className={styles.BankTable}>
                {bank.map((bank, index) => {
                  return (
                    <tr key={index}>
                      <td>{bank.id || index + 1}</td>
                      <td>{Number(bank.amount).toFixed(2)}</td>
                      <td>{bank.date}</td>
                      <td>{bank.finalizedDate || bank.date}</td>
                      <td>{bank.bank}</td>
                      <td>{bank.note}</td>
                      <td>
                        {bank.id ? (
                          <button onClick={() => openModal(bank.id)}>
                            {/* overview gif */}
                            <img
                              src={overview}
                              alt="overview"
                              width="28"
                              height="28"
                            />
                          </button>
                        ) : (
                          "N/A"
                        )}
                      </td>
                      <td>
                        <button
                          onClick={() => {
                            handleDeleteBankHistory(bank.id);
                          }}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          {/* modal */}
          {modal && (
            <div id="myModal" className="modal">
              <div className="modal-content" style={{ width: "70%" }}>
                <span className="close" onClick={closeModal}>
                  &times;
                </span>
                <div className="modal-header">
                  <h2>Order Details</h2>

                  {/* invoice number */}
                  <div className={styles.invoiceNumber}>
                    <p>
                      Invoice Number:{" "}
                      <span>{order.id || order.proformaNumber}</span>
                    </p>
                    {/* date */}
                    <p>
                      Date: <span>{order.date}</span>
                    </p>
                    {/* client number */}
                    <p>
                      Client Number:{" "}
                      <span>{order.selectedResultCustomer.clientNumber}</span>
                    </p>
                    <p>
                      Customer Name:{" "}
                      <span>{order.selectedResultCustomer.companyName}</span>
                    </p>
                  </div>
                </div>
                <div className="modal-body">
                  {/* Order details in a table */}
                  <table>
                    <thead>
                      <tr>
                        <th className={styles.previewTh}>Item ID</th>
                        <th className={styles.previewTh}>Item Name</th>
                        <th className={styles.previewTh}>Item Note</th>
                        <th className={styles.previewTh}>Item Price</th>
                        <th className={styles.previewTh}>Item Quantity</th>

                        {order.btw > 0 && (
                          <th className={styles.previewTh}>Item BTW</th>
                        )}
                        <th className={styles.previewTh}>Item Discount</th>
                        <th className={styles.previewTh}>Item Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {order.items.map((item) => (
                        <tr key={item.id}>
                          <td className={styles.previewTd}>{item.id}</td>
                          <td className={styles.previewTd}>{item.name}</td>
                          <td className={styles.previewTd}>{item.note}</td>
                          <td className={styles.previewTd}>
                            {parseFloat(item.sellPrice).toFixed(2)}
                          </td>
                          <td className={styles.previewTd}>{item.quantity}</td>

                          {order.btw > 0 && (
                            <td className={styles.previewTd}>
                              {parseFloat(item.btw).toFixed(2)}
                            </td>
                          )}
                          <td className={styles.previewTd}>
                            {parseFloat(item.discount).toFixed(2)}
                          </td>
                          <td className={styles.previewTd}>
                            {parseFloat(item.total).toFixed(2)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div>
                    {/* subtotal */}
                    <p>
                      Subtotal:{" "}
                      <span>{parseFloat(order.subTotal).toFixed(2)}</span>
                    </p>
                    {/* discount */}
                    <p>
                      Discount:{" "}
                      <span>
                        {parseFloat(order.discountedPriceTotal).toFixed(2)}
                      </span>
                    </p>
                    {/* tax */}
                    {order.btw > 0 && (
                      <p>
                        Tax: <span>{parseFloat(order.btw).toFixed(3)}</span>
                      </p>
                    )}

                    {/* total amount */}
                    <p>
                      Total Amount:{" "}
                      <span>
                        {parseFloat(
                          Math.round(order.totalAmount * 20) / 20
                        ).toFixed(2)}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="modal-footer">
                  {/* provided by perfectInvoicing */}
                  <p>
                    provided by{" "}
                    <a
                      href="https://perfectinvoicing.netlify.app/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      PerfectInvoicing
                    </a>
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Bank;
