import React from "react";

function PrintComponent({
  indexNumber,
  date,
  performaInvoiceNumber,
  expDate,
  companyInfoText = {},
  customerInfoText = {},
  customerInfo,
  items,
  remarks,
  subTotal,
  discount,
  tax,
  total,
  currencySymbol,
}) {
  const itemsRows = (items) => {
    return items.map((item) => {
      return (
        <tr key={item.id}>
          <td>{item.name}</td>
          <td>{item.description}</td>
          {customerInfoText.showTransportCode === true ? (
            <td>{item.transportCode}</td>
          ) : (
            ""
          )}
          <td>{item.quantity}</td>
          <td>{item.price}</td>
          {item.discount ? <td>{item.discount}</td> : ""}
          <td>{item.btw}</td>
          <td>
            {item.total} {currencySymbol}
          </td>
        </tr>
      );
    });
  };

  return (
    <div>
      <table
        style={{
          width: "100%",
        }}
      >
        <thead>
          <tr>
            <td>
              <div className="invoice">
                <div
                  className="invoice-title"
                  style={{
                    textAlign: "center",
                    paddingTop: "2rem",
                    marginBottom: "2rem",
                  }}
                >
                  Invoice
                </div>
                <div style={{ float: "right" }}>
                  <div>Invoice Number: {performaInvoiceNumber}</div>
                  <div>
                    Invoice Date: {new Date(date).toLocaleDateString("en-US")}
                  </div>
                  <div>Invoice Exp Date: {expDate}</div>
                </div>
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div className="invoice-details">
                <div>
                  <strong>From:</strong>
                  <br />
                  {companyInfoText.companyName}
                  <br />
                  {companyInfoText.address}
                  <br />
                  {companyInfoText.city}
                  <br />
                  {companyInfoText.zipCode}
                  <br />
                  {companyInfoText.country}
                  {/* Email: {companyInfoText.email} */}
                  {/* Phone: {companyInfoText.phone} */}
                  <br />
                  BTW Number: {companyInfoText.btwNumber}
                  <br />
                  <br />
                </div>
                <div>
                  <strong>To:</strong>
                  <br />
                  {customerInfoText.companyName || customerInfo.companyName}
                  <br />
                  {customerInfoText.address}
                  <br />
                  {customerInfoText.city}
                  <br />
                  {customerInfoText.zipCode}
                  <br />
                  {customerInfoText.country}
                  <br />
                  {/* Email: {customerInfoText.email}<br /> */}
                  {/* Phone: {customerInfoText.phone}<br /> */}
                  <br />
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <table className="invoice-items" 
              style={{
                width: "100%",
                border: "1px solid black",
              }}
              >
                <thead 
                >
                  <tr>
                    <th>Item Name</th>
                    <th>Item Description</th>
                    {customerInfoText.showTransportCode === true && (
                      <th>Transport Code</th>
                    )}
                    <th>Quantity</th>
                    <th>Unit Price</th>
                    {items.some((item) => item.discount) && <th>Discount</th>}
                    <th>Btw</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody >{itemsRows(items)}</tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <br />
              {remarks ? (
                <strong>
                  Remarks: {remarks}
                  <br />
                </strong>
              ) : (
                ""
              )}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td style={{ float: "right" }}>
              <br />
              <div className="invoice-total footer">
                Sub Total: {subTotal}
                <br />
                {items.some((item) => item.discount) && (
                  <>
                    Discount: {discount}
                    <br />
                  </>
                )}
                Tax: {tax}
                <br />
                <strong>
                  Total: {total} {currencySymbol}
                </strong>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}

export default PrintComponent;
