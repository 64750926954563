import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CheckForToken from "../checkForToken";
import config from "./config/config";

import CreateInvoice from "./CreateInvoice";

const DuplicateInvoice = () => {
  CheckForToken();
  // Get the index from the URL parameters
  const { id } = useParams();

  // State to manage the invoice data
  const [invoiceToDuplicate, setInvoiceToDuplicate] = useState(null); // Initialize as null
  const [invoices, setInvoices] = useState([]); // Initialize as empty array
  const getInvoices = async () => {
    try {
      const response = await fetch(`${config.URL_PROD}/invoices`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const { invoices } = data;
        setInvoices(invoices || []);
        console.log("invoices", invoices);
        // console.log("invoices", invoices);
        const invoiceData = invoices.find((invoice) => invoice.id === id); // Ensure id is converted to an integer
        // console.log("invoiceData", invoiceData);
        setInvoiceToDuplicate(invoiceData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getInvoices();
  }, []);

  return (
    <>
      <CreateInvoice invoiceToDuplicate={invoiceToDuplicate} />
    </>
  );
};

export default DuplicateInvoice;
