import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { generatePDFsForOrders } from "../pdf/pdfGeneratorDelayed";
import { generateReceiptPDFCombined } from "../print/combindePDFAndPrint";
import generatePDFPackingListByCustomer from "../pdf/generatePDFPackingListByCustomer.js";
import generatePDFPackingListByProduct from "../pdf/generatePDFPackingListByProduct.js";
import ConfirmACreatePackageList from "./CreatePackagingList";
import overview from "./icon-overview.gif";
import * as XLSX from "xlsx";
import config from "./config/config.js";

import calculateQuantityBoxesAndUnits from "../components/calc/calculateQuantityBoxesAndUnits";

import styles from "./invoices.module.css";
import viewStyles from "./view.module.css";

const Invoices = () => {
  if (!localStorage.getItem("token")) {
    window.location.href = "/login";
  }
  const [invoices, setInvoices] = useState([]);
  const [allInvoices, setAllInvoices] = useState([]);
  const [costumers, setCostumers] = useState([]);
  const [products, setProducts] = useState([]);
  const getProducts = async () => {
    fetch(`${config.URL_PROD}/products`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // the data has a object with a key of products array and a key of count please destructure the products array from the data object
        const { products } = data;
        const { count } = data;
        /* console.log("products", products); */
        console.log("count", count);
        setProducts(products);
        // Remove duplicate products from the array but keep the first occurence
      })
      .catch((err) => console.error(err))
      .finally(() => {
        /*  setProducts(products); */
        console.log("fetching products done");
      });
  };
  useEffect(() => {
    getProducts();
  }, []);

  const getInvoices = async () => {
    try {
      const response = await fetch(`${config.URL_PROD}/invoices`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const { invoices } = data;
        setInvoices(invoices || []);
        setAllInvoices(invoices || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getInvoices();
  }, []);

  // log the total amount of the invoices and the total paid and the total unpaid and total discount of all invoices
  const totalAmount = invoices.reduce(
    (acc, curr) => acc + Number(curr.totalAmount),
    0
  );
  const totalPaid = invoices.reduce(
    (acc, curr) => acc + Number(curr.totalPaid),
    0
  );
  const totalUnpaid = invoices.reduce(
    (acc, curr) =>
      Number(curr.totalPaid) > 0
        ? acc + Number(curr.totalAmount - curr.totalPaid)
        : acc + Number(curr.totalAmount),
    0
  );
  const totalDiscount = invoices.reduce(
    (acc, curr) => acc + Number(curr.discountedPriceTotal),
    0
  );

  console.log("totalAmount", totalAmount.toFixed(2));
  console.log("totalDiscount", totalDiscount.toFixed(2));

  const getCostumers = async () => {
    try {
      const response = await fetch(`${config.URL_PROD}/contacts`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setCostumers(data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCostumers();
  }, []);

  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedInvoice, setSelectedInvoice] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sortBy, setSortBy] = useState(""); // Initialize with an empty string for no initial sorting
  const [sortOrder, setSortOrder] = useState("asc"); // Initialize with ascending order
  const [invoicesSelected, setInvoicesSelected] = useState(
    selectedInvoice.length > 0
  );
  const [searchInput, setSearchInput] = useState("");
  const [searchInInvoiceInput, setSearchInInvoiceInput] = useState("");

  // State to manage selected dates
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [filteredByDate, setFilteredByDate] = useState([]); // Initialize with an empty array [] for no initial filtering
  const [isPaymentDateModalOpen, setIsPaymentDateModalOpen] = useState(false);
  // the next 3 states are for the payment info updates
  const InitializePayments = [
    {
      date: new Date().toISOString().slice(0, 10),
      amount: 0.0,
      paymentMethod: "",
      finalizedDate: "",
      status: "Inovice",
    },
  ];
  const [updatedTotalPaid, setUpdatedTotalPaid] = useState(InitializePayments);
  const [invoiceUpdatedPayments, setInvoiceUpdatedPayments] = useState([]);

  const [updatedPaymentDate, setUpdatedPaymentDate] = useState("");
  const [updatedPaymentMethod, setUpdatedPaymentMethod] = useState("");
  const [createPackageList, setCreatePackageList] = useState(false);

  const handleSort = (column) => {
    if (column === sortBy) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }

    const sortedOrders = [...invoices]
      .filter((order) => order.status !== "Completed")
      .sort(sortFunction);

    setInvoices(sortedOrders); // Update the state directly with the sorted array
  };

  // Define a sorting function to sort the orders based on the selected column and order
  const sortFunction = (a, b) => {
    let aValue = a[sortBy];
    let bValue = b[sortBy];

    if (sortBy === "clientNumber") {
      aValue = a.selectedResultCustomer.clientNumber;
      bValue = b.selectedResultCustomer.clientNumber;
    }

    if (sortBy === "id") {
      aValue = parseInt(a.id || a.proformaNumber);
      bValue = parseInt(b.id || b.proformaNumber);
    }

    if (sortBy === "selectedResultCustomer.saveAs") {
      // Sorting function for comparing two objects based on the "saveAs" field
      const compareSaveAs = (a, b) => {
        // Accessing the "saveAs" field from the "selectedResultCustomer" object
        const saveAsA = a.selectedResultCustomer?.saveAs || "";
        const saveAsB = b.selectedResultCustomer?.saveAs || "";
        // Comparing the "saveAs" values
        return saveAsA.localeCompare(saveAsB);
      };

      // Sorting the array based on the comparison function
      return sortOrder === "asc" ? compareSaveAs(a, b) : compareSaveAs(b, a);
    }

    if (sortBy === "date") {
      aValue = new Date(a.date);
      bValue = new Date(b.date);
    }

    if (sortBy === "totalAmount") {
      aValue = parseFloat(a.totalAmount);
      bValue = parseFloat(b.totalAmount);
    }

    if (sortBy === "paid") {
      aValue = parseFloat(a.totalPaid);
      bValue = parseFloat(b.totalPaid);
    }

    if (sortBy === "paymentDate") {
      aValue = new Date(a.paymentDate);
      bValue = new Date(b.paymentDate);
    }

    if (sortBy === "unpaid") {
      aValue = parseFloat(a.totalAmount - a.totalPaid);
      bValue = parseFloat(b.totalAmount - b.totalPaid);
    }

    if (sortBy === "paid full") {
      aValue = parseFloat(a.totalPaid);
      bValue = parseFloat(b.totalPaid);
    }

    if (sortBy === "status") {
      aValue = aValue;
      bValue = bValue;
    }

    if (aValue < bValue) return sortOrder === "asc" ? -1 : 1;
    if (aValue > bValue) return sortOrder === "asc" ? 1 : -1;
    return 0;
  };

  let sortedOrders = invoices
    .filter((order) => order.status !== "Completed") // Filter out completed orders
    .sort(sortFunction); // Sort the orders based on the selected column and order

  const openModal = (order) => {
    setSelectedOrder(order);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedOrder(null);
  };

  const handleOnExportInvoices = () => {
    if (selectedInvoice.length === 0) {
      const confirm = window.confirm(
        "Are you sure you want to export all active orders?"
      );
      if (!confirm) return;
    }
    const invoicesTOExport =
      selectedInvoice.length > 0 ? selectedInvoice : invoices;
    // Create an array of objects with the desired structure for export
    const exportData = invoicesTOExport.map((order) => {
      const allItemsQuantities = order.items.map((item) => item.quantity); // Calculate quantities based on the order

      const allItemsNames = order.items.map(
        // Extract the names of the items from the order items based on the order items id
        (item) => products.find((product) => product.id === item.id).name
      );

      console.log("allItemsNames", allItemsNames);
      const allItemsColumns = allItemsNames.reduce((acc, item, index) => {
        return {
          ...acc,
          [item]: allItemsQuantities[index],
        };
      }, {});

      return {
        "Invoice Number": order.proformaNumber || order.id,
        Date: order.date,
        "Client Number": order.selectedResultCustomer.clientNumber,
        "Client Address": order.selectedResultCustomer.address,
        name: order.selectedResultCustomer.name,
        Discount: order.discountedPriceTotal.toFixed(2),
        paid: order?.totalPaid ? order.totalPaid : 0,
        Total: order?.totalPaid
          ? (order.totalAmount - order.totalPaid).toFixed(2)
          : order.totalAmount.toFixed(2),
        ...allItemsColumns,
      };
    });

    // Create a worksheet from the modified data
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.json_to_sheet(exportData);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workBook, workSheet, "Active Orders");

    // Export the workbook to a file
    XLSX.writeFile(workBook, "Active-Orders.xlsx");

    setSelectedInvoice([]); // Clear the selected invoices
  };

  const generatePDFAll = () => {
    if (selectedInvoice.length === 0) {
      const confirm = window.confirm(
        "Are you sure you want to Generate PDFs for all active orders?"
      );
      if (!confirm) return;
    }
    const data = invoices.filter((order) => order.status !== "Completed");

    switch (true) {
      case selectedInvoice.length > 0:
        generatePDFsForOrders(selectedInvoice);
        break;
      case selectedInvoice.length === 0:
        generatePDFsForOrders(data);
        break;
      default:
        // Default case if none of the conditions is met
        setSelectedInvoice([]); // Clear the selected invoices
        break;
    }
    setSelectedInvoice([]); // Clear the selected invoices
  };

  const generatePDFAndPrintAllInOne = () => {
    if (selectedInvoice.length === 0) {
      const confirm = window.confirm(
        "Are you sure you want to Generate PDFs & Print for all active orders?"
      );
      if (!confirm) return;
    }
    const data = invoices.filter((order) => order.status !== "Completed");

    switch (true) {
      case selectedInvoice.length > 0:
        generateReceiptPDFCombined(selectedInvoice);
        break;
      case selectedInvoice.length === 0:
        generateReceiptPDFCombined(data);
        break;
      default:
        // Default case if none of the conditions is met
        setSelectedInvoice([]); // Clear the selected invoices
        break;
    }
    setSelectedInvoice([]); // Clear the selected invoices
  };

  const handleDeleteSelectedInvoices = async () => {
    if (selectedInvoice.length === 0) {
      alert("No active orders selected for deletion.");
      return;
    }

    const userConfirmed = window.confirm(
      "Are you sure you want to delete the selected active orders?"
    );

    if (!userConfirmed) {
      return;
    }

    // delete the selected invoice from the server
    if (selectedInvoice.length === 1) {
      try {
        const response = await fetch(
          `${config.URL_PROD}/invoices/${selectedInvoice[0].id}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setInvoices(data || []);
          setAllInvoices(data || []);
          setSelectedInvoice([]);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      const selectedInvoiceIds = selectedInvoice.map((invoice) => invoice.id);
      // delete the multiple selected invoices from the server
      try {
        const response = await fetch(
          `${config.URL_PROD}/invoices/${selectedInvoiceIds}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setInvoices(data || []);
          setAllInvoices(data || []);
          setSelectedInvoice([]);
        }
      } catch (error) {
        console.log(error);
      }
    }

    /*  const invoices = JSON.parse(localStorage.getItem("invoices")) || [];

    // Use filter to exclude selected invoices based on their id
    const remainingInvoices = invoices.filter(
      // (invoice) => invoice.id !== selectedInvoice[0].id multi select
      (invoice) => !selectedInvoice.some((item) => item.id === invoice.id)
    );

    const products = JSON.parse(localStorage.getItem("products")) || [];
    const selectedInvoicesItems = selectedInvoice.map(
      (invoice) => invoice.items
    );
    const selectedInvoicesItemsFlat = selectedInvoicesItems.flat();

    const updatedProducts = products.map((product) => {
      const matchingItems = selectedInvoicesItemsFlat.filter(
        (item) => item.id === product.id
      );
      if (matchingItems.length > 0) {
        const totalQuantityToAdd = matchingItems.reduce(
          (total, item) => total + item.quantity,
          0
        );
        return {
          ...product,
          quantity: product.quantity + totalQuantityToAdd,
        };
      }
      return product;
    });

    // Update the local storage with the modified products catalog
    localStorage.setItem("products", JSON.stringify(updatedProducts));
    /*  console.log(updatedProducts); 

    // Update the local storage and state with the modified invoices
    localStorage.setItem("invoices", JSON.stringify(remainingInvoices)); */
  };

  const handleSelectedInvoice = (e) => {
    if (e === "all") {
      if (selectedInvoice.length === 0) {
        setSelectedInvoice([...sortedOrders]);
      } else {
        setSelectedInvoice([]);
      }
    } else {
      const uniqueIdentifier = e.id || e.proformaNumber;

      if (
        selectedInvoice.some(
          (item) => (item.id || item.proformaNumber) === uniqueIdentifier
        )
      ) {
        const filtered = selectedInvoice.filter(
          (item) => (item.id || item.proformaNumber) !== uniqueIdentifier
        );
        setSelectedInvoice([...filtered]);
      } else {
        setSelectedInvoice([...selectedInvoice, e]);
      }
    }
  };

  const handleSearchInInvoice = (e) => {
    setSearchInInvoiceInput(e.target.value);

    const inputValue = String(e.target.value).toLowerCase();

    const allOrders = allInvoices || [];

    const allOrdersByDate =
      filteredByDate.length > 0 ? filteredByDate : allOrders;

    const filteredInvoices = allOrdersByDate.filter((order) => {
      const searchTerms = inputValue.toLowerCase().split(" ");

      return searchTerms.every((term) =>
        order.id.toString().includes(term) ||
        (order.selectedResultCustomer.name &&
          order.selectedResultCustomer.name.toLowerCase().includes(term)) ||
        typeof order.selectedResultCustomer.clientNumber === "string"
          ? order.selectedResultCustomer.clientNumber
              .toLowerCase()
              .includes(term)
          : order.selectedResultCustomer.clientNumber.includes(term) ||
            (order.date && order.date.toLowerCase().includes(term)) ||
            order.totalAmount.toString().includes(term) ||
            order?.status?.toLowerCase().includes(term) ||
            order.items.some((item) =>
              item.name.toLowerCase().includes(term)
            ) ||
            order.items.some(
              (item) =>
                item.description.toLowerCase().includes(term) ||
                (item.id && item.id.toString().includes(term)) ||
                (item.sellPrice && item.sellPrice.toString().includes(term)) ||
                (item.name && item.name.toLowerCase().includes(term)) ||
                (item.description &&
                  item.description.toLowerCase().includes(term))
            )
      );
    });

    setInvoices(filteredInvoices);
  };

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
    const inputValue = e.target.value.toLowerCase(); // Convert input value to lowercase directly

    console.log("inputValue", inputValue);
    const allOrders = allInvoices || [];

    const allOrdersByDate =
      filteredByDate.length > 0 ? filteredByDate : allOrders;

    const filtered = allOrdersByDate.filter((order) => {
      const customerName = order.selectedResultCustomer.saveAS
        ? order.selectedResultCustomer.saveAS.toLowerCase()
        : "";
      const clientNumber = order.selectedResultCustomer.clientNumber
        ? String(order.selectedResultCustomer.clientNumber)
        : ""; // Ensure clientNumber is converted to string
      const date = order.date ? order.date.toLowerCase() : ""; // Convert date to lowercase if it exists
      const status = order.status ? order.status.toLowerCase() : "";

      return order.id.toString().includes(inputValue) ||
        customerName.includes(inputValue) ||
        typeof clientNumber === "string"
        ? clientNumber.toLowerCase().includes(inputValue)
        : clientNumber.includes(inputValue) || // Now calling includes() on string value
            date.includes(inputValue) || // Now calling includes() on string value
            order.totalAmount.toString().includes(inputValue) ||
            status.includes(inputValue);
    });

    setInvoices(filtered);
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;

    if (name === "dateFrom") {
      setDateFrom(value);
      setDateTo(value);
    } else {
      setDateTo(value);
    }

    if (name && value) {
      const dateFromUpdeted = name === "dateFrom" ? value : dateFrom;
      const dateToUpdeted = name === "dateTo" ? value : dateFromUpdeted;
      const allOrders = JSON.parse(localStorage.getItem("invoices")) || [];

      const filtered = allOrders.filter((order) => {
        return (
          new Date(order.date) >= new Date(dateFromUpdeted) &&
          new Date(order.date) <= new Date(dateToUpdeted)
        );
      });

      setFilteredByDate(filtered);
      setInvoices(filtered);
    }
  };

  const handleReset = () => {
    // Reset form and clear filtered orders
    setDateFrom("");
    setDateTo("");
    setSearchInput("");
    setInvoices(allInvoices);

    setFilteredByDate([]);
  };

  useEffect(() => {
    setInvoicesSelected(selectedInvoice.length > 0);
    if (selectedInvoice.length === 0) {
      setCreatePackageList(false);
    }
  }, [selectedInvoice]);

  const editHandler = () => {
    if (selectedInvoice.length === 0) {
      alert("No invoice selected for editing.");
      return;
    }

    if (selectedInvoice.length > 1) {
      alert("Please select only one active order for editing.");
      return;
    }

    // Find the invoice id from the selectedInvoice
    const invoiceId = selectedInvoice[0].id;

    // Locate the index in the sorted array based on the id
    const index = sortedOrders.findIndex((invoice) => invoice.id === invoiceId);

    if (index === -1) {
      alert("Selected invoice not found in the list.");
      return;
    }

    window.location.href = `/EditInvoice/${invoiceId}`;

    setSelectedInvoice([]);
  };

  const duplicateHandler = () => {
    if (selectedInvoice.length === 0) {
      alert("No invoice selected for duplication.");
      return;
    }

    if (selectedInvoice.length > 1) {
      alert("Please select only one active order for duplication.");
      return;
    }

    /* const confirm = window.confirm(
      "Are you sure you want to duplicate the selected invoice?"
    );
    if (!confirm) {
      setSelectedInvoice([]);
      return;
    } */

    const invoiceId = selectedInvoice[0].id;

    const index = sortedOrders.findIndex((invoice) => invoice.id === invoiceId);

    if (index === -1) {
      alert("Selected invoice not found in the list.");
      return;
    }

    window.location.href = `/DuplicateInvoice/${invoiceId}`;

    setSelectedInvoice([]);
  };

  const handlePaidFull = async (order) => {
    try {
      const findInvoice = invoices.find(
        (invoice) => parseInt(invoice.id) === parseInt(order.id)
      );

      if (!findInvoice) {
        console.error("Invoice not found");
        return;
      }

      // calculate the total amount of the invoice
      const calculatePaidTotal = order.payments?.reduce(
        (acc, curr) => acc + Number(curr.amount),
        0
      );
      const paymentStatus = calculatePaidTotal === Number(order.totalAmount);

      // Update only the specific invoice
      const updatedInvoice = paymentStatus
        ? {
            ...findInvoice,
            payments: [{ date: "", amount: 0.0, paymentMethod: "" }],
            totalPaid: 0.0,
            status: "invoice",
          }
        : {
            ...findInvoice,
            payments: [
              {
                date: new Date().toISOString().slice(0, 10),
                amount: Number(findInvoice.totalAmount),
                paymentMethod: "",
                status: "",
              },
            ],
            totalPaid: Number(findInvoice.totalAmount),
            status: "invoice",
          };

      // Send the updated invoice to the server
      const response = await fetch(`${config.URL_PROD}/invoices/${order.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(updatedInvoice),
      });

      if (!response.ok) {
        throw new Error("Failed to update invoice on the server");
      }

      // Update state with the updated invoice if the request was successful
      setInvoices((prevInvoices) =>
        prevInvoices.map((invoice) =>
          invoice.id === updatedInvoice.id ? updatedInvoice : invoice
        )
      );
    } catch (error) {
      console.error("Error updating invoice:", error);
      // Handle error, show error message, etc.
    }
  };

  const handlePaymentDateChange = async (e, order, paymentIndex) => {
    try {
      const { value } = e.target;

      const updatedInvoices = invoices.map((invoice) => {
        if (invoice === order) {
          const updatedPayments = [...invoice.payments];
          updatedPayments[paymentIndex] = {
            ...updatedPayments[paymentIndex],
            date: value,
            status: "invoice",
          };

          return {
            ...invoice,
            payments: updatedPayments,
          };
        }
        return invoice;
      });

      // Send the updated invoice to the server
      const response = await fetch(`${config.URL_PROD}/invoices/${order.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(
          updatedInvoices.find((invoice) => invoice.id === order.id)
        ),
      });

      if (!response.ok) {
        throw new Error("Failed to update payment date on the server");
      }
    } catch (error) {
      console.error("Error updating payment date:", error);
      // Handle error, show error message, etc.
    }
  };

  const handleTotalPaidChange = async (order) => {
    const updatedTotalPaid = order.reduce(
      (acc, curr) => Number(acc) + Number(curr.amount),
      0
    );

    const updatedInvoices = invoices.map((invoice) => {
      // Assuming invoiceUpdatedPayments is an object
      if (invoice.id === invoiceUpdatedPayments.id) {
        invoice.totalPaid = Number(updatedTotalPaid).toFixed(2);
        invoice.payments = order;
        invoice.status = "invoice";
      }
      return invoice;
    });

    console.log(invoiceUpdatedPayments);
    // Update the server with the updated invoice
    // Send the updated invoice to the server
    try {
      const response = await fetch(
        `${config.URL_PROD}/invoices/${invoiceUpdatedPayments.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(invoiceUpdatedPayments),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update total paid on the server");
      }

      if (response.ok) {
        setInvoices(updatedInvoices);
      }
    } catch (error) {}
  };

  const openPaymentPopUpModal = (order) => {
    setInvoiceUpdatedPayments(order);
    setIsPaymentDateModalOpen(true);
    const selectedOrderPayments = order.payments || [
      {
        date: new Date().toISOString().slice(0, 10),
        amount: 0.0,
        paymentMethod: "",
        status: "",
      },
    ];
    setUpdatedTotalPaid(selectedOrderPayments);
  };

  const closePaymentPopUpModal = () => {
    setIsPaymentDateModalOpen(false);
    setUpdatedTotalPaid([]); // Reset selected invoice
  };

  const handlePaymentMethodChangeRightOnInvoice = (e, order) => {
    const updatedInvoicePayments = order.payments.map((payment) => {
      return {
        ...payment,
        paymentMethod: e,
        finalizedDate:
          e === "Bank Contact"
            ? new Date(new Date(payment.date).getTime() + 24 * 60 * 60 * 1000)
                .toISOString()
                .slice(0, 10)
            : new Date().toISOString().slice(0, 10),
        status: "invoice",
      };
    });

    const updatedInvoices = invoices.map((invoice) => {
      if (invoice.id === order.id) {
        return {
          ...invoice,
          payments: updatedInvoicePayments,
        };
      }
      return invoice;
    });

    // update the server with the updated invoice
    // Send the updated invoice to the server
    fetch(`${config.URL_PROD}/invoices/${order.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(
        updatedInvoices.find((invoice) => invoice.id === order.id)
      ),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((err) => console.error(err));

    const existingBankData = JSON.parse(localStorage.getItem("bank")) || [];

    // Filter existing bank data to exclude items with matching IDs from updatedInvoices
    const updatedBankData = existingBankData.filter(
      (item) => !updatedInvoices.some((invoice) => invoice.id === item.id)
    );

    // Filter the updatedInvoices to include only Bank Transfer or Bank Contact
    const filteredUpdatedInvoices = updatedInvoices.filter(
      (invoice) =>
        invoice.payments[0]?.paymentMethod === "Bank Transfer" ||
        invoice.payments[0]?.paymentMethod === "Bank Contact"
    );

    // Combine the updated bank data and the filtered updated invoices
    const bankUpdate = [...updatedBankData, ...filteredUpdatedInvoices];

    localStorage.setItem("bank", JSON.stringify(bankUpdate));

    setInvoices(updatedInvoices);
  };

  const createPackageListByProduct = () => {
    if (selectedInvoice.length === 0) {
      alert("No invoice selected for creating a package list.");
      setCreatePackageList(false);
      return;
    }

    const packageList = selectedInvoice.flatMap((invoice) => {
      const { selectedResultCustomer, items } = invoice;
      const { name, clientNumber } = selectedResultCustomer;
      return items.map((item) => ({
        companyName: name, // Renamed to avoid conflict with item name
        companyID: clientNumber,
        id: item.id,
        itemName: item.name, // Renamed to avoid conflict with companyName
        image: item?.image || "n/a",
        quantity: item.quantity,
      }));
    });

    const quantities = products.map((product) => ({
      id: product.id,
      product: product.name,
      quantityUnits: product.quantity,
      pricePerUnitOrBox: product.pricePerUnitOrBox,
      unitsInBox: product.unitsInBox,
      pricePerXAmount: product.pricePerXAmount,
      quantityBoxesAndUnits: calculateQuantityBoxesAndUnits(product),
    }));

    // Group by product within each company
    const groupedByProduct = packageList.reduce((acc, item) => {
      const { companyName, companyID, id, itemName, image, quantity } = item;
      if (!acc[id]) {
        acc[id] = [];
      }
      acc[id].push({
        id,
        companyName,
        companyID,
        itemName,
        image,
        quantity,
      });
      return acc;
    }, {});

    generatePDFPackingListByProduct(groupedByProduct);
    setCreatePackageList(false);
    setSelectedInvoice([]);
    return groupedByProduct;
  };

  const createPackageListByCustomer = () => {
    if (selectedInvoice.length === 0) {
      alert("No invoice selected for creating a package list.");
      setCreatePackageList(false);
      return;
    }

    // Flatten the selected invoices and map them to required format
    const packageList = selectedInvoice.flatMap((invoice) => {
      const { selectedResultCustomer, items } = invoice;
      const { name, clientNumber } = selectedResultCustomer;
      return items.map((item) => ({
        companyName: name,
        companyID: clientNumber,
        id: item.id,
        itemName: item.name,
        description: item.description,
        // image: item?.image || "n/a",
        quantity: item.quantity,
      }));
    });

    // Group items by customer
    const groupedByCustomer = packageList.reduce((acc, item) => {
      const { companyName } = item;
      if (!acc[companyName]) {
        acc[companyName] = [];
      }
      acc[companyName].push(item);
      return acc;
    }, {});

    generatePDFPackingListByCustomer(groupedByCustomer, products);
    setCreatePackageList(false);
    setSelectedInvoice([]);
  };

  const [removeItemPopup, setRemoveItemPopup] = useState(false);
  const [itemIdToRemove, setItemIdToRemove] = useState("");

  // Define state for itemIdToRemove

  async function handleRemoveItem(itemIdToRemove) {
    // Please enter the admin password to remove the item
    const promptPassword = prompt("Please enter the admin password");

    // If cancel is clicked on the prompt
    if (promptPassword === null) {
      return;
    }

    if (promptPassword !== "adminShloma") {
      alert("Incorrect password. Please try again.");
      return;
    }

    // Create an array to store the updated invoices
    const updatedInvoices = [];

    // Iterate over the invoices
    for (const invoice of invoices) {
      // Check if the invoice is selected
      if (
        selectedInvoice.some((selectedInv) => selectedInv.id === invoice.id)
      ) {
        // Only update selected invoices
        const updatedItems = invoice.items.filter(
          (item) => item.id !== parseInt(itemIdToRemove)
        );

        const calculateSubTotal = updatedItems.reduce(
          (acc, curr) =>
            acc +
            curr.quantity * (Number(curr.sellPrice) || Number(curr.salePrice)),
          0
        );

        const calculateDiscountedPriceTotal = updatedItems.reduce(
          (acc, curr) =>
            acc +
            (parseFloat(curr.discount) / 100) *
              curr.quantity *
              (parseFloat(curr.sellPrice) || parseFloat(curr.salePrice)),
          0
        );

        const updatedInvoice = {
          ...invoice,
          items: updatedItems,
          subTotal: calculateSubTotal,
          discountedPriceTotal: calculateDiscountedPriceTotal,
          totalAmount: calculateSubTotal - calculateDiscountedPriceTotal,
          totalPaid: 0,
          status: "invoice",
          payments: [{ date: "", amount: 0.0, paymentMethod: "" }],
          paymentDate: "", // You may want to handle this differently
        };

        updatedInvoices.push(updatedInvoice);
      }
    }

    console.log(updatedInvoices);

    // Update the server with the updated invoices
    try {
      const response = await fetch(`${config.URL_PROD}/invoices/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(updatedInvoices),
      });

      if (!response.ok) {
        throw new Error("Failed to update invoice on the server");
      }

      console.log("Invoice updated successfully");
      // Update state
      setInvoices(updatedInvoices);
      // Deselect the invoices
      setSelectedInvoice([]);
    } catch (error) {
      console.error("Error updating invoice:", error);
      // Handle error, show error message, etc.
    }
  }

  return (
    <div
      style={{
        justifyContent: "space-around",
        marginTop: "8rem",
        marginLeft: "22rem",
        marginRight: "2rem",
      }}
    >
      <h1>Invoices</h1>

      {createPackageList && selectedInvoice.length > 0 && (
        <ConfirmACreatePackageList
          createPackageListByProduct={createPackageListByProduct}
          createPackageListByCustomer={createPackageListByCustomer}
        />
      )}

      <div className={styles.totalDiv}>
        <div className={styles.totalDivItem}>
          <p className={styles.totalDivItemTitle}>Total</p>
          <p className={styles.totalDivItemValue}>
            {invoices
              .reduce((acc, curr) => acc + Number(curr.totalAmount), 0)
              .toFixed(2)}
          </p>
        </div>
        <div className={styles.totalDivItem}>
          <p className={styles.totalDivItemTitle}>Paid</p>
          <p className={styles.totalDivItemValue}>
            {
              // sum all the payments from all invoices arrays of payments
              invoices
                .reduce(
                  (acc, curr) =>
                    acc + Number(curr.totalPaid ? curr.totalPaid : 0),
                  0
                )
                .toFixed(2)
            }
          </p>
        </div>
        <div className={styles.totalDivItem}>
          <p className={styles.totalDivItemTitle}>Unpaid</p>
          <p className={styles.totalDivItemValue}>
            {invoices
              .reduce(
                (acc, curr) =>
                  Number(curr.totalPaid) > 0
                    ? acc + Number(curr.totalAmount - curr.totalPaid)
                    : acc + Number(curr.totalAmount),
                0
              )
              .toFixed(2)}
          </p>
        </div>
      </div>

      {/* button to open the poup to remove an item */}
      <button
        onClick={() => setRemoveItemPopup(true)}
        style={{ marginBottom: "1rem" }}
      >
        Remove Item
      </button>

      {/* add here the alert */}
      <div
        className={`alert alert-warning alert-dismissible fade show`}
        style={{
          display: "none",
        }}
        role="alert"
      >
        <h4 className="alert-heading">Generate PDFs</h4>
        <p>
          Please wait while we generate the PDFs for all invoices. This might
          take a while depending on the number of invoices.
        </p>
        <hr />
        <p className="mb-0">
          Please do not close the browser or refresh the page until all PDFs
          have been generated.
        </p>
        <p className="munber-of-pdf"></p>
      </div>
      <div>
        <NavLink
          to={"/ImportInvoicesExcelTable"}
          style={{
            padding: "0.07rem 0.5rem",
            margin: "0.2rem",
            marginBottom: "1rem",
            color: "white",
            borderRadius: "5px",
            textDecoration: "none",
            cursor: "pointer",
          }}
          className="btn btn-primary"
        >
          Import Excel Invoices
        </NavLink>
      </div>
      <div>
        {/* Search of all view from outside */}
        <div>
          <div className={styles.searchDiv}>
            {/* search any info that is in the invoice */}
            <input
              type="text"
              placeholder="Search Any thing IN THE INVOICE, Date (yyyy-mm-dd)"
              className={styles.searchInput}
              value={searchInInvoiceInput}
              onChange={handleSearchInInvoice}
            />
          </div>
          <input
            type="text"
            placeholder="Search Any thing, Date (yyyy-mm-dd)"
            className={styles.searchInput}
            value={searchInput}
            onChange={handleSearch}
          />
          {/* input date  */}
          <div className={styles.dateInput}>
            <label htmlFor="dateFrom">
              From:{" "}
              <input
                type="date"
                id="dateFrom"
                name="dateFrom"
                value={dateFrom}
                onChange={handleDateChange}
              />
            </label>

            <label htmlFor="dateTo">
              To:{" "}
              <input
                type="date"
                id="dateTo"
                name="dateTo"
                value={dateTo}
                onChange={handleDateChange}
              />
            </label>
            <button type="button" onClick={handleReset}>
              Reset
            </button>
          </div>
        </div>
        {/* search by id name date total  status */}
        {/* empty div to hold the place for styling butnsActionsNotSelected */}
        <div
          className={`${
            invoicesSelected ? "" : styles.butnsActionsNotSelected
          }`}
        >
          <p
            style={{
              width: "48px",
              height: "48px",
            }}
          ></p>
        </div>
        <div className={`${invoicesSelected ? styles.butnsActions : ""}`}>
          <button
            onClick={generatePDFAndPrintAllInOne}
            style={{ marginRight: "2rem" }}
            title="Generate PDF & Print"
          >
            <svg
              width="24"
              height="24"
              fill="currentColor"
              className="bi bi-printer"
              viewBox="0 0 16 16"
            >
              <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
            </svg>
            <br />
            <span className={styles.textUnderIcon}>Print</span>
          </button>

          <button
            onClick={generatePDFAll}
            style={{ marginRight: "2rem" }}
            title="Generate PDFs"
          >
            <svg
              width="24"
              height="24"
              fill="currentColor"
              className="bi bi-file-earmark-pdf"
              viewBox="0 0 16 16"
            >
              <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
              <path d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
            </svg>
            <br />
            <span className={styles.textUnderIcon}>PDF</span>
          </button>

          {/* download excel of all invoices */}
          <button
            onClick={handleOnExportInvoices}
            style={{ marginRight: "2rem" }}
            title="Export to Excel"
          >
            <svg
              width="24"
              height="24"
              fill="currentColor"
              className="bi bi-filetype-xls"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM6.472 15.29a1.176 1.176 0 0 1-.111-.449h.765a.578.578 0 0 0 .254.384c.07.049.154.087.25.114.095.028.202.041.319.041.164 0 .302-.023.413-.07a.559.559 0 0 0 .255-.193.507.507 0 0 0 .085-.29.387.387 0 0 0-.153-.326c-.101-.08-.255-.144-.462-.193l-.619-.143a1.72 1.72 0 0 1-.539-.214 1.001 1.001 0 0 1-.351-.367 1.068 1.068 0 0 1-.123-.524c0-.244.063-.457.19-.639.127-.181.303-.322.527-.422.225-.1.484-.149.777-.149.305 0 .564.05.78.152.216.102.383.239.5.41.12.17.186.359.2.566h-.75a.56.56 0 0 0-.12-.258.625.625 0 0 0-.247-.181.923.923 0 0 0-.369-.068c-.217 0-.388.05-.513.152a.472.472 0 0 0-.184.384c0 .121.048.22.143.3a.97.97 0 0 0 .405.175l.62.143c.217.05.406.12.566.211a1 1 0 0 1 .375.358c.09.148.135.335.135.56 0 .247-.063.466-.188.656a1.216 1.216 0 0 1-.539.439c-.234.105-.52.158-.858.158-.254 0-.476-.03-.665-.09a1.404 1.404 0 0 1-.478-.252 1.13 1.13 0 0 1-.29-.375Zm-2.945-3.358h-.893L1.81 13.37h-.036l-.832-1.438h-.93l1.227 1.983L0 15.931h.861l.853-1.415h.035l.85 1.415h.908L2.253 13.94l1.274-2.007Zm2.727 3.325H4.557v-3.325h-.79v4h2.487v-.675Z"
              />
            </svg>
            <br />
            <span className={styles.textUnderIcon}>Excel</span>
          </button>

          {/* delete selected invoices */}
          <button
            style={{ marginRight: "2rem" }}
            onClick={() => {
              handleDeleteSelectedInvoices();
            }}
            title="Delete Selected"
          >
            <svg
              x="0px"
              y="0px"
              width="26"
              height="26"
              fill="white"
              viewBox="0 0 24 24"
            >
              <path d="M 10 2 L 9 3 L 4 3 L 4 5 L 5 5 L 5 20 C 5 20.522222 5.1913289 21.05461 5.5683594 21.431641 C 5.9453899 21.808671 6.4777778 22 7 22 L 17 22 C 17.522222 22 18.05461 21.808671 18.431641 21.431641 C 18.808671 21.05461 19 20.522222 19 20 L 19 5 L 20 5 L 20 3 L 15 3 L 14 2 L 10 2 z M 7 5 L 17 5 L 17 20 L 7 20 L 7 5 z M 9 7 L 9 18 L 11 18 L 11 7 L 9 7 z M 13 7 L 13 18 L 15 18 L 15 7 L 13 7 z"></path>
            </svg>
            <br />
            <span className={styles.textUnderIcon}>Trash</span>
          </button>
          {/* button to edit with a icon */}
          <button
            style={{ marginRight: "2rem" }}
            title="Edit"
            onClick={editHandler}
          >
            <svg
              x="0px"
              y="0px"
              width="26"
              height="26"
              viewBox="0 0 50 50"
              fill="white"
            >
              <path d="M 43.125 2 C 41.878906 2 40.636719 2.488281 39.6875 3.4375 L 38.875 4.25 L 45.75 11.125 C 45.746094 11.128906 46.5625 10.3125 46.5625 10.3125 C 48.464844 8.410156 48.460938 5.335938 46.5625 3.4375 C 45.609375 2.488281 44.371094 2 43.125 2 Z M 37.34375 6.03125 C 37.117188 6.0625 36.90625 6.175781 36.75 6.34375 L 4.3125 38.8125 C 4.183594 38.929688 4.085938 39.082031 4.03125 39.25 L 2.03125 46.75 C 1.941406 47.09375 2.042969 47.457031 2.292969 47.707031 C 2.542969 47.957031 2.90625 48.058594 3.25 47.96875 L 10.75 45.96875 C 10.917969 45.914063 11.070313 45.816406 11.1875 45.6875 L 43.65625 13.25 C 44.054688 12.863281 44.058594 12.226563 43.671875 11.828125 C 43.285156 11.429688 42.648438 11.425781 42.25 11.8125 L 9.96875 44.09375 L 5.90625 40.03125 L 38.1875 7.75 C 38.488281 7.460938 38.578125 7.011719 38.410156 6.628906 C 38.242188 6.246094 37.855469 6.007813 37.4375 6.03125 C 37.40625 6.03125 37.375 6.03125 37.34375 6.03125 Z"></path>
            </svg>
            <br />
            <span className={styles.textUnderIcon}>Edit</span>
          </button>

          {/* button to duplicate with a icon */}
          <button
            style={{ marginRight: "2rem" }}
            title="Duplicate"
            onClick={duplicateHandler}
          >
            <svg
              x="0px"
              y="0px"
              width="28"
              height="28"
              viewBox="0 0 88 88"
              fill="white"
            >
              <title />
              <path d="M57.11,63.66h-36V17.79H57.11ZM24.34,60.38H53.83V21.06H24.34Z" />
              <path d="M29.25,37.45H48.92v3.27H29.25Zm0,6.55H48.92v3.28H29.25Zm0,6.55H48.92v3.28H29.25Zm0-19.69h9.83v3.28H29.25Z" />
              <path d="M66.94,70.21H32.53V66.94H63.66V27.62H60.47V24.34h6.47Z" />
            </svg>
            <br />
            <span className={styles.textUnderIcon}>Duplicate</span>
          </button>

          {/* button to create package list with a icon */}
          <button
            title="Create Package List"
            onClick={() => {
              if (selectedInvoice.length === 0) {
                alert("No invoice selected for creating a package list.");
                return;
              } else {
                setCreatePackageList(!createPackageList);
              }
            }}
          >
            <svg
              x="0px"
              y="0px"
              width="28"
              height="28"
              fill="white"
              viewBox="0 0 640 512"
            >
              <path d="M256 48c0-26.5 21.5-48 48-48H592c26.5 0 48 21.5 48 48V464c0 26.5-21.5 48-48 48H381.3c1.8-5 2.7-10.4 2.7-16V253.3c18.6-6.6 32-24.4 32-45.3V176c0-26.5-21.5-48-48-48H256V48zM571.3 347.3c6.2-6.2 6.2-16.4 0-22.6l-64-64c-6.2-6.2-16.4-6.2-22.6 0l-64 64c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L480 310.6V432c0 8.8 7.2 16 16 16s16-7.2 16-16V310.6l36.7 36.7c6.2 6.2 16.4 6.2 22.6 0zM0 176c0-8.8 7.2-16 16-16H368c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H16c-8.8 0-16-7.2-16-16V176zm352 80V480c0 17.7-14.3 32-32 32H64c-17.7 0-32-14.3-32-32V256H352zM144 320c-8.8 0-16 7.2-16 16s7.2 16 16 16h96c8.8 0 16-7.2 16-16s-7.2-16-16-16H144z" />
            </svg>
            <br />
            <span className={styles.textUnderIcon}>Packaging List</span>
          </button>
        </div>
      </div>

      {removeItemPopup && (
        <div className={styles.popup}>
          <div className={styles.popupContent}>
            <h3>Remove Item</h3>
            <input
              type="text"
              placeholder="Enter the item id to remove"
              value={itemIdToRemove}
              onChange={(e) => setItemIdToRemove(e.target.value)}
            />
            <button
              onClick={() => {
                handleRemoveItem(itemIdToRemove);
                setItemIdToRemove("");
                setRemoveItemPopup(false);
              }}
            >
              Remove Item
            </button>
            <button onClick={() => setRemoveItemPopup(false)}>Cancel</button>
          </div>
        </div>
      )}

      <table className="table table-striped table-bordered table-hover table-sm">
        <thead style={{ backgroundColor: "#A8A8A8" }}>
          <tr>
            <th
              scope="col"
              style={{
                textAlign: "center",
                verticalAlign: "middle",
                backgroundColor: "#A8A8A8",
                cursor: "pointer",
              }}
              onClick={() => handleSort("id")}
            >
              #
              {sortBy === "id" && (
                <i
                  className="material-icons"
                  style={{
                    fontSize: "24px",
                    marginLeft: "0.5rem",
                    verticalAlign: "middle",
                  }}
                >
                  {sortOrder === "asc" ? "arrow_drop_down" : "arrow_drop_up"}
                </i>
              )}
            </th>
            <th
              scope="col"
              style={{
                textAlign: "center",
                verticalAlign: "middle",
                backgroundColor: "#A8A8A8",
                cursor: "pointer",
              }}
              onClick={() => handleSort("clientNumber")}
            >
              Client Number
              {sortBy === "clientNumber" && (
                <i
                  className="material-icons"
                  style={{
                    fontSize: "24px",
                    marginLeft: "0.5rem",
                    verticalAlign: "middle",
                  }}
                >
                  {sortOrder === "asc" ? "arrow_drop_down" : "arrow_drop_up"}
                </i>
              )}
            </th>
            <th
              scope="col"
              style={{
                textAlign: "center",
                verticalAlign: "middle",
                backgroundColor: "#A8A8A8",
                cursor: "pointer",
              }}
              onClick={() => handleSort("selectedResultCustomer.saveAs")}
            >
              Customer Name
              {sortBy === "selectedResultCustomer.saveAs" && (
                <i
                  className="material-icons"
                  style={{
                    fontSize: "24px",
                    marginLeft: "0.5rem",
                    verticalAlign: "middle",
                  }}
                >
                  {sortOrder === "asc" ? "arrow_drop_down" : "arrow_drop_up"}
                </i>
              )}
            </th>
            <th
              scope="col"
              className={styles.tableHead}
              onClick={() => handleSort("date")}
            >
              Order Date
              {sortBy === "date" && (
                <i
                  className="material-icons"
                  style={{
                    fontSize: "24px",
                    marginLeft: "0.5rem",
                    verticalAlign: "middle",
                  }}
                >
                  {sortOrder === "asc" ? "arrow_drop_down" : "arrow_drop_up"}
                </i>
              )}
            </th>
            <th
              scope="col"
              className={styles.tableHead}
              onClick={() => handleSort("totalAmount")}
            >
              Total
              {sortBy === "totalAmount" && (
                <i
                  className="material-icons"
                  style={{
                    fontSize: "24px",
                    marginLeft: "0.5rem",
                    verticalAlign: "middle",
                  }}
                >
                  {sortOrder === "asc" ? "arrow_drop_down" : "arrow_drop_up"}
                </i>
              )}
            </th>
            <th
              scope="col"
              className={styles.tableHead}
              onClick={() => handleSort("paid")}
            >
              Paid
              {sortBy === "paid" && (
                <i
                  className="material-icons"
                  style={{
                    fontSize: "24px",
                    marginLeft: "0.5rem",
                    verticalAlign: "middle",
                  }}
                >
                  {sortOrder === "asc" ? "arrow_drop_down" : "arrow_drop_up"}
                </i>
              )}
            </th>
            <th
              scope="col"
              className={styles.tableHead}
              onClick={() => handleSort("paymentDate")}
            >
              Payment Date
              {sortBy === "paymentDate" && (
                <i
                  className="material-icons"
                  style={{
                    fontSize: "24px",
                    marginLeft: "0.5rem",
                    verticalAlign: "middle",
                  }}
                >
                  {sortOrder === "asc" ? "arrow_drop_down" : "arrow_drop_up"}
                </i>
              )}
            </th>
            <th
              scope="col"
              className={styles.tableHead}
              onClick={() => handleSort("unpaid")}
            >
              Unpaid
              {sortBy === "unpaid" && (
                <i
                  className="material-icons"
                  style={{
                    fontSize: "24px",
                    marginLeft: "0.5rem",
                    verticalAlign: "middle",
                  }}
                >
                  {sortOrder === "asc" ? "arrow_drop_down" : "arrow_drop_up"}
                </i>
              )}
            </th>
            <th
              scope="col"
              className={styles.tableHead}
              onClick={() => handleSort("paid full")}
            >
              Paid Full
              {sortBy === "paid full" && (
                <i
                  className="material-icons"
                  style={{
                    fontSize: "24px",
                    marginLeft: "0.5rem",
                    verticalAlign: "middle",
                  }}
                >
                  {sortOrder === "asc" ? "arrow_drop_down" : "arrow_drop_up"}
                </i>
              )}
            </th>
            <th scope="col" className={styles.tableHead}>
              Method
            </th>
            <th
              scope="col"
              className={styles.tableHead}
              onClick={() => handleSort("status")}
            >
              Status
              {sortBy === "status" && (
                <i
                  className="material-icons"
                  style={{
                    fontSize: "24px",
                    marginLeft: "0.5rem",
                    verticalAlign: "middle",
                  }}
                >
                  {sortOrder === "asc" ? "arrow_drop_down" : "arrow_drop_up"}
                </i>
              )}
            </th>
            <th scope="col" className={styles.tableHead}>
              View Order
            </th>
            <th scope="col" className={styles.tableHead}>
              <input
                type="checkbox"
                checked={selectedInvoice.length === sortedOrders.length}
                style={{ marginLeft: "1rem", marginTop: "0.5rem" }}
                onClick={(e) => {
                  handleSelectedInvoice("all");
                }}
              />
            </th>
            {/*  <th scope="col" className={styles.tableHead}>
              Remove Item
            </th> */}
          </tr>
        </thead>
        <tbody>
          {sortedOrders.map((order, index) => {
            const contacts = costumers;
            // Find the contact by company name
            const customerContact = contacts.find((contact) =>
              contact.name.includes(order.selectedResultCustomer.name)
            );
            const clientNumber = customerContact
              ? customerContact.clientNumber
              : "";
            // Extract the desired property from the contact
            const customerSaveAs = customerContact
              ? customerContact.saveAs
              : "";
            const paymentStatus =
              Number(order.totalAmount).toFixed(2) ===
              Number(order.totalPaid).toFixed(2)
                ? styles.paid
                : styles.tableData;
            const paidFull =
              Number(order.totalAmount).toFixed(2) ===
              Number(order.totalPaid).toFixed(2)
                ? styles.paymentDateInputpaid
                : styles.paymentDateInputUnpaid;

            return (
              <tr key={index} className={styles.table}>
                <td className={paymentStatus}>
                  {order.id || order.proformaNumber}
                </td>
                <td className={paymentStatus}>{clientNumber}</td>
                <td className={paymentStatus}>{customerSaveAs}</td>
                <td className={paymentStatus}>{order.date || ""}</td>
                <td
                  className={paymentStatus}
                  onClick={() => {
                    openPaymentPopUpModal(order);
                    setSelectedOrder(order);
                  }}
                >
                  {parseFloat(Math.round(order.totalAmount * 20) / 20).toFixed(
                    2
                  )}
                </td>
                <td
                  className={paymentStatus}
                  onClick={() => {
                    openPaymentPopUpModal(order);
                    setSelectedOrder(order);
                  }}
                >
                  {order.totalPaid
                    ? Number(order.totalPaid).toFixed(2)
                    : Number(0).toFixed(2)}
                </td>
                <td className={paymentStatus}>
                  {/* input a date picker  */}
                  <input
                    type="date"
                    value={
                      order.payments
                        ? order.payments[[order.payments.length - 1]]?.date
                        : ""
                    }
                    className={paidFull}
                    onChange={(e) => {
                      handlePaymentDateChange(e, order);
                    }}
                  />
                </td>
                <td
                  className={paymentStatus}
                  onClick={() => {
                    openPaymentPopUpModal(order);
                    setSelectedOrder(order);
                  }}
                >
                  {order.totalPaid
                    ? (
                        Number(order.totalAmount) - Number(order.totalPaid)
                      ).toFixed(2)
                    : Number(order.totalAmount).toFixed(2)}
                </td>
                <td className={paymentStatus}>
                  <input
                    type="checkbox"
                    checked={
                      Number(order.totalAmount).toFixed(2) ===
                      Number(order.totalPaid).toFixed(2)
                    }
                    onChange={(e) => {
                      handlePaidFull(order);
                    }}
                  />
                </td>
                <td className={paymentStatus}>
                  {order.payments && order.payments.paymentMethod !== "" ? (
                    <select
                      className={styles.paymentMethodUpdate}
                      value={
                        order.payments?.length > 0
                          ? order.payments[order.payments.length - 1]
                              ?.paymentMethod
                          : ""
                      }
                      onChange={(e) => {
                        handlePaymentMethodChangeRightOnInvoice(
                          e.target.value,
                          order
                        );
                      }}
                    >
                      <option value="" disabled>
                        Select Method
                      </option>
                      <option value="Cash/Kassa">Cash/Kassa</option>
                      <option value="Bank Contact">Bank Contact</option>
                      <option value="Bank Transfer">Bank Transfer</option>
                    </select>
                  ) : (
                    <select className={styles.paymentMethodUpdate}>
                      <option
                        value={
                          order.payments && order.payments.length > 0
                            ? order.payments[order.payments.length - 1]
                                ?.paymentMethod
                            : ""
                        }
                        disabled
                        defaultValue
                      >
                        {order.payments && order.payments.length > 0
                          ? order.payments[order.payments.length - 1]
                              ?.paymentMethod
                          : ""}
                      </option>
                    </select>
                  )}
                </td>
                <td className={paymentStatus}>{order.status}</td>
                <td className={paymentStatus}>
                  <button onClick={() => openModal(order)}>
                    {/* overview gif */}
                    <img src={overview} alt="overview" width="28" height="28" />
                  </button>
                </td>
                <td className={(styles.tdCheckbox, paymentStatus)}>
                  <input
                    type="checkbox"
                    className={styles.checkbox}
                    checked={selectedInvoice.includes(order)}
                    onChange={(e) => {
                      handleSelectedInvoice(order);
                    }}
                  />
                </td>{" "}
                {/* Add the remove item button */}
                {/*   <td className={styles.tableData}>
                  <input
                    type="number"
                    value={itemIdToRemove}
                    onChange={(e) => setItemIdToRemove(e.target.value)}
                  />
                </td>
                <td className={styles.tableData}>
                  <button onClick={() => handleRemoveItem(itemIdToRemove)}>
                    Remove
                  </button>
                </td> */}
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* modal for order details */}
      {isPaymentDateModalOpen && (
        <div id="myModal" className={styles.popupPaymentDetails}>
          <div className="modal-content" style={{ width: "70%" }}>
            <span className="close" onClick={closePaymentPopUpModal}>
              &times;
            </span>
            <div className="modal-header">
              <h2>Payment Details</h2>
              <h5 className={styles.popupPaymentDetailsId}>
                # {selectedOrder.id}
              </h5>
            </div>
            <div className="modal-body">
              {/* total balance */}
              <div className={styles.totalsUpdateModalAllDiv}>
                <div className={styles.totalsUpdateModal}>
                  <label className={styles.totalAmountUpdateModal}>
                    Total Balance:
                  </label>
                  <input
                    className={styles.totalAmountInputUpdateModal}
                    type="number"
                    value={selectedOrder.totalAmount || ""}
                    disabled
                  />
                </div>
                <div className={styles.totalsUpdateModal}>
                  {/* unpaid */}
                  <label className={styles.unpaidUpdateModal}>Unpaid:</label>
                  <input
                    className={styles.unpaidInputUpdateModal}
                    type="number"
                    value={
                      Number(
                        selectedOrder.totalAmount -
                          updatedTotalPaid.reduce(
                            (acc, curr) => acc + Number(curr.amount),
                            0
                          )
                      ).toFixed(2) || ""
                    }
                    disabled
                  />
                </div>
              </div>
              {/* Display existing payments */}
              <table className={styles.popupPaymentDetailsTble}>
                <thead className={styles.popupPaymentDetailsTb}>
                  <tr>
                    <th className={styles.paymentUpdateTableHeader}>
                      Payment Date
                    </th>
                    <th className={styles.paymentUpdateTableHeader}>
                      Total Paid
                    </th>
                    <th className={styles.paymentUpdateTableHeader}>
                      Payment Method
                    </th>
                    <th className={styles.paymentUpdateTableHeader}>Action</th>
                  </tr>
                </thead>
                <tbody className={styles.popupPaymentDetailsTb}>
                  {updatedTotalPaid?.map((order, index) => (
                    <tr key={index} className={styles.row}>
                      <td>
                        {/* <label className={styles.paymentDateLabelUpdate} htmlFor={`dateFrom_${index}`}>Payment Date:</label> */}
                        <input
                          type="date"
                          className={styles.paymentDateInputUpdate}
                          id={`dateFrom_${index}`}
                          name={`dateFrom_${index}`}
                          value={
                            new Date().toISOString().slice(0, 10) ||
                            order.date ||
                            updatedPaymentDate ||
                            order.paymentDate ||
                            ""
                          }
                          onChange={(e) => {
                            setUpdatedTotalPaid((prev) => {
                              const updated = [...prev];
                              updated[index].date = e.target.value;
                              return updated;
                            });
                          }}
                        />
                      </td>

                      <td>
                        {/* <label className={styles.paymentDateLabelUpdate} htmlFor={`payments_${index}`}>Total Paid:</label> */}
                        <input
                          className={styles.paymentTotalPaidUpdate}
                          type="number"
                          id={`payments_${index}`}
                          name={`payments_${index}`}
                          value={
                            updatedTotalPaid[index]?.amount ||
                            order.amount ||
                            ""
                          }
                          onChange={(e) => {
                            if (
                              Number(e.target.value) >
                                Number(selectedOrder.totalAmount) ||
                              Number(e.target.value) < 0
                            ) {
                              return;
                            }
                            setUpdatedTotalPaid((prev) => {
                              const updated = [...prev];
                              updated[index].amount = e.target.value;
                              return updated;
                            });
                          }}
                        />
                      </td>

                      <td>
                        {/* <label className={styles.paymentDateLabelUpdate} htmlFor={`paymentMethoud_${index}`}>Payment Method:</label> */}
                        {/* add a selection of cash or bank */}
                        <select
                          className={styles.paymentMethodUpdate}
                          id={`paymentMethoud_${index}`}
                          name={`paymentMethoud_${index}`}
                          value={
                            updatedTotalPaid[index]?.paymentMethod ||
                            order.paymentMethod ||
                            ""
                          }
                          onChange={(e) => {
                            setUpdatedTotalPaid((prev) => {
                              const updated = [...prev];
                              updated[index].paymentMethod = e.target.value;
                              return updated;
                            });
                          }}
                        >
                          <option value="" disabled>
                            Select Method
                          </option>
                          <option value="Cash/Kassa">Cash/Kassa</option>
                          <option value="Bank Contact">Bank Contact</option>
                          <option value="Bank Transfer">Bank Transfer</option>
                        </select>
                      </td>

                      <td>
                        <button
                          onClick={() => {
                            setUpdatedTotalPaid((prev) => {
                              const updated = [...prev];
                              updated.splice(index, 1);
                              return updated;
                            });
                          }}
                        >
                          Delete Payment
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* update a payment */}
              <button
                style={{ marginRight: "2rem" }}
                onClick={() => {
                  handleTotalPaidChange(updatedTotalPaid);
                  closePaymentPopUpModal();
                }}
              >
                Save Payment
              </button>

              {/* Add a button to add a new payment */}
              <button
                onClick={() => {
                  // Add a new payment to the array of payments
                  setUpdatedTotalPaid((prev) => {
                    const updated = [...prev];
                    updated.push({
                      date: new Date().toISOString().slice(0, 10),
                      amount: "",
                    });
                    return updated;
                  });
                }}
              >
                Add Payment
              </button>
            </div>

            <div className="modal-footer">
              <p>
                provided by{" "}
                <a
                  href="https://perfectinvoicing.netlify.app/"
                  target="_blank"
                  rel="noreferrer"
                >
                  PerfectInvoicing
                </a>
              </p>
            </div>
          </div>
        </div>
      )}

      {isModalOpen && (
        <div id="myModal" className="modal">
          <div className="modal-content" style={{ width: "70%" }}>
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            <div className="modal-header">
              <h2>Order Details</h2>

              {/* invoice number */}
              <div className={viewStyles.invoiceNumber}>
                <p>
                  Invoice Number:{" "}
                  <span>
                    {selectedOrder.id || selectedOrder.proformaNumber}
                  </span>
                </p>
                {/* date */}
                <p>
                  Date: <span>{selectedOrder.date}</span>
                </p>
                {/* client number */}
                <p>
                  Client Number:{" "}
                  <span>
                    {selectedOrder.selectedResultCustomer.clientNumber}
                  </span>
                </p>
                <p>
                  Customer Name:{" "}
                  <span>{selectedOrder.selectedResultCustomer.name}</span>
                </p>
              </div>
            </div>
            <div className="modal-body">
              {/* Order details in a table */}
              <table>
                <thead>
                  <tr>
                    <th className={viewStyles.previewTh}>Item ID</th>
                    <th className={viewStyles.previewTh}>Item Name</th>
                    <th className={viewStyles.previewTh}>Item Description</th>
                    <th className={viewStyles.previewTh}>Item Price</th>
                    <th className={viewStyles.previewTh}>Item Quantity</th>

                    {selectedOrder.btw > 0 && (
                      <th className={viewStyles.previewTh}>Item BTW</th>
                    )}
                    <th className={viewStyles.previewTh}>Item Discount</th>
                    <th className={viewStyles.previewTh}>Item Total</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedOrder.items.map((item, index) => (
                    <tr key={index} className={viewStyles.previewTr}>
                      <td className={viewStyles.previewTd}>{item.id}</td>
                      <td className={viewStyles.previewTd}>{item.name}</td>
                      <td className={viewStyles.previewTd}>
                        {item.description}
                      </td>
                      <td className={viewStyles.previewTd}>
                        {parseFloat(item.sellPrice).toFixed(2)}
                      </td>
                      <td className={viewStyles.previewTd}>{item.quantity}</td>

                      {selectedOrder.btw > 0 && (
                        <td className={viewStyles.previewTd}>
                          {parseFloat(item.btw).toFixed(3)}
                        </td>
                      )}
                      <td className={viewStyles.previewTd}>
                        {parseFloat(item.discount).toFixed(2)}
                      </td>
                      <td className={viewStyles.previewTd}>
                        {parseFloat(item.total).toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div>
                {/* subtotal */}
                <p>
                  Subtotal:{" "}
                  <span>{parseFloat(selectedOrder.subTotal).toFixed(2)}</span>
                </p>
                {/* discount */}
                <p>
                  Discount:{" "}
                  <span>
                    {parseFloat(selectedOrder.discountedPriceTotal).toFixed(2)}
                  </span>
                </p>
                {/* tax */}
                {selectedOrder.btw > 0 && (
                  <p>
                    Tax: <span>{parseFloat(selectedOrder.btw).toFixed(3)}</span>
                  </p>
                )}

                {/* total amount */}
                <p>
                  Total Amount:{" "}
                  <span>
                    {parseFloat(
                      Math.round(selectedOrder.totalAmount * 20) / 20
                    ).toFixed(2)}
                  </span>
                </p>
              </div>
            </div>
            <div className="modal-footer">
              {/* provided by perfectInvoicing */}
              <p>
                provided by{" "}
                <a
                  href="https://perfectinvoicing.netlify.app/"
                  target="_blank"
                  rel="noreferrer"
                >
                  PerfectInvoicing
                </a>
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Invoices;
