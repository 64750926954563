import React from "react";

const Btw = ({
  item,
  itemsOnInvoice,
  setItemsOnInvoice,
  setItemsArray,
  setTaxAmount,
  setTotalAmount,
  setSubTotal,
}) => {
  const handleBtwAmoutChange = (itemId, event) => {
    const parsedBtw = parseFloat(event.target.value) || 0;

    const calUpdatedItemsOnInvoice = itemsOnInvoice.map((invoiceItem) =>
      invoiceItem.id === itemId
        ? {
            ...invoiceItem,
            btw: parsedBtw,
            total: parseFloat(
              parseFloat(invoiceItem.sellPrice) * invoiceItem.quantity +
                ((invoiceItem.sellPrice * invoiceItem.quantity * parsedBtw) /
                  100 -
                  (invoiceItem.sellPrice *
                    invoiceItem.quantity *
                    invoiceItem.discount) /
                    100)
            ).toFixed(2),
          }
        : invoiceItem
    );

    const sumForSub = calUpdatedItemsOnInvoice.reduce((acc, invoiceItem) => {
      return (
        acc +
        parseFloat(invoiceItem.sellPrice) * parseFloat(invoiceItem.quantity)
      );
    }, 0);

    const updatedTaxAmount = calUpdatedItemsOnInvoice.map((invoiceItem) =>
      (
        (invoiceItem.sellPrice * invoiceItem.quantity * invoiceItem.btw) /
        100
      ).toFixed(3)
    );

    const sumForTaxAmount = updatedTaxAmount.reduce(
      (acc, tax) => acc + parseFloat(tax),
      0
    );

    const sumForDiscounts = calUpdatedItemsOnInvoice.map(
      (invoiceItem) =>
        (invoiceItem.quantity * invoiceItem.sellPrice * invoiceItem.discount) /
        100
    );

    const updatedDiscountArray = sumForDiscounts.reduce(
      (acc, discount) => acc + parseFloat(discount),
      0
    );

    const sumForTotalAmount =
      sumForSub - updatedDiscountArray + sumForTaxAmount;
    console.log(
      sumForTotalAmount,
      sumForSub,
      updatedDiscountArray,
      sumForTaxAmount
    );

    setSubTotal(parseFloat(sumForSub).toFixed(2));

    setTaxAmount(parseFloat(sumForTaxAmount).toFixed(3));
    setTotalAmount(parseFloat(sumForTotalAmount).toFixed(2));

    setItemsOnInvoice(calUpdatedItemsOnInvoice);
    setItemsArray(calUpdatedItemsOnInvoice);
  };

  return (
    <td>
      <input
        type="number"
        value={item.btw || ""}
        className="form-control"
        onChange={(e) => {
          handleBtwAmoutChange(item.id, e);
        }}
        style={{ width: "6rem" }}
      />
    </td>
  );
};

export default Btw;
