import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import SearchBar from "./SearchBar";

import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import config from "./config/config";

import styles from "./Catalog.module.css";

export default function Catalog() {
  if (!localStorage.getItem("token")) {
    window.location.href = "/login";
  }

  const [products, setProducts] = useState([]);
  // fetch the products from the server
  useEffect(() => {
    const getProducts = async () => {
      fetch(`${config.URL_PROD}/products`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          // the data has a object with a key of products array and a key of count please destructure the products array from the data object
          const { products } = data;
          const { count } = data;
          /*  console.log("products", products); */
          console.log("count", count);
          const sortedProducts = products.sort((a, b) => a.id - b.id);
          setProducts(sortedProducts);
          // Remove duplicate products from the array but keep the first occurence
        })
        .catch((err) => console.error(err))
        .finally(() => {
          /*  setProducts(products); */
          console.log("fetching products done");
        });
    };
    getProducts();
  }, []);

  const navigate = useNavigate();
  const companyPerferdHeaderBackgroundColor =
    localStorage.getItem("tableHeaderBackgroundColor") || "#00193d";

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [updatedThePage, setUpdatedThePage] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [isAnyItemSelected, setIsAnyItemSelected] = useState(false); // New state for tracking if any item is selected

  const [viewAllDetails, setViewAllDetails] = useState(false);

  // Function to toggle view all details
  const toggleViewAllDetails = () => {
    setViewAllDetails(!viewAllDetails);
  };

  const [selectedProductId, setSelectedProductId] = useState(null);

  // Function to toggle view all details for a specific product
  const toggleViewDetails = (productId) => {
    setSelectedProductId(selectedProductId === productId ? null : productId);
  };

  const [imageUrls, setImageUrls] = useState([]);

  // useEffect(() => {
  //   const fetchImages = async () => {
  //     const updatedProducts = await Promise.all(
  //       filteredProducts.map(async (product) => {
  //         if (product.image && !imageUrls.includes(product.image)) {
  //           try {
  //             /* https://serverperfectinvoicing.netlify.app `http://localhost:4000/images/${product.image}` || */
  //             const response = await fetch(
  //               `https://perfectinvoicing-server.onrender.com/images/${product.image}`
  //             );
  //             if (response.ok) {
  //               const blob = await response.blob();
  //               product.image = URL.createObjectURL(blob);
  //             } else {
  //               console.error("Error fetching image:", response.statusText);
  //             }
  //           } catch (error) {
  //             console.error("Error fetching image:", error);
  //           }
  //         }
  //         return product;
  //       })
  //     );
  //     setProducts(updatedProducts);
  //   };

  //   fetchImages();
  // }, [imageUrls]);

  useEffect(() => {
    // Check if any product is selected
    const productsAreSelected = selectedProducts.length > 0;
    setIsAnyItemSelected(productsAreSelected);
  }, [selectedProducts]);

  const handleSelectedProducts = (e) => {
    if (e === "all") {
      if (selectedProducts.length === 0) {
        setSelectedProducts(products.map((product) => product.id));
        setSelectAll(true);
      } else {
        setSelectedProducts([]);
        setSelectAll(false);
      }
    } else {
      if (selectedProducts.includes(e)) {
        const filtered = selectedProducts.filter((item) => item !== e);
        setSelectedProducts([...filtered]);
        setSelectAll(false);
      } else {
        setSelectedProducts([...selectedProducts, e]);
      }
    }
  };

  const filteredProducts = products.filter((product) => {
    // sort the products by id
    const productString = JSON.stringify(product).toLowerCase();
    const searchWords = searchTerm.toLowerCase().split(" " || ",");
    // Check if all search words are in the product string
    return searchWords.every((word) => productString.includes(word));
  });

  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
  };

  const deleteSelectedProducts = () => {
    const ids = selectedProducts.join(", ");
    const confirmation = window.confirm(
      "Are you sure you want to delete the selected products?"
    );
    if (confirmation) {
      // Delete the selected products from the server
      const deleteProducts = async () => {
        const response = await fetch(`${config.URL_PROD}/products/${ids}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          console.log(data);
          const updatedProducts = products.filter(
            (product) => !selectedProducts.includes(product.id)
          );
          console.log("updatedProducts", updatedProducts);
          setProducts(updatedProducts);
          setSelectedProducts([]);
          setSelectAll(false);
        } else {
          console.error("Error deleting products:", response.statusText);
        }
      };

      deleteProducts();
      // Uncheck the select all checkbox
      setSelectAll(false);
    } else {
      setSelectAll(false);
      setSelectedProducts([]);
      return;
    }
  };

  // Update in the server
  const updateProduct = async (productId, inventoryAtion) => {
    console.log(inventoryAtion);
    const response = await fetch(`${config.URL_PROD}/products/${productId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({ quantity: inventoryAtion }),
    });

    if (response.ok) {
      const data = await response.json();
      console.log(data);
    }
  };

  const addSomeInventory = (productId) => {
    // Pop up a window to ask for the quantity to add
    const addInventory = prompt("Please enter the quantity to add", 0);
    if (addInventory === null) {
      return;
    }

    // Find the product in the selected products
    const productToAdd = filteredProducts.find(
      (product) => product.id === productId
    );

    // Add the quantity to the product
    productToAdd.quantity =
      parseInt(productToAdd.quantity) + parseInt(addInventory);

    updateProduct(productId, productToAdd.quantity);
    setUpdatedThePage(!updatedThePage);
  };

  const removeSomeInventory = (productId) => {
    // Pop up a window to ask for the quantity to remove
    const removeInventory = prompt("Please enter the quantity to remove", 0);
    if (removeInventory === null) {
      return;
    }
    const quantityRemoving = parseInt(removeInventory);
    const productToRemove = filteredProducts.find(
      (product) => product.id === productId
    );
    if (quantityRemoving > parseInt(productToRemove.quantity)) {
      alert("You cannot remove more than the quantity in stock");
      return;
    }
    productToRemove.quantity =
      parseInt(productToRemove.quantity) - quantityRemoving;

    const removeFromInventory = parseInt(productToRemove.quantity);
    updateProduct(productId, productToRemove.quantity, removeFromInventory);
    setUpdatedThePage(!updatedThePage);
  };

  const pdfPrint = () => {
    if (selectedProducts.length === 0) {
      alert("No products selected to print");
      return;
    }
    const seleceted = selectedProducts
      ? products.filter((product) => selectedProducts.includes(product.id))
      : products;

    const dateYearMonthDAy = new Date().toISOString().slice(0, 10);

    const header = [
      "Barcode/ID",
      "Product Name",
      "Description",
      "Supplier",
      "Buy Price",
      "Sell Price",
      "Sale Price",
      "Quantity",
      "Price Per Unit Or Box",
      "Units In Box/Case",
      "Price Per X Amount",
    ];

    // Create a new instance of jsPDF
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
      putOnlyUsedFonts: false,
    });

    // Set up initial parameters
    const pageWidth = doc.internal.pageSize.width;
    const margin = 20;
    let y = margin; // Start at the top of the page

    // Set font size for the date
    doc.setFontSize(24); // Larger font size for "Catalog As Of"
    doc.text("Catalog", pageWidth / 2, y, { align: "center" });
    y += 10; // Increase the offset after "Catalog As Of"

    // Add date to the PDF
    const dateYearMonthDay = new Date().toISOString().slice(0, 10);
    doc.setFontSize(12);
    doc.text(` As Of ${dateYearMonthDay}`, pageWidth / 2, y, {
      align: "center",
    });
    // y += 20; // Increase the offset after the date
    y += 20; // Increase the offset to create space between title and table

    // Set font size for the table
    doc.setFontSize(10);

    doc.autoTable({
      startY: y,
      head: [header],
      body: seleceted.map((product) => [
        product.id,
        product.name,
        product.description,
        product.manufacturer,
        Number(product.buyPrice).toFixed(2),
        Number(product.sellPrice).toFixed(2),
        Number(product.salePrice).toFixed(2),
        product.quantity,
        product.pricePerUnitOrBox,
        product.unitsInBox,
        product.pricePerXAmount,
      ]),
      headStyles: {
        fillColor: companyPerferdHeaderBackgroundColor || [1, 29, 74],
        textColor: 240,
      },
      // make the text smaller to fit the columns
      styles: { fontSize: 8 },
    });

    const fileName = `Catalog As Of ${dateYearMonthDAy}.pdf`;
    // Save the PDF with a specific name
    // doc.save(fileName);

    // Print the PDF
    doc.autoPrint();
    doc.output("dataurlnewwindow");

    setSelectedProducts([]);
    setSelectAll(false);
  };

  const exportToExcel = () => {
    if (selectedProducts.length === 0) {
      alert("Please select the products you want to export");
      return;
    }
    const seleceted = selectedProducts
      ? products.filter((product) => selectedProducts.includes(product.id))
      : products;
    const dateYearMonthDAy = new Date().toISOString().slice(0, 10);

    const header = [
      "Barcode/ID",
      "Product Name",
      "Description",
      "Supplier",
      "Buy Price",
      "Sell Price",
      "Sale Price",
      "Quantity",
      "Price Per Unit Or Box",
      "Units In Box/Case",
      "Price Per X Amount",
    ];
    const data = seleceted.map((product) => [
      product.id,
      product.name,
      product.description,
      product.manufacturer,
      Number(product.buyPrice).toFixed(2),
      Number(product.sellPrice).toFixed(2),
      Number(product.salePrice).toFixed(2),
      product.quantity,
      product.pricePerUnitOrBox,
      product.unitsInBox,
      product.pricePerXAmount,
    ]);

    data.unshift(header);

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, "Catalog");
    XLSX.writeFile(wb, `Catalog As Of ${dateYearMonthDAy}.xlsx`);

    setSelectedProducts([]);
    setSelectAll(false);
  };

  const editHandler = (selected) => {
    if (selectedProducts.length !== 1) {
      alert("Please select only one product to edit");
      setSelectAll(false);
      setSelectedProducts([]);
      return;
    }

    const productId = selected[0];

    const index = productId;

    navigate(`/EditProduct/${index}`);
  };

  const resizeImage = (e) => {
    if (e.target.style.width === "100%") {
      e.target.style.width = "4.5rem";
      e.target.style.height = "3.5rem";
    } else {
      e.target.style.width = "100%";
      e.target.style.height = "auto";
    }
  };

  return (
    <div className={styles.container}>
      <h1>Catalog</h1>

      <div>
        <div className={styles.searchBar}>
          <SearchBar onSearch={handleSearch} />
        </div>
        <Link to="/ImportProductsFromExcel">
          {/* import products  */}
          <button style={{ marginRight: "2rem" }} title="Import Products">
            <svg
              width="24"
              height="24"
              fill="currentColor"
              className="bi bi-filetype-xls"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM6.472 15.29a1.176 1.176 0 0 1-.111-.449h.765a.578.578 0 0 0 .254.384c.07.049.154.087.25.114.095.028.202.041.319.041.164 0 .302-.023.413-.07a.559.559 0 0 0 .255-.193.507.507 0 0 0 .085-.29.387.387 0 0 0-.153-.326c-.101-.08-.255-.144-.462-.193l-.619-.143a1.72 1.72 0 0 1-.539-.214 1.001 1.001 0 0 1-.351-.367 1.068 1.068 0 0 1-.123-.524c0-.244.063-.457.19-.639.127-.181.303-.322.527-.422.225-.1.484-.149.777-.149.305 0 .564.05.78.152.216.102.383.239.5.41.12.17.186.359.2.566h-.75a.56.56 0 0 0-.12-.258.625.625 0 0 0-.247-.181.923.923 0 0 0-.369-.068c-.217 0-.388.05-.513.152a.472.472 0 0 0-.184.384c0 .121.048.22.143.3a.97.97 0 0 0 .405.175l.62.143c.217.05.406.12.566.211a1 1 0 0 1 .375.358c.09.148.135.335.135.56 0 .247-.063.466-.188.656a1.216 1.216 0 0 1-.539.439c-.234.105-.52.158-.858.158-.254 0-.476-.03-.665-.09a1.404 1.404 0 0 1-.478-.252 1.13 1.13 0 0 1-.29-.375Zm-2.945-3.358h-.893L1.81 13.37h-.036l-.832-1.438h-.93l1.227 1.983L0 15.931h.861l.853-1.415h.035l.85 1.415h.908L2.253 13.94l1.274-2.007Zm2.727 3.325H4.557v-3.325h-.79v4h2.487v-.675Z"
              />
            </svg>
            <br />
            <span className={styles.textUnderIcon}>Import</span>
          </button>
        </Link>
        <Link to="/AddProduct">
          {/* add product  */}
          <button style={{ marginRight: "2rem" }} title="Add Product">
            <svg
              width="24"
              height="24"
              fill="currentColor"
              className="bi bi-file-earmark-plus"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M8 3a.5.5 0 0 1 .5.5V7h3.5a.5.5 0 0 1 0 1H8v3.5a.5.5 0 0 1-1 0V8H3.5a.5.5 0 0 1 0-1H7V3.5A.5.5 0 0 1 7.5 3H8Z"
              />
            </svg>
            <br />
            <span className={styles.textUnderIcon}>Add Product</span>
          </button>
        </Link>
        {/* {isAnyItemSelected && ( */}
        <div
          className={
            styles.butnsActionsNotSelected
            //   `${
            //   isAnyItemSelected
            //     ? styles.butnsActions
            //     : styles.butnsActionsNotSelected
            // }`
          }
        >
          <button
            onClick={() => {
              pdfPrint();
            }}
            style={{ marginRight: "2rem" }}
            title="Generate PDF & Print"
          >
            <svg
              width="24"
              height="24"
              fill="currentColor"
              className="bi bi-printer"
              viewBox="0 0 16 16"
            >
              <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
            </svg>
            <br />
            <span className={styles.textUnderIcon}>Print</span>
          </button>

          {/* download excel of all invoices */}
          <button
            onClick={() => {
              exportToExcel();
            }}
            style={{ marginRight: "2rem" }}
            title="Export to Excel"
          >
            <svg
              width="24"
              height="24"
              fill="currentColor"
              className="bi bi-filetype-xls"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM6.472 15.29a1.176 1.176 0 0 1-.111-.449h.765a.578.578 0 0 0 .254.384c.07.049.154.087.25.114.095.028.202.041.319.041.164 0 .302-.023.413-.07a.559.559 0 0 0 .255-.193.507.507 0 0 0 .085-.29.387.387 0 0 0-.153-.326c-.101-.08-.255-.144-.462-.193l-.619-.143a1.72 1.72 0 0 1-.539-.214 1.001 1.001 0 0 1-.351-.367 1.068 1.068 0 0 1-.123-.524c0-.244.063-.457.19-.639.127-.181.303-.322.527-.422.225-.1.484-.149.777-.149.305 0 .564.05.78.152.216.102.383.239.5.41.12.17.186.359.2.566h-.75a.56.56 0 0 0-.12-.258.625.625 0 0 0-.247-.181.923.923 0 0 0-.369-.068c-.217 0-.388.05-.513.152a.472.472 0 0 0-.184.384c0 .121.048.22.143.3a.97.97 0 0 0 .405.175l.62.143c.217.05.406.12.566.211a1 1 0 0 1 .375.358c.09.148.135.335.135.56 0 .247-.063.466-.188.656a1.216 1.216 0 0 1-.539.439c-.234.105-.52.158-.858.158-.254 0-.476-.03-.665-.09a1.404 1.404 0 0 1-.478-.252 1.13 1.13 0 0 1-.29-.375Zm-2.945-3.358h-.893L1.81 13.37h-.036l-.832-1.438h-.93l1.227 1.983L0 15.931h.861l.853-1.415h.035l.85 1.415h.908L2.253 13.94l1.274-2.007Zm2.727 3.325H4.557v-3.325h-.79v4h2.487v-.675Z"
              />
            </svg>
            <br />
            <span className={styles.textUnderIcon}>Excel</span>
          </button>

          {/* delete selected invoices */}
          <button
            style={{ marginRight: "2rem" }}
            onClick={deleteSelectedProducts}
            title="Delete Selected"
          >
            <svg
              x="0px"
              y="0px"
              width="26"
              height="26"
              fill="white"
              viewBox="0 0 24 24"
            >
              <path d="M 10 2 L 9 3 L 4 3 L 4 5 L 5 5 L 5 20 C 5 20.522222 5.1913289 21.05461 5.5683594 21.431641 C 5.9453899 21.808671 6.4777778 22 7 22 L 17 22 C 17.522222 22 18.05461 21.808671 18.431641 21.431641 C 18.808671 21.05461 19 20.522222 19 20 L 19 5 L 20 5 L 20 3 L 15 3 L 14 2 L 10 2 z M 7 5 L 17 5 L 17 20 L 7 20 L 7 5 z M 9 7 L 9 18 L 11 18 L 11 7 L 9 7 z M 13 7 L 13 18 L 15 18 L 15 7 L 13 7 z"></path>
            </svg>
            <br />
            <span className={styles.textUnderIcon}>Delete</span>
          </button>
          {/* button to edit with a icon */}
          <button
            style={{ marginRight: "2rem" }}
            title="Edit"
            onClick={() => {
              editHandler(selectedProducts);
            }}
          >
            <svg
              x="0px"
              y="0px"
              width="26"
              height="26"
              viewBox="0 0 50 50"
              fill="white"
            >
              <path d="M 43.125 2 C 41.878906 2 40.636719 2.488281 39.6875 3.4375 L 38.875 4.25 L 45.75 11.125 C 45.746094 11.128906 46.5625 10.3125 46.5625 10.3125 C 48.464844 8.410156 48.460938 5.335938 46.5625 3.4375 C 45.609375 2.488281 44.371094 2 43.125 2 Z M 37.34375 6.03125 C 37.117188 6.0625 36.90625 6.175781 36.75 6.34375 L 4.3125 38.8125 C 4.183594 38.929688 4.085938 39.082031 4.03125 39.25 L 2.03125 46.75 C 1.941406 47.09375 2.042969 47.457031 2.292969 47.707031 C 2.542969 47.957031 2.90625 48.058594 3.25 47.96875 L 10.75 45.96875 C 10.917969 45.914063 11.070313 45.816406 11.1875 45.6875 L 43.65625 13.25 C 44.054688 12.863281 44.058594 12.226563 43.671875 11.828125 C 43.285156 11.429688 42.648438 11.425781 42.25 11.8125 L 9.96875 44.09375 L 5.90625 40.03125 L 38.1875 7.75 C 38.488281 7.460938 38.578125 7.011719 38.410156 6.628906 C 38.242188 6.246094 37.855469 6.007813 37.4375 6.03125 C 37.40625 6.03125 37.375 6.03125 37.34375 6.03125 Z"></path>
            </svg>
            <br />
            <span className={styles.textUnderIcon}>Edit</span>
          </button>
        </div>
        {/* )} */}
        <table className={styles.CatalogTable}>
          <thead>
            <tr>
              <th className={styles.CatalogTableHead}>Barcode / ID</th>
              <th className={styles.CatalogTableHead}>Item Name</th>
              <th className={styles.CatalogTableHead}>Description</th>
              <th className={styles.CatalogTableHead}>Image</th>
              <th className={styles.CatalogTableHead}>Sell Price</th>
              <th className={styles.CatalogTableHead}>Sale Price</th>
              <th className={styles.CatalogTableHead}>Qty</th>
              <th className={styles.CatalogTableHead}>Inventory</th>
              <th className={styles.CatalogTableHead}>Category</th>
              <th className={styles.CatalogTableHead}>
                <label htmlFor="checkbox">More Actions</label>
                <input
                  id="checkbox"
                  className={styles.CatalogTableHead}
                  type="checkbox"
                  checked={selectAll}
                  onChange={() => handleSelectedProducts("all")}
                />
              </th>
              <th
                className={styles.CatalogTableHead}
                style={{
                  width: "10rem",
                  height: "2rem",
                }}
              >
                View
              </th>
            </tr>
          </thead>
          <tbody className={styles.CatalogTableBody}>
            {filteredProducts.map((product) => (
              <React.Fragment key={product.id}>
                <tr>
                  <td>{product.id ? product.id.toString() : ""}</td>
                  <td>{product.name ? product.name.toString() : ""}</td>
                  <td>
                    {product.description ? product.description.toString() : ""}
                  </td>
                  <td>
                    {product.image ? (
                      <img
                        src={product.image}
                        alt="product image"
                        onClick={resizeImage}
                        className={styles.productImage}
                      />
                    ) : (
                      ""
                    )}
                  </td>
                  <td>
                    {product.sellPrice ? product.sellPrice.toString() : 0}
                  </td>
                  <td>
                    {product.salePrice
                      ? parseFloat(product.salePrice).toFixed(2).toString()
                      : 0}
                  </td>
                  <td>{product.quantity ? product.quantity.toString() : 0}</td>
                  <td>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="16"
                      width="14"
                      viewBox="0 0 448 512"
                      onClick={() => addSomeInventory(product.id)}
                      style={{ marginRight: "2rem" }}
                    >
                      <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="16"
                      width="14"
                      viewBox="0 0 448 512"
                      onClick={() => removeSomeInventory(product.id)}
                    >
                      <path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z" />
                    </svg>
                  </td>
                  <td>
                    {product.category ? product.category.toString() : "Unknown"}
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedProducts.includes(product.id)}
                      onChange={() => handleSelectedProducts(product.id)}
                    />
                  </td>
                  <td>
                    <button onClick={() => toggleViewDetails(product.id)}>
                      {selectedProductId === product.id
                        ? "Hide Details"
                        : "View Details"}
                    </button>
                  </td>
                </tr>
                {selectedProductId === product.id && (
                  <>
                    <tr>
                      <th className={styles.CatalogTableHead}>
                        Transport Code
                      </th>
                      <th className={styles.CatalogTableHead}>Supplier</th>
                      <th className={styles.CatalogTableHead}>Buy Price</th>
                      <th className={styles.CatalogTableHead}>
                        Price Per Unit/Box
                      </th>
                      <th className={styles.CatalogTableHead}>Units In Box</th>
                      <th className={styles.CatalogTableHead}>Price Per X</th>
                      <th className={styles.CatalogTableHead}>Papular</th>
                    </tr>
                    <tr>
                      {/* Additional details view based on viewAllDetails state */}
                      <td>
                        {product.transportCode
                          ? product.transportCode.toString()
                          : ""}
                      </td>
                      <td>
                        {product.manufacturer
                          ? product.manufacturer.toString()
                          : ""}
                      </td>
                      <td>
                        {product.buyPrice ? product.buyPrice.toString() : 0}
                      </td>
                      <td>
                        {product.pricePerUnitOrBox
                          ? product.pricePerUnitOrBox.toString()
                          : ""}
                      </td>
                      <td>
                        {product.unitsInBox
                          ? product.unitsInBox.toString()
                          : ""}
                      </td>
                      <td>
                        {product.pricePerXAmount
                          ? product.pricePerXAmount.toString()
                          : ""}
                      </td>
                      <td>
                        {product.papular
                          ? product.papular.toString()
                          : "Unknown"}
                      </td>
                    </tr>
                  </>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
