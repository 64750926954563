import jsPDF from "jspdf";
import "jspdf-autotable";
import base64Rubic from "../components/base64Rubic";
import EmptyLogo from "../../src/components/EmptyLogo.png";
const logoImage = localStorage.getItem("companyLogo") || "";

const generateReceiptPDFCombined = (invoices) => {
  const companyPerferdHeaderBackgroundColor = localStorage.getItem(
    "tableHeaderBackgroundColor"
  ) || [41, 41, 41];
  const showBtw = localStorage.getItem("showBtw")
    ? localStorage.getItem("showBtw") === "true"
      ? "SHOW"
      : "HIDE"
    : "SHOW";
  const myCompany = JSON.parse(localStorage.getItem("companyDetails"));
  // get the footer from the local storage
  const footer = JSON.parse(localStorage.getItem("footer")) || {};
  const removeTextForCompanyInInvoiceHeader = JSON.parse(
    localStorage.getItem("removeTextForCompanyInInvoiceHeader")
  );

  const logoImage = localStorage.getItem("companyLogo") || "";
  console.log("Starting generateReceiptPDF");

  // Check if the text is in Yiddish or Hebrew
  function isYiddishOrHebrew(text) {
    return /[א-ת]/.test(text);
  }

  const doc = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: "a4",
    putOnlyUsedFonts: false,
  });

  // Add the custom font to the PDF
  doc.addFileToVFS("Rubik", base64Rubic);
  doc.addFont("Rubik", "Rubik", "normal");

  let totalPageCount = 0;

  invoices.forEach((invoice, index) => {
    doc.setFont("Rubik");

    if (index !== 0) {
      doc.addPage(); // Add a new page for each invoice (except the first one).
    }

    const columns = [
      "ID",
      "Name",
      "Description",
      "Price",
      "Quantity",
      "Discount",
      "Total",
    ];

    if (invoice.items.length > 0) {
      const firstItem = invoice.items[0];

      /*   if (firstItem.id) {
        columns.unshift("ID");
      }
    
      if (!firstItem.name) {
        columns.splice(firstItem.id ? 1 : 0, firstItem.id ? 1 : 2);
      }
    
      if (!firstItem.description) {
        const nameIndex = firstItem.id ? 1 : 0;
        columns.splice(nameIndex + (firstItem.name ? 1 : 0), 1);
      } */

      /*   // Remove "Discount" column if it exists
      const discountIndex = columns.indexOf("Discount");
      if (discountIndex !== -1) {
        columns.splice(discountIndex, 1);
      } */

      // Assuming selectedResultCustomer is an object
      if (
        firstItem.selectedResultCustomer &&
        firstItem.selectedResultCustomer.showTransportCode !== false
      ) {
        columns.splice(4, 0, "TransportCode");
      }

      // Assuming showBtw is a variable
      if (showBtw === "SHOW") {
        // Add "BTW" column but too one position before the last column
        columns.splice(columns.length - 1, 0, "BTW");
      }
    }

    const tableData = invoice.items.map((item) => {
      const rowData = [
        item.id || "", // Assuming ID should always be present
        item.name || "",
        item.description || "",
        item.sellPrice || 0,
        item.quantity || 0,
        // item.discount || 0, round it to 2 decimal places with Math.round
        Number(Math.round(item.discount * 100) / 100).toFixed(0) + "%" || 0,
        // Number(item.total).toFixed(2) || 0, round it to 2 decimal places with Math.round
        Number(Math.round(item.total * 100) / 100).toFixed(2) || 0,
      ];

      // Assuming showBtw is a variable
      if (showBtw === "SHOW") {
        rowData.splice(rowData.length - 1, 0, item.btw || 0); // Insert btw or 0
      }

      // Assuming selectedResultCustomer is an object
      if (
        item.selectedResultCustomer &&
        item.selectedResultCustomer.showTransportCode !== false
      ) {
        rowData.splice(4, 0, item.transportCode || ""); // Insert transportCode or an empty string
      }

      return rowData;
    });

    // Page dimensions and starting position
    const pageWidth = doc.internal.pageSize.width;
    const margin = 10;
    let y = margin + 15;

    // Function to add the logo image
    const addLogo = () => {
      if (logoImage !== "") {
        doc.addImage(logoImage, "PNG", 10, 30, 60, 0);
      }

      // Adjust the x-coordinate (3rd argument) to move the image to the right
      // Adjust the y-coordinate (4th argument) to move the image down
      // Adjust the width (4th argument) to make it smaller
      // Adjust the height (5th argument) to make it smaller
    };

    // Function to add content to the page
    const addContentToPage = () => {
      // Add content to the page
      // Set the font based on language

      // Calculate the height of the "From" section
      const fromSectionHeight = 20 + 6 * 5; // Initial offset + 6 lines of text

      y += 10; // Initial offset
      addLogo(); // Add the logo image
      console.log("Inside addContentToPage");

      // Additional console log for debugging
      console.log(
        "Value of removeTextForCompanyInInvoiceHeader inside addContentToPage:",
        removeTextForCompanyInInvoiceHeader
      );

      if (removeTextForCompanyInInvoiceHeader !== true) {
        console.log("Inside if block");
        y += 10; // Increase the vertical space
        // Add "From" section
        doc.setFontSize(12);
        doc.text("From:", margin, y);
        doc.setFontSize(10);
        if (
          isYiddishOrHebrew(
            invoice.companyDetails?.companyName || myCompany.companyName
          )
        ) {
          doc.text(
            `${
              invoice.companyDetails?.companyName
                .split("")
                .reverse()
                .join("") || myCompany.companyName.split("").reverse().join("")
            }`,
            margin,
            (y += 5)
          );
        } else {
          doc.text(
            `Name: ${
              invoice.companyDetails?.companyName || myCompany.companyName
            }`,
            margin,
            (y += 5)
          );
        }
        if (
          invoice.companyDetails?.companyAddress ||
          myCompany.companyAddress
        ) {
          doc.text(
            `Address: ${
              invoice.companyDetails?.companyAddress || myCompany.companyAddress
            }`,
            margin,
            (y += 5)
          );
        } else {
          y += 10;
        }
        if (invoice.companyDetails?.companyEmail || myCompany.companyEmail) {
          doc.text(
            `City: ${
              invoice.companyDetails?.companyCity || myCompany.companyCity
            }`,
            margin,
            (y += 5)
          );
        }
        if (invoice.companyDetails?.companyPhone || myCompany.companyPhone) {
          doc.text(
            `Phone: ${
              invoice.companyDetails?.companyPhone || myCompany.companyPhone
            }`,
            margin,
            (y += 5)
          );
        }
        if (invoice.companyDetails?.website || myCompany.website) {
          doc.text(
            `Website: ${myCompany.website || invoice.companyDetails?.website}`,
            margin,
            (y += 5)
          );
        }
        if (
          invoice.companyDetails?.companyBtwNumber ||
          myCompany.companyBtwNumber
        ) {
          doc.text(
            `BTW Number: ${
              invoice.companyDetails?.companyBtwNumber ||
              myCompany.companyBtwNumber
            }`,
            margin,
            (y += 5)
          );
        }
      }
      y += 20;

      if (removeTextForCompanyInInvoiceHeader) y += 5; // Increase the vertical space

      // Calculate the height of the "To" section based on the "From" section
      const toSectionHeight = fromSectionHeight;

      // "To" section
      doc.setFontSize(12);
      const xOffset = 60; // Adjust this value to control the position
      y -= toSectionHeight - 8; // Move "To" section up to meet the height of "From"
      doc.text("To:", pageWidth - margin - xOffset, (y += 5));
      doc.setFontSize(10);
      if (isYiddishOrHebrew(invoice.selectedResultCustomer.name)) {
        doc.text(
          `${invoice.selectedResultCustomer.name.split("").reverse().join("")}`,
          pageWidth - margin - xOffset,
          (y += 5)
        );
      } else {
        doc.text(
          `Name: ${invoice.selectedResultCustomer.name}`,
          pageWidth - margin - xOffset,
          (y += 5)
        );
      }
      if (invoice.selectedResultCustomer.deliveryAddress) {
        doc.text(
          `Address: ${invoice.selectedResultCustomer.deliveryAddress}`,
          pageWidth - margin - xOffset,
          (y += 5)
        );
      }
      if (invoice.selectedResultCustomer.email) {
        doc.text(
          `Email: ${invoice.selectedResultCustomer.email}`,
          pageWidth - margin - xOffset,
          (y += 5)
        );
      }
    };
    doc.page = 1; // Initialize the page counter.

    const addPageHeader = () => {
      doc.setFontSize(16);
      // doc.setFont('helvetica', 'bold');
      doc.text(`Invoice Receipt`, pageWidth / 2, (y = 25), { align: "center" });
      y = 35; // Reset the y-coordinate
      doc.setFontSize(12);
      doc.text(
        `Invoice Number: ${invoice.id || invoice.proformaNumber}`,
        margin + 140,
        y
      );
      y += 5;
      doc.text(`Date: ${invoice.date}`, margin + 140, y);
      y += 5;
      doc.text(
        `Client Number: ${invoice.selectedResultCustomer.clientNumber}`,
        margin + 140,
        y
      );
      y += 20;

      totalPageCount++; // Increment the counter.

      addContentToPage();
    };

    // Create the first page
    addPageHeader();

    // Invoice Details Table Header
    y += 30; // Increase the vertical space
    doc.setFontSize(12);
    /* doc.setFont('helvetica', 'bold'); */
    doc.text("Invoice Details", margin, y);
    y += 5;

    // Calculate remaining space on the current page
    // Check if there's enough space for both sections
    const requiredSpace = 60; // Space for both sections
    const remainingSpaceOnPage = doc.internal.pageSize.height - y;

    if (remainingSpaceOnPage >= requiredSpace) {
      // There's enough space, continue on the current page
    } else {
      doc.addPage(); // Move to the next page
      y = 10; // Reset the y-coordinate
      addPageHeader(); // Re-add the header content
      y += 50; // Increase the vertical space
    }

    // Set a fixed column width for each column
    const columnWidth = pageWidth / columns.length;
    const columnWidths = columns.map(() => columnWidth);

    doc.autoTable({
      startY: y + 2,
      head: [columns],
      body: tableData,
      headStyles: { fontStyle: "bold" },
      columnStyles: columnWidths,
      margin: { top: y + 10, hcenter: "center", hwidth: "95%" },
      styles: { overflow: "linebreak" },
      headStyles: {
        fillColor: companyPerferdHeaderBackgroundColor || [41, 41, 41],
        textColor: 240,
      },
      didDrawPage: function (dataArg) {
        if (dataArg.pageCount > 1) {
          addPageHeader();
        }
      },
    });

    y = doc.autoTable.previous.finalY + 5; // Move below the table

    const remainingPageSpace = doc.internal.pageSize.height - y - 20;

    if (remainingPageSpace >= 30) {
      y += 10; // Increase the vertical space
      // set font to bold
      // Move "Subtotal" and its value to the right side of the page
      const subtotalX = 140; // Adjust this value as needed
      doc.text(`Subtotal:`, subtotalX, y);
      doc.text(`€${Number(invoice.subTotal).toFixed(2)}`, subtotalX + 25, y);
      y += 5;
      if (invoice.discountedPriceTotal > 0) {
        doc.text(`Discount:`, subtotalX, y);
        doc.text(
          `€${Number(invoice.discountedPriceTotal).toFixed(2)}`,
          subtotalX + 25,
          y
        );
        y += 5;
      }

      if (showBtw === "SHOW") {
        doc.text(`Tax:`, subtotalX, y);
        doc.text(`€${Number(invoice.btw).toFixed(2)}`, subtotalX + 25, y);
        y += 5;
      }

      if (invoice?.totalPaid > 0) {
        doc.text(`Total Paid:`, subtotalX, y);
        doc.text(`€${Number(invoice.totalPaid).toFixed(2)}`, subtotalX + 25, y);
        y += 5;
      }
      doc.text(`Total:`, subtotalX, y);
      // doc.text(`€${invoice.totalAmount.toFixed(2)}`, subtotalX + 25, y);round it to 2 decimal places with Math.round
      if (invoice?.totalPaid > 0) {
        doc.text(
          `€${Number(
            Math.round((invoice.totalAmount - invoice.totalPaid) * 100) / 100
          ).toFixed(2)}`,
          subtotalX + 25,
          y
        );
      } else {
        doc.text(
          `€${Number(Math.round(invoice.totalAmount * 100) / 100).toFixed(2)}`,
          subtotalX + 25,
          y
        );
      }
    } else {
      doc.addPage(); // Move to the next page
      y = 10; // Reset the y-coordinate
      addPageHeader();
      y += 50; // Increase the vertical space
      y += 10; // Increase the vertical space
      // set font to bold
      // Move "Subtotal" and its value to the right side of the page

      const subtotalX = 140; // Adjust this value as needed
      doc.text(`Subtotal:`, subtotalX, y);
      doc.text(`€${Number(invoice.subTotal).toFixed(2)}`, subtotalX + 25, y);
      y += 5;
      if (invoice.discountedPriceTotal > 0) {
        doc.text(`Discount:`, subtotalX, y);
        doc.text(
          `€${Number(invoice.discountedPriceTotal).toFixed(2)}`,
          subtotalX + 25,
          y
        );
        y += 5;
      }
      if (showBtw === "SHOW") {
        doc.text(`Tax:`, subtotalX, y);
        doc.text(`€${Number(invoice.btw).toFixed(2)}`, subtotalX + 25, y);
        y += 5;
      }
      if (invoice?.totalPaid > 0) {
        doc.text(`Total Paid:`, subtotalX, y);
        doc.text(`€${Number(invoice.totalPaid).toFixed(2)}`, subtotalX + 25, y);
        y += 5;
      }
      doc.text(`Total:`, subtotalX, y);
      // doc.text(`€${invoice.totalAmount.toFixed(2)}`, subtotalX + 25, y);round it to 2 decimal places with Math.round
      if (invoice?.totalPaid > 0) {
        doc.text(
          `€${Number(
            Math.round((invoice.totalAmount - invoice.totalPaid) * 100) / 100
          ).toFixed(2)}`,
          subtotalX + 25,
          y
        );
      } else {
        doc.text(
          `€${Number(Math.round(invoice.totalAmount * 100) / 100).toFixed(2)}`,
          subtotalX + 25,
          y
        );
      }
    }

    // Remarks
    y += 20; // Increase the vertical space
    /*  doc.setFont('helvetica', 'bold'); */

    if (invoice?.remarks) {
      y += 5;

      const remarksText = invoice.remarks;
      const remarksWidth = pageWidth - 2 * margin;
      const remainingPageSpace = doc.internal.pageSize.height - y;

      if (remainingPageSpace >= 60) {
        const remarksLines = doc.splitTextToSize(remarksText, remarksWidth);

        /* doc.setFont('helvetica', 'normal'); */
        for (let i = 0; i < remarksLines.length; i++) {
          if (y + 5 > doc.internal.pageSize.height) {
            doc.addPage(); // Move to the next page if there's not enough space
            y = 10; // Reset the y-coordinate
            addPageHeader();
          }
          doc.text(remarksLines[i], margin, y);
          y += 5; // Adjust the line spacing as needed
        }
      } else {
        doc.addPage(); // Move to the next page if there's not enough space
        y = 15; // Reset the y-coordinate
        addPageHeader();
        y += 45;
        doc.text("Remarks", margin, y);
        // doc.setFont('helvetica', 'normal');
        y += 5;

        const remarksText = invoice.remarks;
        const remarksWidth = pageWidth - 2 * margin;
        const remainingPageSpace = doc.internal.pageSize.height - y;

        if (remainingPageSpace >= 60) {
          const remarksLines = doc.splitTextToSize(remarksText, remarksWidth);

          for (let i = 0; i < remarksLines.length; i++) {
            if (y + 5 > doc.internal.pageSize.height) {
              doc.addPage(); // Move to the next page if there's not enough space
              y = 10; // Reset the y-coordinate
              addPageHeader();
            }
            doc.text(remarksLines[i], margin, y);
            y += 5; // Adjust the line spacing as needed
          }
        }
      }
    }

    // add the footer from the loacal storeage to the page and style it nicely
    // Calculate center position
    const textWidth =
      (doc.getStringUnitWidth("Bank Details") * 12) / doc.internal.scaleFactor; // Assuming font size is 12
    const centerPos = (doc.internal.pageSize.width - textWidth) / 2;

    // Calculate y position for the bottom
    const bottomMargin = 20; // Adjust as needed
    const yBottom = doc.internal.pageSize.height - bottomMargin + 15;

    if (footer && Object.keys(footer).length > 0) {
      const { bic, iban, bankName, companyName, companyAddress, message } =
        footer;

      // Check if footer title exists
      if (bankName || iban || bic || companyName || companyAddress || message) {
        // Reset y position to bottom
        let y = yBottom;

        // Increase the vertical space
        y -= 20;

        // Set font size and style
        doc.setFontSize(12);
        doc.setFont("helvetica", "normal");

        y += 5;

        // Set font size for details
        doc.setFontSize(10);

        // Construct details in two lines
        let line1 = [];
        let line2 = [];

        // Push non-empty details to respective lines
        if (bankName) line1.push(`Bank Name: ${bankName}`);
        if (iban) line1.push(`IBAN: ${iban}`);
        if (bic) line2.push(`BIC: ${bic}`);
        if (companyName) {
          if (isYiddishOrHebrew(companyName)) {
            doc.setFont("Rubik");
            line2.push(`Company Name: ${companyName}`);
          } else {
            line2.push(`Company Name: ${companyName}`);
          }
        }

        if (companyAddress) line2.push(`Company Address: ${companyAddress}`);
        if (message) {
          if (isYiddishOrHebrew(message)) {
            doc.setFont("Rubik");
            line2.push(`${message.split("").reverse().join("")}`);
          } else {
            line2.push(`Message: ${message}`);
          }
        }

        // Render line 1
        doc.text(line1.join(", "), centerPos, y);
        y += 5;

        // Render line 2 if exists
        if (line2.length > 0) {
          doc.text(line2.join(", "), centerPos, y);
          y += 5;
        }
      }
    }
  });

  console.log("Finished generateReceiptPDF");

  // Save the combined PDF
  // doc.save("combined_invoices.pdf");

  // To print the PDF, uncomment the following lines:
  doc.autoPrint();
  window.open(doc.output("bloburl"), "_blank");
};

export { generateReceiptPDFCombined };
