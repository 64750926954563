import React, { useState, useEffect } from "react";
// import  use navigation react dom
import { Link, useNavigate } from "react-router-dom";
import config from "../../components/config/config";
import styles from "./AdminSettings.module.css";

const AdminSettings = ({ settings, setSettings }) => {
  const navigation = useNavigate();

  const [accountInfo, setAccountInfo] = useState({
    bic: "",
    iban: "",
  });

  const [paymentInfo, setPaymentInfo] = useState({
    beneficiary: "",
    bankAddress: "",
    city: "",
  });

  const [goodsInfo, setGoodsInfo] = useState({
    message: "",
  });

  useEffect(() => {
    localStorage.setItem("showBtw", JSON.stringify(settings?.showBtw));
    localStorage.setItem(
      "tableHeaderBackgroundColor",
      settings?.tableHeaderBackgroundColor
    );
    localStorage.setItem(
      "companyDetails",
      JSON.stringify(settings?.companyDetails || {})
    );
    localStorage.setItem(
      "proformaNumber",
      JSON.stringify(settings?.proformaNumber)
    );
    localStorage.setItem("alertNoQnty", JSON.stringify(settings?.alertNoQnty));
    localStorage.setItem(
      "invoiceNumber",
      JSON.stringify(settings?.invoiceNumber)
    );
    localStorage.setItem(
      "dubbleDiscount",
      JSON.stringify(settings?.dubbleDiscount)
    );
    localStorage.setItem(
      "removeTextForCompanyInInvoiceHeader",
      JSON.stringify(settings?.removeTextForCompanyInInvoiceHeader)
    );
  }, [settings]);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      window.location.href = "/login";
    } else {
      // Fetch settings from the server
      fetchSettings();
    }
  }, []);

  const fetchSettings = async () => {
    try {
      const response = await fetch(`${config.URL_PROD}/settings`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      console.log("message Sent but not yet returned");
      if (response.ok) {
        const responseJson = await response.json();
        // Merge received settings with default settings
        setSettings(...responseJson.data);
      } else {
        console.error("Error fetching settings:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching settings:", error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]:
        type === "checkbox"
          ? checked
          : type === "number"
          ? parseInt(value)
          : value,
      companyDetails: {
        ...prevSettings?.companyDetails,
        [name]: value,
      },
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // merge accountInfo, paymentInfo, goodsInfo with settings

    const updateSettings = {
      ...settings,
      footer: {
        ...accountInfo,
        ...paymentInfo,
        ...goodsInfo,
      },
    };

    console.log("updateSettings", updateSettings);

    //  if it is only an update, then we can use the PUT method
    //  if it is a new record, then we can use the POST method
    if (settings._id) {
      // Update settings
      try {
        console.log(
          "settings",
          settings.invoiceNumber.toString().padStart(3, "0"),
          settings.invoiceNumber.toString()
        );
        const response = await fetch(
          `${config.URL_PROD}/settings/${settings._id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify(updateSettings),
          }
        );
        if (response.ok) {
          const responseJson = await response.json();
          console.log("Settings saved successfully!");
          setSettings(responseJson.data);

          // Redirect to the Invoices page
          navigation("../../Invoices");
        } else {
          console.error("Error saving settings:", response.statusText);
        }
      } catch (error) {
        console.error("Error saving settings:", error);
      }
    } else {
      // Create new settings
      try {
        const response = await fetch(`${config.URL_PROD}/settings`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(settings),
        });
        if (response.ok) {
          const responseJson = await response.json();
          setSettings(responseJson.data);
          console.log("Settings saved successfully!");
          // Redirect to the Invoices page
          navigation("../../Invoices");
        } else {
          console.error("Error saving settings:", response.statusText);
        }
      } catch (error) {
        console.error("Error saving settings:", error);
      }
    }
  };

  return (
    <div className={styles.mainBox}>
      <form
        onSubmit={handleSubmit}
        id="mainSettingsForm"
        className={styles.mainSettingsForm}
      >
        <h2>General settings</h2>
        <label>
          Show BTW:
          <input
            type="checkbox"
            name="showBtw"
            checked={settings?.showBtw || false}
            onChange={handleInputChange}
          />
        </label>
        <label>
          Table Header Background Color:
          <input
            type="color"
            name="tableHeaderBackgroundColor"
            value={settings?.tableHeaderBackgroundColor || "#002c6a"}
            onChange={handleInputChange}
          />
        </label>
        <label>
          Company Name:
          <input
            type="text"
            name="companyName"
            value={settings?.companyDetails?.companyName || ""}
            onChange={handleInputChange}
          />
        </label>
        <label>
          Company Address:
          <input
            type="text"
            name="companyAddress"
            value={settings?.companyDetails?.companyAddress || ""}
            onChange={handleInputChange}
          />
        </label>
        <label>
          Company City:
          <input
            type="text"
            name="companyCity"
            value={settings?.companyDetails?.companyCity || ""}
            onChange={handleInputChange}
          />
        </label>
        <label>
          Company Zip Code:
          <input
            type="text"
            name="companyZipCode"
            value={settings?.companyDetails?.companyZipCode || ""}
            onChange={handleInputChange}
          />
        </label>
        <label>
          Company Country:
          <input
            type="text"
            name="companyCountry"
            value={settings?.companyDetails?.companyCountry || ""}
            onChange={handleInputChange}
          />
        </label>
        <label>
          Company Email:
          <input
            type="email"
            name="companyEmail"
            value={settings?.companyDetails?.companyEmail || ""}
            onChange={handleInputChange}
          />
        </label>
        <label>
          Company Phone:
          <input
            type="tel"
            name="companyPhone"
            value={settings?.companyDetails?.companyPhone || ""}
            onChange={handleInputChange}
          />
        </label>
        <label>
          Company BTW Number:
          <input
            type="text"
            name="companyBtwNumber"
            value={settings?.companyDetails?.companyBtwNumber || ""}
            onChange={handleInputChange}
          />
        </label>
        {/* footer for accountInfo
paymentInfo
goodsInfo*/}
        <h3>Footer</h3>
        <div className={styles.footerBox}>
          <label>
            BIC:
            <input
              type="text"
              name="bic"
              value={accountInfo.bic || settings.footer?.bic}
              onChange={(e) => {
                setAccountInfo({ ...accountInfo, bic: e.target.value });
              }}
            />
          </label>
          <label>
            IBAN:
            <input
              type="text"
              name="iban"
              value={accountInfo.iban || settings.footer?.iban}
              onChange={(e) => {
                setAccountInfo({ ...accountInfo, iban: e.target.value });
              }}
            />
          </label>

          <label>
            Beneficiary:
            <input
              type="text"
              name="beneficiary"
              value={paymentInfo.beneficiary || settings.footer?.beneficiary}
              onChange={(e) => {
                setPaymentInfo({ ...paymentInfo, beneficiary: e.target.value });
              }}
            />
          </label>
          <label>
            Bank Address:
            <input
              type="text"
              name="bankAddress"
              value={paymentInfo.bankAddress || settings.footer?.bankAddress}
              onChange={(e) => {
                setPaymentInfo({ ...paymentInfo, bankAddress: e.target.value });
              }}
            />
          </label>
          <label>
            City:
            <input
              type="text"
              name="city"
              value={paymentInfo.city || settings.footer?.city}
              onChange={(e) => {
                setPaymentInfo({ ...paymentInfo, city: e.target.value });
              }}
            />
          </label>
          <label>
            Message:
            <textarea
              name="message"
              value={goodsInfo.message || " " || settings.footer?.message}
              onChange={(e) => {
                setGoodsInfo({ ...goodsInfo, message: e.target.value });
              }}
            />
          </label>
        </div>
        <label>
          Proforma Number:
          <input
            type="text"
            name="proformaNumber"
            value={settings?.proformaNumber || 0}
            onChange={handleInputChange}
          />
        </label>
        <label>
          Alert No Quantity:
          <input
            type="checkbox"
            name="alertNoQnty"
            checked={settings?.alertNoQnty || false}
            onChange={handleInputChange}
          />
        </label>
        <label>
          Invoice Number:
          <input
            type="text"
            name="invoiceNumber"
            value={settings?.invoiceNumber || 0}
            onChange={
              //remove spaces from the input
              (event) => {
                event.target.value = event.target.value.replace(/\s/g, "");
                handleInputChange(event);
              }
            }
          />
        </label>
        <label>
          Dubble Discount:
          <input
            type="checkbox"
            name="dubbleDiscount"
            checked={settings?.dubbleDiscount || false}
            onChange={handleInputChange}
          />
        </label>
        <label>
          Remove Text For Company In Invoice Header:
          <input
            type="checkbox"
            name="removeTextForCompanyInInvoiceHeader"
            checked={settings?.removeTextForCompanyInInvoiceHeader || false}
            onChange={handleInputChange}
          />
        </label>
        {/*setting to show on packagings the id or name */}
        <label>
          Show Packaging ID: (Instead of the name on the packaging list)
          <input
            type="checkbox"
            name="showIdInsteadOfName"
            checked={settings?.showIdInsteadOfName || false}
            onChange={handleInputChange}
          />
        </label>
        {/* default Percentage to add on the buy price to get the sell price */}
        <label>
          Default Percentage to add on the buy price to get the sell price:
          <input
            type="number"
            name="defaultPercentageToAdd"
            value={settings?.defaultPercentageToAdd || 0}
            onChange={handleInputChange}
          />
          </label>
        <button type="submit">Save</button>
      </form>
    </div>
  );
};

export default AdminSettings;
